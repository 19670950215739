// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

var initialState = {
  show: true,
  timeout: 0,
  retries: 0
};

function reducer(state, action) {
  if (action === "Retry") {
    return {
            show: false,
            timeout: Math.imul(state.retries + 1 | 0, 1000),
            retries: state.retries + 1 | 0
          };
  } else {
    return {
            show: true,
            timeout: state.timeout,
            retries: state.retries
          };
  }
}

function use(param) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var onRetry = function (param) {
    Curry._1(dispatch, "Retry");
  };
  React.useEffect((function (param) {
          if (state.show !== false) {
            return ;
          }
          var id = setTimeout((function (param) {
                  Curry._1(dispatch, "Show");
                }), state.timeout);
          return (function (param) {
                    clearTimeout(id);
                  });
        }), [state.show]);
  return [
          state.show,
          onRetry
        ];
}

export {
  initialState ,
  reducer ,
  use ,
}
/* react Not a pure module */
