// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Bucket$LiteracyplanetTupperware from "../Bucket.res.mjs";

var make = Bucket$LiteracyplanetTupperware.Provider.make;

var $$default = make;

export {
  make ,
  $$default as default,
}
/* Bucket-LiteracyplanetTupperware Not a pure module */
