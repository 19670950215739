import * as Core from '@literacyplanet/client_core';
import {print} from 'graphql/language/printer';

const signIn = () => {
  // iOS
  if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.signInMessageHandler.postMessage({
      param1: 'Signing in',
      param2: '1000'
    });
  }

  // Android
  if (typeof bridge !== 'undefined') {
    bridge.signInMessageHandler('Signing in');
  }
};

let oidc = {
  incr: 0,
  state: 'IDLE',
  user: null,
  check: async () => {
    oidc.incr++;

    if (oidc.state == 'IDLE') {
      oidc.state = 'CHECKING';
      let prevUser = oidc.user;

      // console.log('CHECKING...')
      oidc.user = new Promise(async (resolve, reject) => {
        let user = prevUser
          ? await prevUser
          : await Core.openIdUserManager.getUser();

        if (!process.env.MOBILE) {
          // console.log('user.expires_in', user.expires_in)
          if (user.expires_in < 5) {
            // console.log('RENEWING...')
            try {
              user = await Core.openIdUserManager.signinSilent();
            } catch(e) {
              if (e.error === 'login_required') {
                await Core.openIdUserManager.removeUser();
              } else {
                reject(e);
              }
            } 
          }
        } else {
          if (user.expired) {
            await Core.openIdUserManager.removeUser();
            signIn();
            return;
          }
        }

        // console.log('IDLE')
        resolve(user);

        oidc.state = 'IDLE';
      });
    }

    // console.log('BLOCKING...')
    let user = await oidc.user;
    // console.log('user', user.expires_in, user.access_token);

    return {
      'authorization': user.access_token,
      'X-IdToken': user.id_token
    };
  }
};

export default async function (ast, variables, cb) {
  const url = process.env.GRAPHQL_SERVER + process.env.GRAPHQL_PATH;
  // To test 502's
  // const url = 'https://httpstat.us/504?sleep=5000'

  const headers = await oidc.check();

  const query = print(ast);
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify({
        query,
        variables
      })
    });

    if (!res.ok) {
      return cb({
        error: 'HTTP status ' + res.status
      });
    }

    const payload = await res.json();

    const unauthorised = payload.errors?.find(({message}) => message.includes('Unauthorised'));
    if (unauthorised && process.env.MOBILE) {
      await Core.openIdUserManager.removeUser();
      signIn()
      return;
    }

    return cb({
      ...payload,
      error: payload.errors && payload.errors
        .map(e => e.message)
        .join(', ')
    });

  } catch (e) {
    // Failed to fetch
    // https://sentry.io/answers/failed-to-fetch-javascript/

    // ... or somthing else exploded inside the try block
    return cb({
      error: e.message
    });
  }
};
