// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ShuffleChildren from "./ShuffleChildren";
import ShuffleChildren$1 from "./ShuffleChildren";

var make = ShuffleChildren$1;

function shuffle(prim) {
  return ShuffleChildren.shuffle(prim);
}

var $$default = make;

export {
  make ,
  shuffle ,
  $$default as default,
}
/* make Not a pure module */
