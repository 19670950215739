// Generated by ReScript, PLEASE EDIT WITH CARE


function toDiagReferrerStr(referrer) {
  if (referrer === "IntroVideoReferrer") {
    return "video";
  } else {
    return "missions";
  }
}

function toDiagReferrer(referrer) {
  if (referrer === "video") {
    return "IntroVideoReferrer";
  } else {
    return "JourneyMissionsReferrer";
  }
}

function toIsAssessmentInt(assessment) {
  if (assessment) {
    return 1;
  } else {
    return 0;
  }
}

function toIsAssessment(assessment) {
  return assessment === "1";
}

function classicUrl(path) {
  return process.env.LP_URL + path;
}

function layoutToString(layout) {
  switch (layout) {
    case "AuthWithMenu" :
        return "AuthWithMenu";
    case "AuthWithTitleBar" :
        return "AuthWithTitleBar";
    case "AuthWithTitleBarAndHeader" :
        return "AuthWithTitleBarAndHeader";
    case "DarkBackground" :
        return "DarkBackground";
    case "PlainBackground" :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "PageTypes.res",
                85,
                2
              ],
              Error: new Error()
            };
    case "AuthNoMenu" :
        return "AuthNoMenu";
    
  }
}

function backgroundToString(bg) {
  switch (bg) {
    case "MainBackground" :
        return "MainBackground";
    case "IntrepizineBackground" :
        return "IntrepizineBackground";
    case "VoidBackground" :
        return "VoidBackground";
    case "NoBackground" :
        return "NoBackground";
    
  }
}

function pageToTitle(page) {
  if (typeof page !== "object") {
    if (page === "StudentCollections") {
      return "Collections";
    } else {
      return "LiteracyPlanet";
    }
  } else if (page.TAG === "StudentLibrary") {
    return "Library";
  } else {
    return "LiteracyPlanet";
  }
}

export {
  toDiagReferrerStr ,
  toDiagReferrer ,
  toIsAssessmentInt ,
  toIsAssessment ,
  classicUrl ,
  layoutToString ,
  backgroundToString ,
  pageToTitle ,
}
/* No side effect */
