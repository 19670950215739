// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.res.mjs";
import * as BubbleStyles$LiteracyplanetClientSxEntry from "./BubbleStyles.res.mjs";
import * as JourneyHeaderIcons$LiteracyplanetClientSxEntry from "./JourneyHeaderIcons.res.mjs";
import * as JourneyHeaderStyles$LiteracyplanetClientSxEntry from "./JourneyHeaderStyles.res.mjs";

function BubbleComponents$HeaderPanel(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderPanel.className
            }, props.children);
}

var HeaderPanel = {
  make: BubbleComponents$HeaderPanel
};

function BubbleComponents$HeaderSubPanel(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderSubPanel.className
            }, props.children);
}

var HeaderSubPanel = {
  make: BubbleComponents$HeaderSubPanel
};

function BubbleComponents$HeaderSubSubPanel(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderSubSubPanel.className
            }, props.children);
}

var HeaderSubSubPanel = {
  make: BubbleComponents$HeaderSubSubPanel
};

function BubbleComponents$HeaderBar(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderBar.className
            });
}

var HeaderBar = {
  make: BubbleComponents$HeaderBar
};

function BubbleComponents$HeaderList(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderList.className
            }, props.children);
}

var HeaderList = {
  make: BubbleComponents$HeaderList
};

function BubbleComponents$HeaderItem(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderItem.className(props.large, props.highlightColor),
              onClick: props.onClick
            }, props.children);
}

var HeaderItem = {
  make: BubbleComponents$HeaderItem
};

function BubbleComponents$MissionText(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionText.className
            }, props.children);
}

var MissionText = {
  make: BubbleComponents$MissionText
};

function BubbleComponents$PlanetIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Planet.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var PlanetIcon = {
  make: BubbleComponents$PlanetIcon
};

function BubbleComponents$ChestIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Chest.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var ChestIcon = {
  make: BubbleComponents$ChestIcon
};

function BubbleComponents$AvatarIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Avatar.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var AvatarIcon = {
  make: BubbleComponents$AvatarIcon
};

function BubbleComponents$LetterAIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Lettera.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var LetterAIcon = {
  make: BubbleComponents$LetterAIcon
};

function BubbleComponents$ArcadeIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Arcade.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var ArcadeIcon = {
  make: BubbleComponents$ArcadeIcon
};

function BubbleComponents$BookIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Book.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var BookIcon = {
  make: BubbleComponents$BookIcon
};

function BubbleComponents$MyWordsIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.MyWords.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var MyWordsIcon = {
  make: BubbleComponents$MyWordsIcon
};

function BubbleComponents$MagazineIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Magazine.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var MagazineIcon = {
  make: BubbleComponents$MagazineIcon
};

function BubbleComponents$LibraryIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Library.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var LibraryIcon = {
  make: BubbleComponents$LibraryIcon
};

function BubbleComponents$LockSmallIcon(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.LockSmallIcon.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.LockSmallIcon.className
            });
}

var LockSmallIcon = {
  make: BubbleComponents$LockSmallIcon
};

function BubbleComponents$HeaderLockOverlay(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderLockOverlay.className
            }, React.createElement(BubbleComponents$LockSmallIcon, {}));
}

var HeaderLockOverlay = {
  make: BubbleComponents$HeaderLockOverlay
};

function BubbleComponents$JourneyHeaderItem(props) {
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.planetColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$PlanetIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Journey")
                }));
}

var JourneyHeaderItem = {
  make: BubbleComponents$JourneyHeaderItem
};

function BubbleComponents$CollectionsHeaderItem(props) {
  var __lock = props.lock;
  var lock = __lock !== undefined ? __lock : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.chestColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$ChestIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Collections")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var CollectionsHeaderItem = {
  make: BubbleComponents$CollectionsHeaderItem
};

function BubbleComponents$AvatarHeaderItem(props) {
  var __lock = props.lock;
  var lock = __lock !== undefined ? __lock : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.avatarColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$AvatarIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Shop")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var AvatarHeaderItem = {
  make: BubbleComponents$AvatarHeaderItem
};

function BubbleComponents$IntrepizineHeaderItem(props) {
  var __lock = props.lock;
  var lock = __lock !== undefined ? __lock : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.avatarColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$MagazineIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Intrepizine")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var IntrepizineHeaderItem = {
  make: BubbleComponents$IntrepizineHeaderItem
};

function BubbleComponents$ArcadeHeaderItem(props) {
  var __lock = props.lock;
  var lock = __lock !== undefined ? __lock : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.letteraColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$ArcadeIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Arcade")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var ArcadeHeaderItem = {
  make: BubbleComponents$ArcadeHeaderItem
};

function BubbleComponents$MyWordsHeaderItem(props) {
  var __lock = props.lock;
  var lock = __lock !== undefined ? __lock : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.chestColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$MyWordsIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("My Words")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var MyWordsHeaderItem = {
  make: BubbleComponents$MyWordsHeaderItem
};

function BubbleComponents$DropdownHeaderPanel(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.DropdownHeaderPanel.className
            }, props.children);
}

var DropdownHeaderPanel = {
  make: BubbleComponents$DropdownHeaderPanel
};

function BubbleComponents$DropdownHeaderItem(props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.DropdownHeaderItem.className,
              onClick: props.onClick
            }, props.children);
}

var DropdownHeaderItem = {
  make: BubbleComponents$DropdownHeaderItem
};

function BubbleComponents$BooksDropdownItem(props) {
  return React.createElement(BubbleComponents$DropdownHeaderItem, {
              children: null,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$BookIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Books")
                }));
}

var BooksDropdownItem = {
  make: BubbleComponents$BooksDropdownItem
};

function BubbleComponents$IntrepizineDropdownItem(props) {
  return React.createElement(BubbleComponents$DropdownHeaderItem, {
              children: null,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$MagazineIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Intrepizine")
                }));
}

var IntrepizineDropdownItem = {
  make: BubbleComponents$IntrepizineDropdownItem
};

function BubbleComponents$LibraryClosedHeaderItem(props) {
  var __lock = props.lock;
  var lock = __lock !== undefined ? __lock : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: props.large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.bookColor,
              onClick: props.onClick
            }, React.createElement(BubbleComponents$LibraryIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Library")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var LibraryClosedHeaderItem = {
  make: BubbleComponents$LibraryClosedHeaderItem
};

function BubbleComponents$LibraryOpenDropdown(props) {
  return React.createElement(BubbleComponents$DropdownHeaderPanel, {
              children: null
            }, React.createElement("hr", {
                  className: BubbleStyles$LiteracyplanetClientSxEntry.divider
                }), React.createElement(BubbleComponents$BooksDropdownItem, {
                  onClick: props.onClickLibrary
                }), React.createElement("hr", {
                  className: BubbleStyles$LiteracyplanetClientSxEntry.divider
                }), React.createElement(BubbleComponents$IntrepizineDropdownItem, {
                  onClick: props.onClickIntrepizine
                }));
}

var LibraryOpenDropdown = {
  make: BubbleComponents$LibraryOpenDropdown
};

function BubbleComponents$LibraryHeaderItem(props) {
  var openSubnav = props.openSubnav;
  var closeSubnav = props.closeSubnav;
  var onClickLibrary = props.onClickLibrary;
  var onClickIntrepizine = props.onClickIntrepizine;
  var highlightLibrary = props.highlightLibrary;
  var lockLibrary = props.lockLibrary;
  if (lockLibrary) {
    return React.createElement(BubbleComponents$LibraryClosedHeaderItem, {
                large: highlightLibrary,
                onClick: (function (param) {
                    Curry._1(onClickIntrepizine, undefined);
                  }),
                lock: lockLibrary
              });
  } else if (props.subnavOpen) {
    return React.createElement("div", undefined, React.createElement(BubbleComponents$LibraryClosedHeaderItem, {
                    large: highlightLibrary,
                    onClick: (function (param) {
                        Curry._1(closeSubnav, undefined);
                      }),
                    lock: lockLibrary
                  }), React.createElement(BubbleComponents$LibraryOpenDropdown, {
                    onClickLibrary: (function (param) {
                        Curry._1(onClickLibrary, undefined);
                        Curry._1(closeSubnav, undefined);
                      }),
                    onClickIntrepizine: (function (param) {
                        Curry._1(onClickIntrepizine, undefined);
                        Curry._1(closeSubnav, undefined);
                      })
                  }));
  } else {
    return React.createElement(BubbleComponents$LibraryClosedHeaderItem, {
                large: highlightLibrary,
                onClick: (function (param) {
                    Curry._1(openSubnav, undefined);
                  }),
                lock: lockLibrary
              });
  }
}

var LibraryHeaderItem = {
  make: BubbleComponents$LibraryHeaderItem
};

export {
  HeaderPanel ,
  HeaderSubPanel ,
  HeaderSubSubPanel ,
  HeaderBar ,
  HeaderList ,
  HeaderItem ,
  MissionText ,
  PlanetIcon ,
  ChestIcon ,
  AvatarIcon ,
  LetterAIcon ,
  ArcadeIcon ,
  BookIcon ,
  MyWordsIcon ,
  MagazineIcon ,
  LibraryIcon ,
  LockSmallIcon ,
  HeaderLockOverlay ,
  JourneyHeaderItem ,
  CollectionsHeaderItem ,
  AvatarHeaderItem ,
  IntrepizineHeaderItem ,
  ArcadeHeaderItem ,
  MyWordsHeaderItem ,
  DropdownHeaderPanel ,
  DropdownHeaderItem ,
  BooksDropdownItem ,
  IntrepizineDropdownItem ,
  LibraryClosedHeaderItem ,
  LibraryOpenDropdown ,
  LibraryHeaderItem ,
}
/* react Not a pure module */
