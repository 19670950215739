// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "@rescript/react/src/React.res.mjs";
import * as React$1 from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function Make(S) {
  var context = React$1.createContext(undefined);
  var make = context.Provider;
  var P = {
    make: make
  };
  var ContextMaker$Make$Provider = function (props) {
    return React$1.createElement(make, {
                value: Caml_option.some(props.value),
                children: props.children
              });
  };
  var Provider = {
    make: ContextMaker$Make$Provider
  };
  var ContextMakerProviderNotFound = /* @__PURE__ */Caml_exceptions.create("ContextMaker-LiteracyplanetClientFlags.Make(S).ContextMakerProviderNotFound");
  var use = function (param) {
    var c = React$1.useContext(context);
    try {
      return Belt_Option.getExn(c);
    }
    catch (exn){
      throw {
            RE_EXN_ID: ContextMakerProviderNotFound,
            _1: S.name,
            Error: new Error()
          };
    }
  };
  return {
          initialContext: undefined,
          context: context,
          P: P,
          Provider: Provider,
          ContextMakerProviderNotFound: ContextMakerProviderNotFound,
          use: use
        };
}

export {
  Make ,
}
/* React Not a pure module */
