// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function makeAction(questionIndex, responseOptionId, value) {
  return {
          TAG: "SetQuestionState",
          _0: questionIndex,
          _1: {
            TAG: "SetResponse",
            _0: {
              TAG: "StrResponsePayload",
              _0: {
                TAG: "SingleId",
                _0: responseOptionId
              },
              _1: value
            }
          }
        };
}

function use(questionIndex, responseOptionId) {
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch();
  var dispatch = match[1];
  var onChange = function ($$event) {
    var value = $$event.target.value;
    Curry._1(dispatch, makeAction(questionIndex, responseOptionId, value));
  };
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use();
  var value = Reducer$LiteracyplanetTupperware.getStepResponseValue(Curry._1(match$1.getStep, questionIndex), responseOptionId);
  return [
          onChange,
          value
        ];
}

export {
  makeAction ,
  use ,
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
