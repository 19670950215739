// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";
import * as NoMatch$LiteracyplanetClientSxEntry from "./NoMatch.res.mjs";
import * as UserContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/UserContext.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/LocaleContext.res.mjs";
import * as CobraContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/CobraContainer.res.mjs";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "./BackgroundChanger.res.mjs";
import * as DeepLinkMissionQuery$LiteracyplanetClientSxEntry from "./DeepLinkMissionQuery.res.mjs";
import * as JourneyActivitiesScreen$LiteracyplanetClientSxEntry from "./JourneyActivitiesScreen.res.mjs";
import * as StudentExperienceLayout$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceLayout.res.mjs";
import * as DeepLinkActivityMutation$LiteracyplanetClientSxEntry from "./DeepLinkActivityMutation.res.mjs";

function DeepLinkActivitiesScreen(props) {
  var missionRef = props.missionRef;
  var redirect = props.redirect;
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  var api = LocaleContext$LiteracyplanetClientFlags.use();
  var usersLocale = api.locale;
  var localeString = api.localeString;
  var deeplinkState = DeepLinkMissionQuery$LiteracyplanetClientSxEntry.use(userId, missionRef, localeString);
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use();
  var setBackground = match[1];
  var match$1 = DeepLinkActivityMutation$LiteracyplanetClientSxEntry.use(usersLocale);
  var assignData = match$1[1];
  var assign = match$1[0];
  var match$2 = Error$LiteracyplanetClientErrors.Consumer.use();
  var onSetError = match$2.onSetError;
  var match$3 = Curry._1(StudentExperienceLayout$LiteracyplanetClientSxEntry.Context.use, undefined);
  React.useEffect((function (param) {
          Belt_Option.map(assignData, (function (param) {
                  var match = param.assignMissionSynchronous;
                  Curry._1(redirect, {
                        TAG: "JourneyActivity",
                        _0: api.localeString,
                        _1: api.accentString,
                        _2: match.studentMissionId.toString(),
                        _3: match.studentActivityId.toString(),
                        _4: match.isAssessment
                      });
                }));
        }), [assignData]);
  if (typeof deeplinkState !== "object") {
    if (deeplinkState === "LoadingMission") {
      return null;
    }
    Curry._1(match$3.setLayout, "AuthWithMenu");
    Curry._1(match$3.setBackground, "VoidBackground");
    return React.createElement(NoMatch$LiteracyplanetClientSxEntry.make, {
                children: "Sorry, we can't find any matching activities!"
              });
  } else {
    if (deeplinkState.TAG === "CurrentStudentMission") {
      return React.createElement(JourneyActivitiesScreen$LiteracyplanetClientSxEntry.make, {
                  redirect: redirect,
                  studentMissionId: deeplinkState._0
                });
    }
    var onDispatch = {
      onJourneyActivitySelected: (function (texasActivityRef) {
          return Curry._3(assign, userId, missionRef, texasActivityRef);
        }),
      onExit: (function (param) {
          Curry._1(redirect, "JourneyMissions");
        }),
      onPreloaderReady: (function (c) {
          Curry._1(setBackground, (function (param) {
                  return c.backgroundColor;
                }));
        }),
      onJourneyReady: (function (c) {
          Curry._1(setBackground, (function (param) {
                  return c.backgroundColor;
                }));
        }),
      onFailed: (function (err) {
          Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError("JourneyActivitiesError", err));
        })
    };
    return React.createElement("div", {
                style: match[0]
              }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                    view: "jas",
                    props: deeplinkState._0,
                    onDispatch: onDispatch
                  }));
  }
}

var make = DeepLinkActivitiesScreen;

export {
  make ,
}
/* react Not a pure module */
