// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as PullinIcon$LiteracyplanetTupperware from "./PullinIcon.res.mjs";
import * as PulloutIcon$LiteracyplanetTupperware from "./PulloutIcon.res.mjs";
import * as StimulusTabContext$LiteracyplanetTupperware from "./StimulusTabContext.res.mjs";
import * as StimulusToggleContext$LiteracyplanetTupperware from "./StimulusToggleContext.res.mjs";

function SkilfulStimulusContainer$StimulusHandle(props) {
  var onToggleStimulus = props.onToggleStimulus;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.StimulusHandle.className,
              onClick: (function (param) {
                  Curry._1(onToggleStimulus, undefined);
                })
            }, props.showStimulus ? React.createElement(PullinIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.PullinIcon.className
                  }) : React.createElement(PulloutIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.PulloutIcon.className
                  }));
}

var StimulusHandle = {
  make: SkilfulStimulusContainer$StimulusHandle
};

function SkilfulStimulusContainer$StimulusTab(props) {
  var tab = props.tab;
  var onChangeTab = Curry._1(StimulusTabContext$LiteracyplanetTupperware.useTabLink, undefined);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.StimulusTab.className,
              onClick: (function (param) {
                  Curry._1(onChangeTab, tab);
                })
            }, tab);
}

var StimulusTab = {
  make: SkilfulStimulusContainer$StimulusTab
};

function SkilfulStimulusContainer$StimulusTabSelected(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.StimulusTabSelected.className
            }, props.tab);
}

var StimulusTabSelected = {
  make: SkilfulStimulusContainer$StimulusTabSelected
};

function SkilfulStimulusContainer$StimulusTabs(props) {
  var tabs = props.tabs;
  var isTabSelected = Curry._1(StimulusTabContext$LiteracyplanetTupperware.useIsTabSelected, undefined);
  if (tabs.length <= 1) {
    return null;
  }
  var t = Belt_Array.map(tabs, (function (tab) {
          if (Curry._1(isTabSelected, tab)) {
            return React.createElement(SkilfulStimulusContainer$StimulusTabSelected, {
                        tab: tab
                      });
          } else {
            return React.createElement(SkilfulStimulusContainer$StimulusTab, {
                        tab: tab
                      });
          }
        }));
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.StimulusTabs.className
            }, t);
}

var StimulusTabs = {
  make: SkilfulStimulusContainer$StimulusTabs
};

function SkilfulStimulusContainer$StimulusPanel(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.StimulusPanel.className
            }, props.showStimulus ? React.createElement(SkilfulStimulusContainer$StimulusTabs, {
                    tabs: props.tabs,
                    selected: 0
                  }) : null, props.children);
}

var StimulusPanel = {
  make: SkilfulStimulusContainer$StimulusPanel
};

function SkilfulStimulusContainer$StimulusSidebar(props) {
  var showStimulus = props.showStimulus;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.StimulusSidebar.className(showStimulus)
            }, React.createElement(SkilfulStimulusContainer$StimulusPanel, {
                  showStimulus: showStimulus,
                  tabs: props.tabs,
                  children: showStimulus ? props.children : null
                }), React.createElement(SkilfulStimulusContainer$StimulusHandle, {
                  showStimulus: showStimulus,
                  onToggleStimulus: props.onToggleStimulus
                }));
}

var StimulusSidebar = {
  make: SkilfulStimulusContainer$StimulusSidebar
};

function SkilfulStimulusContainer$StimulusSidebarContainer(props) {
  var tabs = props.tabs;
  return React.createElement(StimulusTabContext$LiteracyplanetTupperware.make, {
              tabs: tabs,
              children: React.createElement(SkilfulStimulusContainer$StimulusSidebar, {
                    tabs: tabs,
                    showStimulus: props.showStimulus,
                    onToggleStimulus: props.onToggleStimulus,
                    children: props.children
                  })
            });
}

var StimulusSidebarContainer = {
  make: SkilfulStimulusContainer$StimulusSidebarContainer
};

function SkilfulStimulusContainer(props) {
  var match = Curry._1(StimulusToggleContext$LiteracyplanetTupperware.useState, undefined);
  return React.createElement(SkilfulStimulusContainer$StimulusSidebarContainer, {
              tabs: props.tabs,
              showStimulus: match[0],
              onToggleStimulus: match[1],
              children: props.children
            });
}

var make = SkilfulStimulusContainer;

var $$default = SkilfulStimulusContainer;

export {
  StimulusHandle ,
  StimulusTab ,
  StimulusTabSelected ,
  StimulusTabs ,
  StimulusPanel ,
  StimulusSidebar ,
  StimulusSidebarContainer ,
  make ,
  $$default as default,
}
/* react Not a pure module */
