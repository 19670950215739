// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Cloze$LiteracyplanetTupperware from "../Cloze.res.mjs";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function findDroppedId(step, id) {
  return Reducer$LiteracyplanetTupperware.mapDropId(Reducer$LiteracyplanetTupperware.mapIdPair(step, (function (param, dragId) {
                    return dragId === id;
                  })));
}

function makeActions(questionIndex, step) {
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch();
  var dispatch = match[1];
  var dropAction = function (drag, drop) {
    if (Reducer$LiteracyplanetTupperware.isMarked(step)) {
      return ;
    } else {
      return Curry._1(dispatch, {
                  TAG: "SetQuestionState",
                  _0: questionIndex,
                  _1: {
                    TAG: "SetResponse",
                    _0: {
                      TAG: "BoolResponsePayload",
                      _0: {
                        TAG: "IdPair",
                        _0: drop,
                        _1: drag
                      }
                    }
                  }
                });
    }
  };
  var undoAction = function (drag, drop) {
    if (Reducer$LiteracyplanetTupperware.isMarked(step)) {
      return ;
    } else {
      return Curry._1(dispatch, {
                  TAG: "DeleteResponseIdentifier",
                  _0: questionIndex,
                  _1: {
                    TAG: "IdPair",
                    _0: drop,
                    _1: drag
                  }
                });
    }
  };
  return [
          dropAction,
          undoAction
        ];
}

function use(questionIndex, id) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, questionIndex);
  var step = Curry._1(match.getStep, questionIndex);
  var droppedId = findDroppedId(step, id);
  var isDropped = Belt_Option.isSome(droppedId);
  var match$1 = makeActions(questionIndex, step);
  var onUndo = match$1[1];
  var undo = function (param) {
    Belt_Option.map(droppedId, (function (drop) {
            Curry._2(onUndo, id, drop);
          }));
  };
  var match$2 = Cloze$LiteracyplanetTupperware.useDrag(id, match$1[0]);
  var marked = Reducer$LiteracyplanetTupperware.getMarkTypeWip(step, id);
  return [
          marked,
          match$2[0],
          isDropped,
          answerVisible,
          droppedId,
          undo,
          match$2[1]
        ];
}

function useGetDomElement(id) {
  var match = React.useState(function (param) {
        
      });
  var setEl = match[1];
  React.useLayoutEffect((function (param) {
          var id$1 = "DropId-" + id;
          var el = document.getElementById(id$1);
          var el$1 = (el == null) ? undefined : Caml_option.some(el);
          Curry._1(setEl, (function (param) {
                  return el$1;
                }));
        }), [id]);
  return match[0];
}

export {
  findDroppedId ,
  makeActions ,
  use ,
  useGetDomElement ,
}
/* react Not a pure module */
