// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

var el = Curry._1(Css.style, {
      hd: Css.display(Css.none),
      tl: /* [] */0
    });

var className = Curry._1(Css.merge, {
      hd: el,
      tl: {
        hd: "Hide",
        tl: /* [] */0
      }
    });

var Hide = {
  el: el,
  className: className
};

var el$1 = Curry._1(Css.style, {
      hd: Css.display(Css.block),
      tl: /* [] */0
    });

var className$1 = Curry._1(Css.merge, {
      hd: el$1,
      tl: {
        hd: "Show",
        tl: /* [] */0
      }
    });

var Show = {
  el: el$1,
  className: className$1
};

function BehaviourQuestionVisible$Visible(props) {
  var className$2 = props.visible ? className$1 : className;
  return React.createElement("div", {
              className: className$2
            }, props.children);
}

var Visible = {
  make: BehaviourQuestionVisible$Visible
};

function BehaviourQuestionVisible(props) {
  var children = props.children;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  if (Curry._1(match.isQuestionVisible, props.questionIndex)) {
    return React.createElement(BehaviourQuestionVisible$Visible, {
                visible: true,
                children: children
              });
  } else {
    return React.createElement(BehaviourQuestionVisible$Visible, {
                visible: false,
                children: children
              });
  }
}

var make = BehaviourQuestionVisible;

var $$default = BehaviourQuestionVisible;

export {
  Hide ,
  Show ,
  Visible ,
  make ,
  $$default as default,
}
/* el Not a pure module */
