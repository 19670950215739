// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as ReactPdf from "react-pdf";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as BookContext$LiteracyplanetTupperware from "./BookContext.res.mjs";
import * as BookSpinner$LiteracyplanetTupperware from "./BookSpinner.res.mjs";
import * as ArrowLeftIcon$LiteracyplanetTupperware from "./ArrowLeftIcon.res.mjs";
import * as NextPageButton$LiteracyplanetTupperware from "./NextPageButton.res.mjs";
import * as OpenBookButton$LiteracyplanetTupperware from "./OpenBookButton.res.mjs";
import * as PrevPageButton$LiteracyplanetTupperware from "./PrevPageButton.res.mjs";
import * as CloseBookButton$LiteracyplanetTupperware from "./CloseBookButton.res.mjs";
import * as FullScreenButton$LiteracyplanetTupperware from "./FullScreenButton.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as BehaviourExitButton$LiteracyplanetTupperware from "./BehaviourExitButton.res.mjs";
import * as PlayBookAudioButton$LiteracyplanetTupperware from "./PlayBookAudioButton.res.mjs";
import * as ContainerDimensions$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContainerDimensions.res.mjs";
import * as ClickWordToHearContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ClickWordToHearContainer.res.mjs";

function K2BookQuestions$Question(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.Question.className
            }, props.children);
}

var Question = {
  make: K2BookQuestions$Question
};

function K2BookQuestions$QuestionContent(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.QuestionContent.className
            }, props.children);
}

var QuestionContent = {
  make: K2BookQuestions$QuestionContent
};

function K2BookQuestions$NoAudioButton(props) {
  return React.createElement("div", undefined);
}

var NoAudioButton = {
  make: K2BookQuestions$NoAudioButton
};

function usePageLinks(pages, onClickBack, page, changePage) {
  var match = React.useState(function (param) {
        return 0;
      });
  var setNumPages = match[1];
  var numPages = match[0];
  var previousButton = page > 0 ? React.createElement(PrevPageButton$LiteracyplanetTupperware.make, {
          onClick: (function (param) {
              Curry._1(changePage, -1);
            })
        }) : null;
  var nextButton = page === (numPages - 1 | 0) ? React.createElement(NextPageButton$LiteracyplanetTupperware.make, {
          finishedBook: true,
          onClick: (function (param) {
              Curry._1(onClickBack, undefined);
            })
        }) : (
      page < (numPages - 1 | 0) ? React.createElement(NextPageButton$LiteracyplanetTupperware.make, {
              finishedBook: false,
              onClick: (function (param) {
                  Curry._1(changePage, 1);
                })
            }) : null
    );
  var src = Belt_Option.flatMap(Js_array.find((function (x) {
              return x.pageIndex === page;
            }), pages), (function (param) {
          return param.mp3;
        }));
  var playButton = src !== undefined ? React.createElement(PlayBookAudioButton$LiteracyplanetTupperware.make, {
          src: src
        }) : React.createElement(K2BookQuestions$NoAudioButton, {});
  var onDocumentLoadSuccess = function (success) {
    Curry._1(setNumPages, (function (param) {
            return success.numPages;
          }));
    Curry._1(changePage, 0);
  };
  var pageNumber = page + 1 | 0;
  return [
          previousButton,
          nextButton,
          playButton,
          pageNumber,
          onDocumentLoadSuccess
        ];
}

function K2BookQuestions$BookPdf(props) {
  var book = props.book;
  var match = usePageLinks(book.pages, props.onClickBack, props.page, props.changePage);
  return React.createElement(React.Fragment, {}, match[0], React.createElement("div", {
                  className: Flavor$LiteracyplanetTupperware.K2BookQuestions.BookPdf.className
                }, React.createElement(ReactPdf.Document, {
                      file: book.pdf,
                      onLoadSuccess: match[4],
                      loading: (function (param) {
                          return React.createElement(BookSpinner$LiteracyplanetTupperware.make, {});
                        }),
                      children: Caml_option.some(React.createElement(ClickWordToHearContainer$LiteracyplanetClientFlags.make, {
                                children: React.createElement(ReactPdf.Page, {
                                      pageNumber: match[3],
                                      height: props.height,
                                      width: undefined,
                                      renderMode: "canvas",
                                      renderTextLayer: true
                                    })
                              }))
                    })), match[1], match[2]);
}

var BookPdf = {
  usePageLinks: usePageLinks,
  make: K2BookQuestions$BookPdf
};

function K2BookQuestions$AutoSizingBookPdf(props) {
  var match = ContainerDimensions$LiteracyplanetClientFlags.useWidth();
  var width = match[1];
  var pdf = width !== undefined ? React.createElement(K2BookQuestions$BookPdf, {
          onClickBack: props.onClickBack,
          height: width | 0,
          book: props.book,
          page: props.page,
          changePage: props.changePage
        }) : null;
  return React.createElement("div", {
              ref: Caml_option.some(match[0]),
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.AutoSizingBookPdf.className
            }, pdf);
}

var AutoSizingBookPdf = {
  make: K2BookQuestions$AutoSizingBookPdf
};

function K2BookQuestions$BookContent(props) {
  return React.createElement("div", {
              className: Curry._2(Flavor$LiteracyplanetTupperware.K2BookQuestions.BookContent.className, props.w, props.h)
            }, React.createElement(K2BookQuestions$AutoSizingBookPdf, {
                  onClickBack: props.onClickBack,
                  book: props.book,
                  page: props.page,
                  changePage: props.changePage
                }));
}

var BookContent = {
  make: K2BookQuestions$BookContent
};

function K2BookQuestions$QuestionContentStyling(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.QuestionContentStyling.className
            }, props.children);
}

var QuestionContentStyling = {
  make: K2BookQuestions$QuestionContentStyling
};

function K2BookQuestions$BehaviourTabs(props) {
  var toggleFullscreen = props.toggleFullscreen;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var onSetQuestionTab = match.onSetQuestionTab;
  if (match.questionTab === "QuestionTab") {
    return React.createElement(React.Fragment, {}, React.createElement(OpenBookButton$LiteracyplanetTupperware.make, {
                    onClick: (function (param) {
                        Curry._1(onSetQuestionTab, "BookStimulusTab");
                      })
                  }), React.createElement(K2BookQuestions$QuestionContentStyling, {
                    children: React.createElement(K2BookQuestions$QuestionContent, {
                          children: props.children
                        })
                  }));
  } else {
    return React.createElement(React.Fragment, {}, React.createElement(FullScreenButton$LiteracyplanetTupperware.make, {
                    onClick: (function (param) {
                        Curry._1(toggleFullscreen, undefined);
                      })
                  }), React.createElement(K2BookQuestions$QuestionContentStyling, {
                    children: React.createElement("div", {
                          className: Flavor$LiteracyplanetTupperware.K2BookQuestions.BehaviourTabs.className
                        }, React.createElement(K2BookQuestions$BookContent, {
                              onClickBack: (function (param) {
                                  Curry._1(onSetQuestionTab, "QuestionTab");
                                }),
                              book: props.book,
                              page: props.page,
                              changePage: props.changePage,
                              w: 480,
                              h: 560
                            }))
                  }), React.createElement(CloseBookButton$LiteracyplanetTupperware.make, {
                    onClick: (function (param) {
                        Curry._1(onSetQuestionTab, "QuestionTab");
                      })
                  }));
  }
}

var BehaviourTabs = {
  make: K2BookQuestions$BehaviourTabs
};

function K2BookQuestions$MainNav(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.MainNav.className
            }, React.createElement(BehaviourExitButton$LiteracyplanetTupperware.make, {
                  children: React.createElement(ArrowLeftIcon$LiteracyplanetTupperware.make, {
                        className: Flavor$LiteracyplanetTupperware.K2BookQuestions.ArrowLeftIcon.className
                      })
                }));
}

var MainNav = {
  make: K2BookQuestions$MainNav
};

function K2BookQuestions$QuestionsTab(props) {
  return React.createElement(K2BookQuestions$Question, {
              children: null
            }, React.createElement(K2BookQuestions$MainNav, {}), React.createElement(K2BookQuestions$BehaviourTabs, {
                  book: props.book,
                  page: props.page,
                  changePage: props.changePage,
                  toggleFullscreen: props.toggleFullscreen,
                  children: props.children
                }));
}

var QuestionsTab = {
  make: K2BookQuestions$QuestionsTab
};

function K2BookQuestions$NoStimuliQuestionsTab(props) {
  return React.createElement(K2BookQuestions$Question, {
              children: null
            }, React.createElement(K2BookQuestions$MainNav, {}), React.createElement(K2BookQuestions$QuestionContentStyling, {
                  children: React.createElement(K2BookQuestions$QuestionContent, {
                        children: props.children
                      })
                }));
}

var NoStimuliQuestionsTab = {
  make: K2BookQuestions$NoStimuliQuestionsTab
};

function K2BookQuestions(props) {
  var children = props.children;
  return React.createElement(BookContext$LiteracyplanetTupperware.Consumer.make, {
              children: (function (book, page, changePage, toggleFullscreen, stimuliPresent) {
                  if (stimuliPresent) {
                    if (book !== undefined) {
                      return React.createElement(K2BookQuestions$QuestionsTab, {
                                  page: page,
                                  changePage: changePage,
                                  book: book,
                                  toggleFullscreen: toggleFullscreen,
                                  children: children
                                });
                    } else {
                      return null;
                    }
                  } else {
                    return React.createElement(K2BookQuestions$NoStimuliQuestionsTab, {
                                children: children
                              });
                  }
                })
            });
}

var make = K2BookQuestions;

var $$default = K2BookQuestions;

export {
  Question ,
  QuestionContent ,
  NoAudioButton ,
  BookPdf ,
  AutoSizingBookPdf ,
  BookContent ,
  QuestionContentStyling ,
  BehaviourTabs ,
  MainNav ,
  QuestionsTab ,
  NoStimuliQuestionsTab ,
  make ,
  $$default as default,
}
/* react Not a pure module */
