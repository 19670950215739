// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";
import * as Option2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Option2.res.mjs";
import * as Result2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Result2.res.mjs";
import * as MapResult$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/MapResult.res.mjs";
import * as Md5$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/Md5.res.mjs";
import * as HasuraTypes$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/HasuraTypes.res.mjs";
import * as Flipper$LiteracyplanetClientSxActivity from "./Flipper.res.mjs";
import * as InMemoryStore$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/InMemoryStore.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/LocaleContext.res.mjs";
import * as FlipperStore$LiteracyplanetClientSxActivity from "./FlipperStore.res.mjs";
import * as AvatarQuery$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/AvatarQuery.res.mjs";
import * as FlipperTypes$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/FlipperTypes.res.mjs";
import * as FlipperStoreTypes$LiteracyplanetClientSxActivity from "./FlipperStoreTypes.res.mjs";
import * as BehaviourTestActivityPage$LiteracyplanetTupperware from "@literacyplanet/tupperware/src/tupperware/v2/BehaviourTestActivityPage.res.mjs";
import * as Flipper_GameContent$LiteracyplanetClientSxActivity from "./Flipper_GameContent.res.mjs";
import * as ChecksActivityQuery$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/ChecksActivityQuery.res.mjs";
import * as CobraPersistedState$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/CobraPersistedState.res.mjs";
import * as FlipperCobraCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraCallbacks.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "@literacyplanet/tupperware/src/tupperware/v2/V2ExerciseStateStoreContext.res.mjs";
import * as CobraQuestionResponse$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/CobraQuestionResponse.res.mjs";
import * as FlipperMutationApiAsync$LiteracyplanetClientSxActivity from "./FlipperMutationApiAsync.res.mjs";
import * as ChecksActivityMutations$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/ChecksActivityMutations.res.mjs";
import * as DeleteStudentQuestionResponses$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/DeleteStudentQuestionResponses.res.mjs";
import * as StudentResponseMutation$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/StudentResponseMutation.res.mjs";
import * as FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity from "./FlipperTupperwareCallbacks.res.mjs";
import * as FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraResultsCallbacks.res.mjs";
import * as TupperwareContentQueryParser$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/TupperwareContentQueryParser.res.mjs";

var FlipperChecksCantFindActivity = /* @__PURE__ */Caml_exceptions.create("FlipperChecksActivityLauncher-LiteracyplanetClientSxActivity.FlipperChecksCantFindActivity");

var FlipperChecksCantFindGameId = /* @__PURE__ */Caml_exceptions.create("FlipperChecksActivityLauncher-LiteracyplanetClientSxActivity.FlipperChecksCantFindGameId");

function parseActivities(res) {
  return Belt_Array.get(res.literacy_checks_outstanding_activities, 0);
}

function getActivity(res) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(Belt_Option.map(Belt_Array.get(res.literacy_checks_outstanding_activities, 0), (function (a) {
                    return a.content;
                  })), {
              RE_EXN_ID: FlipperChecksCantFindActivity
            });
}

function getGameId(res) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(Belt_Option.flatMap(Belt_Array.get(res.literacy_checks_outstanding_activities, 0), (function (a) {
                    return a.gameId;
                  })), {
              RE_EXN_ID: FlipperChecksCantFindGameId
            });
}

function getLatestResponse(attempt) {
  return Belt_Option.flatMap(attempt, (function (attempt) {
                return Belt_Array.get(attempt.responses, 0);
              }));
}

function getLatestQuestionIndex(attempt) {
  return Belt_Option.getWithDefault(Belt_Option.map(getLatestResponse(attempt), (function (response) {
                    return response.questionIndex + 1 | 0;
                  })), 0);
}

function getNumCorrect(attempt) {
  return Belt_Option.getWithDefault(Belt_Option.map(attempt, (function (attempt) {
                    return Belt_Array.reduce(attempt.responses, 0, (function (acc, res) {
                                  return acc + res.points | 0;
                                }));
                  })), 0);
}

function getAttemptId(attempt) {
  return Belt_Option.map(attempt, (function (a) {
                return HasuraTypes$LiteracyplanetClientFlags.decode(a.id);
              }));
}

function getPersistedState(state) {
  return Belt_Option.map(state, (function (s) {
                return s.state;
              }));
}

function getContentMd5(state) {
  return Belt_Option.flatMap(state, (function (s) {
                return s.contentMd5;
              }));
}

function useExitOnCompleteHook(param) {
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function (param) {
          if (state.loadResults) {
            Curry._1(dispatch, "Exit");
          }
          
        }), [state.loadResults]);
}

function useMutations(activityId, assignationId, gameId) {
  var match = ChecksActivityMutations$LiteracyplanetClientLibsActivity.useInsertAttempt(assignationId, gameId, activityId);
  var match$1 = ChecksActivityMutations$LiteracyplanetClientLibsActivity.useMarkComplete(assignationId);
  useExitOnCompleteHook();
  return [
          match[0],
          match[1],
          match$1[0],
          match$1[1]
        ];
}

function use(questionPasseMutation, questionFailedMutation, activityCompletedMutation, dispatch) {
  return FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity.wrapApiWithStoreEvents(FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity.api, dispatch, (function (param) {
                return Curry._1(activityCompletedMutation, undefined);
              }), undefined, undefined, (function (param, param$1) {
                console.log("exit on outside");
              }), (function (question, state) {
                return Curry._3(questionFailedMutation, question, state, undefined);
              }), (function (question, state) {
                return Curry._3(questionPasseMutation, question, state, undefined);
              }), undefined, undefined);
}

function FlipperChecksActivityLauncher$TupperwareCallbacks(props) {
  var lc = props.lc;
  var match = FlipperStore$LiteracyplanetClientSxActivity.Context.initaliseState(false, TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.highestAnsweredQuestion(lc.state), lc.numCorrect);
  var activityDispachables = use(props.questionPasseMutation, props.questionFailedMutation, props.activityCompletedMutation, match[1]);
  var resultDispatchables = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.useResultsDispatch();
  return React.createElement(FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
              dispachables: activityDispachables,
              children: React.createElement(FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
                    dispachables: resultDispatchables,
                    children: React.createElement(V2ExerciseStateStoreContext$LiteracyplanetTupperware.Provider.make, {
                          children: React.createElement(BehaviourTestActivityPage$LiteracyplanetTupperware.make, {
                                persistedState: lc.state,
                                assessment: lc.assessment,
                                questionIndex: lc.questionIndex,
                                children: props.children
                              })
                        })
                  })
            });
}

var TupperwareCallbacks = {
  use: use,
  make: FlipperChecksActivityLauncher$TupperwareCallbacks
};

function use$1(persistedState, md5, questionPasseMutation, questionFailedMutation, activityCompletedMutation, dispatch) {
  var match = CobraPersistedState$LiteracyplanetClientLibsActivity.use(persistedState, Md5$LiteracyplanetClientLibsActivity.make(md5));
  var collectResponses = match.collectResponses;
  var match$1 = Error$LiteracyplanetClientErrors.useErrorModal();
  var onSetError = match$1.onSetError;
  return FlipperCobraCallbacks$LiteracyplanetClientSxActivity.wrapApiWithStoreEvents(FlipperTypes$LiteracyplanetClientLibsActivity.Cobra.api, dispatch, activityCompletedMutation, undefined, undefined, undefined, undefined, undefined, undefined, (function (err) {
                Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(undefined, err));
              }), (function (response) {
                var optState = Curry._1(collectResponses, response);
                CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.mapQuestionIndexAndAnswers(response, (function (questionIndex, answers) {
                        Curry._3(questionFailedMutation, questionIndex, optState, answers);
                      }));
              }), (function (response) {
                var optState = Curry._1(collectResponses, response);
                CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.mapQuestionIndexAndAnswers(response, (function (questionIndex, answers) {
                        Curry._3(questionPasseMutation, questionIndex, optState, answers);
                      }));
              }), undefined, undefined, undefined, undefined);
}

function FlipperChecksActivityLauncher$CobraCallbacks(props) {
  var lc = props.lc;
  var match = FlipperStore$LiteracyplanetClientSxActivity.Context.initaliseState(false, lc.numComplete, lc.numCorrect);
  var activityDispachables = use$1(lc.state, lc.contentMd5, props.questionPasseMutation, props.questionFailedMutation, props.activityCompletedMutation, match[1]);
  var resultDispatchables = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.useResultsDispatch();
  return React.createElement(FlipperCobraCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
              dispachables: activityDispachables,
              children: React.createElement(FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
                    dispachables: resultDispatchables,
                    children: props.children
                  })
            });
}

var CobraCallbacks = {
  use: use$1,
  make: FlipperChecksActivityLauncher$CobraCallbacks
};

function setFinishedActivityOnLoad(param) {
  var setInMemory = InMemoryStore$LiteracyplanetClientFlags.useSetter();
  React.useEffect((function (param) {
          Curry._2(setInMemory, "ranLcActivity", "true");
        }), []);
}

function FlipperChecksActivityLauncher$Mutations(props) {
  var lc = props.lc;
  setFinishedActivityOnLoad();
  var existingAttemptId = lc.studentActivityAttemptId;
  var match = useMutations(props.activityId, props.assignationId, props.gameId);
  var match$1 = StudentResponseMutation$LiteracyplanetClientLibsActivity.use();
  var match$2 = FlipperMutationApiAsync$LiteracyplanetClientSxActivity.useMutationApi(lc.contentMd5, match[0], match[1], existingAttemptId, match[2], match$1[0], match$1[1], match[3]);
  return Curry._3(props.children, match$2[0], match$2[1], match$2[2]);
}

var Mutations = {
  make: FlipperChecksActivityLauncher$Mutations
};

function FlipperChecksActivityLauncher$LocaleAndMutations(props) {
  var children = props.children;
  var accent = props.accent;
  var locale = props.locale;
  var render = function (questionPasseMutation, questionFailedMutation, activityCompletedMutation) {
    return React.createElement(LocaleContext$LiteracyplanetClientFlags.make, {
                value: {
                  locale: LocaleContext$LiteracyplanetClientFlags.fromLocaleString(locale),
                  accent: accent,
                  localeString: locale,
                  accentString: LocaleContext$LiteracyplanetClientFlags.toAccentString(accent)
                },
                children: Curry._3(children, questionPasseMutation, questionFailedMutation, activityCompletedMutation)
              });
  };
  return React.createElement(FlipperMutationApiAsync$LiteracyplanetClientSxActivity.Later.make, {
              children: React.createElement(FlipperChecksActivityLauncher$Mutations, {
                    lc: props.lc,
                    activityId: props.activityId,
                    assignationId: props.assignationId,
                    gameId: props.gameId,
                    children: render
                  })
            });
}

var LocaleAndMutations = {
  make: FlipperChecksActivityLauncher$LocaleAndMutations
};

function parseLiteracyCheckActivity(res) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(Belt_Option.flatMap(Belt_Array.get(res.literacy_checks_outstanding_activities, 0), (function (a) {
                    return Option2$LiteracyplanetClientFlags.combine7(a.locale, a.accent, a.activityName, Caml_option.some(a.attempt), Caml_option.some(a.state), a.assignationId, a.activityId);
                  })), {
              RE_EXN_ID: FlipperChecksCantFindGameId
            });
}

function makeNewLc(content, state, attempt) {
  return {
          studentActivityAttemptId: getAttemptId(attempt),
          contentMd5: Flipper_GameContent$LiteracyplanetClientSxActivity.getMd5(content),
          attemptMd5: Belt_Option.flatMap(state, (function (s) {
                  return s.contentMd5;
                })),
          state: Belt_Option.map(state, (function (s) {
                  return s.state;
                })),
          assessment: false,
          questionIndex: getLatestQuestionIndex(attempt),
          numComplete: getLatestQuestionIndex(attempt),
          numCorrect: getNumCorrect(attempt),
          batchQuestions: Flipper$LiteracyplanetClientSxActivity.Tupperware.isSkilfulLayout(content)
        };
}

function FlipperChecksActivityLauncher$FixVersionConflict(props) {
  var attempt = props.attempt;
  var content = props.content;
  var state = props.state;
  var match = React.useState(function (param) {
        return makeNewLc(content, state, attempt);
      });
  var setLc = match[1];
  var lc = match[0];
  var match$1 = React.useState(function (param) {
        if (Belt_Option.isSome(lc.contentMd5) && Belt_Option.isSome(lc.attemptMd5) && Caml_obj.notequal(lc.contentMd5, lc.attemptMd5)) {
          return true;
        } else if (Belt_Option.isSome(lc.contentMd5) && Belt_Option.isNone(lc.attemptMd5)) {
          if (lc.numComplete > 0 || lc.questionIndex > 0) {
            return true;
          } else {
            return Belt_Option.mapWithDefault(lc.state, false, (function (s) {
                          return s !== "";
                        }));
          }
        } else {
          return false;
        }
      });
  var setConflict = match$1[1];
  var conflict = match$1[0];
  var match$2 = DeleteStudentQuestionResponses$LiteracyplanetClientFlags.use();
  var newAttempt = match$2[2];
  var run = match$2[0];
  React.useEffect((function (param) {
          if (conflict) {
            Belt_Option.map(lc.studentActivityAttemptId, Curry.__1(run));
          }
          
        }), [conflict]);
  React.useEffect((function (param) {
          if (newAttempt !== undefined) {
            Curry._1(setLc, (function (lc) {
                    return {
                            studentActivityAttemptId: lc.studentActivityAttemptId,
                            contentMd5: lc.contentMd5,
                            attemptMd5: undefined,
                            state: undefined,
                            assessment: lc.assessment,
                            questionIndex: 0,
                            numComplete: 0,
                            numCorrect: 0,
                            batchQuestions: lc.batchQuestions
                          };
                  }));
            Curry._1(setConflict, (function (param) {
                    return false;
                  }));
          }
          
        }), [match$2[1]]);
  if (conflict) {
    return React.createElement("div", undefined, "This content has been updated, and there are previous responses to an earlier version.");
  } else {
    return Curry._1(props.children, lc);
  }
}

var FixVersionConflict = {
  makeNewLc: makeNewLc,
  make: FlipperChecksActivityLauncher$FixVersionConflict
};

function FlipperChecksActivityLauncher$Render(props) {
  var query = props.query;
  var game = props.game;
  var content = props.content;
  return React.createElement(AvatarQuery$LiteracyplanetClientLibsActivity.make, {
              game: game,
              children: React.createElement(MapResult$LiteracyplanetClientFlags.make, {
                    result: parseLiteracyCheckActivity(query),
                    onOk: (function (param) {
                        var activityId = param[6];
                        var assignationId = param[5];
                        var title = param[2];
                        var accent = param[1];
                        var locale = param[0];
                        return React.createElement(FlipperChecksActivityLauncher$FixVersionConflict, {
                                    state: param[4],
                                    content: content,
                                    attempt: param[3],
                                    children: (function (lc) {
                                        return React.createElement(FlipperChecksActivityLauncher$LocaleAndMutations, {
                                                    locale: locale,
                                                    accent: accent,
                                                    lc: lc,
                                                    activityId: activityId,
                                                    assignationId: assignationId,
                                                    gameId: game.id,
                                                    batchQuestions: lc.batchQuestions,
                                                    children: (function (questionPasseMutation, questionFailedMutation, activityCompletedMutation) {
                                                        var component = React.createElement(Flipper_GameContent$LiteracyplanetClientSxActivity.RenderGameComponent.make, {
                                                              hasResultsScreen: false,
                                                              getTitle: (function (param) {
                                                                  return title;
                                                                }),
                                                              game: game,
                                                              content: content,
                                                              query: query
                                                            });
                                                        var match = game.activityType;
                                                        if (typeof match !== "object") {
                                                          if (match === "InstructionalVideo") {
                                                            return null;
                                                          }
                                                          
                                                        } else if (match.TAG === "CobraActivity") {
                                                          return React.createElement(FlipperChecksActivityLauncher$CobraCallbacks, {
                                                                      lc: lc,
                                                                      questionPasseMutation: questionPasseMutation,
                                                                      questionFailedMutation: questionFailedMutation,
                                                                      activityCompletedMutation: activityCompletedMutation,
                                                                      children: component
                                                                    });
                                                        }
                                                        return React.createElement(FlipperChecksActivityLauncher$TupperwareCallbacks, {
                                                                    questionPasseMutation: questionPasseMutation,
                                                                    questionFailedMutation: questionFailedMutation,
                                                                    activityCompletedMutation: activityCompletedMutation,
                                                                    lc: lc,
                                                                    children: component
                                                                  });
                                                      })
                                                  });
                                      })
                                  });
                      })
                  })
            });
}

var Render = {
  make: FlipperChecksActivityLauncher$Render
};

function FlipperChecksActivityLauncher$MarkCompleteWhenAllComplete(props) {
  var studentActivityAttemptId = props.studentActivityAttemptId;
  var match = ChecksActivityMutations$LiteracyplanetClientLibsActivity.useMarkComplete(props.assignationId);
  var updateActivityCompleted = match[0];
  React.useEffect((function (param) {
          Curry._1(updateActivityCompleted, studentActivityAttemptId);
        }), []);
  return null;
}

var MarkCompleteWhenAllComplete = {
  make: FlipperChecksActivityLauncher$MarkCompleteWhenAllComplete
};

function FlipperChecksActivityLauncher$CallError(props) {
  var e = props.e;
  var onBack = props.onBack;
  React.useEffect((function (param) {
          console.log(e);
          Curry._1(onBack, undefined);
        }), []);
  return null;
}

var CallError = {
  make: FlipperChecksActivityLauncher$CallError
};

function FlipperChecksActivityLauncher(props) {
  var onBack = props.onBack;
  var queryState = ChecksActivityQuery$LiteracyplanetClientLibsActivity.use();
  var onTryBack = FlipperMutationApiAsync$LiteracyplanetClientSxActivity.useTryBack(onBack);
  var onError = function (e) {
    return React.createElement(FlipperChecksActivityLauncher$CallError, {
                onBack: onBack,
                e: e
              });
  };
  return React.createElement(FlipperStore$LiteracyplanetClientSxActivity.ProviderWithExit.make, {
              onExit: onTryBack,
              children: React.createElement(Flipper$LiteracyplanetClientSxActivity.Container.make, {
                    onError: onError,
                    getActivity: getActivity,
                    getGameId: getGameId,
                    queryState: queryState,
                    onOk: (function (game, content, query) {
                        return React.createElement(FlipperChecksActivityLauncher$Render, {
                                    content: content,
                                    game: game,
                                    query: query
                                  });
                      })
                  })
            });
}

var decode = HasuraTypes$LiteracyplanetClientFlags.decode;

var make = FlipperChecksActivityLauncher;

export {
  FlipperChecksCantFindActivity ,
  FlipperChecksCantFindGameId ,
  decode ,
  parseActivities ,
  getActivity ,
  getGameId ,
  getLatestResponse ,
  getLatestQuestionIndex ,
  getNumCorrect ,
  getAttemptId ,
  getPersistedState ,
  getContentMd5 ,
  useExitOnCompleteHook ,
  useMutations ,
  TupperwareCallbacks ,
  CobraCallbacks ,
  setFinishedActivityOnLoad ,
  Mutations ,
  LocaleAndMutations ,
  parseLiteracyCheckActivity ,
  FixVersionConflict ,
  Render ,
  MarkCompleteWhenAllComplete ,
  CallError ,
  make ,
}
/* react Not a pure module */
