// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as NextIcon$LiteracyplanetTupperware from "./NextIcon.res.mjs";
import * as CloseBookIcon$LiteracyplanetTupperware from "./CloseBookIcon.res.mjs";

function NextPageButton(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.NextPageButton.className,
              onClick: props.onClick
            }, props.finishedBook ? React.createElement(CloseBookIcon$LiteracyplanetTupperware.make, {
                    className: Flavor$LiteracyplanetTupperware.CloseBookIcon.className
                  }) : React.createElement(NextIcon$LiteracyplanetTupperware.make, {
                    className: Flavor$LiteracyplanetTupperware.NextPageButton.iconStyle
                  }));
}

var make = NextPageButton;

export {
  make ,
}
/* react Not a pure module */
