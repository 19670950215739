// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import Nav_barSvg from "./assets/nav_bar.svg";
import Menu_buttonSvg from "./assets/menu_button.svg";
import Button_logoutSvg from "./assets/button_logout.svg";
import Icon_nav_levelSvg from "./assets/icon_nav_level.svg";
import Icon_nav_journeySvg from "./assets/icon_nav_journey.svg";
import Icon_nav_librarySvg from "./assets/icon_nav_library.svg";
import Student_overviewSvg from "./assets/student_overview.svg";
import Icon_nav_word_listSvg from "./assets/icon_nav_word_list.svg";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";
import Button_logout_hoverSvg from "./assets/button_logout_hover.svg";
import Icon_nav_collectionSvg from "./assets/icon_nav_collection.svg";
import Icon_nav_word_maniaSvg from "./assets/icon_nav_word_mania.svg";
import Button_logout_activeSvg from "./assets/button_logout_active.svg";
import Icon_nav_intrepizineSvg from "./assets/icon_nav_intrepizine.svg";
import RightToBracketSolidSvg from "./assets/right-to-bracket-solid.svg";
import * as CssUtils$LiteracyplanetClientSxEntry from "./CssUtils.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";
import * as ForestComponents$LiteracyplanetClientSxEntry from "./ForestComponents.res.mjs";
import * as StoryversePanelContext$LiteracyplanetClientSxEntry from "../StoryversePanelContext.res.mjs";

var navBar = Nav_barSvg;

var menuButton = Menu_buttonSvg;

var journeyIcon = Icon_nav_journeySvg;

var libraryIcon = Icon_nav_librarySvg;

var listIcon = Icon_nav_word_listSvg;

var intrepizineIcon = Icon_nav_intrepizineSvg;

var levelIcon = Icon_nav_levelSvg;

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        76
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: /* [] */0
      }
    });

var css = Curry._1(Css.style, {
      hd: Css.borderColor(Css.red),
      tl: {
        hd: Css.borderWidth(Css.px(1)),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.backgroundImage(Css.url(journeyIcon)),
            tl: {
              hd: Css.backgroundSize(Css.cover),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.hover({
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: Css.borderColor(Css.blue),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: r,
        tl: {
          hd: "JourneyIcon",
          tl: /* [] */0
        }
      }
    });

var JourneyIcon = {
  r: r,
  css: css,
  className: className
};

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        76
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: /* [] */0
      }
    });

var css$1 = Curry._1(Css.style, {
      hd: Css.borderColor(Css.red),
      tl: {
        hd: Css.borderWidth(Css.px(1)),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.backgroundImage(Css.url(libraryIcon)),
            tl: {
              hd: Css.backgroundSize(Css.cover),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.hover({
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: Css.borderColor(Css.blue),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: r$1,
        tl: {
          hd: "LibraryIcon",
          tl: /* [] */0
        }
      }
    });

var LibraryIcon = {
  r: r$1,
  css: css$1,
  className: className$1
};

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        76
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: /* [] */0
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.borderColor(Css.red),
      tl: {
        hd: Css.borderWidth(Css.px(1)),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.backgroundImage(Css.url(listIcon)),
            tl: {
              hd: Css.backgroundSize(Css.cover),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.hover({
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: Css.borderColor(Css.blue),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: r$2,
        tl: {
          hd: "ListIcon",
          tl: /* [] */0
        }
      }
    });

var ListIcon = {
  r: r$2,
  css: css$2,
  className: className$2
};

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        76
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: /* [] */0
      }
    });

var css$3 = Curry._1(Css.style, {
      hd: Css.borderColor(Css.red),
      tl: {
        hd: Css.borderWidth(Css.px(1)),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.backgroundImage(Css.url(intrepizineIcon)),
            tl: {
              hd: Css.backgroundSize(Css.cover),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.hover({
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: Css.borderColor(Css.blue),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: r$3,
        tl: {
          hd: "IntrepizineIcon",
          tl: /* [] */0
        }
      }
    });

var IntrepizineIcon = {
  r: r$3,
  css: css$3,
  className: className$3
};

var r$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        76
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: /* [] */0
      }
    });

var css$4 = Curry._1(Css.style, {
      hd: Css.borderColor(Css.red),
      tl: {
        hd: Css.borderWidth(Css.px(1)),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.backgroundImage(Css.url(levelIcon)),
            tl: {
              hd: Css.backgroundSize(Css.cover),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.hover({
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: Css.borderColor(Css.blue),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$4 = Curry._1(Css.merge, {
      hd: css$4,
      tl: {
        hd: r$4,
        tl: {
          hd: "LevelIcon",
          tl: /* [] */0
        }
      }
    });

var LevelIcon = {
  r: r$4,
  css: css$4,
  className: className$4
};

var iconCellWidth = 104;

var cols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: iconCellWidth
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: iconCellWidth
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: iconCellWidth
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: iconCellWidth
            },
            tl: /* [] */0
          }
        }
      }
    });

var css$5 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignContent(Css.center),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.justifyItems(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$5 = Curry._1(Css.merge, {
      hd: css$5,
      tl: {
        hd: cols,
        tl: {
          hd: "HeaderIconRow",
          tl: /* [] */0
        }
      }
    });

var HeaderIconRow = {
  iconCellWidth: iconCellWidth,
  cols: cols,
  css: css$5,
  className: className$5
};

var r$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        Theme$LiteracyplanetClientFlags.maximumWidth
      ],
      tl: {
        hd: [
          Css.height,
          768
        ],
        tl: /* [] */0
      }
    });

var css$6 = Curry._1(Css.style, {
      hd: Css.zIndex(30),
      tl: {
        hd: Css.position(Css.fixed),
        tl: {
          hd: Css.top(Css.zero),
          tl: {
            hd: Css.left(Css.zero),
            tl: {
              hd: Css.backgroundImage(Css.url(navBar)),
              tl: {
                hd: Css.backgroundSize(Css.cover),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$6 = Curry._1(Css.merge, {
      hd: css$6,
      tl: {
        hd: r$5,
        tl: {
          hd: "NavBar",
          tl: /* [] */0
        }
      }
    });

var NavBar = {
  r: r$5,
  css: css$6,
  className: className$6
};

var journeyNavIcon = Icon_nav_journeySvg;

var css$7 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.backgroundImage(Css.url(journeyNavIcon)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$7 = Curry._1(Css.merge, {
      hd: css$7,
      tl: {
        hd: "JourneyNav",
        tl: /* [] */0
      }
    });

var JourneyNavStyle = {
  css: css$7,
  className: className$7
};

function ForestHeader$JourneyNav(props) {
  return React.createElement("div", {
              className: className$7
            }, props.children);
}

var JourneyNav = {
  make: ForestHeader$JourneyNav
};

var libraryNavIcon = Icon_nav_librarySvg;

var css$8 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.backgroundImage(Css.url(libraryNavIcon)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$8 = Curry._1(Css.merge, {
      hd: css$8,
      tl: {
        hd: "LibraryNav",
        tl: /* [] */0
      }
    });

var LibraryNavStyle = {
  css: css$8,
  className: className$8
};

function ForestHeader$LibraryNav(props) {
  return React.createElement("div", {
              className: className$8
            }, props.children);
}

var LibraryNav = {
  make: ForestHeader$LibraryNav
};

var wordListNavIcon = Icon_nav_word_listSvg;

var css$9 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.backgroundImage(Css.url(wordListNavIcon)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$9 = Curry._1(Css.merge, {
      hd: css$9,
      tl: {
        hd: "WordListNav",
        tl: /* [] */0
      }
    });

var WordListNavStyle = {
  css: css$9,
  className: className$9
};

function ForestHeader$WordListNav(props) {
  return React.createElement("div", {
              className: className$9
            }, props.children);
}

var WordListNav = {
  make: ForestHeader$WordListNav
};

var levelNavIcon = Icon_nav_levelSvg;

var r$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        80
      ],
      tl: {
        hd: [
          Css.marginLeft,
          12
        ],
        tl: /* [] */0
      }
    });

var css$10 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(levelNavIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: css$10,
      tl: {
        hd: r$6,
        tl: {
          hd: "LevelNav",
          tl: /* [] */0
        }
      }
    });

var LevelNavStyle = {
  r: r$6,
  css: css$10,
  className: className$10
};

function ForestHeader$LevelNav(props) {
  return React.createElement("div", {
              className: className$10
            }, props.children);
}

var LevelNav = {
  make: ForestHeader$LevelNav
};

var collectionNavIcon = Icon_nav_collectionSvg;

var css$11 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(collectionNavIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$11 = Curry._1(Css.merge, {
      hd: css$11,
      tl: {
        hd: "CollectionNav",
        tl: /* [] */0
      }
    });

var CollectionNavStyle = {
  css: css$11,
  className: className$11
};

function ForestHeader$CollectionNav(props) {
  return React.createElement("div", {
              className: className$11
            }, props.children);
}

var CollectionNav = {
  make: ForestHeader$CollectionNav
};

var intrepizineNavIcon = Icon_nav_intrepizineSvg;

var r$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginTop,
        10
      ],
      tl: /* [] */0
    });

var css$12 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(intrepizineNavIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.height(Css.pct(87.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$12 = Curry._1(Css.merge, {
      hd: css$12,
      tl: {
        hd: r$7,
        tl: {
          hd: "IntrepizineNav",
          tl: /* [] */0
        }
      }
    });

var IntrepizineNavStyle = {
  r: r$7,
  css: css$12,
  className: className$12
};

function ForestHeader$IntrepizineNav(props) {
  return React.createElement("div", {
              className: className$12
            }, props.children);
}

var IntrepizineNav = {
  make: ForestHeader$IntrepizineNav
};

var logoutNavIcon = Button_logoutSvg;

var logoutNavIconHover = Button_logout_hoverSvg;

var logoutNavIconActive = Button_logout_activeSvg;

var r$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        51
      ],
      tl: {
        hd: [
          Css.height,
          43
        ],
        tl: {
          hd: [
            Css.marginTop,
            19
          ],
          tl: {
            hd: [
              Css.marginLeft,
              8
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$13 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(logoutNavIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundImage(Css.url(logoutNavIconHover)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.active({
                        hd: Css.backgroundImage(Css.url(logoutNavIconActive)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: CssUtils$LiteracyplanetClientSxEntry.preloadImages({
                          hd: logoutNavIconActive,
                          tl: {
                            hd: logoutNavIconHover,
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var className$13 = Curry._1(Css.merge, {
      hd: css$13,
      tl: {
        hd: r$8,
        tl: {
          hd: "LogoutNav",
          tl: /* [] */0
        }
      }
    });

var LogoutNavStyle = {
  r: r$8,
  css: css$13,
  className: className$13
};

function ForestHeader$LogoutNav(props) {
  return React.createElement("div", {
              className: className$13
            }, props.children);
}

var LogoutNav = {
  make: ForestHeader$LogoutNav
};

var wordManiaNavIcon = Icon_nav_word_maniaSvg;

var css$14 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(wordManiaNavIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$14 = Curry._1(Css.merge, {
      hd: css$14,
      tl: {
        hd: "WordManiaNav",
        tl: /* [] */0
      }
    });

var WordManiaNavStyle = {
  css: css$14,
  className: className$14
};

function ForestHeader$WordManiaNav(props) {
  return React.createElement("div", {
              className: className$14
            }, props.children);
}

var WordManiaNav = {
  make: ForestHeader$WordManiaNav
};

var r$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        Theme$LiteracyplanetClientFlags.maximumWidth
      ],
      tl: {
        hd: [
          Css.height,
          768
        ],
        tl: /* [] */0
      }
    });

var css$15 = Curry._1(Css.style, {
      hd: Css.top(Css.zero),
      tl: {
        hd: Css.left(Css.zero),
        tl: {
          hd: Css.backgroundImage(Css.url(menuButton)),
          tl: {
            hd: Css.backgroundSize(Css.cover),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$15 = Curry._1(Css.merge, {
      hd: css$15,
      tl: {
        hd: r$9,
        tl: {
          hd: "MenuButton",
          tl: /* [] */0
        }
      }
    });

var MenuButton = {
  r: r$9,
  css: css$15,
  className: className$15
};

var css$16 = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.position(Css.fixed),
        tl: {
          hd: Css.top(Css.zero),
          tl: {
            hd: Css.left(Css.zero),
            tl: {
              hd: Css.width({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: {
                hd: Css.height({
                      NAME: "percent",
                      VAL: 100.0
                    }),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.alignContent(Css.center),
                    tl: {
                      hd: Css.alignItems(Css.center),
                      tl: {
                        hd: Css.justifyContent(Css.center),
                        tl: {
                          hd: Css.justifyItems(Css.center),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$16 = Curry._1(Css.merge, {
      hd: css$16,
      tl: {
        hd: "ForestEmptyLayout",
        tl: /* [] */0
      }
    });

var ForestEmptyLayout = {
  css: css$16,
  className: className$16
};

var r$10 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        76
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: /* [] */0
      }
    });

var css$17 = Curry._1(Css.style, /* [] */0);

var className$17 = Curry._1(Css.merge, {
      hd: r$10,
      tl: {
        hd: css$17,
        tl: /* [] */0
      }
    });

var ResizeOnHoverStyle = {
  r: r$10,
  css: css$17,
  className: className$17
};

function ForestHeader$ResizeOnHover(props) {
  return React.createElement("div", {
              className: className$17
            }, props.children);
}

var ResizeOnHover = {
  make: ForestHeader$ResizeOnHover
};

var cssHover = Theme$LiteracyplanetClientFlags.dimensionsHover(className$17, 84, 72);

var css$18 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: /* [] */0
    });

var className$18 = Curry._1(Css.merge, {
      hd: cssHover,
      tl: {
        hd: css$18,
        tl: {
          hd: "HeaderIcon",
          tl: /* [] */0
        }
      }
    });

var HeaderIconStyle = {
  cssHover: cssHover,
  css: css$18,
  className: className$18
};

function ForestHeader$HeaderIcon(props) {
  return React.createElement("div", {
              className: className$18,
              onClick: props.onClick
            }, props.children);
}

var HeaderIcon = {
  make: ForestHeader$HeaderIcon
};

var loginIcon = RightToBracketSolidSvg;

function ForestHeader$LoginIcon(props) {
  var r = Theme$LiteracyplanetClientFlags.resize({
        hd: [
          Css.width,
          15
        ],
        tl: /* [] */0
      });
  var css = Curry._1(Css.style, /* [] */0);
  var className = Curry._1(Css.merge, {
        hd: css,
        tl: {
          hd: r,
          tl: {
            hd: "LoginIcon",
            tl: /* [] */0
          }
        }
      });
  return React.createElement("img", {
              className: className,
              src: loginIcon
            });
}

var LoginIcon = {
  make: ForestHeader$LoginIcon
};

var reportsIcon = Student_overviewSvg;

function ForestHeader$ReportsWidget(props) {
  var onClick = props.onClick;
  var match = Curry._1(StoryversePanelContext$LiteracyplanetClientSxEntry.Context.use, undefined);
  var closePanel = match.closePanel;
  var r = Theme$LiteracyplanetClientFlags.resize({
        hd: [
          Css.top,
          12
        ],
        tl: {
          hd: [
            Css.left,
            -70
          ],
          tl: {
            hd: [
              Css.height,
              60
            ],
            tl: /* [] */0
          }
        }
      });
  var css = Curry._1(Css.style, {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.hover(Belt_List.concat({
                    hd: Css.cursor(Css.pointer),
                    tl: /* [] */0
                  }, Theme$LiteracyplanetClientFlags.resizeList({
                        hd: [
                          Css.top,
                          10
                        ],
                        tl: {
                          hd: [
                            Css.height,
                            65
                          ],
                          tl: /* [] */0
                        }
                      }))),
          tl: /* [] */0
        }
      });
  var className = Curry._1(Css.merge, {
        hd: css,
        tl: {
          hd: r,
          tl: {
            hd: "ReportsWidget",
            tl: /* [] */0
          }
        }
      });
  return React.createElement("img", {
              className: className,
              src: reportsIcon,
              onClick: (function (param) {
                  Curry._1(onClick, undefined);
                  Curry._1(closePanel, undefined);
                })
            });
}

var ReportsWidget = {
  make: ForestHeader$ReportsWidget
};

function ForestHeader$TeacherModeWidget(props) {
  var r = Theme$LiteracyplanetClientFlags.resize({
        hd: [
          Css.width,
          105
        ],
        tl: {
          hd: [
            Css.height,
            20
          ],
          tl: {
            hd: [
              Css.right,
              -145
            ],
            tl: {
              hd: [
                Css.top,
                -6
              ],
              tl: {
                hd: [
                  Css.bottom,
                  0
                ],
                tl: {
                  hd: [
                    Theme$LiteracyplanetClientFlags.borderRadius,
                    16
                  ],
                  tl: {
                    hd: [
                      Theme$LiteracyplanetClientFlags.padding,
                      10
                    ],
                    tl: {
                      hd: [
                        Css.gridGap,
                        8
                      ],
                      tl: {
                        hd: [
                          Theme$LiteracyplanetClientFlags.borderWidth,
                          2
                        ],
                        tl: {
                          hd: [
                            Theme$LiteracyplanetClientFlags.fontSize,
                            12
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var css = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection(Css.row),
          tl: {
            hd: Css.position(Css.absolute),
            tl: {
              hd: Css.backgroundColor(Css.hex("0dadf7")),
              tl: {
                hd: Css.hover({
                      hd: Css.cursor(Css.pointer),
                      tl: {
                        hd: Css.backgroundColor(Css.hex("0979ac")),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.borderColor(Css.white),
                  tl: {
                    hd: Css.borderStyle(Css.solid),
                    tl: {
                      hd: Css.color(Css.white),
                      tl: {
                        hd: Css.fontWeight(Css.bold),
                        tl: {
                          hd: Css.textDecoration(Css.none),
                          tl: {
                            hd: Css.marginTop(Css.auto),
                            tl: {
                              hd: Css.marginBottom(Css.auto),
                              tl: {
                                hd: Css.alignItems(Css.center),
                                tl: {
                                  hd: Css.fontFamily({
                                        NAME: "custom",
                                        VAL: "museo-sans-rounded"
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var className = Curry._1(Css.merge, {
        hd: css,
        tl: {
          hd: r,
          tl: {
            hd: "TeacherModeWidget",
            tl: /* [] */0
          }
        }
      });
  return React.createElement("a", {
              className: className,
              href: props.href
            }, "Teacher Mode", React.createElement(ForestHeader$LoginIcon, {}));
}

var TeacherModeWidget = {
  make: ForestHeader$TeacherModeWidget
};

function ForestHeader(props) {
  var onClickLogout = props.onClickLogout;
  var onClickReports = props.onClickReports;
  var onClickWordMania = props.onClickWordMania;
  var onClickCollections = props.onClickCollections;
  var onClickLibrary = props.onClickLibrary;
  var onClickMyWords = props.onClickMyWords;
  var onClickIntrepizine = props.onClickIntrepizine;
  var onClickMissions = props.onClickMissions;
  FlagsContext$LiteracyplanetClientFlags.use();
  var match = Curry._1(StoryversePanelContext$LiteracyplanetClientSxEntry.Context.use, undefined);
  var toggleHeaderPanel = match.togglePanel;
  var closeHeaderPanel = match.closePanel;
  return React.createElement(ForestComponents$LiteracyplanetClientSxEntry.HeaderSubPanel.make, {
              children: null
            }, React.createElement(ForestHeader$ReportsWidget, {
                  onClick: (function (param) {
                      Curry._1(onClickReports, undefined);
                      Curry._1(closeHeaderPanel, undefined);
                    })
                }), React.createElement(ForestHeader$HeaderIcon, {
                  onClick: (function (param) {
                      Curry._1(toggleHeaderPanel, undefined);
                    }),
                  children: React.createElement(ForestHeader$ResizeOnHover, {
                        children: React.createElement(ForestHeader$LevelNav, {
                              children: ""
                            })
                      })
                }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.HeaderIconPanel.make, {
                  children: null
                }, React.createElement(ForestHeader$HeaderIcon, {
                      onClick: (function (param) {
                          Curry._1(onClickMissions, undefined);
                          Curry._1(closeHeaderPanel, undefined);
                        }),
                      children: React.createElement(ForestHeader$ResizeOnHover, {
                            children: React.createElement(ForestHeader$JourneyNav, {
                                  children: ""
                                })
                          })
                    }), props.showLibraryLink ? React.createElement(ForestHeader$HeaderIcon, {
                        onClick: (function (param) {
                            Curry._1(onClickLibrary, undefined);
                            Curry._1(closeHeaderPanel, undefined);
                          }),
                        children: React.createElement(ForestHeader$ResizeOnHover, {
                              children: React.createElement(ForestHeader$LibraryNav, {
                                    children: ""
                                  })
                            })
                      }) : null, React.createElement(ForestHeader$HeaderIcon, {
                      onClick: (function (param) {
                          Curry._1(onClickMyWords, undefined);
                          Curry._1(closeHeaderPanel, undefined);
                        }),
                      children: React.createElement(ForestHeader$ResizeOnHover, {
                            children: React.createElement(ForestHeader$WordListNav, {
                                  children: ""
                                })
                          })
                    }), React.createElement(ForestHeader$HeaderIcon, {
                      onClick: (function (param) {
                          Curry._1(onClickCollections, undefined);
                          Curry._1(closeHeaderPanel, undefined);
                        }),
                      children: React.createElement(ForestHeader$ResizeOnHover, {
                            children: React.createElement(ForestHeader$CollectionNav, {
                                  children: ""
                                })
                          })
                    }), React.createElement(ForestHeader$HeaderIcon, {
                      onClick: (function (param) {
                          Curry._1(onClickIntrepizine, undefined);
                          Curry._1(closeHeaderPanel, undefined);
                        }),
                      children: React.createElement(ForestHeader$ResizeOnHover, {
                            children: React.createElement(ForestHeader$IntrepizineNav, {
                                  children: ""
                                })
                          })
                    }), props.showWordManiaLink ? React.createElement(ForestHeader$HeaderIcon, {
                        onClick: (function (param) {
                            Curry._1(onClickWordMania, undefined);
                            Curry._1(closeHeaderPanel, undefined);
                          }),
                        children: null
                      }, React.createElement(ForestHeader$ResizeOnHover, {
                            children: React.createElement(ForestHeader$WordManiaNav, {
                                  children: ""
                                })
                          }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.Spacer.make, {})) : null), React.createElement(ForestHeader$HeaderIcon, {
                  onClick: (function (param) {
                      Curry._1(onClickLogout, undefined);
                      Curry._1(closeHeaderPanel, undefined);
                    }),
                  children: React.createElement(ForestHeader$LogoutNav, {
                        children: ""
                      })
                }), props.showTeacherModeWidget ? React.createElement(ForestHeader$TeacherModeWidget, {
                    href: props.teacherModeLink
                  }) : null);
}

var ratio = Theme$LiteracyplanetClientFlags.resize;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var bottomIconGap = 14;

var bottomIconWidth = 76;

var bottomIconHeight = 66;

var make = ForestHeader;

export {
  ratio ,
  resizeGridCols ,
  resizeGridRows ,
  maximumWidth ,
  navBar ,
  menuButton ,
  journeyIcon ,
  libraryIcon ,
  listIcon ,
  intrepizineIcon ,
  levelIcon ,
  bottomIconGap ,
  bottomIconWidth ,
  bottomIconHeight ,
  JourneyIcon ,
  LibraryIcon ,
  ListIcon ,
  IntrepizineIcon ,
  LevelIcon ,
  HeaderIconRow ,
  NavBar ,
  journeyNavIcon ,
  JourneyNavStyle ,
  JourneyNav ,
  libraryNavIcon ,
  LibraryNavStyle ,
  LibraryNav ,
  wordListNavIcon ,
  WordListNavStyle ,
  WordListNav ,
  levelNavIcon ,
  LevelNavStyle ,
  LevelNav ,
  collectionNavIcon ,
  CollectionNavStyle ,
  CollectionNav ,
  intrepizineNavIcon ,
  IntrepizineNavStyle ,
  IntrepizineNav ,
  logoutNavIcon ,
  logoutNavIconHover ,
  logoutNavIconActive ,
  LogoutNavStyle ,
  LogoutNav ,
  wordManiaNavIcon ,
  WordManiaNavStyle ,
  WordManiaNav ,
  MenuButton ,
  ForestEmptyLayout ,
  ResizeOnHoverStyle ,
  ResizeOnHover ,
  HeaderIconStyle ,
  HeaderIcon ,
  loginIcon ,
  LoginIcon ,
  reportsIcon ,
  ReportsWidget ,
  TeacherModeWidget ,
  make ,
}
/* navBar Not a pure module */
