// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactDom from "react-dom";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Cloze$LiteracyplanetTupperware from "../Cloze.res.mjs";
import * as Render$LiteracyplanetTupperware from "./Render.res.mjs";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as SkilfulClozeCommon$LiteracyplanetTupperware from "./SkilfulClozeCommon.res.mjs";
import * as V2ClozeDragTextHook$LiteracyplanetTupperware from "./V2ClozeDragTextHook.res.mjs";

function SkilfulClozeDragTextContainer$ResponseContainer(props) {
  return React.createElement("span", {
              ref: props.drag,
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.ResponseContainer.className,
              onClick: props.onClick
            }, props.children);
}

var ResponseContainer = {
  make: SkilfulClozeDragTextContainer$ResponseContainer
};

function SkilfulClozeDragTextContainer$Dimmed(props) {
  return React.createElement("span", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Dimmed.className
            }, props.children);
}

var Dimmed = {
  make: SkilfulClozeDragTextContainer$Dimmed
};

function SkilfulClozeDragTextContainer$PortaledAndDimmed(props) {
  var dropEl = V2ClozeDragTextHook$LiteracyplanetTupperware.useGetDomElement(props.domId);
  var rendered = React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
        onClick: props.onClick,
        children: props.children
      });
  return Render$LiteracyplanetTupperware.mapWithNull(dropEl, (function (el) {
                return React.createElement(React.Fragment, {}, ReactDom.createPortal(rendered, el), React.createElement(SkilfulClozeDragTextContainer$Dimmed, {
                                children: rendered
                              }));
              }));
}

var PortaledAndDimmed = {
  make: SkilfulClozeDragTextContainer$PortaledAndDimmed
};

function SkilfulClozeDragTextContainer$Portaled(props) {
  var children = props.children;
  var dropEl = V2ClozeDragTextHook$LiteracyplanetTupperware.useGetDomElement(props.domId);
  return Render$LiteracyplanetTupperware.mapWithNull(dropEl, (function (el) {
                return ReactDom.createPortal(React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
                                children: children
                              }), el);
              }));
}

var Portaled = {
  make: SkilfulClozeDragTextContainer$Portaled
};

function SkilfulClozeDragTextContainer$PortaledCorrectResponse(props) {
  return React.createElement(SkilfulClozeDragTextContainer$Portaled, {
              domId: props.domId,
              children: React.createElement(SkilfulClozeCommon$LiteracyplanetTupperware.CorrectResponse.make, {
                    children: props.children
                  })
            });
}

var PortaledCorrectResponse = {
  make: SkilfulClozeDragTextContainer$PortaledCorrectResponse
};

function SkilfulClozeDragTextContainer$PortaledIncorrectResponse(props) {
  return React.createElement(SkilfulClozeDragTextContainer$Portaled, {
              domId: props.domId,
              children: React.createElement(SkilfulClozeCommon$LiteracyplanetTupperware.IncorrectResponse.make, {
                    children: props.children
                  })
            });
}

var PortaledIncorrectResponse = {
  make: SkilfulClozeDragTextContainer$PortaledIncorrectResponse
};

function position(param) {
  return {
          display: "block",
          left: String(param.x) + "px",
          overflow: "visible",
          position: "fixed",
          top: String(param.y) + "px",
          pointerEvents: "none"
        };
}

function SkilfulClozeDragTextContainer$DragLayer(props) {
  return React.createElement("span", {
              style: position(props.currentOffset)
            }, props.children);
}

var DragLayer = {
  position: position,
  make: SkilfulClozeDragTextContainer$DragLayer
};

function SkilfulClozeDragTextContainer$DragLayerContainer(props) {
  var children = props.children;
  var id = props.id;
  var match = Cloze$LiteracyplanetTupperware.useDragLayer();
  var currentOffset = match.currentOffset;
  var isDragging = match.isDragging;
  return Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Option.map(match.item, (function (i) {
                        if (i.id === id) {
                          return isDragging;
                        } else {
                          return false;
                        }
                      })), (function (dragging) {
                    if (dragging) {
                      return currentOffset;
                    }
                    
                  })), null, (function (currentOffset) {
                return React.createElement(SkilfulClozeDragTextContainer$DragLayer, {
                            currentOffset: currentOffset,
                            children: React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
                                  children: React.createElement("span", {
                                        className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Undropped.className
                                      }, children)
                                })
                          });
              }));
}

var DragLayerContainer = {
  make: SkilfulClozeDragTextContainer$DragLayerContainer
};

function SkilfulClozeDragTextContainer$Undropped(props) {
  var el = React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
        drag: Caml_option.some(props.drag),
        children: React.createElement("span", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Undropped.className
            }, props.children)
      });
  if (props.isDragging) {
    return React.createElement(SkilfulClozeDragTextContainer$Dimmed, {
                children: el
              });
  } else {
    return el;
  }
}

var Undropped = {
  make: SkilfulClozeDragTextContainer$Undropped
};

function SkilfulClozeDragTextContainer$Dropped(props) {
  var undo = props.undo;
  return React.createElement(SkilfulClozeDragTextContainer$PortaledAndDimmed, {
              domId: props.domId,
              onClick: (function (param) {
                  Curry._1(undo, undefined);
                }),
              children: React.createElement("span", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Dropped.className
                  }, props.children)
            });
}

var Dropped = {
  make: SkilfulClozeDragTextContainer$Dropped
};

function SkilfulClozeDragTextContainer(props) {
  var children = props.children;
  var id = props.id;
  var match = V2ClozeDragTextHook$LiteracyplanetTupperware.use(props.questionIndex, id);
  var droppedId = match[4];
  var answerVisible = match[3];
  var marked = match[0];
  var tmp;
  var exit = 0;
  if (answerVisible !== undefined && answerVisible) {
    tmp = React.createElement(SkilfulClozeDragTextContainer$PortaledCorrectResponse, {
          domId: id,
          children: children
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (droppedId !== undefined) {
      switch (marked) {
        case "Correct" :
            tmp = React.createElement(SkilfulClozeDragTextContainer$PortaledCorrectResponse, {
                  domId: droppedId,
                  children: children
                });
            break;
        case "Wrong" :
            tmp = React.createElement(SkilfulClozeDragTextContainer$PortaledIncorrectResponse, {
                  domId: droppedId,
                  children: children
                });
            break;
        case "Unanswered" :
            tmp = React.createElement(SkilfulClozeDragTextContainer$Dropped, {
                  domId: droppedId,
                  undo: match[5],
                  children: children
                });
            break;
        
      }
    } else {
      tmp = marked !== "Unanswered" ? null : React.createElement(SkilfulClozeDragTextContainer$Undropped, {
              isDragging: match[1],
              id: id,
              drag: match[6],
              children: children
            });
    }
  }
  return React.createElement(React.Fragment, {}, tmp, React.createElement(SkilfulClozeDragTextContainer$DragLayerContainer, {
                  id: id,
                  children: children
                }));
}

var make = SkilfulClozeDragTextContainer;

var $$default = SkilfulClozeDragTextContainer;

export {
  ResponseContainer ,
  Dimmed ,
  PortaledAndDimmed ,
  Portaled ,
  PortaledCorrectResponse ,
  PortaledIncorrectResponse ,
  DragLayer ,
  DragLayerContainer ,
  Undropped ,
  Dropped ,
  make ,
  $$default as default,
}
/* react Not a pure module */
