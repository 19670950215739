// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as BubbleComponents$LiteracyplanetClientSxEntry from "./BubbleComponents.res.mjs";

function BubbleNavBarRatio(props) {
  var onClickMyWords = props.onClickMyWords;
  var onClickIntrepizine = props.onClickIntrepizine;
  var lockLibrary = props.lockLibrary;
  var highlightLibrary = props.highlightLibrary;
  var onClickShop = props.onClickShop;
  var onClickArcade = props.onClickArcade;
  var onClickCollections = props.onClickCollections;
  var onClickMissions = props.onClickMissions;
  var match = React.useState(function (param) {
        return false;
      });
  var setSubnavOpen = match[1];
  var closeSubnav = function (param) {
    Curry._1(setSubnavOpen, (function (param) {
            return false;
          }));
  };
  var openSubnav = function (param) {
    Curry._1(setSubnavOpen, (function (param) {
            return true;
          }));
  };
  return React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.HeaderPanel.make, {
              children: null
            }, React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.HeaderSubPanel.make, {
                  children: React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.HeaderSubSubPanel.make, {
                        children: null
                      }, React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.HeaderBar.make, {}), React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.HeaderList.make, {
                            children: null
                          }, React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.JourneyHeaderItem.make, {
                                large: props.highlightMissions,
                                onClick: (function (param) {
                                    Curry._1(onClickMissions, undefined);
                                    Curry._1(setSubnavOpen, (function (param) {
                                            return false;
                                          }));
                                  })
                              }), React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.MyWordsHeaderItem.make, {
                                large: props.highlightMyWords,
                                onClick: (function (param) {
                                    Curry._1(onClickMyWords, undefined);
                                    Curry._1(setSubnavOpen, (function (param) {
                                            return false;
                                          }));
                                  }),
                                lock: props.lockMyWords
                              }), props.showLibraryLink ? React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.LibraryHeaderItem.make, {
                                  lockLibrary: lockLibrary,
                                  highlightLibrary: highlightLibrary,
                                  onClickIntrepizine: onClickIntrepizine,
                                  onClickLibrary: props.onClickLibrary,
                                  subnavOpen: match[0],
                                  closeSubnav: closeSubnav,
                                  openSubnav: openSubnav
                                }) : React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.IntrepizineHeaderItem.make, {
                                  large: highlightLibrary,
                                  onClick: (function (param) {
                                      Curry._1(onClickIntrepizine, undefined);
                                    }),
                                  lock: lockLibrary
                                }), React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.CollectionsHeaderItem.make, {
                                large: props.highlightCollections,
                                onClick: (function (param) {
                                    Curry._1(onClickCollections, undefined);
                                    Curry._1(setSubnavOpen, (function (param) {
                                            return false;
                                          }));
                                  }),
                                lock: props.lockCollections
                              }), props.showArcade ? React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.ArcadeHeaderItem.make, {
                                  large: props.highlightArcade,
                                  onClick: (function (param) {
                                      Curry._1(onClickArcade, undefined);
                                      Curry._1(setSubnavOpen, (function (param) {
                                              return false;
                                            }));
                                    }),
                                  lock: props.lockArcade
                                }) : null, React.createElement(BubbleComponents$LiteracyplanetClientSxEntry.AvatarHeaderItem.make, {
                                large: false,
                                onClick: (function (param) {
                                    Curry._1(onClickShop, undefined);
                                    Curry._1(setSubnavOpen, (function (param) {
                                            return false;
                                          }));
                                  }),
                                lock: props.lockShop
                              })))
                }), props.menu);
}

var make = BubbleNavBarRatio;

export {
  make ,
}
/* react Not a pure module */
