// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";
import * as MemoPlay$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/MemoPlay.res.mjs";

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        16
      ],
      tl: {
        hd: [
          Css.height,
          16
        ],
        tl: /* [] */0
      }
    });

var css = Curry._1(Css.style, /* [] */0);

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: r,
        tl: {
          hd: "AudioPlayButton-PlayArrow",
          tl: /* [] */0
        }
      }
    });

function AudioPlayButton$PlayArrow(props) {
  return React.createElement("svg", {
              className: className,
              fill: "currentColor",
              viewBox: "0 0 384 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("span", {
                  dangerouslySetInnerHTML: {
                    __html: "<!-- !Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc, -->"
                  }
                }), React.createElement("path", {
                  d: "M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                }));
}

var PlayArrow = {
  r: r,
  css: css,
  className: className,
  make: AudioPlayButton$PlayArrow
};

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        10
      ],
      tl: /* [] */0
    });

var inline = Curry._1(Css.style, {
      hd: Css.display(Css.inlineBlock),
      tl: /* [] */0
    });

var mainClassName = Curry._1(Css.merge, {
      hd: r$1,
      tl: {
        hd: inline,
        tl: {
          hd: "AudioPlayButton",
          tl: /* [] */0
        }
      }
    });

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        50
      ],
      tl: {
        hd: [
          Css.width,
          50
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.padding,
            10
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.borderRadius,
              15
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$1 = Curry._1(Css.style, {
      hd: Css.color(Css.hex("000000de")),
      tl: {
        hd: Css.display(Css.inlineFlex),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.cursor(Css.pointer),
              tl: /* [] */0
            }
          }
        }
      }
    });

var buttonClassName = Curry._1(Css.merge, {
      hd: r$2,
      tl: {
        hd: css$1,
        tl: {
          hd: "AudioPlayButton-button",
          tl: /* [] */0
        }
      }
    });

function AudioPlayButton(props) {
  var match = React.useState(function (param) {
        return false;
      });
  var setPlaying = match[1];
  var playing = match[0];
  return React.createElement("div", {
              className: mainClassName
            }, React.createElement("button", {
                  className: buttonClassName,
                  onClick: (function (param) {
                      if (!playing) {
                        return Curry._1(setPlaying, (function (param) {
                                      return true;
                                    }));
                      }
                      
                    })
                }, React.createElement(AudioPlayButton$PlayArrow, {})), React.createElement("div", {
                  className: inline
                }, React.createElement(MemoPlay$LiteracyplanetClientFlags.make, {
                      src: props.src,
                      onEnd: (function (param) {
                          Curry._1(setPlaying, (function (param) {
                                  return false;
                                }));
                        }),
                      playing: playing
                    })));
}

var ratio = Theme$LiteracyplanetClientFlags.resize;

var make = AudioPlayButton;

var $$default = AudioPlayButton;

export {
  ratio ,
  PlayArrow ,
  inline ,
  mainClassName ,
  r$2 as r,
  css$1 as css,
  buttonClassName ,
  make ,
  $$default as default,
}
/* r Not a pure module */
