// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

function use(param) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  if (match.activityEnded) {
    return "ActivityEnded";
  }
  if (match.finishedLastQuestion) {
    return {
            TAG: "FinishActivity",
            _0: match.onSetActivityEnd
          };
  }
  var tmp = match.currentQuestionStep;
  if (typeof tmp !== "object" || tmp.TAG === "Attempted") {
    return "QuestionNotMarked";
  } else {
    return {
            TAG: "QuestionMarked",
            _0: match.onNextQuestion
          };
  }
}

export {
  use ,
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
