// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function combine(a, b) {
  if (a !== undefined && b !== undefined) {
    return [
            Caml_option.valFromOption(a),
            Caml_option.valFromOption(b)
          ];
  }
  
}

function combine3(a, b, c) {
  return Belt_Option.flatMap(combine(combine(a, b), c), (function (param) {
                var match = param[0];
                return [
                        match[0],
                        match[1],
                        param[1]
                      ];
              }));
}

function combine4(a, b, c, d) {
  return Belt_Option.flatMap(combine(combine3(a, b, c), d), (function (param) {
                var match = param[0];
                return [
                        match[0],
                        match[1],
                        match[2],
                        param[1]
                      ];
              }));
}

function combine5(a, b, c, d, e) {
  return Belt_Option.flatMap(combine(combine4(a, b, c, d), e), (function (param) {
                var match = param[0];
                return [
                        match[0],
                        match[1],
                        match[2],
                        match[3],
                        param[1]
                      ];
              }));
}

function combine6(a, b, c, d, e, f) {
  return Belt_Option.flatMap(combine(combine5(a, b, c, d, e), f), (function (param) {
                var match = param[0];
                return [
                        match[0],
                        match[1],
                        match[2],
                        match[3],
                        match[4],
                        param[1]
                      ];
              }));
}

function combine7(a, b, c, d, e, f, g) {
  return Belt_Option.flatMap(combine(combine6(a, b, c, d, e, f), g), (function (param) {
                var match = param[0];
                return [
                        match[0],
                        match[1],
                        match[2],
                        match[3],
                        match[4],
                        match[5],
                        param[1]
                      ];
              }));
}

export {
  combine ,
  combine3 ,
  combine4 ,
  combine5 ,
  combine6 ,
  combine7 ,
}
/* No side effect */
