// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Page$LiteracyplanetClientSxEntry from "./Page.res.mjs";
import * as PageTypes$LiteracyplanetClientSxEntry from "./PageTypes.res.mjs";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceWrap.res.mjs";

function pageToUrl(page) {
  if (typeof page !== "object") {
    switch (page) {
      case "StudentExperience" :
          return "/sx";
      case "StudentCollections" :
          return "/sx/collections";
      case "AvatarShop" :
          return "/sx/avatar_shop";
      case "NoMatch" :
          return "/sx/";
      case "StoryverseIntro" :
          return "/sx/storyverse";
      case "Bog" :
          return "/sx/book_of_goings";
      case "Journey" :
          return "/sx/journey";
      case "JourneyIntroPreDiag" :
          return "/sx/journey/pre_diag";
      case "JourneyIntroPostDiag" :
          return "/sx/journey/post_diag";
      case "JourneyIntroNoDiag" :
          return "/sx/journey/no_diag";
      case "JourneyMissions" :
          return "/sx/journey/missions";
      case "Arcade" :
          return "/sx/arcade";
      case "WordManiaGame" :
          return "/sx/arcade/wordmania";
      case "MyWordsLists" :
          return "/sx/my_words";
      case "ChecksLobby" :
          return "/sx/checks/lobby";
      case "ChecksActivity" :
          return "/sx/checks/activity";
      case "OverviewReport" :
          return "/sx/overview_report";
      
    }
  } else {
    switch (page.TAG) {
      case "SecondaryMissions" :
          var studentMissionId = page._0;
          if (studentMissionId !== undefined) {
            return "/sx/secondary/" + studentMissionId;
          } else {
            return "/sx/secondary";
          }
      case "StudentLibrary" :
          var groupIndex = page._0;
          if (groupIndex === undefined) {
            return "/sx/library";
          }
          var bookId = page._1;
          if (bookId !== undefined) {
            return "/sx/library/" + groupIndex + "/" + bookId;
          } else {
            return "/sx/library/" + groupIndex;
          }
      case "StudentActivity" :
          return "/sx/" + page._0 + "/" + page._1 + "/activities/" + page._2;
      case "PlayAnyActivity" :
          return "/sx/" + page._0 + "/" + page._1 + "/" + page._2 + "/" + page._3 + "/" + page._4 + "/" + page._5;
      case "JourneyActivities" :
          return "/sx/journey/activities/" + page._0;
      case "JourneyActivity" :
          var isAssessment = PageTypes$LiteracyplanetClientSxEntry.toIsAssessmentInt(page._4);
          return "/sx/journey/activity/" + page._0 + "/" + page._1 + "/" + page._2 + "/" + page._3 + "/" + String(isAssessment);
      case "TupperwareDemo" :
          return "/sx/demo/" + page._0;
      case "ArcadeGame" :
          return "/sx/arcade/" + page._0;
      case "DeepLinkActivities" :
          return "/sx/missions/" + page._0;
      case "MyWordsAssign" :
          return "/sx/my_words_assign/" + page._0;
      case "MyWordsNew" :
          return "/sx/my_words_new/" + page._0;
      case "MyWordsEdit" :
          return "/sx/my_words_edit/" + page._0;
      case "MyWordsActivity" :
          return "/sx/my_words_activities/" + page._0 + "/" + page._1;
      case "Intrepizine" :
          var optionalPostId = page._0;
          if (optionalPostId !== undefined) {
            return "/sx/intrepizine/" + optionalPostId;
          } else {
            return "/sx/intrepizine";
          }
      
    }
  }
}

function pathToPage(path) {
  if (!path) {
    return "NoMatch";
  }
  var exit = 0;
  if (path.hd === "sx") {
    var match = path.tl;
    if (!match) {
      return "StudentExperience";
    }
    switch (match.hd) {
      case "avatar_shop" :
          if (!match.tl) {
            return "AvatarShop";
          }
          exit = 1;
          break;
      case "home" :
          if (!match.tl) {
            return "StudentExperience";
          }
          exit = 1;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var match$1 = path.tl;
    if (!match$1) {
      return "NoMatch";
    }
    var locale = match$1.hd;
    var exit$1 = 0;
    switch (locale) {
      case "arcade" :
          var match$2 = match$1.tl;
          if (!match$2) {
            return "Arcade";
          }
          var gameKind = match$2.hd;
          if (gameKind === "wordmania") {
            if (!match$2.tl) {
              return "WordManiaGame";
            }
            exit$1 = 2;
          } else {
            if (!match$2.tl) {
              return {
                      TAG: "ArcadeGame",
                      _0: gameKind
                    };
            }
            exit$1 = 2;
          }
          break;
      case "book_of_goings" :
          if (!match$1.tl) {
            return "Bog";
          }
          exit$1 = 2;
          break;
      case "checks" :
          var match$3 = match$1.tl;
          if (!match$3) {
            return "NoMatch";
          }
          switch (match$3.hd) {
            case "activity" :
                if (!match$3.tl) {
                  return "ChecksActivity";
                }
                exit$1 = 2;
                break;
            case "lobby" :
                if (!match$3.tl) {
                  return "ChecksLobby";
                }
                exit$1 = 2;
                break;
            default:
              exit$1 = 2;
          }
          break;
      case "collections" :
          if (!match$1.tl) {
            return "StudentCollections";
          }
          exit$1 = 2;
          break;
      case "demo" :
          var match$4 = match$1.tl;
          if (!match$4) {
            return "NoMatch";
          }
          if (!match$4.tl) {
            return {
                    TAG: "TupperwareDemo",
                    _0: match$4.hd
                  };
          }
          exit$1 = 2;
          break;
      case "intrepizine" :
          var match$5 = match$1.tl;
          if (!match$5) {
            return {
                    TAG: "Intrepizine",
                    _0: undefined
                  };
          }
          if (!match$5.tl) {
            return {
                    TAG: "Intrepizine",
                    _0: match$5.hd
                  };
          }
          exit$1 = 2;
          break;
      case "journey" :
          var match$6 = match$1.tl;
          if (!match$6) {
            return "Journey";
          }
          switch (match$6.hd) {
            case "activities" :
                var match$7 = match$6.tl;
                if (!match$7) {
                  return "NoMatch";
                }
                if (!match$7.tl) {
                  return {
                          TAG: "JourneyActivities",
                          _0: match$7.hd
                        };
                }
                exit$1 = 2;
                break;
            case "activity" :
                var match$8 = match$6.tl;
                if (!match$8) {
                  return "NoMatch";
                }
                var match$9 = match$8.tl;
                if (!match$9) {
                  return "NoMatch";
                }
                var match$10 = match$9.tl;
                if (match$10) {
                  var match$11 = match$10.tl;
                  if (!match$11) {
                    return "NoMatch";
                  }
                  var match$12 = match$11.tl;
                  if (match$12) {
                    if (match$12.tl) {
                      return "NoMatch";
                    } else {
                      return {
                              TAG: "JourneyActivity",
                              _0: match$8.hd,
                              _1: match$9.hd,
                              _2: match$10.hd,
                              _3: match$11.hd,
                              _4: PageTypes$LiteracyplanetClientSxEntry.toIsAssessment(match$12.hd)
                            };
                    }
                  }
                  exit$1 = 2;
                } else {
                  exit$1 = 2;
                }
                break;
            case "missions" :
                if (!match$6.tl) {
                  return "JourneyMissions";
                }
                exit$1 = 2;
                break;
            case "no_diag" :
                if (!match$6.tl) {
                  return "JourneyIntroNoDiag";
                }
                exit$1 = 2;
                break;
            case "post_diag" :
                if (!match$6.tl) {
                  return "JourneyIntroPostDiag";
                }
                exit$1 = 2;
                break;
            case "pre_diag" :
                if (!match$6.tl) {
                  return "JourneyIntroPreDiag";
                }
                exit$1 = 2;
                break;
            default:
              exit$1 = 2;
          }
          break;
      case "library" :
          var match$13 = match$1.tl;
          if (!match$13) {
            return {
                    TAG: "StudentLibrary",
                    _0: undefined,
                    _1: undefined
                  };
          }
          var match$14 = match$13.tl;
          var groupIndex = match$13.hd;
          if (!match$14) {
            return {
                    TAG: "StudentLibrary",
                    _0: groupIndex,
                    _1: undefined
                  };
          }
          if (!match$14.tl) {
            return {
                    TAG: "StudentLibrary",
                    _0: groupIndex,
                    _1: match$14.hd
                  };
          }
          exit$1 = 2;
          break;
      case "missions" :
          var match$15 = match$1.tl;
          if (!match$15) {
            return "NoMatch";
          }
          if (!match$15.tl) {
            return {
                    TAG: "DeepLinkActivities",
                    _0: match$15.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words" :
          if (!match$1.tl) {
            return "MyWordsLists";
          }
          exit$1 = 2;
          break;
      case "my_words_activities" :
          var match$16 = match$1.tl;
          if (!match$16) {
            return "NoMatch";
          }
          var match$17 = match$16.tl;
          if (!match$17) {
            return "NoMatch";
          }
          if (!match$17.tl) {
            return {
                    TAG: "MyWordsActivity",
                    _0: match$16.hd,
                    _1: match$17.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words_assign" :
          var match$18 = match$1.tl;
          if (!match$18) {
            return "NoMatch";
          }
          if (!match$18.tl) {
            return {
                    TAG: "MyWordsAssign",
                    _0: match$18.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words_edit" :
          var match$19 = match$1.tl;
          if (!match$19) {
            return "NoMatch";
          }
          if (!match$19.tl) {
            return {
                    TAG: "MyWordsEdit",
                    _0: match$19.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words_new" :
          var match$20 = match$1.tl;
          if (!match$20) {
            return "NoMatch";
          }
          if (!match$20.tl) {
            return {
                    TAG: "MyWordsNew",
                    _0: match$20.hd
                  };
          }
          exit$1 = 2;
          break;
      case "overview_report" :
          if (!match$1.tl) {
            return "OverviewReport";
          }
          exit$1 = 2;
          break;
      case "secondary" :
          var match$21 = match$1.tl;
          if (!match$21) {
            return {
                    TAG: "SecondaryMissions",
                    _0: undefined
                  };
          }
          if (!match$21.tl) {
            return {
                    TAG: "SecondaryMissions",
                    _0: match$21.hd
                  };
          }
          exit$1 = 2;
          break;
      case "storyverse" :
          if (!match$1.tl) {
            return "StoryverseIntro";
          }
          exit$1 = 2;
          break;
      default:
        exit$1 = 2;
    }
    if (exit$1 === 2) {
      var match$22 = match$1.tl;
      if (!match$22) {
        return "NoMatch";
      }
      var match$23 = match$22.tl;
      if (!match$23) {
        return "NoMatch";
      }
      var contentId = match$23.hd;
      var accent = match$22.hd;
      var exit$2 = 0;
      if (contentId === "activities") {
        var match$24 = match$23.tl;
        if (!match$24) {
          return "NoMatch";
        }
        if (!match$24.tl) {
          return {
                  TAG: "StudentActivity",
                  _0: locale,
                  _1: accent,
                  _2: match$24.hd
                };
        }
        exit$2 = 3;
      } else {
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        var match$25 = match$23.tl;
        if (!match$25) {
          return "NoMatch";
        }
        var match$26 = match$25.tl;
        if (!match$26) {
          return "NoMatch";
        }
        var match$27 = match$26.tl;
        if (match$27 && !match$27.tl) {
          return {
                  TAG: "PlayAnyActivity",
                  _0: locale,
                  _1: accent,
                  _2: contentId,
                  _3: match$25.hd,
                  _4: match$26.hd,
                  _5: match$27.hd
                };
        } else {
          return "NoMatch";
        }
      }
      
    }
    
  }
  
}

function pageToComponent(page, state, goBack, redirect, redirectWithState, replaceWithState, isPageBlacklisted, isItemBlacklisted, onToggleLockedModal, onToggleProModal, pageToUrlWithState) {
  var onGoToMissions = StudentExperienceWrap$LiteracyplanetClientSxEntry.useStudentRedirectToMissions(redirect, isPageBlacklisted);
  if (typeof page !== "object") {
    switch (page) {
      case "StudentExperience" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentExperience.make, {
                      redirect: redirect
                    });
      case "StudentCollections" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentCollections.make, {
                      redirect: redirect,
                      isPageBlacklisted: isPageBlacklisted,
                      isItemBlacklisted: isItemBlacklisted,
                      onToggleProModal: onToggleProModal,
                      onToggleLockedModal: onToggleLockedModal
                    });
      case "AvatarShop" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.AvatarShop.make, {
                      onGoToMissions: onGoToMissions
                    });
      case "NoMatch" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.NoMatch.make, {});
      case "StoryverseIntro" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StoryverseIntro.make, {
                      redirect: redirect
                    });
      case "Bog" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.Bog.make, {
                      redirect: redirect,
                      state: state,
                      redirectWithState: redirectWithState
                    });
      case "JourneyMissions" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.JourneyMissions.make, {
                      redirect: redirect
                    });
      case "Arcade" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.Arcade.make, {
                      redirect: redirect
                    });
      case "WordManiaGame" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.WordManiaGame.make, {
                      redirect: redirect
                    });
      case "MyWordsLists" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsLists.make, {
                      redirect: redirect,
                      replaceWithState: replaceWithState,
                      state: state
                    });
      case "ChecksLobby" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.ChecksLobby.make, {
                      redirect: redirect,
                      onGoToMissions: onGoToMissions
                    });
      case "ChecksActivity" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.ChecksActivity.make, {
                      redirect: redirect
                    });
      case "OverviewReport" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.OverviewReport.make, {});
      default:
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "Routes.res",
                141,
                2
              ],
              Error: new Error()
            };
    }
  } else {
    switch (page.TAG) {
      case "SecondaryMissions" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.SecondaryMissions.make, {
                      studentMissionId: page._0,
                      redirect: redirect
                    });
      case "StudentLibrary" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentLibrary.make, {
                      groupIndex: page._0,
                      bookId: page._1,
                      redirect: redirect
                    });
      case "StudentActivity" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentActivity.make, {
                      redirect: redirect,
                      locale: page._0,
                      accent: page._1,
                      studentActivityId: page._2,
                      isPageBlacklisted: isPageBlacklisted,
                      onToggleLocked: onToggleLockedModal,
                      onGoToMissions: onGoToMissions
                    });
      case "PlayAnyActivity" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.PlayAnyActivity.make, {
                      state: state,
                      goBack: goBack,
                      locale: page._0,
                      accent: page._1,
                      contentId: page._2,
                      contentVersion: page._3,
                      contentKind: page._4,
                      kind: page._5
                    });
      case "JourneyActivities" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.JourneyActivities.make, {
                      redirect: redirect,
                      studentMissionId: page._0
                    });
      case "JourneyActivity" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.JourneyActivity.make, {
                      redirect: redirect,
                      locale: page._0,
                      accent: page._1,
                      studentMissionId: page._2,
                      studentActivityId: page._3,
                      isAssessment: page._4,
                      isPageBlacklisted: isPageBlacklisted,
                      onToggleLocked: onToggleLockedModal
                    });
      case "ArcadeGame" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.ArcadeGame.make, {
                      redirect: redirect,
                      gameKind: page._0
                    });
      case "DeepLinkActivities" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.DeepLinkActivities.make, {
                      redirect: redirect,
                      missionRef: page._0
                    });
      case "MyWordsAssign" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsAssign.make, {
                      redirect: redirect,
                      id: page._0
                    });
      case "MyWordsNew" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsNew.make, {
                      id: page._0,
                      redirect: redirect
                    });
      case "MyWordsEdit" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsEdit.make, {
                      id: page._0,
                      redirect: redirect
                    });
      case "MyWordsActivity" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsActivity.make, {
                      id: page._0,
                      gameId: page._1,
                      redirect: redirect
                    });
      case "Intrepizine" :
          return React.createElement(Page$LiteracyplanetClientSxEntry.Intrepizine.make, {
                      optionalPostId: page._0,
                      pageToUrlWithState: pageToUrlWithState,
                      state: state
                    });
      default:
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "Routes.res",
                141,
                2
              ],
              Error: new Error()
            };
    }
  }
}

function pageToLayout(page) {
  if (typeof page !== "object") {
    switch (page) {
      case "Bog" :
          return "PlainBackground";
      case "Arcade" :
          return "AuthWithTitleBarAndHeader";
      case "StudentCollections" :
      case "MyWordsLists" :
          return "AuthWithTitleBar";
      case "NoMatch" :
      case "JourneyMissions" :
      case "OverviewReport" :
          return "AuthWithMenu";
      default:
        return "AuthNoMenu";
    }
  } else {
    switch (page.TAG) {
      case "SecondaryMissions" :
          return "AuthWithMenu";
      case "StudentLibrary" :
          if (page._1 !== undefined) {
            return "DarkBackground";
          } else {
            return "AuthWithTitleBarAndHeader";
          }
      case "MyWordsAssign" :
      case "MyWordsNew" :
      case "MyWordsEdit" :
          return "DarkBackground";
      case "Intrepizine" :
          return "AuthWithTitleBarAndHeader";
      default:
        return "AuthNoMenu";
    }
  }
}

function pageToBackground(page) {
  if (typeof page !== "object") {
    switch (page) {
      case "NoMatch" :
      case "Bog" :
      case "Arcade" :
      case "WordManiaGame" :
      case "MyWordsLists" :
      case "OverviewReport" :
          return "VoidBackground";
      default:
        return "NoBackground";
    }
  } else {
    switch (page.TAG) {
      case "SecondaryMissions" :
      case "StudentLibrary" :
      case "MyWordsAssign" :
      case "MyWordsNew" :
      case "MyWordsEdit" :
          return "VoidBackground";
      case "Intrepizine" :
          return "IntrepizineBackground";
      default:
        return "NoBackground";
    }
  }
}

export {
  pageToUrl ,
  pathToPage ,
  pageToComponent ,
  pageToLayout ,
  pageToBackground ,
}
/* react Not a pure module */
