// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "./SpaceX.res.mjs";
import * as Timing$LiteracyplanetClientFlags from "./Timing.res.mjs";
import * as MutationHook2$LiteracyplanetClientFlags from "./MutationHook2.res.mjs";

var Raw = {};

var query = GraphqlTag.gql(["mutation requestStudentMissions($userId: String!, $startingPoint: String, $lastMissionRefId: String, $lastMissionVersion: String, $lastMissionOrder: Int, $activeMissionCount: Int, $pendingMissions: [MissionDefinition])  {\nrequestStudentMissions(userId: $userId, startingPoint: $startingPoint, lastMissionRefId: $lastMissionRefId, lastMissionVersion: $lastMissionVersion, lastMissionOrder: $lastMissionOrder, activeMissionCount: $activeMissionCount, pendingMissions: $pendingMissions)  \n}\n"]);

function parse(value) {
  return {
          requestStudentMissions: value.requestStudentMissions
        };
}

function serialize(value) {
  var value$1 = value.requestStudentMissions;
  return {
          requestStudentMissions: value$1
        };
}

function serializeInputObjectMissionDefinition(inp) {
  var a = inp.version;
  return {
          refId: inp.refId,
          version: a !== undefined ? a : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.startingPoint;
  var a$1 = inp.lastMissionRefId;
  var a$2 = inp.lastMissionVersion;
  var a$3 = inp.lastMissionOrder;
  var a$4 = inp.activeMissionCount;
  var a$5 = inp.pendingMissions;
  return {
          userId: inp.userId,
          startingPoint: a !== undefined ? a : undefined,
          lastMissionRefId: a$1 !== undefined ? a$1 : undefined,
          lastMissionVersion: a$2 !== undefined ? a$2 : undefined,
          lastMissionOrder: a$3 !== undefined ? a$3 : undefined,
          activeMissionCount: a$4 !== undefined ? a$4 : undefined,
          pendingMissions: a$5 !== undefined ? a$5.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectMissionDefinition(b);
                  }
                  
                }) : undefined
        };
}

function makeVariables(userId, startingPoint, lastMissionRefId, lastMissionVersion, lastMissionOrder, activeMissionCount, pendingMissions, param) {
  return {
          userId: userId,
          startingPoint: startingPoint,
          lastMissionRefId: lastMissionRefId,
          lastMissionVersion: lastMissionVersion,
          lastMissionOrder: lastMissionOrder,
          activeMissionCount: activeMissionCount,
          pendingMissions: pendingMissions
        };
}

function makeInputObjectMissionDefinition(refId, version, param) {
  return {
          refId: refId,
          version: version
        };
}

var RequestMissionsMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectMissionDefinition: serializeInputObjectMissionDefinition,
  makeVariables: makeVariables,
  makeInputObjectMissionDefinition: makeInputObjectMissionDefinition
};

var include = SpaceX$LiteracyplanetClientFlags.Mutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var RequestMissionsMutation = {
  RequestMissionsMutation_inner: RequestMissionsMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectMissionDefinition: serializeInputObjectMissionDefinition,
  makeVariables: makeVariables,
  makeInputObjectMissionDefinition: makeInputObjectMissionDefinition,
  use: use
};

var M = MutationHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function useRequest(param) {
  var match = Curry._1(M.use, undefined);
  var mutate = match[0];
  return function (userId, startingPoint) {
    return Curry._1(mutate, {
                userId: userId,
                startingPoint: startingPoint,
                lastMissionRefId: undefined,
                lastMissionVersion: undefined,
                lastMissionOrder: undefined,
                activeMissionCount: undefined,
                pendingMissions: undefined
              });
  };
}

function useRequestMissionsHook(userId, startingPoint, loading, isMissingMissions) {
  var requestForMore = useRequest();
  Timing$LiteracyplanetClientFlags.useTriggerAfterTimeout([
        loading,
        isMissingMissions
      ], (function (param) {
          return isMissingMissions;
        }), Timing$LiteracyplanetClientFlags.seconds(10), (function (param) {
          Curry._2(requestForMore, userId, startingPoint);
        }));
}

export {
  RequestMissionsMutation ,
  M ,
  useRequest ,
  useRequestMissionsHook ,
}
/* query Not a pure module */
