// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Render$LiteracyplanetTupperware from "./Render.res.mjs";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as ExpandingInput$LiteracyplanetTupperware from "./ExpandingInput.res.mjs";
import * as V2TextInputHook$LiteracyplanetTupperware from "./V2TextInputHook.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as SkilfulResponseMark$LiteracyplanetTupperware from "./SkilfulResponseMark.res.mjs";
import * as V2TextAnswerStatesHook$LiteracyplanetTupperware from "./V2TextAnswerStatesHook.res.mjs";

function useLabelTransition(value) {
  var checkInputState = function (param) {
    if (value === "") {
      return Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.LabelLarge.className;
    } else {
      return Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.LabelSmall.className;
    }
  };
  var match = React.useState(function (param) {
        return checkInputState();
      });
  var setClassName = match[1];
  var onFocus = function (param) {
    Curry._1(setClassName, (function (param) {
            return Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.LabelSmall.className;
          }));
  };
  var onBlur = function (param) {
    Curry._1(setClassName, (function (param) {
            return checkInputState();
          }));
  };
  return [
          match[0],
          onFocus,
          onBlur
        ];
}

function SkilfulTextInputContainer$InputWithLabel(props) {
  var value = props.value;
  var match = useLabelTransition(value);
  var labelClassName = match[0];
  return React.createElement(React.Fragment, {}, Render$LiteracyplanetTupperware.mapWithNull(props.label, (function (label) {
                    return React.createElement("span", {
                                className: labelClassName
                              }, label);
                  })), React.createElement(ExpandingInput$LiteracyplanetTupperware.make, {
                  value: value,
                  className: Curry._1(Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.Input.className, props.largeField),
                  onChange: props.onChange,
                  onFocus: match[1],
                  onBlur: match[2]
                }));
}

var InputWithLabel = {
  make: SkilfulTextInputContainer$InputWithLabel
};

function SkilfulTextInputContainer(props) {
  var __largeField = props.largeField;
  var responseOptionId = props.responseOptionId;
  var questionIndex = props.questionIndex;
  var largeField = __largeField !== undefined ? __largeField : false;
  var match = V2TextAnswerStatesHook$LiteracyplanetTupperware.use(questionIndex, responseOptionId);
  var marked = match[1];
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use();
  var answerVisible = Curry._1(match$1.isCorrectAnswerVisible, questionIndex);
  var match$2 = V2TextInputHook$LiteracyplanetTupperware.use(questionIndex, responseOptionId);
  var value = match$2[1];
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  switch (marked) {
    case "Correct" :
    case "Wrong" :
        exit$1 = 2;
        break;
    case "Unanswered" :
        tmp = React.createElement(SkilfulTextInputContainer$InputWithLabel, {
              value: value,
              onChange: match$2[0],
              label: props.label,
              largeField: largeField
            });
        break;
    
  }
  if (exit$1 === 2) {
    if (answerVisible !== undefined && answerVisible) {
      tmp = React.createElement(SkilfulResponseMark$LiteracyplanetTupperware.make, {
            inputClassName: Skillet$LiteracyplanetTupperware.inputSizeClass(largeField),
            marked: marked,
            answerVisible: answerVisible,
            value: Belt_Option.getWithDefault(match[2], "")
          });
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = React.createElement(SkilfulResponseMark$LiteracyplanetTupperware.make, {
          inputClassName: Skillet$LiteracyplanetTupperware.inputSizeClass(largeField),
          marked: marked,
          answerVisible: answerVisible,
          value: value
        });
  }
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.className(largeField)
            }, tmp);
}

var make = SkilfulTextInputContainer;

var $$default = SkilfulTextInputContainer;

export {
  useLabelTransition ,
  InputWithLabel ,
  make ,
  $$default as default,
}
/* react Not a pure module */
