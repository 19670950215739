// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as V2EventsContext$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/v2/V2EventsContext.res.mjs";

function call(f) {
  Belt_Option.map(f, (function (f) {
          return Curry._1(f, undefined);
        }));
}

function callWith2(f, x, y) {
  Belt_Option.map(f, (function (f) {
          return Curry._2(f, x, y);
        }));
}

function wrapApiWithStoreEvents(api, dispatch, onActivityCompleted, onActivityReachedEnd, onActivityStarted, onExit, onQuestionFailed, onQuestionPassed, onQuestionStarted, param) {
  return Curry._7(api, (function (param) {
                Curry._1(dispatch, "ActivityCompleted");
                call(onActivityCompleted);
              }), (function (param) {
                Curry._1(dispatch, "ActivityReachedEnd");
                call(onActivityReachedEnd);
              }), (function (param) {
                Curry._1(dispatch, "ActivityStarted");
                call(onActivityStarted);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, "Exit");
                callWith2(onExit, questionIndex, serializedState);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, {
                      TAG: "QuestionFailed",
                      _0: questionIndex + 1 | 0
                    });
                callWith2(onQuestionFailed, questionIndex, serializedState);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, {
                      TAG: "QuestionPassed",
                      _0: questionIndex + 1 | 0
                    });
                callWith2(onQuestionPassed, questionIndex, serializedState);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, "QuestionStarted");
                callWith2(onQuestionStarted, questionIndex, serializedState);
              }));
}

function api(onActivityCompleted, onActivityReachedEnd, onActivityStarted, onExit, onQuestionFailed, onQuestionPassed, onQuestionStarted) {
  return {
          onActivityCompleted: onActivityCompleted,
          onActivityReachedEnd: onActivityReachedEnd,
          onActivityStarted: onActivityStarted,
          onExit: onExit,
          onQuestionFailed: onQuestionFailed,
          onQuestionPassed: onQuestionPassed,
          onQuestionStarted: onQuestionStarted
        };
}

function FlipperTupperwareCallbacks$Provider(props) {
  return React.createElement(V2EventsContext$LiteracyplanetClientLibsActivity.make, {
              value: props.dispachables,
              children: props.children
            });
}

var Provider = {
  make: FlipperTupperwareCallbacks$Provider
};

export {
  call ,
  callWith2 ,
  wrapApiWithStoreEvents ,
  api ,
  Provider ,
}
/* react Not a pure module */
