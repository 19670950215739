// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2EventsContext$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/v2/V2EventsContext.res.mjs";

var use = V2EventsContext$LiteracyplanetClientLibsActivity.use;

export {
  use ,
}
/* V2EventsContext-LiteracyplanetClientLibsActivity Not a pure module */
