// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as PlayIcon$LiteracyplanetTupperware from "./PlayIcon.res.mjs";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.res.mjs";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

function SkilfulEndScreenContainer$Button(props) {
  return React.createElement("div", {
              className: props.className,
              onClick: props.onClick
            }, props.children);
}

var Button = {
  make: SkilfulEndScreenContainer$Button
};

function SkilfulEndScreenContainer$MarkButton(props) {
  return React.createElement(SkilfulEndScreenContainer$Button, {
              onClick: props.onClick,
              className: props.className,
              children: "Submit"
            });
}

var MarkButton = {
  make: SkilfulEndScreenContainer$MarkButton
};

var enabledCss = Skillet$LiteracyplanetTupperware.BehaviourMarkButton.enabledCss;

function SkilfulEndScreenContainer$BehaviourMarkAllButton(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var activityEnded = match.activityEnded;
  var onSetActivityEnd = match.onSetActivityEnd;
  var onMarkActivity = match.onMarkActivity;
  if (match.finishedLastQuestion) {
    if (!activityEnded) {
      return React.createElement(SkilfulEndScreenContainer$Button, {
                  onClick: (function (param) {
                      Curry._1(onSetActivityEnd, undefined);
                    }),
                  className: enabledCss,
                  children: "Continue"
                });
    }
    
  } else if (!activityEnded) {
    return React.createElement(SkilfulEndScreenContainer$MarkButton, {
                className: enabledCss,
                onClick: (function (param) {
                    Curry._1(onMarkActivity, undefined);
                  })
              });
  }
  return React.createElement(SkilfulEndScreenContainer$Button, {
              onClick: (function (param) {
                  
                }),
              className: Skillet$LiteracyplanetTupperware.BehaviourMarkButton.disabledCss,
              children: "Continue"
            });
}

var BehaviourMarkAllButton = {
  enabledCss: enabledCss,
  make: SkilfulEndScreenContainer$BehaviourMarkAllButton
};

function SkilfulEndScreenContainer$EndScreenHeader(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenHeader.className
            }, "Results Summary");
}

var EndScreenHeader = {
  make: SkilfulEndScreenContainer$EndScreenHeader
};

function SkilfulEndScreenContainer$EndScreenFooter(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenFooter.className
            }, React.createElement(SkilfulEndScreenContainer$BehaviourMarkAllButton, {}));
}

var EndScreenFooter = {
  make: SkilfulEndScreenContainer$EndScreenFooter
};

function SkilfulEndScreenContainer$EndScreenQuestionIndex(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenQuestionIndex.className
            }, String(props.i + 1 | 0));
}

var EndScreenQuestionIndex = {
  make: SkilfulEndScreenContainer$EndScreenQuestionIndex
};

function SkilfulEndScreenContainer$EndScreenPlayIcon(props) {
  return React.createElement(PlayIcon$LiteracyplanetTupperware.make, {
              className: Skillet$LiteracyplanetTupperware.EndScreenPlayIcon.className,
              playClassName: Skillet$LiteracyplanetTupperware.EndScreenPlayIcon.playClassName,
              backgroundClassName: Skillet$LiteracyplanetTupperware.EndScreenPlayIcon.backgroundClassName
            });
}

var EndScreenPlayIcon = {
  make: SkilfulEndScreenContainer$EndScreenPlayIcon
};

function SkilfulEndScreenContainer$EndScreenMarkingState(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenMarkingState.className
            }, props.answer);
}

var EndScreenMarkingState = {
  make: SkilfulEndScreenContainer$EndScreenMarkingState
};

function SkilfulEndScreenContainer$EndScreenLink(props) {
  var i = props.i;
  var onChangeQuestion = props.onChangeQuestion;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var match$1 = Curry._2(props.detailRowRender, match.getStep, i);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenLink.className,
              onClick: (function (param) {
                  Curry._1(onChangeQuestion, i);
                })
            }, React.createElement(SkilfulEndScreenContainer$EndScreenQuestionIndex, {
                  i: i
                }), React.createElement("div", undefined, match$1[1], match$1[0]), React.createElement(SkilfulEndScreenContainer$EndScreenMarkingState, {
                  answer: match$1[2]
                }), React.createElement(SkilfulEndScreenContainer$EndScreenPlayIcon, {}));
}

var EndScreenLink = {
  make: SkilfulEndScreenContainer$EndScreenLink
};

function SkilfulEndScreenContainer$EndScreenTableHeader(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenTableHeader.className
            }, React.createElement("div", undefined), React.createElement("div", undefined, "YOUR RESPONSE"), React.createElement("div", undefined, "ANSWER"), React.createElement("div", undefined));
}

var EndScreenTableHeader = {
  make: SkilfulEndScreenContainer$EndScreenTableHeader
};

function SkilfulEndScreenContainer$EndScreenGrid(props) {
  var detailRowRender = props.detailRowRender;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var onChangeQuestion = match.onChangeQuestion;
  return React.createElement("div", undefined, React.createElement(SkilfulEndScreenContainer$EndScreenTableHeader, {}), Belt_Array.mapWithIndex(Belt_Array.make(match.questionCount, 1), (function (i, param) {
                    return React.createElement(SkilfulEndScreenContainer$EndScreenLink, {
                                detailRowRender: detailRowRender,
                                onChangeQuestion: onChangeQuestion,
                                i: i
                              });
                  })));
}

var EndScreenGrid = {
  make: SkilfulEndScreenContainer$EndScreenGrid
};

function SkilfulEndScreenContainer$EndScreenContent(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenContent.className
            }, React.createElement(SkilfulEndScreenContainer$EndScreenGrid, {
                  detailRowRender: props.detailRowRender
                }));
}

var EndScreenContent = {
  make: SkilfulEndScreenContainer$EndScreenContent
};

function detailRowRender(getStep, i) {
  var match = Curry._1(getStep, i);
  if (typeof match !== "object") {
    return [
            "Not Attempted",
            null,
            "---"
          ];
  }
  if (match.TAG === "Attempted") {
    return [
            "Attempted",
            null,
            "---"
          ];
  }
  var match$1 = match._0;
  if (typeof match$1 !== "object") {
    return [
            "Attempted",
            null,
            ""
          ];
  }
  var questionCorrect = match$1._0.questionCorrect;
  var tmp = match._1;
  if (typeof tmp !== "object") {
    if (questionCorrect) {
      return [
              "Not Attempted",
              React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.EndScreenCorrectIcon.className
                  }),
              "Correct"
            ];
    }
    if (!questionCorrect) {
      return [
              "Not Attempted",
              React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.EndScreenIncorrectIcon.className
                  }),
              "Incorrect"
            ];
    }
    
  }
  if (questionCorrect) {
    return [
            "Attempted",
            React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.EndScreenCorrectIcon.className
                }),
            "Correct"
          ];
  } else if (questionCorrect) {
    return [
            "Attempted",
            null,
            ""
          ];
  } else {
    return [
            "Attempted",
            React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.EndScreenIncorrectIcon.className
                }),
            "Incorrect"
          ];
  }
}

function SkilfulEndScreenContainer(props) {
  var __detailRowRender = props.detailRowRender;
  var detailRowRender$1 = __detailRowRender !== undefined ? __detailRowRender : detailRowRender;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenContainer.className
            }, React.createElement(SkilfulEndScreenContainer$EndScreenHeader, {}), React.createElement(SkilfulEndScreenContainer$EndScreenContent, {
                  detailRowRender: detailRowRender$1
                }), React.createElement(SkilfulEndScreenContainer$EndScreenFooter, {}));
}

var make = SkilfulEndScreenContainer;

export {
  Button ,
  MarkButton ,
  BehaviourMarkAllButton ,
  EndScreenHeader ,
  EndScreenFooter ,
  EndScreenQuestionIndex ,
  EndScreenPlayIcon ,
  EndScreenMarkingState ,
  EndScreenLink ,
  EndScreenTableHeader ,
  EndScreenGrid ,
  EndScreenContent ,
  detailRowRender ,
  make ,
}
/* react Not a pure module */
