// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import BackgroundJpg from "./assets/background.jpg";
import Tile_backgroundSvg from "./assets/tile_background.svg";
import Void_backgroundWebp from "./assets/void_background.webp";
import * as Wrap$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Wrap.res.mjs";
import * as PageTypes$LiteracyplanetClientSxEntry from "../PageTypes.res.mjs";
import * as UserContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/UserContext.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";
import * as MarginPanel$LiteracyplanetClientSxEntry from "./MarginPanel.res.mjs";
import * as MobileUtils$LiteracyplanetClientSxEntry from "../MobileUtils.res.mjs";
import * as ForestNavBar$LiteracyplanetClientSxEntry from "./ForestNavBar.res.mjs";
import * as BubbleNavBarRatio$LiteracyplanetClientSxEntry from "./BubbleNavBarRatio.res.mjs";
import * as InitialsMenuRatio$LiteracyplanetClientSxEntry from "./InitialsMenuRatio.res.mjs";
import * as ModernNavBarRatio$LiteracyplanetClientSxEntry from "./ModernNavBarRatio.res.mjs";
import * as BackgroundComponent$LiteracyplanetClientSxEntry from "./BackgroundComponent.res.mjs";
import * as JourneyInitialsMenu$LiteracyplanetClientSxEntry from "./JourneyInitialsMenu.res.mjs";

var backgroundSrc = BackgroundJpg;

var intrepizineBackgroundSrc = Tile_backgroundSvg;

var voidBackgroundSrc = Void_backgroundWebp;

function use(redirect, whatExperience, isPageBlacklisted) {
  var libraryPage = {
    TAG: "StudentLibrary",
    _0: undefined,
    _1: undefined
  };
  FlagsContext$LiteracyplanetClientFlags.use();
  var onClickCollections = function (param) {
    return Curry._1(redirect, "StudentCollections");
  };
  var onClickShop = function (param) {
    return Curry._1(redirect, "AvatarShop");
  };
  var onClickArcade = function (param) {
    return Curry._1(redirect, "Arcade");
  };
  var onClickLibrary = function (param) {
    return Curry._1(redirect, libraryPage);
  };
  var onClickIntrepizine = function (param) {
    return Curry._1(redirect, {
                TAG: "Intrepizine",
                _0: undefined
              });
  };
  var onClickMyWords = function (param) {
    return Curry._1(redirect, "MyWordsLists");
  };
  var onClickBog = function (param) {
    return Curry._1(redirect, "Bog");
  };
  var onClickReports = function (param) {
    return Curry._1(redirect, "OverviewReport");
  };
  var lockCollections = Curry._1(isPageBlacklisted, "StudentCollections");
  var lockShop = Curry._1(isPageBlacklisted, "AvatarShop");
  var lockArcade = Curry._1(isPageBlacklisted, "Arcade");
  var lockLibrary = Curry._1(isPageBlacklisted, libraryPage);
  var lockMyWords = Curry._1(isPageBlacklisted, "MyWordsLists");
  switch (whatExperience) {
    case "Secondary" :
        var onClickMissions = function (param) {
          return Curry._1(redirect, {
                      TAG: "SecondaryMissions",
                      _0: undefined
                    });
        };
        return [
                onClickMissions,
                onClickCollections,
                onClickShop,
                onClickArcade,
                onClickLibrary,
                onClickIntrepizine,
                onClickMyWords,
                onClickBog,
                onClickReports,
                lockCollections,
                lockShop,
                lockArcade,
                lockLibrary,
                lockMyWords
              ];
    case "Journey" :
    case "Storyverse" :
        break;
    
  }
  var onClickMissions$1 = function (param) {
    return Curry._1(redirect, "JourneyMissions");
  };
  return [
          onClickMissions$1,
          onClickCollections,
          onClickShop,
          onClickArcade,
          onClickLibrary,
          onClickIntrepizine,
          onClickMyWords,
          onClickBog,
          onClickReports,
          lockCollections,
          lockShop,
          lockArcade,
          lockLibrary,
          lockMyWords
        ];
}

var MakeRoutes = {
  use: use
};

function getInitial(word) {
  var sanitised = word.trim().toUpperCase();
  if (sanitised.length > 0) {
    return sanitised.substring(0, 1) + ".";
  } else {
    return "";
  }
}

function StudentExperienceWrap(props) {
  var children = props.children;
  var page = props.page;
  var layout = props.layout;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userName = user.name;
  var userInitials = getInitial(user.givenName) + getInitial(user.familyName);
  var whatExperience = Wrap$LiteracyplanetClientFlags.useStudentExperience();
  var match = use(props.redirect, whatExperience, props.isPageBlacklisted);
  var lockMyWords = match[13];
  var lockLibrary = match[12];
  var lockArcade = match[11];
  var lockShop = match[10];
  var lockCollections = match[9];
  var onClickMyWords = match[6];
  var onClickIntrepizine = match[5];
  var onClickLibrary = match[4];
  var onClickArcade = match[3];
  var onClickShop = match[2];
  var onClickCollections = match[1];
  var onClickMissions = match[0];
  var showWordManiaLink = Curry._1(hasFlag, "StudentCanAccessWM");
  var showLibraryLink = Curry._1(hasFlag, "StudentCanUseLibrary");
  var showArcade = Curry._1(hasFlag, "StudentHasArcade");
  var showTeacherModeWidget = Curry._1(hasFlag, "StudentIsStudentMode") && !process.env.MOBILE;
  var teacherModeLink = PageTypes$LiteracyplanetClientSxEntry.classicUrl("/student_mode");
  var onClickWordMania = function (param) {
    MobileUtils$LiteracyplanetClientSxEntry.openWordMania();
  };
  var onClickLogout = function (param) {
    MobileUtils$LiteracyplanetClientSxEntry.signOut();
  };
  var whatMenu = Wrap$LiteracyplanetClientFlags.useMenu();
  var whatNavBar = Wrap$LiteracyplanetClientFlags.useNavBar();
  var menu;
  switch (whatMenu) {
    case "Initials" :
        menu = React.createElement(InitialsMenuRatio$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              userName: userName,
              userInitials: userInitials,
              showWordManiaLink: showWordManiaLink,
              showTeacherModeWidget: showTeacherModeWidget,
              teacherModeLink: teacherModeLink,
              onClickWordMania: onClickWordMania,
              onClickLogout: onClickLogout
            });
        break;
    case "JourneyInitials" :
        menu = React.createElement(JourneyInitialsMenu$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              userName: userName,
              userInitials: userInitials,
              showWordManiaLink: showWordManiaLink,
              onClickWordMania: onClickWordMania,
              onClickLogout: onClickLogout
            });
        break;
    case "Menuless" :
        menu = null;
        break;
    
  }
  var isMissions;
  isMissions = typeof page !== "object" ? (
      page === "JourneyMissions" ? true : false
    ) : (
      page.TAG === "SecondaryMissions" ? true : false
    );
  var isCollections;
  isCollections = typeof page !== "object" && page === "StudentCollections" ? true : false;
  var isLibrary;
  if (typeof page !== "object") {
    isLibrary = false;
  } else {
    switch (page.TAG) {
      case "StudentLibrary" :
      case "Intrepizine" :
          isLibrary = true;
          break;
      default:
        isLibrary = false;
    }
  }
  var isArcade;
  isArcade = typeof page !== "object" && page === "Arcade" ? true : false;
  var isMyWords;
  isMyWords = typeof page !== "object" && page === "MyWordsLists" ? true : false;
  var navBar;
  switch (whatNavBar) {
    case "Modern" :
        navBar = React.createElement(ModernNavBarRatio$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              highlightMissions: isMissions,
              onClickMissions: onClickMissions,
              highlightCollections: isCollections,
              lockCollections: lockCollections,
              onClickCollections: onClickCollections,
              highlightArcade: isArcade,
              showArcade: showArcade,
              lockArcade: lockArcade,
              onClickArcade: onClickArcade,
              lockShop: lockShop,
              onClickShop: onClickShop,
              highlightLibrary: isLibrary,
              showLibraryLink: showLibraryLink,
              lockLibrary: lockLibrary,
              onClickLibrary: onClickLibrary,
              onClickIntrepizine: onClickIntrepizine,
              highlightMyWords: isMyWords,
              lockMyWords: lockMyWords,
              onClickMyWords: onClickMyWords,
              menu: menu
            });
        break;
    case "Barless" :
        navBar = menu;
        break;
    case "Bubble" :
        navBar = React.createElement(BubbleNavBarRatio$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              highlightMissions: isMissions,
              onClickMissions: onClickMissions,
              highlightCollections: isCollections,
              lockCollections: lockCollections,
              onClickCollections: onClickCollections,
              highlightArcade: isArcade,
              showArcade: showArcade,
              lockArcade: lockArcade,
              onClickArcade: onClickArcade,
              lockShop: lockShop,
              onClickShop: onClickShop,
              highlightLibrary: isLibrary,
              showLibraryLink: showLibraryLink,
              lockLibrary: lockLibrary,
              onClickLibrary: onClickLibrary,
              onClickIntrepizine: onClickIntrepizine,
              highlightMyWords: isMyWords,
              lockMyWords: lockMyWords,
              onClickMyWords: onClickMyWords,
              menu: menu
            });
        break;
    case "Papyrus" :
        navBar = React.createElement(ForestNavBar$LiteracyplanetClientSxEntry.make, {
              userName: userName,
              location: "Shabby Hut",
              onClickMissions: onClickMissions,
              onClickIntrepizine: onClickIntrepizine,
              onClickMyWords: onClickMyWords,
              onClickLibrary: onClickLibrary,
              onClickBog: match[7],
              onClickCollections: onClickCollections,
              lockArcade: lockArcade,
              onClickArcade: onClickArcade,
              lockShop: lockShop,
              onClickShop: onClickShop,
              showLibraryLink: showLibraryLink,
              showWordManiaLink: showWordManiaLink,
              onClickReports: match[8],
              onClickWordMania: onClickWordMania,
              onClickLogout: onClickLogout,
              showTeacherModeWidget: showTeacherModeWidget,
              teacherModeLink: teacherModeLink
            });
        break;
    
  }
  var bg;
  switch (props.background) {
    case "MainBackground" :
        bg = {
          TAG: "Src",
          _0: backgroundSrc
        };
        break;
    case "IntrepizineBackground" :
        bg = {
          TAG: "TiledSrc",
          _0: intrepizineBackgroundSrc
        };
        break;
    case "VoidBackground" :
        bg = {
          TAG: "Src",
          _0: voidBackgroundSrc
        };
        break;
    case "NoBackground" :
        bg = {
          TAG: "Color",
          _0: "FFF"
        };
        break;
    
  }
  switch (whatExperience) {
    case "Secondary" :
        switch (layout) {
          case "AuthWithMenu" :
              return React.createElement(React.Fragment, {}, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                              background: bg,
                              dark: true,
                              children: children
                            }));
          case "AuthWithTitleBar" :
              return React.createElement(React.Fragment, {}, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                              background: bg,
                              dark: false,
                              children: React.createElement(MarginPanel$LiteracyplanetClientSxEntry.make, {
                                    children: children,
                                    topMargin: 100
                                  })
                            }));
          case "AuthWithTitleBarAndHeader" :
              return React.createElement(React.Fragment, {}, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                              background: bg,
                              dark: false,
                              children: React.createElement(MarginPanel$LiteracyplanetClientSxEntry.make, {
                                    children: children,
                                    topMargin: 150
                                  })
                            }));
          case "DarkBackground" :
              return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                          background: bg,
                          dark: true,
                          children: children
                        });
          case "PlainBackground" :
          case "AuthNoMenu" :
              return React.createElement(React.Fragment, {}, navBar, children);
          
        }
    case "Journey" :
    case "Storyverse" :
        break;
    
  }
  switch (layout) {
    case "AuthWithMenu" :
        return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                    background: bg,
                    dark: false,
                    children: null
                  }, navBar, children);
    case "AuthWithTitleBar" :
        return React.createElement(React.Fragment, {}, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                        background: bg,
                        dark: false,
                        children: children
                      }));
    case "AuthWithTitleBarAndHeader" :
        return React.createElement(React.Fragment, {}, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                        background: bg,
                        dark: false,
                        children: React.createElement(MarginPanel$LiteracyplanetClientSxEntry.make, {
                              children: children,
                              topMargin: 150
                            })
                      }));
    case "DarkBackground" :
        return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                    background: bg,
                    dark: true,
                    children: children
                  });
    case "PlainBackground" :
    case "AuthNoMenu" :
        return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                    background: bg,
                    dark: false,
                    children: children
                  });
    
  }
}

function useStudentRedirectToMissions(redirect, isPageBlacklisted) {
  var whatExperience = Wrap$LiteracyplanetClientFlags.useStudentExperience();
  return use(redirect, whatExperience, isPageBlacklisted)[0];
}

var make = StudentExperienceWrap;

export {
  backgroundSrc ,
  intrepizineBackgroundSrc ,
  voidBackgroundSrc ,
  MakeRoutes ,
  getInitial ,
  make ,
  useStudentRedirectToMissions ,
}
/* backgroundSrc Not a pure module */
