import Main from './Main.res.mjs';

export const routes = (_appApi) => {
  return [
    {
      path: '/sx',
      component: Main
    },
    {
      path: '/sx/*',
      component: Main
    },
    {
      path: '/sx/**/*',
      component: Main
    }
  ];
};
