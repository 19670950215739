// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as BookContext$LiteracyplanetTupperware from "./BookContext.res.mjs";
import * as K2BookQuestions$LiteracyplanetTupperware from "./K2BookQuestions.res.mjs";
import * as MinimizeBookIcon$LiteracyplanetTupperware from "./MinimizeBookIcon.res.mjs";
import * as LibraryBookContext$LiteracyplanetClientSxLibrary from "@literacyplanet/client-sx-library/src/LibraryBookContext.res.mjs";

function StimulusLayout$BookQueryComponent(props) {
  var bookState = Curry._2(LibraryBookContext$LiteracyplanetClientSxLibrary.use, undefined, props.id);
  if (typeof bookState !== "object") {
    return null;
  } else {
    return Curry._1(props.children, bookState._0);
  }
}

var BookQueryComponent = {
  make: StimulusLayout$BookQueryComponent
};

function StimulusLayout$FullPageStyling(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.StimulusLayout.FullPageStyling.className
            }, props.children);
}

var FullPageStyling = {
  make: StimulusLayout$FullPageStyling
};

function StimulusLayout$MinimizeBookButton(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.MinimizeBookButton.className,
              onClick: props.onClick
            }, React.createElement(MinimizeBookIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.MinimizeBookIcon.className
                }));
}

var MinimizeBookButton = {
  make: StimulusLayout$MinimizeBookButton
};

function StimulusLayout$BookComponent(props) {
  var onClickBack = props.onClickBack;
  var endedBook = props.endedBook;
  return React.createElement(BookContext$LiteracyplanetTupperware.Consumer.make, {
              children: (function (book, page, changePage, toggleFullscreen, stimuliPresent) {
                  return React.createElement(React.Fragment, {}, React.createElement(K2BookQuestions$LiteracyplanetTupperware.MainNav.make, {}), book !== undefined ? React.createElement(K2BookQuestions$LiteracyplanetTupperware.BookContent.make, {
                                    onClickBack: onClickBack,
                                    book: book,
                                    page: page,
                                    changePage: changePage,
                                    w: 600,
                                    h: 680
                                  }) : null, endedBook ? React.createElement(StimulusLayout$MinimizeBookButton, {
                                    onClick: (function (param) {
                                        Curry._1(toggleFullscreen, undefined);
                                      })
                                  }) : null);
                })
            });
}

var BookComponent = {
  make: StimulusLayout$BookComponent
};

function StimulusLayout$Book(props) {
  var children = props.children;
  var match = React.useState(function (param) {
        return true;
      });
  var setFullScreen = match[1];
  var fullScreen = match[0];
  var toggleFullscreen = function (param) {
    Curry._1(setFullScreen, (function (fs) {
            return !fs;
          }));
  };
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setEndBook = match$1[1];
  var endedBook = match$1[0];
  var onClickBack = function (param) {
    Curry._1(setFullScreen, (function (fs) {
            return !fs;
          }));
    Curry._1(setEndBook, (function (param) {
            return true;
          }));
  };
  return React.createElement(StimulusLayout$BookQueryComponent, {
              id: props.bookId,
              children: (function (book) {
                  return React.createElement(BookContext$LiteracyplanetTupperware.Provider.make, {
                              book: book,
                              toggleFullscreen: toggleFullscreen,
                              children: fullScreen ? React.createElement(StimulusLayout$FullPageStyling, {
                                      children: React.createElement(StimulusLayout$BookComponent, {
                                            endedBook: endedBook,
                                            onClickBack: onClickBack
                                          })
                                    }) : children,
                              stimuliPresent: true
                            });
                })
            });
}

var Book = {
  make: StimulusLayout$Book
};

function StimulusLayout$NoBook(props) {
  var match = React.useState(function (param) {
        return true;
      });
  var setFullScreen = match[1];
  var toggleFullscreen = function (param) {
    Curry._1(setFullScreen, (function (fs) {
            return !fs;
          }));
  };
  return React.createElement(BookContext$LiteracyplanetTupperware.Provider.make, {
              toggleFullscreen: toggleFullscreen,
              children: props.children,
              stimuliPresent: false
            });
}

var NoBook = {
  make: StimulusLayout$NoBook
};

function StimulusLayout(props) {
  var children = props.children;
  var stimulusType = props.stimulusType;
  var stimulusId = props.stimulusId;
  if (stimulusId !== undefined && stimulusType === "Book") {
    return React.createElement(StimulusLayout$Book, {
                bookId: stimulusId,
                children: children
              });
  } else {
    return React.createElement(StimulusLayout$NoBook, {
                children: children
              });
  }
}

var make = StimulusLayout;

export {
  BookQueryComponent ,
  FullPageStyling ,
  MinimizeBookButton ,
  BookComponent ,
  Book ,
  NoBook ,
  make ,
}
/* react Not a pure module */
