// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as MemoPlay$LiteracyplanetClientFlags from "./MemoPlay.res.mjs";

function AudioButtonApi(props) {
  var onTogglePlay = props.onTogglePlay;
  var onEnd = props.onEnd;
  var src = props.src;
  var isPlaying = props.isPlaying;
  var match = React.useState(function (param) {
        if (isPlaying) {
          return "Loading";
        } else {
          return "Stopped";
        }
      });
  var setState = match[1];
  var match$1 = React.useState(function (param) {
        return "NothingCalled";
      });
  var setEvent = match$1[1];
  var $$event = match$1[0];
  React.useEffect((function (param) {
          var exit = 0;
          if (isPlaying) {
            switch ($$event) {
              case "NothingCalled" :
                  Curry._1(setState, (function (param) {
                          return "Loading";
                        }));
                  break;
              case "ToggleCalled" :
                  Curry._1(setState, (function (param) {
                          return "Loading";
                        }));
                  break;
              case "PlayingCalled" :
              case "LoadedCalled" :
                  exit = 2;
                  break;
              case "EndedCalled" :
                  exit = 1;
                  break;
              
            }
          } else {
            switch ($$event) {
              case "ToggleCalled" :
                  Curry._1(setState, (function (param) {
                          return "Stopped";
                        }));
                  break;
              case "EndedCalled" :
                  exit = 1;
                  break;
              default:
                exit = 2;
            }
          }
          switch (exit) {
            case 1 :
                Curry._1(setState, (function (param) {
                        return "Stopped";
                      }));
                break;
            case 2 :
                if (isPlaying) {
                  Curry._1(setState, (function (param) {
                          return "Playing";
                        }));
                } else {
                  Curry._1(setState, (function (param) {
                          return "Stopped";
                        }));
                }
                break;
            
          }
        }), [
        isPlaying,
        $$event
      ]);
  var onPlaying = function (param) {
    Curry._1(setEvent, (function (param) {
            return "PlayingCalled";
          }));
  };
  var onLoaded = function (param) {
    Curry._1(setEvent, (function (param) {
            return "LoadedCalled";
          }));
  };
  var toggle = function (param) {
    Curry._1(onTogglePlay, undefined);
    Curry._1(setEvent, (function (param) {
            return "ToggleCalled";
          }));
  };
  var ended = function (param) {
    Curry._1(onEnd, undefined);
    Curry._1(setEvent, (function (param) {
            return "EndedCalled";
          }));
  };
  return React.createElement(React.Fragment, {}, Curry._2(props.children, match[0], toggle), isPlaying && src !== undefined ? React.createElement(MemoPlay$LiteracyplanetClientFlags.make, {
                    src: src,
                    onEnd: ended,
                    onLoaded: onLoaded,
                    onPlaying: onPlaying
                  }) : null);
}

var make = AudioButtonApi;

export {
  make ,
}
/* react Not a pure module */
