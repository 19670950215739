// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SpaceX.res.mjs";
import * as HasuraTypes$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/HasuraTypes.res.mjs";
import * as MutationHook2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/MutationHook2.res.mjs";

var Raw = {};

var query = GraphqlTag.gql(["mutation InsertChecksAttempt($id: uuid, $assignationId: uuid, $activityId: uuid, $gameKind: String)  {\ninsertChecksAttempt: insert_literacy_checks_activity_scores_one(object: {assignationId: $assignationId, activityId: $activityId, attempt: {data: {id: $id, gameKind: $gameKind}}})  {\n__typename  \nstudentActivityAttemptId  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.insertChecksAttempt;
  return {
          insertChecksAttempt: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                studentActivityAttemptId: value$1.studentActivityAttemptId
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.insertChecksAttempt;
  var insertChecksAttempt;
  if (value$1 !== undefined) {
    var value$2 = value$1.studentActivityAttemptId;
    var value$3 = value$1.__typename;
    insertChecksAttempt = {
      __typename: value$3,
      studentActivityAttemptId: value$2
    };
  } else {
    insertChecksAttempt = null;
  }
  return {
          insertChecksAttempt: insertChecksAttempt
        };
}

function serializeVariables(inp) {
  var a = inp.id;
  var a$1 = inp.assignationId;
  var a$2 = inp.activityId;
  var a$3 = inp.gameKind;
  return {
          id: a !== undefined ? a : undefined,
          assignationId: a$1 !== undefined ? a$1 : undefined,
          activityId: a$2 !== undefined ? a$2 : undefined,
          gameKind: a$3 !== undefined ? a$3 : undefined
        };
}

function makeVariables(id, assignationId, activityId, gameKind, param) {
  return {
          id: id,
          assignationId: assignationId,
          activityId: activityId,
          gameKind: gameKind
        };
}

function makeDefaultVariables(param) {
  return {
          id: undefined,
          assignationId: undefined,
          activityId: undefined,
          gameKind: undefined
        };
}

var InsertAttemptMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Mutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var InsertAttemptMutation = {
  InsertAttemptMutation_inner: InsertAttemptMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  use: use
};

var InsertAttempt = MutationHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function useInsertAttempt(assignationId, gameId, activityId) {
  var match = Curry._1(InsertAttempt.useWithWordMonsterError, "ChecksActivityMutations.useInsertAttempt");
  var mutation = match[0];
  var id = React.useMemo((function (param) {
          return Uuid.v4();
        }), []);
  var attemptId = Belt_Option.flatMap(match[3], (function (d) {
          return Belt_Option.map(d.insertChecksAttempt, (function (a) {
                        return HasuraTypes$LiteracyplanetClientFlags.decode(a.studentActivityAttemptId);
                      }));
        }));
  var insert = function (param) {
    Curry._1(mutation, {
          id: HasuraTypes$LiteracyplanetClientFlags.encode(id),
          assignationId: assignationId,
          activityId: activityId,
          gameKind: gameId
        });
  };
  return [
          insert,
          attemptId
        ];
}

var Raw$1 = {};

var query$1 = GraphqlTag.gql(["mutation MarkComplete($studentActivityAttemptId: uuid, $assignationId: uuid)  {\nupdate_student_activity_attempts(where: {id: {_eq: $studentActivityAttemptId}}, _set: {complete: true})  {\n__typename  \naffected_rows  \n}\n\nliteracy_checks_mark_student_assignation_complete(args: {assignation_id: $assignationId, student_activity_attempt_id: $studentActivityAttemptId})  {\n__typename  \ncompletedAt  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.update_student_activity_attempts;
  var value$2 = value.literacy_checks_mark_student_assignation_complete;
  return {
          update_student_activity_attempts: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                affected_rows: value$1.affected_rows
              }) : undefined,
          literacy_checks_mark_student_assignation_complete: value$2.map(function (value) {
                var value$1 = value.completedAt;
                return {
                        __typename: value.__typename,
                        completedAt: !(value$1 == null) ? value$1 : undefined
                      };
              })
        };
}

function serialize$1(value) {
  var value$1 = value.literacy_checks_mark_student_assignation_complete;
  var literacy_checks_mark_student_assignation_complete = value$1.map(function (value) {
        var value$1 = value.completedAt;
        var completedAt = value$1 !== undefined ? value$1 : null;
        var value$2 = value.__typename;
        return {
                __typename: value$2,
                completedAt: completedAt
              };
      });
  var value$2 = value.update_student_activity_attempts;
  var update_student_activity_attempts;
  if (value$2 !== undefined) {
    var value$3 = value$2.affected_rows;
    var value$4 = value$2.__typename;
    update_student_activity_attempts = {
      __typename: value$4,
      affected_rows: value$3
    };
  } else {
    update_student_activity_attempts = null;
  }
  return {
          update_student_activity_attempts: update_student_activity_attempts,
          literacy_checks_mark_student_assignation_complete: literacy_checks_mark_student_assignation_complete
        };
}

function serializeVariables$1(inp) {
  var a = inp.studentActivityAttemptId;
  var a$1 = inp.assignationId;
  return {
          studentActivityAttemptId: a !== undefined ? a : undefined,
          assignationId: a$1 !== undefined ? a$1 : undefined
        };
}

function makeVariables$1(studentActivityAttemptId, assignationId, param) {
  return {
          studentActivityAttemptId: studentActivityAttemptId,
          assignationId: assignationId
        };
}

function makeDefaultVariables$1(param) {
  return {
          studentActivityAttemptId: undefined,
          assignationId: undefined
        };
}

var MarkCompleteMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = SpaceX$LiteracyplanetClientFlags.Mutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var MarkCompleteMutation = {
  MarkCompleteMutation_inner: MarkCompleteMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1,
  use: use$1
};

var MarkComplete = MutationHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw$1,
      use: use$1
    });

function useMarkComplete(assignationId) {
  var match = Curry._2(MarkComplete.useRetry, "ChecksActivityMutations.useMarkComplete", 3);
  var mutation = match[0];
  var update = function (studentActivityAttemptId) {
    var r_studentActivityAttemptId = HasuraTypes$LiteracyplanetClientFlags.encode(studentActivityAttemptId);
    var r_assignationId = assignationId;
    var r = {
      studentActivityAttemptId: r_studentActivityAttemptId,
      assignationId: r_assignationId
    };
    Curry._2(mutation, r, undefined);
  };
  return [
          update,
          match[3]
        ];
}

var decode = HasuraTypes$LiteracyplanetClientFlags.decode;

export {
  InsertAttemptMutation ,
  InsertAttempt ,
  decode ,
  useInsertAttempt ,
  MarkCompleteMutation ,
  MarkComplete ,
  useMarkComplete ,
}
/* query Not a pure module */
