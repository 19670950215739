// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxPPXReactSupportC from "rescript/lib/es6/jsxPPXReactSupportC.js";
import * as Lang$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Lang.res.mjs";
import * as Wrap$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Wrap.res.mjs";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";
import * as Lazy$LiteracyplanetClientSxEntry from "./Lazy.res.mjs";
import * as Option2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Option2.res.mjs";
import * as NoMatch$LiteracyplanetClientSxEntry from "./NoMatch.res.mjs";
import * as HasuraTypes$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/HasuraTypes.res.mjs";
import * as UserContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/UserContext.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";
import * as RouteContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/RouteContext.res.mjs";
import * as InMemoryStore$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/InMemoryStore.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/LocaleContext.res.mjs";
import * as MobileUtils$LiteracyplanetClientSxEntry from "./MobileUtils.res.mjs";
import * as ProRequired$LiteracyplanetClientSxEntry from "./ProRequired.res.mjs";
import * as CobraContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/CobraContainer.res.mjs";
import * as SeenBeforeQuery$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SeenBeforeQuery.res.mjs";
import * as StoryverseIntro$LiteracyplanetClientSxEntry from "./book_of_goings/StoryverseIntro.res.mjs";
import * as ChecksLobbyQuery$LiteracyplanetClientSxEntry from "./checks/ChecksLobbyQuery.res.mjs";
import * as SeenBeforeMutation$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SeenBeforeMutation.res.mjs";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "./BackgroundChanger.res.mjs";
import * as BogTabSerialisation$LiteracyplanetClientSxEntry from "./book_of_goings/BogTabSerialisation.res.mjs";
import * as JourneyMissionsQuery$LiteracyplanetClientSxEntry from "./JourneyMissionsQuery.res.mjs";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "./JourneyCurrentStudent.res.mjs";
import * as JourneyActivitiesQuery$LiteracyplanetClientSxEntry from "./JourneyActivitiesQuery.res.mjs";
import * as JourneyActivitiesScreen$LiteracyplanetClientSxEntry from "./JourneyActivitiesScreen.res.mjs";
import * as DeepLinkActivitiesScreen$LiteracyplanetClientSxEntry from "./DeepLinkActivitiesScreen.res.mjs";
import * as FlipperChecksActivityLauncher$LiteracyplanetClientSxActivity from "@literacyplanet/client-sx-activity/src/flipper/FlipperChecksActivityLauncher.res.mjs";

function vodFolder(video) {
  return "$videoFolder/$video/Default/HLS/$video.m3u8";
}

function devPrefix(isDev, name) {
  return (
          isDev ? "dev_" : ""
        ) + name;
}

function source(isDev, videoName) {
  devPrefix(isDev, videoName);
  return "$videoFolder/$video/Default/HLS/$video.m3u8";
}

function Page$JourneyIntroCheck(props) {
  var redirect = props.redirect;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var redirectJourney = function (param) {
    Curry._1(redirect, "Journey");
  };
  var redirectJourneyMissions = function (param) {
    Curry._1(redirect, "JourneyMissions");
  };
  var seenBefore = SeenBeforeQuery$LiteracyplanetClientFlags.useCurrentUser();
  React.useEffect((function (param) {
          if (Curry._1(hasFlag, "StudentHasJourneyIntro")) {
            SeenBeforeQuery$LiteracyplanetClientFlags.hasSeenType(seenBefore, "JOURNEY_INTRO", redirectJourneyMissions, redirectJourney);
          } else {
            Curry._1(redirect, "JourneyMissions");
          }
        }), [seenBefore]);
  return null;
}

var JourneyIntroCheck = {
  make: Page$JourneyIntroCheck
};

function Page$StoryverseIntroCheck(props) {
  var redirect = props.redirect;
  FlagsContext$LiteracyplanetClientFlags.use();
  var redirectStoryverseIntro = function (param) {
    Curry._1(redirect, "StoryverseIntro");
  };
  var redirectChecksLobby = function (param) {
    Curry._1(redirect, "ChecksLobby");
  };
  var seenBefore = SeenBeforeQuery$LiteracyplanetClientFlags.useCurrentUser();
  React.useEffect((function (param) {
          SeenBeforeQuery$LiteracyplanetClientFlags.hasSeenType(seenBefore, "STORYVERSE_INTRO", redirectChecksLobby, redirectStoryverseIntro);
        }), [seenBefore]);
  return null;
}

var StoryverseIntroCheck = {
  make: Page$StoryverseIntroCheck
};

function Page$StudentExperience(props) {
  var redirect = props.redirect;
  var whatExperience = Wrap$LiteracyplanetClientFlags.useStudentExperience();
  React.useEffect((function (param) {
          if (whatExperience === "Secondary") {
            Curry._1(redirect, "ChecksLobby");
          }
          
        }), []);
  switch (whatExperience) {
    case "Secondary" :
        return null;
    case "Journey" :
        return React.createElement(Page$JourneyIntroCheck, {
                    redirect: redirect
                  });
    case "Storyverse" :
        return React.createElement(Page$StoryverseIntroCheck, {
                    redirect: redirect
                  });
    
  }
}

var StudentExperience = {
  make: Page$StudentExperience
};

function Page$StudentCollections(props) {
  var isPageBlacklisted = props.isPageBlacklisted;
  var redirect = props.redirect;
  FlagsContext$LiteracyplanetClientFlags.use();
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  var onClickShop = function (param) {
    Curry._1(redirect, "AvatarShop");
  };
  var onClickArcade = function (param) {
    Curry._1(redirect, "Arcade");
  };
  var isShopLocked = Curry._1(isPageBlacklisted, "AvatarShop");
  var isArcadeLocked = Curry._1(isPageBlacklisted, "Arcade");
  var isMiniGameLocked = Curry._1(props.isItemBlacklisted, "MiniGame");
  var isProRequired = ProRequired$LiteracyplanetClientSxEntry.use();
  var key = "locked-" + (
    isShopLocked ? "true" : "false"
  );
  return React.createElement(React.Suspense, {
              children: Caml_option.some(JsxPPXReactSupportC.createElementWithKey(key, Lazy$LiteracyplanetClientSxEntry.CollectionsContainer.make, {
                        userId: userId,
                        onClickShop: onClickShop,
                        onClickArcade: onClickArcade,
                        isShopLocked: isShopLocked,
                        isArcadeLocked: isArcadeLocked,
                        isMiniGameLocked: isMiniGameLocked,
                        onToggleProModal: props.onToggleProModal,
                        onToggleLockedModal: props.onToggleLockedModal,
                        isProRequiredForMiniGame: Curry._1(isProRequired, "MiniGame")
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var StudentCollections = {
  make: Page$StudentCollections
};

function Page$StudentLibrary(props) {
  var redirect = props.redirect;
  var defaultGroupIndex = Belt_Option.mapWithDefault(props.groupIndex, 0, Caml_format.int_of_string);
  var onChangeBookId = function (bookId) {
    Curry._1(redirect, {
          TAG: "StudentLibrary",
          _0: String(defaultGroupIndex),
          _1: bookId
        });
  };
  var onChangeGroupIndex = function (groupIndex) {
    Curry._1(redirect, {
          TAG: "StudentLibrary",
          _0: String(groupIndex),
          _1: undefined
        });
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.Library.make, {
                        bookId: props.bookId,
                        groupIndex: defaultGroupIndex,
                        onChangeBookId: onChangeBookId,
                        onChangeGroupIndex: onChangeGroupIndex
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var StudentLibrary = {
  make: Page$StudentLibrary
};

function Page$SecondaryMissions(props) {
  var redirect = props.redirect;
  var value_onClickActivity = function (studentActivityId, locale, accent) {
    Curry._1(redirect, {
          TAG: "StudentActivity",
          _0: locale,
          _1: accent,
          _2: studentActivityId
        });
  };
  var value_onClickMissionFilter = function (_filter) {
    Curry._1(redirect, {
          TAG: "SecondaryMissions",
          _0: undefined
        });
  };
  var value = {
    onClickActivity: value_onClickActivity,
    onClickMissionFilter: value_onClickMissionFilter
  };
  return React.createElement(RouteContext$LiteracyplanetClientFlags.Missions.make, {
              value: value,
              children: React.createElement(React.Suspense, {
                    children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.SecondaryMissions.make, {})),
                    fallback: Caml_option.some(React.createElement("div", undefined))
                  })
            });
}

var SecondaryMissions = {
  make: Page$SecondaryMissions
};

function Page$NoMatch(props) {
  return React.createElement(NoMatch$LiteracyplanetClientSxEntry.make, {});
}

var NoMatch = {
  make: Page$NoMatch
};

function Page$Activity(props) {
  var redirect = props.redirect;
  var isPageBlacklisted = props.isPageBlacklisted;
  var onToggleLocked = props.onToggleLocked;
  var user = UserContext$LiteracyplanetClientFlags.use();
  var onMaybeGotoCollections = function (callback) {
    if (Curry._1(isPageBlacklisted, "StudentCollections")) {
      return Curry._1(onToggleLocked, undefined);
    } else {
      return Curry._1(callback, Curry._1(redirect, "StudentCollections"));
    }
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.FlipperActivity.make, {
                        userId: user.userId,
                        onMaybeGotoCollections: onMaybeGotoCollections,
                        onActivityNotFound: props.onActivityNotFound,
                        onGoToMissions: props.onGoToMissions,
                        onExit: props.onExit,
                        studentActivityId: props.studentActivityId,
                        accent: Lang$LiteracyplanetClientFlags.decodeAccent(props.accent),
                        locale: Lang$LiteracyplanetClientFlags.decodeLocale(props.locale)
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var Activity = {
  make: Page$Activity
};

function Page$StudentActivity(props) {
  var onGoToMissions = props.onGoToMissions;
  return React.createElement(Page$Activity, {
              onGoToMissions: onGoToMissions,
              onActivityNotFound: onGoToMissions,
              onToggleLocked: props.onToggleLocked,
              isPageBlacklisted: props.isPageBlacklisted,
              redirect: props.redirect,
              onExit: onGoToMissions,
              locale: props.locale,
              accent: props.accent,
              studentActivityId: props.studentActivityId
            });
}

var StudentActivity = {
  make: Page$StudentActivity
};

function Page$PlayAnyActivity(props) {
  var goBack = props.goBack;
  FlagsContext$LiteracyplanetClientFlags.use();
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.FlipperPlayAnyActivity.make, {
                        contentId: props.contentId,
                        contentVersion: props.contentVersion,
                        contentKind: props.contentKind,
                        kind: props.kind,
                        locale: Lang$LiteracyplanetClientFlags.decodeLocale(props.locale),
                        accent: Lang$LiteracyplanetClientFlags.decodeAccent(props.accent),
                        title: Js_dict.get(props.state, "title"),
                        onExit: (function (param) {
                            Curry._1(goBack, undefined);
                          }),
                        onGoToMissions: (function (param) {
                            Curry._1(goBack, undefined);
                          }),
                        onMaybeGotoCollections: (function (callback) {
                            Curry._1(callback, undefined);
                          })
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var PlayAnyActivity = {
  make: Page$PlayAnyActivity
};

function Page$AvatarShop(props) {
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  var isProRequired = ProRequired$LiteracyplanetClientSxEntry.use();
  var isProAccessLocked = Curry._1(isProRequired, "AvatarShop");
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.AvatarShop.make, {
                        userId: userId,
                        isProAccessLocked: isProAccessLocked,
                        onExit: props.onGoToMissions
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var AvatarShop = {
  make: Page$AvatarShop
};

function Page$StoryverseIntro(props) {
  var redirect = props.redirect;
  var markSeenBefore = SeenBeforeMutation$LiteracyplanetClientFlags.useSeenBeforeWithUser("STORYVERSE_INTRO");
  var onExit = function (param) {
    Curry._1(markSeenBefore, undefined);
    Curry._1(redirect, "ChecksLobby");
  };
  return React.createElement(StoryverseIntro$LiteracyplanetClientSxEntry.make, {
              onExit: onExit
            });
}

var StoryverseIntro = {
  make: Page$StoryverseIntro
};

function Page$Bog(props) {
  var redirectWithState = props.redirectWithState;
  var state = props.state;
  var redirect = props.redirect;
  var onChangeNavigation = function (nav) {
    state["nav"] = BogTabSerialisation$LiteracyplanetClientSxEntry.encode(nav);
    Curry._2(redirectWithState, "Bog", state);
  };
  var onExit = function (param) {
    Curry._1(redirect, "JourneyMissions");
  };
  var nav = Belt_Option.mapWithDefault(Js_dict.get(state, "nav"), "BogDefault", (function (nav) {
          return BogTabSerialisation$LiteracyplanetClientSxEntry.decode(nav);
        }));
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.BogContainer.make, {
                        onExit: onExit,
                        onChangeNavigation: onChangeNavigation,
                        nav: nav
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var Bog = {
  make: Page$Bog
};

function Page$JourneyMissions(props) {
  var redirect = props.redirect;
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use();
  var setBackground = match[1];
  LocaleContext$LiteracyplanetClientFlags.use();
  var missionStates = JourneyMissionsQuery$LiteracyplanetClientSxEntry.useCurrentUser();
  var match$1 = Error$LiteracyplanetClientErrors.Consumer.use();
  var onSetError = match$1.onSetError;
  JourneyActivitiesQuery$LiteracyplanetClientSxEntry.setReferrerOnLoad("mission");
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var showWordManiaLink = Curry._1(hasFlag, "StudentCanAccessWM");
  if (typeof missionStates !== "object") {
    return null;
  }
  var onDispatch = {
    onJourneyMissionSelected: (function (missionId) {
        return Curry._1(redirect, {
                    TAG: "JourneyActivities",
                    _0: missionId
                  });
      }),
    onPreloaderReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      }),
    onJourneyReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      }),
    onFailed: (function (err) {
        Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError("JourneyMissionsError", err));
      }),
    onGoToWordMania: showWordManiaLink ? (function (param) {
          MobileUtils$LiteracyplanetClientSxEntry.openWordMania();
        }) : undefined
  };
  return React.createElement("div", {
              style: match[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "jms",
                  props: missionStates._0,
                  onDispatch: onDispatch
                }));
}

var JourneyMissions = {
  make: Page$JourneyMissions
};

function Page$JourneyActivities(props) {
  return React.createElement(JourneyActivitiesScreen$LiteracyplanetClientSxEntry.make, {
              redirect: props.redirect,
              studentMissionId: Caml_format.int_of_string(props.studentMissionId)
            });
}

var JourneyActivities = {
  make: Page$JourneyActivities
};

function Page$JourneyActivity(props) {
  var isAssessment = props.isAssessment;
  var studentMissionId = props.studentMissionId;
  var redirect = props.redirect;
  var setReferrer = JourneyActivitiesQuery$LiteracyplanetClientSxEntry.referrerSetter();
  var onGoToMissions = function (param) {
    Curry._1(setReferrer, "activityComplete");
    if (isAssessment) {
      return Curry._1(redirect, "JourneyMissions");
    } else {
      return Curry._1(redirect, {
                  TAG: "JourneyActivities",
                  _0: studentMissionId
                });
    }
  };
  var onExit = function (param) {
    Curry._1(setReferrer, "activityAbort");
    Curry._1(redirect, {
          TAG: "JourneyActivities",
          _0: studentMissionId
        });
  };
  return React.createElement(Page$Activity, {
              onGoToMissions: onGoToMissions,
              onActivityNotFound: onGoToMissions,
              onToggleLocked: props.onToggleLocked,
              isPageBlacklisted: props.isPageBlacklisted,
              redirect: redirect,
              onExit: onExit,
              locale: props.locale,
              accent: props.accent,
              studentActivityId: props.studentActivityId
            });
}

var JourneyActivity = {
  make: Page$JourneyActivity
};

function Page$Arcade(props) {
  var redirect = props.redirect;
  var onClickGame = function (gameName) {
    Curry._1(redirect, {
          TAG: "ArcadeGame",
          _0: gameName
        });
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.Arcade.make, {
                        onClickGame: onClickGame
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var Arcade = {
  make: Page$Arcade
};

function Page$ArcadeGame(props) {
  var redirect = props.redirect;
  var api = LocaleContext$LiteracyplanetClientFlags.use();
  var locale = api.localeString;
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  var onExit = function (param) {
    Curry._1(redirect, "Arcade");
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.ArcadeGame.make, {
                        onExit: onExit,
                        locale: locale,
                        gameKind: props.gameKind,
                        userId: userId
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var ArcadeGame = {
  make: Page$ArcadeGame
};

function Page$WordManiaGame(props) {
  var redirect = props.redirect;
  var onExit = function (param) {
    Curry._1(redirect, "Arcade");
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.WordManiaGame.make, {
                        onExit: onExit
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var WordManiaGame = {
  make: Page$WordManiaGame
};

function Page$MyWordsLists(props) {
  var state = props.state;
  var replaceWithState = props.replaceWithState;
  var redirect = props.redirect;
  var onNewList = function (param) {
    Curry._1(redirect, {
          TAG: "MyWordsNew",
          _0: Uuid.v4()
        });
  };
  var onEditList = function (id) {
    Curry._1(redirect, {
          TAG: "MyWordsAssign",
          _0: id
        });
  };
  var onSetPage = function (page) {
    state["page"] = String(page);
    Curry._2(replaceWithState, "MyWordsLists", state);
  };
  var page = Belt_Option.mapWithDefault(Js_dict.get(state, "page"), 1, Caml_format.int_of_string);
  var searchName = Js_dict.get(state, "searchName");
  var onSearchName = function (name) {
    if (name === "") {
      Js_dict.unsafeDeleteKey(state, "searchName");
    } else {
      state["searchName"] = name;
    }
    state["page"] = "1";
    Curry._2(replaceWithState, "MyWordsLists", state);
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsIndexContainer.make, {
                        onNewList: onNewList,
                        onEditList: onEditList,
                        onSetPage: onSetPage,
                        page: page,
                        searchName: searchName,
                        onSearchName: onSearchName
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var MyWordsLists = {
  make: Page$MyWordsLists
};

function Page$MyWordsAssign(props) {
  var id = props.id;
  var redirect = props.redirect;
  var onBack = function (param) {
    Curry._1(redirect, "MyWordsLists");
  };
  var onEditList = function (param) {
    Curry._1(redirect, {
          TAG: "MyWordsEdit",
          _0: id
        });
  };
  var onPlayActivity = function (kind) {
    Curry._1(redirect, {
          TAG: "MyWordsActivity",
          _0: id,
          _1: kind
        });
  };
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsAssignContainer.make, {
                        onBack: onBack,
                        onEditList: onEditList,
                        onPlayActivity: onPlayActivity,
                        customWordListId: id,
                        userId: userId
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var MyWordsAssign = {
  make: Page$MyWordsAssign
};

function Page$MyWordsEdit(props) {
  var redirect = props.redirect;
  var id = props.id;
  var onBack = function (param) {
    Curry._1(redirect, {
          TAG: "MyWordsAssign",
          _0: id
        });
  };
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsEditContainer.make, {
                        onBack: onBack,
                        onSave: onBack,
                        customWordListId: id,
                        userId: userId
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var MyWordsEdit = {
  make: Page$MyWordsEdit
};

function Page$MyWordsNew(props) {
  var redirect = props.redirect;
  var id = props.id;
  var onBack = function (param) {
    Curry._1(redirect, "MyWordsLists");
  };
  var onSave = function (param) {
    Curry._1(redirect, {
          TAG: "MyWordsAssign",
          _0: id
        });
  };
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsEditContainer.make, {
                        onBack: onBack,
                        onSave: onSave,
                        customWordListId: id,
                        userId: userId
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var MyWordsNew = {
  make: Page$MyWordsNew
};

function Page$MyWordsActivity(props) {
  var redirect = props.redirect;
  var id = props.id;
  var onBack = function (param) {
    Curry._1(redirect, {
          TAG: "MyWordsAssign",
          _0: id
        });
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsActivityContainer.make, {
                        gameId: props.gameId,
                        onBack: onBack,
                        customWordListId: id
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var MyWordsActivity = {
  make: Page$MyWordsActivity
};

function Page$ChecksLobby$LcLobby(props) {
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use();
  var setBackground = match[1];
  var match$1 = Error$LiteracyplanetClientErrors.Consumer.use();
  var onSetError = match$1.onSetError;
  var onDispatch = {
    onJourneyActivitySelected: props.redirectChecksActivity,
    onExit: (function (param) {
        
      }),
    onPreloaderReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      }),
    onJourneyReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      }),
    onFailed: (function (err) {
        Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError("JourneyActivitiesError", err));
      }),
    onLogOut: (function (param) {
        MobileUtils$LiteracyplanetClientSxEntry.signOut();
      })
  };
  var makeProps = function (response, currentCheck) {
    return Belt_Option.map(response.currentStudent, (function (s) {
                  return {
                          currentStudent: JourneyCurrentStudent$LiteracyplanetClientSxEntry.makeCurrentStudent(s),
                          activities: Belt_Array.map(response.checks, (function (check) {
                                  return {
                                          id: check.activityId,
                                          kind: check.gameId,
                                          learningMode: {
                                            kind: check.strand,
                                            label: check.strand
                                          },
                                          complete: check.activityComplete,
                                          bestScore: undefined,
                                          inProgress: false,
                                          locked: Caml_obj.notequal(currentCheck.activityId, check.activityId),
                                          assessment: false
                                        };
                                })),
                          mission: {
                            title: "Literacy Check",
                            chest: "GEM"
                          },
                          referrer: "",
                          isLiteracyCheck: true
                        };
                }));
  };
  return React.createElement("div", {
              style: match[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "jas",
                  props: makeProps(props.res, props.currentCheck),
                  onDispatch: onDispatch
                }));
}

var LcLobby = {
  make: Page$ChecksLobby$LcLobby
};

function Page$ChecksLobby$LcAssignmentComplete(props) {
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use();
  var setBackground = match[1];
  var onDispatch = {
    onResultReady: (function (param) {
        
      }),
    onExit: props.redirectJourneyMissions,
    onPreloaderReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      })
  };
  return React.createElement("div", {
              style: match[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "literacyCheckComplete",
                  props: {
                    results: {
                      title: "Literacy Check"
                    }
                  },
                  onDispatch: onDispatch
                }));
}

var LcAssignmentComplete = {
  make: Page$ChecksLobby$LcAssignmentComplete
};

function Page$ChecksLobby$RedirectComponent(props) {
  var dest = props.dest;
  React.useEffect((function (param) {
          Curry._1(dest, undefined);
        }), []);
  return null;
}

var RedirectComponent = {
  make: Page$ChecksLobby$RedirectComponent
};

function Page$ChecksLobby$MarkAssignationCompleteWhenAllActivitiesAreComplete(props) {
  return React.createElement(React.Fragment, {}, Belt_Option.mapWithDefault(Belt_Array.reduce(props.checks, undefined, (function (acc, c) {
                        return Option2$LiteracyplanetClientFlags.combine(c.assignationId, Belt_Option.map(c.studentActivityAttemptId, HasuraTypes$LiteracyplanetClientFlags.decode));
                      })), null, (function (param) {
                    return React.createElement(FlipperChecksActivityLauncher$LiteracyplanetClientSxActivity.MarkCompleteWhenAllComplete.make, {
                                assignationId: param[0],
                                studentActivityAttemptId: param[1]
                              });
                  })), React.createElement(Page$ChecksLobby$RedirectComponent, {
                  dest: props.dest
                }));
}

var MarkAssignationCompleteWhenAllActivitiesAreComplete = {
  make: Page$ChecksLobby$MarkAssignationCompleteWhenAllActivitiesAreComplete
};

function Page$ChecksLobby(props) {
  var onGoToMissions = props.onGoToMissions;
  var redirect = props.redirect;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var hasLC = Curry._1(hasFlag, "StudentHasLiteracyCheck");
  var setInMemory = InMemoryStore$LiteracyplanetClientFlags.useSetter();
  var redirectJourneyMissions = function (param) {
    Curry._2(setInMemory, "ranLcActivity", "false");
    Curry._1(onGoToMissions, undefined);
  };
  var redirectChecksActivity = function (activityId) {
    return Curry._1(redirect, "ChecksActivity");
  };
  var userComingBackFromActivity = Belt_Option.getWithDefault(Belt_Option.map(InMemoryStore$LiteracyplanetClientFlags.useGetter("ranLcActivity"), (function (v) {
              return v === "true";
            })), false);
  React.useEffect((function (param) {
          if (hasLC) {
            
          } else {
            redirectJourneyMissions();
          }
        }), []);
  var state = ChecksLobbyQuery$LiteracyplanetClientSxEntry.use(!hasLC);
  if (typeof state !== "object") {
    return null;
  }
  var res = state._0;
  var currentCheck = Belt_Array.getBy(res.checks, (function (c) {
          return Caml_obj.equal(c.activityComplete, false);
        }));
  if (currentCheck !== undefined) {
    return React.createElement(Page$ChecksLobby$LcLobby, {
                redirectChecksActivity: redirectChecksActivity,
                redirectJourneyMissions: redirectJourneyMissions,
                res: res,
                currentCheck: currentCheck
              });
  } else if (userComingBackFromActivity) {
    return React.createElement(Page$ChecksLobby$LcAssignmentComplete, {
                redirectJourneyMissions: redirectJourneyMissions
              });
  } else {
    return React.createElement(Page$ChecksLobby$MarkAssignationCompleteWhenAllActivitiesAreComplete, {
                checks: res.checks,
                dest: redirectJourneyMissions
              });
  }
}

var ChecksLobby = {
  LcLobby: LcLobby,
  LcAssignmentComplete: LcAssignmentComplete,
  RedirectComponent: RedirectComponent,
  MarkAssignationCompleteWhenAllActivitiesAreComplete: MarkAssignationCompleteWhenAllActivitiesAreComplete,
  make: Page$ChecksLobby
};

function Page$OverviewReport(props) {
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.OverviewReportContainer.make, {
                        userId: userId
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var OverviewReport = {
  make: Page$OverviewReport
};

function Page$ChecksActivity(props) {
  var redirect = props.redirect;
  var onBack = function (param) {
    Curry._1(redirect, "ChecksLobby");
  };
  return React.createElement(React.Suspense, {
              children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.ChecksActivityContainer.make, {
                        onBack: onBack
                      })),
              fallback: Caml_option.some(React.createElement("div", undefined))
            });
}

var ChecksActivity = {
  make: Page$ChecksActivity
};

function Page$Intrepizine(props) {
  var state = props.state;
  var pageToUrlWithState = props.pageToUrlWithState;
  var postUrl = function (postId, title) {
    state["title"] = title;
    return Curry._2(pageToUrlWithState, {
                TAG: "Intrepizine",
                _0: postId
              }, state);
  };
  var indexUrl = function (param) {
    return Curry._2(pageToUrlWithState, {
                TAG: "Intrepizine",
                _0: undefined
              }, {});
  };
  var postTitle = Belt_Option.getWithDefault(Js_dict.get(state, "title"), "Article");
  return React.createElement("div", {
              className: "sx-iz"
            }, React.createElement(React.Suspense, {
                  children: Caml_option.some(React.createElement(Lazy$LiteracyplanetClientSxEntry.IntrepizineContainer.make, {
                            optionalPostId: props.optionalPostId,
                            postUrl: postUrl,
                            indexUrl: indexUrl,
                            postTitle: postTitle
                          })),
                  fallback: Caml_option.some(React.createElement("div", undefined))
                }));
}

var Intrepizine = {
  make: Page$Intrepizine
};

function Page$DeepLinkActivities(props) {
  return React.createElement(DeepLinkActivitiesScreen$LiteracyplanetClientSxEntry.make, {
              redirect: props.redirect,
              missionRef: props.missionRef
            });
}

var DeepLinkActivities = {
  make: Page$DeepLinkActivities
};

var videoFolder = "https://vod-watchfolder-literacyplanet.s3-ap-southeast-2.amazonaws.com";

export {
  videoFolder ,
  vodFolder ,
  devPrefix ,
  source ,
  JourneyIntroCheck ,
  StoryverseIntroCheck ,
  StudentExperience ,
  StudentCollections ,
  StudentLibrary ,
  SecondaryMissions ,
  NoMatch ,
  Activity ,
  StudentActivity ,
  PlayAnyActivity ,
  AvatarShop ,
  StoryverseIntro ,
  Bog ,
  JourneyMissions ,
  JourneyActivities ,
  JourneyActivity ,
  Arcade ,
  ArcadeGame ,
  WordManiaGame ,
  MyWordsLists ,
  MyWordsAssign ,
  MyWordsEdit ,
  MyWordsNew ,
  MyWordsActivity ,
  ChecksLobby ,
  OverviewReport ,
  ChecksActivity ,
  Intrepizine ,
  DeepLinkActivities ,
}
/* uuid Not a pure module */
