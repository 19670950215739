// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function initialState_hasFlag(param) {
  return false;
}

function initialState_unsafeHasFlag(param) {
  return false;
}

var initialState = {
  hasFlag: initialState_hasFlag,
  unsafeHasFlag: initialState_unsafeHasFlag
};

var context = React.createContext(initialState);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function FlagsContext$Provider(props) {
  var cFlags = Belt_Option.getWithDefault(props.classicFlags, []);
  var sFlags = Belt_Option.getWithDefault(props.systemFlags, {});
  var roles = Belt_Option.getWithDefault(props.userRoles, []);
  var fs = Belt_Option.getWithDefault(props.features, {});
  var match = Curry._1(props.hasFlagInState, {
        classicFlags: cFlags,
        systemFlags: sFlags,
        userRoles: roles,
        features: fs
      });
  return React.createElement(React.Fragment, {
              children: Caml_option.some(React.createElement(make, {
                        value: {
                          hasFlag: match.getFlag,
                          unsafeHasFlag: match.unsafeGetFlag
                        },
                        children: props.children
                      }))
            });
}

var Provider = {
  make: FlagsContext$Provider
};

function use(param) {
  return React.useContext(context).hasFlag;
}

function unsafeUse(param) {
  return React.useContext(context).unsafeHasFlag;
}

function FlagsContext$Consumer(props) {
  return Curry._1(props.children, React.useContext(context).hasFlag);
}

var Consumer = {
  make: FlagsContext$Consumer
};

export {
  initialState ,
  Context ,
  Provider ,
  use ,
  unsafeUse ,
  Consumer ,
}
/* context Not a pure module */
