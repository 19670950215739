// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import MobileDetect from "mobile-detect";

function isIPad(userAgent) {
  var matchesIPad = Js_string.match_(/(iPad)/, userAgent);
  if (matchesIPad !== undefined) {
    return true;
  } else if (window.navigator.platform === "MacIntel") {
    return window.navigator.maxTouchPoints > 1;
  } else {
    return false;
  }
}

function detectDevice(userAgent) {
  var mobileDetect = new MobileDetect(userAgent);
  var mobile = mobileDetect.mobile();
  var phone = mobileDetect.phone();
  var tablet = mobileDetect.tablet();
  var newMeta;
  var exit = 0;
  if (mobile !== undefined || phone !== undefined || tablet !== undefined) {
    exit = 1;
  } else {
    newMeta = {
      isTablet: false,
      isAndroid: false,
      isIOS: false
    };
  }
  if (exit === 1) {
    newMeta = mobileDetect.is("iOS") ? ({
          isTablet: true,
          isAndroid: false,
          isIOS: true
        }) : (
        mobileDetect.is("AndroidOS") ? ({
              isTablet: true,
              isAndroid: true,
              isIOS: false
            }) : ({
              isTablet: false,
              isAndroid: false,
              isIOS: false
            })
      );
  }
  if (isIPad(userAgent)) {
    return {
            isTablet: true,
            isAndroid: false,
            isIOS: true
          };
  } else {
    return newMeta;
  }
}

function getScreenAspectRatio(param) {
  var widthValue = screen.width;
  var heightValue = screen.height;
  var widthFlipped = heightValue > widthValue ? heightValue : widthValue;
  var heightFlipped = heightValue > widthValue ? widthValue : heightValue;
  var widthFloat = widthFlipped;
  var heightFloat = heightFlipped;
  return widthFloat / heightFloat;
}

function getVirtualDimensions(meta) {
  if (!meta.isTablet) {
    return {
            width: 1280,
            height: 768,
            aspectRatio: 1280.0 / 768.0,
            aspectRatioString: "5/3"
          };
  }
  var screenAspectRatio = getScreenAspectRatio();
  if (screenAspectRatio > 1.4) {
    return {
            width: 1280,
            height: 768,
            aspectRatio: 1280.0 / 768.0,
            aspectRatioString: "5/3"
          };
  } else {
    return {
            width: 1024,
            height: 768,
            aspectRatio: 1024.0 / 768.0,
            aspectRatioString: "4/3"
          };
  }
}

function getDimensions(param) {
  return getVirtualDimensions(detectDevice(window.navigator.userAgent));
}

export {
  isIPad ,
  detectDevice ,
  getScreenAspectRatio ,
  getVirtualDimensions ,
  getDimensions ,
}
/* mobile-detect Not a pure module */
