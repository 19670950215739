// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function StopIcon(props) {
  return React.createElement("svg", {
              className: props.className + " StopIcon",
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M437.2,456.1H69.7c-23.6,0-42.8-19.1-42.8-42.8V98.8C26.9,75.1,46,56,69.7,56h367.5c23.6,0,42.8,19.1,42.8,42.8\n  v314.6C479.9,437,460.8,456.1,437.2,456.1z"
                }));
}

var make = StopIcon;

var $$default = StopIcon;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
