// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";

function CloseBookIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 921.7 921.3",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      className: Flavor$LiteracyplanetTupperware.CloseBookIcon.iconStyleWhite,
                      d: "M460.9,6.5C210.2,6.5,7,209.7,7,460.3s203.2,453.9,453.9,453.9S914.8,711,914.8,460.3S711.6,6.5,460.9,6.5z"
                    })), React.createElement("path", {
                  className: Flavor$LiteracyplanetTupperware.CloseBookIcon.iconStyleBlue,
                  d: "M757.6,628.3c0,0,23.7-8.6,41.1-50.6c20.8-50.7,3.6-84.7,3.6-84.7s-19.5-43.9-65.1-62.5\n      c-38.9-16-470.7-193.3-492.8-202.3c-22-9-40.7-21.2-41.9-3.1s-0.7,48.3-0.7,48.3s465.6,191.2,489.7,201.1\n      c24.2,9.9,74.6,46.1,56.9,89.4s-54.7,36.9-54.7,36.9L757.6,628.3z"
                }), React.createElement("path", {
                  className: Flavor$LiteracyplanetTupperware.CloseBookIcon.iconStyleBlue,
                  d: "M699,468.7H160.3c-4.3,0-5.2,1.1-5.1,11.2h529.7c30.2,0,54.8,24.6,54.8,54.8l0,0c0,30.2-24.6,54.8-54.8,54.8\n      H154.1c-2.4,0-4.8-0.1-7.1-0.5c-10.7,34.8-24.6,58.4-4.6,58.4H699c62.3,0,112.9-50.5,112.9-112.9l0,0\n      C811.8,472.5,761.4,468.7,699,468.7z"
                }), React.createElement("path", {
                  className: Flavor$LiteracyplanetTupperware.CloseBookIcon.iconStyleBlue,
                  d: "M697.3,519.8H159.8c-3.3,0-5.8-2.7-5.8-5.8l0,0c0-3.3,2.7-5.8,5.8-5.8h537.6c3.3,0,5.8,2.7,5.8,5.8l0,0\n      C703.2,517.2,700.5,519.8,697.3,519.8z"
                }), React.createElement("path", {
                  className: Flavor$LiteracyplanetTupperware.CloseBookIcon.iconStyleBlue,
                  d: "M697.3,560.1H159.8c-3.3,0-5.8-2.7-5.8-5.8l0,0c0-3.3,2.7-5.8,5.8-5.8h537.6c3.3,0,5.8,2.7,5.8,5.8l0,0\n      C703.2,557.4,700.5,560.1,697.3,560.1z"
                }), React.createElement("g", undefined, React.createElement("path", {
                      className: Flavor$LiteracyplanetTupperware.CloseBookIcon.iconStyleBlue,
                      d: "M216.2,440.4l57.1-57.2c0.8-0.8,1.1-2,0.6-3.1c-0.5-1.1-1.4-1.8-2.7-1.8H229v-71.6c0-1.6-1.3-2.9-2.9-2.9\n        h-24.3c-1.6,0-2.9,1.3-2.9,2.9v71.6h-42.1c-1.1,0-2.2,0.7-2.7,1.8c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.7,0.2,1.4,0.8,2l57.2,57.2\n        c0.5,0.5,1.2,0.8,2,0.8C214.8,441.3,215.6,441,216.2,440.4z"
                    })));
}

var make = CloseBookIcon;

export {
  make ,
}
/* react Not a pure module */
