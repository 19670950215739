// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as V2HighlightHook$LiteracyplanetTupperware from "./V2HighlightHook.res.mjs";

function colorMap(color) {
  switch (color) {
    case "blue" :
        return {
                background: "#EFD9F8",
                color: "black",
                padding: "5px",
                borderRadius: "5px"
              };
    case "green" :
        return {
                background: "#FFECCE",
                color: "black",
                padding: "5px",
                borderRadius: "5px"
              };
    case "magenta" :
        return {
                background: color,
                color: "green"
              };
    case "red" :
        return {
                background: "#D7EEED",
                color: "black",
                padding: "5px",
                borderRadius: "5px"
              };
    case "yellow" :
        return {
                background: color,
                color: "blue"
              };
    default:
      return {
              background: "inherit",
              color: "inherit"
            };
  }
}

var style = {
  cursor: "pointer"
};

function SkilfulHighlightClickToAddToBucket$ClickToAddToGroup(props) {
  var onToggle = props.onToggle;
  return React.createElement("span", {
              style: style,
              onClick: (function (param) {
                  Curry._1(onToggle, undefined);
                })
            }, props.children);
}

var ClickToAddToGroup = {
  style: style,
  make: SkilfulHighlightClickToAddToBucket$ClickToAddToGroup
};

function SkilfulHighlightClickToAddToBucket$ResponseSelected(props) {
  return React.createElement("span", {
              style: colorMap(props.responseColor)
            }, props.children);
}

var ResponseSelected = {
  make: SkilfulHighlightClickToAddToBucket$ResponseSelected
};

function SkilfulHighlightClickToAddToBucket(props) {
  var children = props.children;
  var match = V2HighlightHook$LiteracyplanetTupperware.use(props.questionIndex, props.responseOptionId);
  var coloredResponseSelection = Belt_Option.mapWithDefault(match[2], children, (function (responseColor) {
          return React.createElement(SkilfulHighlightClickToAddToBucket$ResponseSelected, {
                      responseColor: responseColor,
                      children: children
                    });
        }));
  if (Belt_Option.isSome(match[1])) {
    return React.createElement(SkilfulHighlightClickToAddToBucket$ClickToAddToGroup, {
                onToggle: match[3],
                children: coloredResponseSelection
              });
  } else {
    return coloredResponseSelection;
  }
}

var make = SkilfulHighlightClickToAddToBucket;

var $$default = SkilfulHighlightClickToAddToBucket;

export {
  colorMap ,
  ClickToAddToGroup ,
  ResponseSelected ,
  make ,
  $$default as default,
}
/* react Not a pure module */
