// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as K2AnswerWraps$LiteracyplanetTupperware from "./K2AnswerWraps.res.mjs";
import * as K2AnswerMarkIcon$LiteracyplanetTupperware from "./K2AnswerMarkIcon.res.mjs";

function AnswerBoxSlim(props) {
  return React.createElement(K2AnswerWraps$LiteracyplanetTupperware.Answer.make, {
              className: Flavor$LiteracyplanetTupperware.AnswerBoxSlim.Answer.className,
              children: null
            }, React.createElement(K2AnswerWraps$LiteracyplanetTupperware.Box.make, {
                  className: Flavor$LiteracyplanetTupperware.AnswerBoxSlim.Box.className,
                  children: props.children
                }), React.createElement(K2AnswerMarkIcon$LiteracyplanetTupperware.Correct.make, {}), React.createElement(K2AnswerMarkIcon$LiteracyplanetTupperware.Wrong.make, {}));
}

var make = AnswerBoxSlim;

var $$default = AnswerBoxSlim;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
