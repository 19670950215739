// Generated by ReScript, PLEASE EDIT WITH CARE


import 'react-pdf/dist/Page/AnnotationLayer.css';
;

import 'react-pdf/dist/Page/TextLayer.css';
;

var $$Document = {};

var Page = {};

import { pdfjs } from 'react-pdf';

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();
;

export {
  $$Document ,
  Page ,
}
/*  Not a pure module */
