// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.res.mjs";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.res.mjs";
import * as ExpandingInput$LiteracyplanetTupperware from "./ExpandingInput.res.mjs";

function SkilfulResponseMark(props) {
  var value = props.value;
  var answerVisible = props.answerVisible;
  var inputClassName = props.inputClassName;
  var onChange = function (param) {
    
  };
  var exit = 0;
  var exit$1 = 0;
  if (answerVisible !== undefined && answerVisible) {
    exit = 2;
  } else {
    exit$1 = 3;
  }
  if (exit$1 === 3) {
    switch (props.marked) {
      case "Correct" :
          exit = 2;
          break;
      case "Wrong" :
      case "Unanswered" :
          exit = 1;
          break;
      
    }
  }
  switch (exit) {
    case 1 :
        return React.createElement("div", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.className
                  }, React.createElement(ExpandingInput$LiteracyplanetTupperware.make, {
                        value: value,
                        className: Curry._1(Css.merge, {
                              hd: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.InputIncorrect.className,
                              tl: {
                                hd: inputClassName,
                                tl: /* [] */0
                              }
                            }),
                        onChange: onChange,
                        disabled: true
                      }), React.createElement("div", {
                        className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.IconGrid.className
                      }, React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.IncorrectMarkIcon.className
                          })));
    case 2 :
        return React.createElement("div", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.className
                  }, React.createElement(ExpandingInput$LiteracyplanetTupperware.make, {
                        value: value,
                        className: Curry._1(Css.merge, {
                              hd: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.InputCorrect.className,
                              tl: {
                                hd: inputClassName,
                                tl: /* [] */0
                              }
                            }),
                        onChange: onChange,
                        disabled: true
                      }), React.createElement("div", {
                        className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.IconGrid.className
                      }, React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.CorrectMarkIcon.className
                          })));
    
  }
}

var make = SkilfulResponseMark;

export {
  make ,
}
/* Css Not a pure module */
