// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FindWordsQuery$LiteracyplanetClientFlags from "./FindWordsQuery.res.mjs";
import * as ClickWordToHear$LiteracyplanetClientFlags from "./ClickWordToHear.res.mjs";

function ClickWordToHearContainer(props) {
  return React.createElement(ClickWordToHear$LiteracyplanetClientFlags.make, {
              useQuery: FindWordsQuery$LiteracyplanetClientFlags.useWithContext,
              children: props.children
            });
}

var make = ClickWordToHearContainer;

export {
  make ,
}
/* react Not a pure module */
