// Generated by ReScript, PLEASE EDIT WITH CARE


function encodeImageTab(imageTabSelection) {
  if (typeof imageTabSelection !== "object") {
    return "DefaultTab";
  } else {
    return imageTabSelection._0;
  }
}

function encode(nav) {
  var tmp;
  if (typeof nav !== "object") {
    tmp = {
      area: "BogDefault"
    };
  } else {
    switch (nav.TAG) {
      case "BogBooks" :
          var bookSelection = nav._0;
          tmp = typeof bookSelection !== "object" ? ({
                area: "BogDefault"
              }) : (
              bookSelection.TAG === "SelectedBook" ? ({
                    area: "BogBooks",
                    bookId: bookSelection._0
                  }) : ({
                    area: "BogDefault",
                    bookId: bookSelection._0,
                    storyId: bookSelection._1
                  })
            );
          break;
      case "BogLocations" :
          var locationSelection = nav._0;
          tmp = typeof locationSelection !== "object" ? ({
                area: "DefaultLocation"
              }) : ({
                area: "SelectedLocation",
                id: locationSelection._0,
                imagetab: encodeImageTab(locationSelection._1)
              });
          break;
      case "BogCharacters" :
          var characterSelection = nav._0;
          tmp = typeof characterSelection !== "object" ? ({
                area: "DefaultCharacter"
              }) : ({
                area: "SelectedCharacter",
                id: characterSelection._0,
                imagetab: encodeImageTab(characterSelection._1)
              });
          break;
      
    }
  }
  return btoa(JSON.stringify(tmp));
}

function decodeBooks(json) {
  var match = json.bookId;
  var match$1 = json.storyId;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return {
              TAG: "SelectedStory",
              _0: match,
              _1: match$1
            };
    } else {
      return {
              TAG: "SelectedBook",
              _0: match
            };
    }
  }
  if (match$1 === undefined) {
    return "DefaultBook";
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "BogTabSerialisation.res",
          111,
          2
        ],
        Error: new Error()
      };
}

function decodeImageTab(imagetab) {
  if (imagetab === "DefaultTab") {
    return "DefaultTab";
  } else {
    return {
            TAG: "ImageTab",
            _0: imagetab
          };
  }
}

function decodeImageSelection(json) {
  var match = json.id;
  var match$1 = json.imagetab;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return {
              TAG: "SelectedImage",
              _0: match,
              _1: decodeImageTab(match$1)
            };
    } else {
      return {
              TAG: "SelectedImage",
              _0: match,
              _1: "DefaultTab"
            };
    }
  }
  if (match$1 === undefined) {
    return "DefaultImage";
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "BogTabSerialisation.res",
          129,
          2
        ],
        Error: new Error()
      };
}

function decode(str) {
  try {
    var data = JSON.parse(atob(str));
    var match = data.area;
    switch (match) {
      case "BogBooks" :
          return {
                  TAG: "BogBooks",
                  _0: decodeBooks(data)
                };
      case "BogDefault" :
          return "BogDefault";
      case "DefaultCharacter" :
      case "SelectedCharacter" :
          return {
                  TAG: "BogCharacters",
                  _0: decodeImageSelection(data)
                };
      case "DefaultLocation" :
      case "SelectedLocation" :
          return {
                  TAG: "BogLocations",
                  _0: decodeImageSelection(data)
                };
      default:
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "BogTabSerialisation.res",
                162,
                4
              ],
              Error: new Error()
            };
    }
  }
  catch (exn){
    return "BogDefault";
  }
}

export {
  encodeImageTab ,
  encode ,
  decodeBooks ,
  decodeImageTab ,
  decodeImageSelection ,
  decode ,
}
/* No side effect */
