// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_string from "rescript/lib/es6/js_string.js";

function arrayToList(a) {
  var _i = a.length - 1 | 0;
  var _res = /* [] */0;
  while(true) {
    var res = _res;
    var i = _i;
    if (i < 0) {
      return res;
    }
    _res = {
      hd: a[i],
      tl: res
    };
    _i = i - 1 | 0;
    continue ;
  };
}

function use(getPath) {
  var raw = Curry._1(getPath, undefined);
  switch (raw) {
    case "" :
    case "/" :
        return /* [] */0;
    default:
      var raw$1 = Js_string.sliceToEnd(1, raw);
      var match = raw$1[raw$1.length - 1 | 0];
      var raw$2 = match === "/" ? Js_string.slice(0, -1, raw$1) : raw$1;
      return arrayToList(Js_string.split("/", raw$2));
  }
}

export {
  arrayToList ,
  use ,
}
/* No side effect */
