// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as StimulusTabContext$LiteracyplanetTupperware from "./StimulusTabContext.res.mjs";
import * as ClickWordToHearContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ClickWordToHearContainer.res.mjs";

function SkilfulStimulusTabContent(props) {
  var showing = Curry._1(StimulusTabContext$LiteracyplanetTupperware.useTabContent, props.tab);
  return React.createElement(ClickWordToHearContainer$LiteracyplanetClientFlags.make, {
              children: showing ? props.children : null
            });
}

var make = SkilfulStimulusTabContent;

var $$default = SkilfulStimulusTabContent;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
