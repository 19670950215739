// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var initialState = {
  name: "",
  userId: "",
  accountName: "",
  givenName: "",
  familyName: "",
  accountId: 0,
  startingPoint: "",
  yearLevel: "",
  idp: "",
  email: undefined
};

var context = React.createContext(initialState);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

export {
  initialState ,
  context ,
  makeProps ,
  make ,
  use ,
}
/* context Not a pure module */
