// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Uuid$LiteracyplanetTupperware from "../Uuid.res.mjs";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as PlayAudioIcon$LiteracyplanetTupperware from "./PlayAudioIcon.res.mjs";
import * as AudioButtonApi$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/AudioButtonApi.res.mjs";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SoundProviderEmbedded.res.mjs";

function AudioEmbeddedComponent$ButtonContainer(props) {
  var onTogglePlay = props.onTogglePlay;
  return React.createElement("div", {
              className: "AudioQueryComponentButtonContainer",
              onClick: (function (param) {
                  Curry._1(onTogglePlay, undefined);
                })
            }, props.children);
}

var ButtonContainer = {
  make: AudioEmbeddedComponent$ButtonContainer
};

var className = Flavor$LiteracyplanetTupperware.AudioQueryComponent.className;

function AudioEmbeddedComponent(props) {
  var audioId = props.audioId;
  var instance = React.useMemo((function (param) {
          return Uuid$LiteracyplanetTupperware.make();
        }), [audioId]);
  var match = SoundProviderEmbedded$LiteracyplanetClientFlags.use(instance, audioId, props.autoPlay, props.sources);
  var onTogglePlay = match[1];
  return React.createElement(AudioButtonApi$LiteracyplanetClientFlags.make, {
              isPlaying: match[0],
              src: match[2],
              onEnd: onTogglePlay,
              onTogglePlay: onTogglePlay,
              children: (function (state, onTogglePlay) {
                  return React.createElement(AudioEmbeddedComponent$ButtonContainer, {
                              onTogglePlay: onTogglePlay,
                              children: React.createElement(PlayAudioIcon$LiteracyplanetTupperware.make, {
                                    className: className,
                                    state: state
                                  })
                            });
                })
            });
}

var make = AudioEmbeddedComponent;

export {
  ButtonContainer ,
  className ,
  make ,
}
/* react Not a pure module */
