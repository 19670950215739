// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Theme$LiteracyplanetClientFlags from "./Theme.res.mjs";
import * as WindowUtils$LiteracyplanetClientFlags from "./WindowUtils.res.mjs";
import * as ContainerDimensions$LiteracyplanetClientFlags from "./ContainerDimensions.res.mjs";

function css(param) {
  return Curry._1(Css.style, {
              hd: Css.position(Css.fixed),
              tl: {
                hd: Css.zIndex(10000000),
                tl: {
                  hd: Css.left(Css.px(param[0])),
                  tl: {
                    hd: Css.top(Css.px(param[1])),
                    tl: {
                      hd: Css.maxHeight({
                            NAME: "percent",
                            VAL: 90.0
                          }),
                      tl: {
                        hd: Css.maxWidth({
                              NAME: "percent",
                              VAL: 90.0
                            }),
                        tl: {
                          hd: Css.overflow(Css.auto),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function className(c) {
  return Curry._1(Css.merge, {
              hd: css(c),
              tl: {
                hd: "ListPos",
                tl: /* [] */0
              }
            });
}

function centerLeft(ul, width) {
  return (width - ul.width) / 2.0 | 0;
}

function centerTop(ul, height) {
  return (height - ul.height) / 2.0 | 0;
}

function rightAlign(input, ul) {
  return (input.right | 0) - (ul.width | 0) | 0;
}

function leftAlign(input) {
  return input.left | 0;
}

function bottomAlign(input) {
  return input.bottom | 0;
}

function topAlign(input, ul) {
  return (input.top | 0) - (ul.height | 0) | 0;
}

function fitsBelow(input, ul, height) {
  return ((input.bottom | 0) + (ul.height | 0) | 0) < height;
}

function fitsAbove(input, ul) {
  return ((input.top | 0) - (ul.height | 0) | 0) > 0;
}

function fitsLeft(input, ul, width) {
  return ((input.left | 0) + (ul.width | 0) | 0) < width;
}

function fitsRight(input, ul) {
  return ((input.right | 0) - (ul.width | 0) | 0) > 0;
}

function makeCoordinates(input, ul, width, height) {
  var left = fitsLeft(input, ul, width) ? input.left | 0 : (
      fitsRight(input, ul) ? rightAlign(input, ul) : centerLeft(ul, width)
    );
  var top = fitsBelow(input, ul, height) ? input.bottom | 0 : (
      fitsAbove(input, ul) ? topAlign(input, ul) : centerTop(ul, height)
    );
  return [
          left,
          top
        ];
}

function makeOptionalCoordinates(inputDimensions, ulDimensions, width, height) {
  if (inputDimensions !== undefined && ulDimensions !== undefined) {
    return className(makeCoordinates(Caml_option.valFromOption(inputDimensions), Caml_option.valFromOption(ulDimensions), width, height));
  } else {
    return "";
  }
}

function use(inputDimensions) {
  var match = ContainerDimensions$LiteracyplanetClientFlags.useDimensions();
  var match$1 = WindowUtils$LiteracyplanetClientFlags.useWindowSize();
  var className = makeOptionalCoordinates(inputDimensions, match[1], match$1[0], match$1[1]);
  return [
          match[0],
          className
        ];
}

var ratio = Theme$LiteracyplanetClientFlags.resize;

export {
  ratio ,
  css ,
  className ,
  centerLeft ,
  centerTop ,
  rightAlign ,
  leftAlign ,
  bottomAlign ,
  topAlign ,
  fitsBelow ,
  fitsAbove ,
  fitsLeft ,
  fitsRight ,
  makeCoordinates ,
  makeOptionalCoordinates ,
  use ,
}
/* Css Not a pure module */
