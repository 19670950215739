// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as ReactDnd from "react-dnd";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import DragPreviewJsx from "./DragPreview.jsx";

function useDrag(id, onDrop) {
  var match = Curry._1(ReactDnd.useDrag, {
        item: {
          type: "CLOZE",
          id: id
        },
        collect: (function (monitor) {
            return monitor.isDragging();
          }),
        end: (function (item, monitor) {
            var res = monitor.getDropResult();
            if (monitor.didDrop()) {
              return Curry._2(onDrop, item.id, res.droppedId);
            }
            
          })
      });
  return [
          match[0],
          match[1]
        ];
}

function useDragLayer(param) {
  return Curry._1(ReactDnd.useDragLayer, (function (monitor) {
                return {
                        isDragging: monitor.isDragging(),
                        item: Caml_option.nullable_to_opt(monitor.getItem()),
                        currentOffset: Caml_option.nullable_to_opt(monitor.getSourceClientOffset())
                      };
              }));
}

var make = DragPreviewJsx;

var DragPreview = {
  make: make
};

function Cloze$DragClassName(props) {
  var children = props.children;
  var id = props.id;
  var match = useDrag(id, props.onDrop);
  var isDragging = match[0];
  var style = {
    backgroundColor: "#1E9DE3",
    color: "white",
    cursor: "move",
    display: props.isDropped ? "none" : "inline-block",
    margin: "10px",
    overflow: "visible",
    padding: "0.5rem 1rem",
    opacity: isDragging ? "0" : "1",
    borderRadius: "5px"
  };
  return React.createElement("span", {
              id: id
            }, React.createElement(make, {
                  isDragging: isDragging,
                  element: children,
                  domId: id,
                  style: style
                }), React.createElement("span", {
                  ref: Caml_option.some(match[1]),
                  style: style
                }, children));
}

var DragClassName = {
  make: Cloze$DragClassName
};

function useDrop(id) {
  return Curry._1(ReactDnd.useDrop, {
              accept: "CLOZE",
              collect: (function (monitor) {
                  if (monitor.canDrop()) {
                    return monitor.isOver(undefined);
                  } else {
                    return false;
                  }
                }),
              drop: (function (_item, _monitor) {
                  return {
                          droppedId: id
                        };
                })
            });
}

function Cloze$DropClassName(props) {
  var showAnswer = props.showAnswer;
  var id = props.id;
  var match = useDrop(id);
  var childrenToShow = showAnswer ? null : props.children;
  return React.createElement("div", {
              ref: Caml_option.some(match[1]),
              className: $$String.concat(" ", {
                    hd: "ClozeDropContainer",
                    tl: {
                      hd: showAnswer ? "showAnswer" : (
                          match[0] ? "over" : ""
                        ),
                      tl: {
                        hd: props.isDropped ? "dropped" : "",
                        tl: /* [] */0
                      }
                    }
                  }),
              id: "Drop-" + id,
              style: props.style
            }, childrenToShow);
}

var DropClassName = {
  make: Cloze$DropClassName
};

export {
  useDrag ,
  useDragLayer ,
  DragPreview ,
  DragClassName ,
  useDrop ,
  DropClassName ,
}
/* make Not a pure module */
