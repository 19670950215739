// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

function map(responseOptionId, acc, response) {
  var i = response.indexOf(":");
  if (response.substr(0, i) === responseOptionId) {
    return response.substr(i + 1 | 0);
  } else {
    return acc;
  }
}

function lookupAnswer(correctAnswer, responseOptionId) {
  if (correctAnswer !== undefined) {
    return Belt_List.reduce(correctAnswer, undefined, (function (param, param$1) {
                  return map(responseOptionId, param, param$1);
                }));
  }
  
}

function use(questionIndex, responseOptionId) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var step = Curry._1(match.getStep, questionIndex);
  var marked = Reducer$LiteracyplanetTupperware.getMarkTypeWip(step, responseOptionId);
  var ca = Curry._1(match.getCorrectAnswer, questionIndex);
  var correctAnswer = lookupAnswer(ca, responseOptionId);
  return [
          step,
          marked,
          correctAnswer
        ];
}

export {
  map ,
  lookupAnswer ,
  use ,
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
