// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import FontfaceobserverEs from "fontfaceobserver-es";

import 'normalize.css'
;

function BlankUserLayout(props) {
  var match = React.useState(function (param) {
        return true;
      });
  var setIsLoadingAsset = match[1];
  React.useEffect((function (param) {
          var __x = Promise.all([
                new FontfaceobserverEs("Roboto").load(),
                new FontfaceobserverEs("Lato").load()
              ]);
          Js_promise.then_((function (param) {
                  Curry._1(setIsLoadingAsset, (function (param) {
                          return false;
                        }));
                  return Promise.resolve();
                }), __x);
        }), []);
  if (match[0]) {
    return null;
  } else {
    return props.children;
  }
}

var make = BlankUserLayout;

var $$default = BlankUserLayout;

export {
  make ,
  $$default as default,
}
/*  Not a pure module */
