// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as PlayAudioIcon$LiteracyplanetTupperware from "./PlayAudioIcon.res.mjs";
import * as AudioButtonApi$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/AudioButtonApi.res.mjs";

function PlayBookAudioButton(props) {
  var match = React.useState(function (param) {
        return false;
      });
  var setIsPlaying = match[1];
  var onTogglePlay = function (param) {
    Curry._1(setIsPlaying, (function (y) {
            return !y;
          }));
  };
  return React.createElement(AudioButtonApi$LiteracyplanetClientFlags.make, {
              isPlaying: match[0],
              src: props.src,
              onEnd: onTogglePlay,
              onTogglePlay: onTogglePlay,
              children: (function (state, onTogglePlay) {
                  return React.createElement("div", {
                              className: Flavor$LiteracyplanetTupperware.PlayBookAudioButton.className,
                              onClick: (function (param) {
                                  Curry._1(onTogglePlay, undefined);
                                })
                            }, React.createElement(PlayAudioIcon$LiteracyplanetTupperware.make, {
                                  state: state
                                }));
                })
            });
}

var make = PlayBookAudioButton;

export {
  make ,
}
/* react Not a pure module */
