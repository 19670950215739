// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as StopIcon$LiteracyplanetTupperware from "./StopIcon.res.mjs";
import * as VolumeIcon$LiteracyplanetTupperware from "./VolumeIcon.res.mjs";
import * as SpinnerIcon$LiteracyplanetTupperware from "./SpinnerIcon.res.mjs";

function PlayAudioIcon(props) {
  var className = props.className;
  var css = className !== undefined ? Flavor$LiteracyplanetTupperware.PlayAudioIcon.withClassName(className) : Flavor$LiteracyplanetTupperware.PlayAudioIcon.className;
  switch (props.state) {
    case "Loading" :
        return React.createElement(SpinnerIcon$LiteracyplanetTupperware.make, {
                    className: css
                  });
    case "Stopped" :
        return React.createElement(VolumeIcon$LiteracyplanetTupperware.make, {
                    className: css
                  });
    case "Playing" :
        return React.createElement(StopIcon$LiteracyplanetTupperware.make, {
                    className: css
                  });
    
  }
}

var make = PlayAudioIcon;

export {
  make ,
}
/* react Not a pure module */
