// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "rescript/lib/es6/list.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Reducer$LiteracyplanetTupperware from "./Reducer.res.mjs";

function getSelectedOption(questionState, options) {
  if (typeof questionState !== "object") {
    return ;
  }
  var responses = Reducer$LiteracyplanetTupperware.extractAnswers(questionState._0);
  return List.fold_left((function (acc, r) {
                if (acc !== undefined || !List.exists((function (o) {
                          return o === r;
                        }), options)) {
                  return acc;
                } else {
                  return r;
                }
              }), undefined, responses);
}

function getSelectedDropDownValue(step, opts) {
  var options = List.map((function (opt) {
          return opt.responseOptionId;
        }), opts);
  if (typeof step !== "object") {
    return ;
  } else if (step.TAG === "Attempted") {
    return getSelectedOption(step._0, options);
  } else {
    return getSelectedOption(step._1, options);
  }
}

function getMarkTypeForDropDown(value, step, opts) {
  if (value !== undefined) {
    return Reducer$LiteracyplanetTupperware.getMarkType(step, value);
  } else {
    return List.fold_left((function (acc, opt) {
                  var match = Reducer$LiteracyplanetTupperware.getMarkType(step, opt.responseOptionId);
                  switch (match) {
                    case "Correct" :
                        switch (acc) {
                          case "Wrong" :
                              return acc;
                          case "Correct" :
                          case "Unanswered" :
                              return "Correct";
                          
                        }
                    case "Wrong" :
                        return "Wrong";
                    case "Unanswered" :
                        return "Unanswered";
                    
                  }
                }), "Unanswered", opts);
  }
}

function find(opts, correctAnswer) {
  if (correctAnswer !== undefined) {
    return Belt_List.reduce(opts, undefined, (function (acc, opt) {
                  var exists = List.exists((function (answer) {
                          return answer === opt.responseOptionId;
                        }), correctAnswer);
                  if (exists) {
                    return opt.responseOptionId;
                  } else {
                    return acc;
                  }
                }));
  }
  
}

export {
  getSelectedOption ,
  getSelectedDropDownValue ,
  getMarkTypeForDropDown ,
  find ,
}
/* No side effect */
