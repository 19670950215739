// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as Graphql_ppx_runtime from "@reasonml-community/graphql-ppx/rescript-runtime/graphql_ppx_runtime.res.mjs";
import * as SpaceX$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SpaceX.res.mjs";
import * as QueryHook2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/QueryHook2.res.mjs";
import * as UserContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/UserContext.res.mjs";
import * as CobraAvatarType$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/CobraAvatarType.res.mjs";
import * as StudentFragments$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/StudentFragments.res.mjs";
import * as AvatarContext$LiteracyplanetClientLibsActivity from "./AvatarContext.res.mjs";
import * as QueryStudentFragments$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/QueryStudentFragments.res.mjs";

var Raw = {};

var query = GraphqlTag.gql([
      "query CurrentStudentAvatarQuery($userId: String!)  {\ndummy  \n...CurrentStudentAvatarFragment   \n}\n",
      ""
    ], QueryStudentFragments$LiteracyplanetClientFlags.CurrentStudentAvatarFragment.query);

function parse(value) {
  var value$1 = value["dummy"];
  return {
          dummy: !(value$1 == null) ? value$1.map(function (value) {
                  if (!(value == null)) {
                    return value;
                  }
                  
                }) : undefined,
          currentStudentAvatarFragment: QueryStudentFragments$LiteracyplanetClientFlags.CurrentStudentAvatarFragment.verifyArgsAndParse("NonNull_String", "CurrentStudentAvatarFragment", value)
        };
}

function serialize(value) {
  var value$1 = value.dummy;
  return [QueryStudentFragments$LiteracyplanetClientFlags.CurrentStudentAvatarFragment.serialize(value.currentStudentAvatarFragment)].reduce(Graphql_ppx_runtime.deepMerge, {
              dummy: value$1 !== undefined ? value$1.map(function (value) {
                      if (value !== undefined) {
                        return value;
                      } else {
                        return null;
                      }
                    }) : null
            });
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var CurrentStudentAvatarQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CurrentStudentAvatarQuery = {
  CurrentStudentAvatarQuery_inner: CurrentStudentAvatarQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

function handleResponse(res) {
  return {
          TAG: "Avatar",
          _0: Belt_Option.map(Belt_Option.flatMap(res.currentStudentAvatarFragment.currentStudent, StudentFragments$LiteracyplanetClientFlags.avatar), CobraAvatarType$LiteracyplanetClientFlags.makeAvatar)
        };
}

function onLoading(param) {
  return "Loading";
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function use$1(userId) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId
            });
}

function AvatarQuery$RunQuery(props) {
  var avatar = use$1(props.userId);
  if (typeof avatar !== "object") {
    return null;
  } else {
    return Curry._1(props.children, avatar._0);
  }
}

var RunQuery = {
  make: AvatarQuery$RunQuery
};

function AvatarQuery$Fetch(props) {
  var match = props.game.activityType;
  var children = props.children;
  if (typeof match !== "object" || !(match.TAG === "CobraActivity" && match._0.hasAvatar)) {
    return Curry._1(children, undefined);
  } else {
    return React.createElement(AvatarQuery$RunQuery, {
                userId: props.userId,
                children: children
              });
  }
}

var Fetch = {
  make: AvatarQuery$Fetch
};

function AvatarQuery(props) {
  var children = props.children;
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  return React.createElement(AvatarQuery$Fetch, {
              userId: userId,
              game: props.game,
              children: (function (avatar) {
                  return React.createElement(AvatarContext$LiteracyplanetClientLibsActivity.Provider.make, {
                              value: avatar,
                              children: children
                            });
                })
            });
}

var CurrentStudentAvatarFragment;

var make = AvatarQuery;

export {
  CurrentStudentAvatarFragment ,
  CurrentStudentAvatarQuery ,
  handleResponse ,
  onLoading ,
  onResult ,
  Q ,
  use$1 as use,
  RunQuery ,
  Fetch ,
  make ,
}
/* query Not a pure module */
