// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.res.mjs";
import * as JourneyHeaderStyles$LiteracyplanetClientSxEntry from "./JourneyHeaderStyles.res.mjs";
import * as SecondaryHeaderIcons$LiteracyplanetClientSxEntry from "./SecondaryHeaderIcons.res.mjs";
import * as SecondaryHeaderStyles$LiteracyplanetClientSxEntry from "./SecondaryHeaderStyles.res.mjs";

function JourneyInitialsMenu$CloseBtnComponent(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.closeBtn,
              onClick: props.onClick
            }, React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.CloseIcon.make, {
                  className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.closeIcon
                }));
}

var CloseBtnComponent = {
  make: JourneyInitialsMenu$CloseBtnComponent
};

function JourneyInitialsMenu$MouseLinkButton(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.menuLink(props.bgColor, props.hoverColor, props.activeColor),
              onClick: props.onClick
            }, React.createElement("div", {
                  className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.iconAndText
                }, props.children));
}

var MouseLinkButton = {
  make: JourneyInitialsMenu$MouseLinkButton
};

function JourneyInitialsMenu(props) {
  var onClickLogout = props.onClickLogout;
  var onClickWordMania = props.onClickWordMania;
  var match = React.useState(function (param) {
        return false;
      });
  var setIsOpen = match[1];
  return React.createElement(React.Fragment, {
              children: Caml_option.some(match[0] ? React.createElement("div", {
                          className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.navigationContainer
                        }, React.createElement("div", {
                              className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.navigationName
                            }, SecondaryHeaderStyles$LiteracyplanetClientSxEntry.s(props.userName)), React.createElement("div", {
                              className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.navigationLinksContainer
                            }, props.showWordManiaLink ? React.createElement(JourneyInitialsMenu$MouseLinkButton, {
                                    children: null,
                                    onClick: (function (param) {
                                        Curry._1(onClickWordMania, undefined);
                                      }),
                                    bgColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.wordManiaColor,
                                    hoverColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.wordManiaHoverColor,
                                    activeColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.wordManiaActiveColor
                                  }, React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.WordManiaIcon.make, {
                                        className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.linkIcon
                                      }), SecondaryHeaderStyles$LiteracyplanetClientSxEntry.s("Word Mania")) : null, React.createElement(JourneyInitialsMenu$MouseLinkButton, {
                                  children: null,
                                  onClick: (function (param) {
                                      Curry._1(onClickLogout, undefined);
                                    }),
                                  bgColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.logOutColor,
                                  hoverColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.logOutHoverColor,
                                  activeColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.logOutActiveColor
                                }, React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.LogoutIcon.make, {
                                      className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.linkIcon
                                    }), SecondaryHeaderStyles$LiteracyplanetClientSxEntry.s("Exit"))), React.createElement(JourneyInitialsMenu$CloseBtnComponent, {
                              onClick: (function (param) {
                                  Curry._1(setIsOpen, (function (previousValue) {
                                          return !previousValue;
                                        }));
                                })
                            })) : React.createElement(React.Fragment, {
                          children: Caml_option.some(React.createElement("div", {
                                    className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.menuPanel
                                  }, React.createElement("div", {
                                        className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.menuIcon,
                                        onClick: (function (param) {
                                            Curry._1(setIsOpen, (function (previousValue) {
                                                    return !previousValue;
                                                  }));
                                          })
                                      }, props.userInitials, React.createElement(Icons$LiteracyplanetClientSxEntry.MenuOpenIcon.make, {
                                            className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.menuOpenIcon
                                          }))))
                        }))
            });
}

var make = JourneyInitialsMenu;

export {
  CloseBtnComponent ,
  MouseLinkButton ,
  make ,
}
/* react Not a pure module */
