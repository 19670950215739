// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDnd from "react-dnd";
import ReactDndTouchBackend from "react-dnd-touch-backend";

var options = {
  enableMouseEvents: true,
  enableKeyboardEvents: true,
  ignoreContextMenu: true,
  enableHoverOutsideTarget: true
};

var TouchBackend = {
  options: options
};

var Html5Backend = {
  options: undefined
};

var DndProvider = {};

function ReactDnd$Provider(props) {
  var backend = ReactDndTouchBackend;
  return React.createElement(ReactDnd.DndProvider, {
              backend: backend,
              options: options,
              children: props.children
            });
}

var Provider = {
  make: ReactDnd$Provider
};

var Drag = {};

var Drop = {};

var DragLayer = {};

var isMobile = true;

export {
  TouchBackend ,
  Html5Backend ,
  DndProvider ,
  isMobile ,
  Provider ,
  Drag ,
  Drop ,
  DragLayer ,
}
/* react Not a pure module */
