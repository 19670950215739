// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

function BehaviourActivityMarkClassNames(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var currentQuestionIndex = match.currentQuestionIndex;
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, currentQuestionIndex);
  var marked = Curry._1(match.getMarkState, currentQuestionIndex);
  var tmp;
  var exit = 0;
  if (answerVisible !== undefined && answerVisible) {
    tmp = "correct";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = typeof marked !== "object" ? "" : (
        marked._0.questionCorrect ? "correct" : "wrong"
      );
  }
  return React.createElement("div", {
              className: tmp
            }, props.children);
}

var make = BehaviourActivityMarkClassNames;

var $$default = BehaviourActivityMarkClassNames;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
