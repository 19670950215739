// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as BehaviourQuestionVisible$LiteracyplanetTupperware from "./BehaviourQuestionVisible.res.mjs";
import * as BehaviourMultiChoiceContainer$LiteracyplanetTupperware from "./BehaviourMultiChoiceContainer.res.mjs";

function V2MultiChoiceContainer(props) {
  var __showCorrectAnswerBehaviour = props.showCorrectAnswerBehaviour;
  var questionIndex = props.questionIndex;
  var __fuzzyMark = props.fuzzyMark;
  var fuzzyMark = __fuzzyMark !== undefined ? __fuzzyMark : false;
  var showCorrectAnswerBehaviour = __showCorrectAnswerBehaviour !== undefined ? __showCorrectAnswerBehaviour : false;
  var answer = Belt_List.fromArray(props.correctAnswer);
  return React.createElement(BehaviourMultiChoiceContainer$LiteracyplanetTupperware.make, {
              correctAnswer: answer,
              fuzzyMark: fuzzyMark,
              questionIndex: questionIndex,
              showCorrectAnswerBehaviour: showCorrectAnswerBehaviour,
              children: React.createElement(BehaviourQuestionVisible$LiteracyplanetTupperware.make, {
                    questionIndex: questionIndex,
                    children: props.children
                  })
            });
}

var make = V2MultiChoiceContainer;

var $$default = V2MultiChoiceContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
