// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function use(param) {
  var match = React.useState(function (param) {
        return false;
      });
  var setShow = match[1];
  var show = match[0];
  var toggleShow = function (param) {
    Curry._1(setShow, (function (t) {
            return !t;
          }));
  };
  React.useEffect((function (param) {
          var hide = function (param) {
            Curry._1(setShow, (function (param) {
                    return false;
                  }));
          };
          if (show) {
            window.addEventListener("mousedown", hide);
          }
          return (function (param) {
                    if (show) {
                      window.removeEventListener("mousedown", hide);
                      return ;
                    }
                    
                  });
        }), [show]);
  return [
          show,
          toggleShow
        ];
}

export {
  use ,
}
/* react Not a pure module */
