// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GraphqlTag from "graphql-tag";

var Raw = {};

var query = GraphqlTag.gql(["fragment GameAudioFragment on GameAudio   {\n__typename  \nsource  \n}\n"]);

function parse(value) {
  return {
          __typename: value.__typename,
          source: value.source
        };
}

function serialize(value) {
  var value$1 = value.source;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          source: value$1
        };
}

function verifyArgsAndParse(_GameAudioFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var GameAudioFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = GraphqlTag.gql([
      "fragment GameExampleSentenceWordFragment on GameExampleSentenceWord   {\n__typename  \nid  \nname  \naudio  {\n...GameAudioFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$1(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name,
          audio: parse(value.audio)
        };
}

function serialize$1(value) {
  var value$1 = value.audio;
  var audio = serialize(value$1);
  var value$2 = value.name;
  var value$3 = value.id;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          id: value$3,
          name: value$2,
          audio: audio
        };
}

function verifyArgsAndParse$1(_GameExampleSentenceWordFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var GameExampleSentenceWordFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = GraphqlTag.gql([
      "fragment GameExampleSentenceFragment on GameExampleSentence   {\n__typename  \nid  \ntext  \nwords  {\n...GameExampleSentenceWordFragment   \n}\n\n}\n",
      ""
    ], query$1);

function parse$2(value) {
  var value$1 = value.words;
  return {
          __typename: value.__typename,
          id: value.id,
          text: value.text,
          words: value$1.map(parse$1)
        };
}

function serialize$2(value) {
  var value$1 = value.words;
  var words = value$1.map(serialize$1);
  var value$2 = value.text;
  var value$3 = value.id;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          id: value$3,
          text: value$2,
          words: words
        };
}

function verifyArgsAndParse$2(_GameExampleSentenceFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var GameExampleSentenceFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

var Raw$3 = {};

var query$3 = GraphqlTag.gql([
      "fragment GameWordFragment on GameWord   {\n__typename  \nid  \nname  \naudio  {\n...GameAudioFragment   \n}\n\nexampleSentence  {\n...GameExampleSentenceFragment   \n}\n\n}\n",
      "",
      ""
    ], query, query$2);

function parse$3(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name,
          audio: parse(value.audio),
          exampleSentence: parse$2(value.exampleSentence)
        };
}

function serialize$3(value) {
  var value$1 = value.exampleSentence;
  var exampleSentence = serialize$2(value$1);
  var value$2 = value.audio;
  var audio = serialize(value$2);
  var value$3 = value.name;
  var value$4 = value.id;
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          id: value$4,
          name: value$3,
          audio: audio,
          exampleSentence: exampleSentence
        };
}

function verifyArgsAndParse$3(_GameWordFragment, value) {
  return parse$3(value);
}

function verifyName$3(param) {
  
}

var GameWordFragment = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  verifyArgsAndParse: verifyArgsAndParse$3,
  verifyName: verifyName$3
};

var Raw$4 = {};

var query$4 = GraphqlTag.gql([
      "fragment GameWordListFragment on GameWordList   {\n__typename  \nwords  {\n...GameWordFragment   \n}\n\n}\n",
      ""
    ], query$3);

function parse$4(value) {
  var value$1 = value.words;
  return {
          __typename: value.__typename,
          words: value$1.map(parse$3)
        };
}

function serialize$4(value) {
  var value$1 = value.words;
  var words = value$1.map(serialize$3);
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          words: words
        };
}

function verifyArgsAndParse$4(_GameWordListFragment, value) {
  return parse$4(value);
}

function verifyName$4(param) {
  
}

var GameWordListFragment = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  verifyArgsAndParse: verifyArgsAndParse$4,
  verifyName: verifyName$4
};

var Raw$5 = {};

var query$5 = GraphqlTag.gql([
      "fragment WordGameDataFragment on WordGameData   {\n__typename  \nkind  \nmd5  \nlist  {\n...GameWordListFragment   \n}\n\n}\n",
      ""
    ], query$4);

function parse$5(value) {
  var value$1 = value.kind;
  var value$2 = value.md5;
  return {
          __typename: value.__typename,
          kind: !(value$1 == null) ? value$1 : undefined,
          md5: !(value$2 == null) ? value$2 : undefined,
          list: parse$4(value.list)
        };
}

function serialize$5(value) {
  var value$1 = value.list;
  var list = serialize$4(value$1);
  var value$2 = value.md5;
  var md5 = value$2 !== undefined ? value$2 : null;
  var value$3 = value.kind;
  var kind = value$3 !== undefined ? value$3 : null;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          kind: kind,
          md5: md5,
          list: list
        };
}

function verifyArgsAndParse$5(_WordGameDataFragment, value) {
  return parse$5(value);
}

function verifyName$5(param) {
  
}

var WordGameDataFragment = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  verifyArgsAndParse: verifyArgsAndParse$5,
  verifyName: verifyName$5
};

var Raw$6 = {};

var query$6 = GraphqlTag.gql([
      "fragment ContentMCPhonemeFragment on ContentMCPhoneme   {\n__typename  \nname  \naudio  {\n...GameAudioFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$6(value) {
  return {
          __typename: value.__typename,
          name: value.name,
          audio: parse(value.audio)
        };
}

function serialize$6(value) {
  var value$1 = value.audio;
  var audio = serialize(value$1);
  var value$2 = value.name;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          name: value$2,
          audio: audio
        };
}

function verifyArgsAndParse$6(_ContentMCPhonemeFragment, value) {
  return parse$6(value);
}

function verifyName$6(param) {
  
}

var ContentMCPhonemeFragment = {
  Raw: Raw$6,
  query: query$6,
  parse: parse$6,
  serialize: serialize$6,
  verifyArgsAndParse: verifyArgsAndParse$6,
  verifyName: verifyName$6
};

var Raw$7 = {};

var query$7 = GraphqlTag.gql([
      "fragment ContentMCWordFragment on ContentMCWord   {\n__typename  \nid  \nname  \naudio  {\n...GameAudioFragment   \n}\n\nphonemes  {\n...ContentMCPhonemeFragment   \n}\n\ndistractorPhonemes  {\n...ContentMCPhonemeFragment   \n}\n\n}\n",
      "",
      ""
    ], query$6, query);

function parse$7(value) {
  var value$1 = value.phonemes;
  var value$2 = value.distractorPhonemes;
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name,
          audio: parse(value.audio),
          phonemes: value$1.map(parse$6),
          distractorPhonemes: value$2.map(parse$6)
        };
}

function serialize$7(value) {
  var value$1 = value.distractorPhonemes;
  var distractorPhonemes = value$1.map(serialize$6);
  var value$2 = value.phonemes;
  var phonemes = value$2.map(serialize$6);
  var value$3 = value.audio;
  var audio = serialize(value$3);
  var value$4 = value.name;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          name: value$4,
          audio: audio,
          phonemes: phonemes,
          distractorPhonemes: distractorPhonemes
        };
}

function verifyArgsAndParse$7(_ContentMCWordFragment, value) {
  return parse$7(value);
}

function verifyName$7(param) {
  
}

var ContentMCWordFragment = {
  Raw: Raw$7,
  query: query$7,
  parse: parse$7,
  serialize: serialize$7,
  verifyArgsAndParse: verifyArgsAndParse$7,
  verifyName: verifyName$7
};

var Raw$8 = {};

var query$8 = GraphqlTag.gql([
      "fragment MonsterChefDataFragment on MonsterChefData   {\n__typename  \nwords  {\n...ContentMCWordFragment   \n}\n\n}\n",
      ""
    ], query$7);

function parse$8(value) {
  var value$1 = value.words;
  return {
          __typename: value.__typename,
          words: value$1.map(parse$7)
        };
}

function serialize$8(value) {
  var value$1 = value.words;
  var words = value$1.map(serialize$7);
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          words: words
        };
}

function verifyArgsAndParse$8(_MonsterChefDataFragment, value) {
  return parse$8(value);
}

function verifyName$8(param) {
  
}

var MonsterChefDataFragment = {
  Raw: Raw$8,
  query: query$8,
  parse: parse$8,
  serialize: serialize$8,
  verifyArgsAndParse: verifyArgsAndParse$8,
  verifyName: verifyName$8
};

var Raw$9 = {};

var query$9 = GraphqlTag.gql(["fragment ContentPhonemeAudioSourceFragment on ContentPhonemeAudioSource   {\n__typename  \nsource  \n}\n"]);

function parse$9(value) {
  return {
          __typename: value.__typename,
          source: value.source
        };
}

function serialize$9(value) {
  var value$1 = value.source;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          source: value$1
        };
}

function verifyArgsAndParse$9(_ContentPhonemeAudioSourceFragment, value) {
  return parse$9(value);
}

function verifyName$9(param) {
  
}

var ContentPhonemeAudioSourceFragment = {
  Raw: Raw$9,
  query: query$9,
  parse: parse$9,
  serialize: serialize$9,
  verifyArgsAndParse: verifyArgsAndParse$9,
  verifyName: verifyName$9
};

var Raw$10 = {};

var query$10 = GraphqlTag.gql(["fragment ContentWAMResponseFragment on ContentWAMResponse   {\n__typename  \nname  \nisCorrect  \nisFlipped  \n}\n"]);

function parse$10(value) {
  return {
          __typename: value.__typename,
          name: value.name,
          isCorrect: value.isCorrect,
          isFlipped: value.isFlipped
        };
}

function serialize$10(value) {
  var value$1 = value.isFlipped;
  var value$2 = value.isCorrect;
  var value$3 = value.name;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          name: value$3,
          isCorrect: value$2,
          isFlipped: value$1
        };
}

function verifyArgsAndParse$10(_ContentWAMResponseFragment, value) {
  return parse$10(value);
}

function verifyName$10(param) {
  
}

var ContentWAMResponseFragment = {
  Raw: Raw$10,
  query: query$10,
  parse: parse$10,
  serialize: serialize$10,
  verifyArgsAndParse: verifyArgsAndParse$10,
  verifyName: verifyName$10
};

var Raw$11 = {};

var query$11 = GraphqlTag.gql([
      "fragment ContentWAMQuestionFragment on ContentWAMQuestion   {\n__typename  \nname  \naudio  {\n...ContentPhonemeAudioSourceFragment   \n}\n\nphonemes  {\n...ContentWAMResponseFragment   \n}\n\n}\n",
      "",
      ""
    ], query$9, query$10);

function parse$11(value) {
  var value$1 = value.phonemes;
  return {
          __typename: value.__typename,
          name: value.name,
          audio: parse$9(value.audio),
          phonemes: value$1.map(parse$10)
        };
}

function serialize$11(value) {
  var value$1 = value.phonemes;
  var phonemes = value$1.map(serialize$10);
  var value$2 = value.audio;
  var audio = serialize$9(value$2);
  var value$3 = value.name;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          name: value$3,
          audio: audio,
          phonemes: phonemes
        };
}

function verifyArgsAndParse$11(_ContentWAMQuestionFragment, value) {
  return parse$11(value);
}

function verifyName$11(param) {
  
}

var ContentWAMQuestionFragment = {
  Raw: Raw$11,
  query: query$11,
  parse: parse$11,
  serialize: serialize$11,
  verifyArgsAndParse: verifyArgsAndParse$11,
  verifyName: verifyName$11
};

var Raw$12 = {};

var query$12 = GraphqlTag.gql([
      "fragment WhackAMunkDataFragment on WhackAMunkData   {\n__typename  \nid  \nquestions  {\n...ContentWAMQuestionFragment   \n}\n\n}\n",
      ""
    ], query$11);

function parse$12(value) {
  var value$1 = value.questions;
  return {
          __typename: value.__typename,
          id: value.id,
          questions: value$1.map(parse$11)
        };
}

function serialize$12(value) {
  var value$1 = value.questions;
  var questions = value$1.map(serialize$11);
  var value$2 = value.id;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          id: value$2,
          questions: questions
        };
}

function verifyArgsAndParse$12(_WhackAMunkDataFragment, value) {
  return parse$12(value);
}

function verifyName$12(param) {
  
}

var WhackAMunkDataFragment = {
  Raw: Raw$12,
  query: query$12,
  parse: parse$12,
  serialize: serialize$12,
  verifyArgsAndParse: verifyArgsAndParse$12,
  verifyName: verifyName$12
};

var Raw$13 = {};

var query$13 = GraphqlTag.gql(["fragment ContentPhonemeWordImageSourceFragment on ContentPhonemeWordImageSource   {\n__typename  \nsource  \n}\n"]);

function parse$13(value) {
  return {
          __typename: value.__typename,
          source: value.source
        };
}

function serialize$13(value) {
  var value$1 = value.source;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          source: value$1
        };
}

function verifyArgsAndParse$13(_ContentPhonemeWordImageSourceFragment, value) {
  return parse$13(value);
}

function verifyName$13(param) {
  
}

var ContentPhonemeWordImageSourceFragment = {
  Raw: Raw$13,
  query: query$13,
  parse: parse$13,
  serialize: serialize$13,
  verifyArgsAndParse: verifyArgsAndParse$13,
  verifyName: verifyName$13
};

var Raw$14 = {};

var query$14 = GraphqlTag.gql(["fragment ContentPhonemeWordAudioSourceFragment on ContentPhonemeWordAudioSource   {\n__typename  \nsource  \nphonemeSource  \n}\n"]);

function parse$14(value) {
  return {
          __typename: value.__typename,
          source: value.source,
          phonemeSource: value.phonemeSource
        };
}

function serialize$14(value) {
  var value$1 = value.phonemeSource;
  var value$2 = value.source;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          source: value$2,
          phonemeSource: value$1
        };
}

function verifyArgsAndParse$14(_ContentPhonemeWordAudioSourceFragment, value) {
  return parse$14(value);
}

function verifyName$14(param) {
  
}

var ContentPhonemeWordAudioSourceFragment = {
  Raw: Raw$14,
  query: query$14,
  parse: parse$14,
  serialize: serialize$14,
  verifyArgsAndParse: verifyArgsAndParse$14,
  verifyName: verifyName$14
};

var Raw$15 = {};

var query$15 = GraphqlTag.gql([
      "fragment ContentSTWordFragment on ContentSTWord   {\n__typename  \nname  \nisCorrect  \nimage  {\n...ContentPhonemeWordImageSourceFragment   \n}\n\naudio  {\n...ContentPhonemeWordAudioSourceFragment   \n}\n\n}\n",
      "",
      ""
    ], query$14, query$13);

function parse$15(value) {
  return {
          __typename: value.__typename,
          name: value.name,
          isCorrect: value.isCorrect,
          image: parse$13(value.image),
          audio: parse$14(value.audio)
        };
}

function serialize$15(value) {
  var value$1 = value.audio;
  var audio = serialize$14(value$1);
  var value$2 = value.image;
  var image = serialize$13(value$2);
  var value$3 = value.isCorrect;
  var value$4 = value.name;
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          name: value$4,
          isCorrect: value$3,
          image: image,
          audio: audio
        };
}

function verifyArgsAndParse$15(_ContentSTWordFragment, value) {
  return parse$15(value);
}

function verifyName$15(param) {
  
}

var ContentSTWordFragment = {
  Raw: Raw$15,
  query: query$15,
  parse: parse$15,
  serialize: serialize$15,
  verifyArgsAndParse: verifyArgsAndParse$15,
  verifyName: verifyName$15
};

var Raw$16 = {};

var query$16 = GraphqlTag.gql([
      "fragment ContentSTPhonemeFragment on ContentSTPhoneme   {\n__typename  \nlocation  \nname  \naudio  {\n...ContentPhonemeAudioSourceFragment   \n}\n\n}\n",
      ""
    ], query$9);

function parse$16(value) {
  return {
          __typename: value.__typename,
          location: value.location,
          name: value.name,
          audio: parse$9(value.audio)
        };
}

function serialize$16(value) {
  var value$1 = value.audio;
  var audio = serialize$9(value$1);
  var value$2 = value.name;
  var value$3 = value.location;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          location: value$3,
          name: value$2,
          audio: audio
        };
}

function verifyArgsAndParse$16(_ContentSTPhonemeFragment, value) {
  return parse$16(value);
}

function verifyName$16(param) {
  
}

var ContentSTPhonemeFragment = {
  Raw: Raw$16,
  query: query$16,
  parse: parse$16,
  serialize: serialize$16,
  verifyArgsAndParse: verifyArgsAndParse$16,
  verifyName: verifyName$16
};

var Raw$17 = {};

var query$17 = GraphqlTag.gql([
      "fragment ContentSTQuestionFragment on ContentSTQuestion   {\n__typename  \nphoneme  {\n...ContentSTPhonemeFragment   \n}\n\nwords  {\n...ContentSTWordFragment   \n}\n\n}\n",
      "",
      ""
    ], query$16, query$15);

function parse$17(value) {
  var value$1 = value.words;
  return {
          __typename: value.__typename,
          phoneme: parse$16(value.phoneme),
          words: value$1.map(parse$15)
        };
}

function serialize$17(value) {
  var value$1 = value.words;
  var words = value$1.map(serialize$15);
  var value$2 = value.phoneme;
  var phoneme = serialize$16(value$2);
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          phoneme: phoneme,
          words: words
        };
}

function verifyArgsAndParse$17(_ContentSTQuestionFragment, value) {
  return parse$17(value);
}

function verifyName$17(param) {
  
}

var ContentSTQuestionFragment = {
  Raw: Raw$17,
  query: query$17,
  parse: parse$17,
  serialize: serialize$17,
  verifyArgsAndParse: verifyArgsAndParse$17,
  verifyName: verifyName$17
};

var Raw$18 = {};

var query$18 = GraphqlTag.gql([
      "fragment ContentSTStationFragment on ContentSTStation   {\n__typename  \nquestions  {\n...ContentSTQuestionFragment   \n}\n\n}\n",
      ""
    ], query$17);

function parse$18(value) {
  var value$1 = value.questions;
  return {
          __typename: value.__typename,
          questions: value$1.map(parse$17)
        };
}

function serialize$18(value) {
  var value$1 = value.questions;
  var questions = value$1.map(serialize$17);
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          questions: questions
        };
}

function verifyArgsAndParse$18(_ContentSTStationFragment, value) {
  return parse$18(value);
}

function verifyName$18(param) {
  
}

var ContentSTStationFragment = {
  Raw: Raw$18,
  query: query$18,
  parse: parse$18,
  serialize: serialize$18,
  verifyArgsAndParse: verifyArgsAndParse$18,
  verifyName: verifyName$18
};

var Raw$19 = {};

var query$19 = GraphqlTag.gql([
      "fragment SoundTrainDataFragment on SoundTrainData   {\n__typename  \nid  \nstations  {\n...ContentSTStationFragment   \n}\n\n}\n",
      ""
    ], query$18);

function parse$19(value) {
  var value$1 = value.stations;
  return {
          __typename: value.__typename,
          id: value.id,
          stations: value$1.map(parse$18)
        };
}

function serialize$19(value) {
  var value$1 = value.stations;
  var stations = value$1.map(serialize$18);
  var value$2 = value.id;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          id: value$2,
          stations: stations
        };
}

function verifyArgsAndParse$19(_SoundTrainDataFragment, value) {
  return parse$19(value);
}

function verifyName$19(param) {
  
}

var SoundTrainDataFragment = {
  Raw: Raw$19,
  query: query$19,
  parse: parse$19,
  serialize: serialize$19,
  verifyArgsAndParse: verifyArgsAndParse$19,
  verifyName: verifyName$19
};

var Raw$20 = {};

var query$20 = GraphqlTag.gql(["fragment TupperwareDataFragment on TupperwareData   {\n__typename  \nid  \ncontent  \nstaticQuestionsJson  \nlayout  \nmd5  \n}\n"]);

function parse$20(value) {
  var value$1 = value.staticQuestionsJson;
  var value$2 = value.layout;
  var tmp;
  switch (value$2) {
    case "PieceOfCake" :
        tmp = "PieceOfCake";
        break;
    case "Skilful" :
        tmp = "Skilful";
        break;
    case "Vanilla" :
        tmp = "Vanilla";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  var value$3 = value.md5;
  return {
          __typename: value.__typename,
          id: value.id,
          content: value.content,
          staticQuestionsJson: !(value$1 == null) ? value$1 : undefined,
          layout: tmp,
          md5: !(value$3 == null) ? value$3 : undefined
        };
}

function serialize$20(value) {
  var value$1 = value.md5;
  var md5 = value$1 !== undefined ? value$1 : null;
  var value$2 = value.layout;
  var layout = typeof value$2 === "object" ? value$2.VAL : (
      value$2 === "PieceOfCake" ? "PieceOfCake" : (
          value$2 === "Skilful" ? "Skilful" : "Vanilla"
        )
    );
  var value$3 = value.staticQuestionsJson;
  var staticQuestionsJson = value$3 !== undefined ? value$3 : null;
  var value$4 = value.content;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          content: value$4,
          staticQuestionsJson: staticQuestionsJson,
          layout: layout,
          md5: md5
        };
}

function verifyArgsAndParse$20(_TupperwareDataFragment, value) {
  return parse$20(value);
}

function verifyName$20(param) {
  
}

var TupperwareDataFragment = {
  Raw: Raw$20,
  query: query$20,
  parse: parse$20,
  serialize: serialize$20,
  verifyArgsAndParse: verifyArgsAndParse$20,
  verifyName: verifyName$20
};

var Raw$21 = {};

var query$21 = GraphqlTag.gql(["fragment VideoDataFragment on VideoData   {\n__typename  \nkind  \nvideo  {\n__typename  \nid  \ndescription  \nsources  {\n__typename  \nsource  \n}\n\n}\n\n}\n"]);

function parse$21(value) {
  var value$1 = value.kind;
  var value$2 = value.video;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.description;
    var value$4 = value$2.sources;
    tmp = {
      __typename: value$2.__typename,
      id: value$2.id,
      description: !(value$3 == null) ? value$3 : undefined,
      sources: value$4.map(function (value) {
            return {
                    __typename: value.__typename,
                    source: value.source
                  };
          })
    };
  }
  return {
          __typename: value.__typename,
          kind: !(value$1 == null) ? value$1 : undefined,
          video: tmp
        };
}

function serialize$21(value) {
  var value$1 = value.video;
  var video;
  if (value$1 !== undefined) {
    var value$2 = value$1.sources;
    var sources = value$2.map(function (value) {
          var value$1 = value.source;
          var value$2 = value.__typename;
          return {
                  __typename: value$2,
                  source: value$1
                };
        });
    var value$3 = value$1.description;
    var description = value$3 !== undefined ? value$3 : null;
    var value$4 = value$1.id;
    var value$5 = value$1.__typename;
    video = {
      __typename: value$5,
      id: value$4,
      description: description,
      sources: sources
    };
  } else {
    video = null;
  }
  var value$6 = value.kind;
  var kind = value$6 !== undefined ? value$6 : null;
  var value$7 = value.__typename;
  return {
          __typename: value$7,
          kind: kind,
          video: video
        };
}

function verifyArgsAndParse$21(_VideoDataFragment, value) {
  return parse$21(value);
}

function verifyName$21(param) {
  
}

var VideoDataFragment = {
  Raw: Raw$21,
  query: query$21,
  parse: parse$21,
  serialize: serialize$21,
  verifyArgsAndParse: verifyArgsAndParse$21,
  verifyName: verifyName$21
};

var Raw$22 = {};

var query$22 = GraphqlTag.gql(["fragment InternalStaticDataFragment on InternalStaticData   {\n__typename  \nid  \nextraVocalDirectory  \nphonemeVocalDirectory  \nwordVocalDirectory  \nletterVocalDirectory  \nmd5  \n}\n"]);

function parse$22(value) {
  var value$1 = value.md5;
  return {
          __typename: value.__typename,
          id: value.id,
          extraVocalDirectory: value.extraVocalDirectory,
          phonemeVocalDirectory: value.phonemeVocalDirectory,
          wordVocalDirectory: value.wordVocalDirectory,
          letterVocalDirectory: value.letterVocalDirectory,
          md5: !(value$1 == null) ? value$1 : undefined
        };
}

function serialize$22(value) {
  var value$1 = value.md5;
  var md5 = value$1 !== undefined ? value$1 : null;
  var value$2 = value.letterVocalDirectory;
  var value$3 = value.wordVocalDirectory;
  var value$4 = value.phonemeVocalDirectory;
  var value$5 = value.extraVocalDirectory;
  var value$6 = value.id;
  var value$7 = value.__typename;
  return {
          __typename: value$7,
          id: value$6,
          extraVocalDirectory: value$5,
          phonemeVocalDirectory: value$4,
          wordVocalDirectory: value$3,
          letterVocalDirectory: value$2,
          md5: md5
        };
}

function verifyArgsAndParse$22(_InternalStaticDataFragment, value) {
  return parse$22(value);
}

function verifyName$22(param) {
  
}

var InternalStaticDataFragment = {
  Raw: Raw$22,
  query: query$22,
  parse: parse$22,
  serialize: serialize$22,
  verifyArgsAndParse: verifyArgsAndParse$22,
  verifyName: verifyName$22
};

var Raw$23 = {};

var query$23 = GraphqlTag.gql(["fragment UnsafeDataFragment on UnsafeData   {\n__typename  \nid  \nmd5  \ndata  \ngameTypeId  \nextraVocalDirectory  \nphonemeVocalDirectory  \nwordVocalDirectory  \nletterVocalDirectory  \n}\n"]);

function parse$23(value) {
  var value$1 = value.md5;
  return {
          __typename: value.__typename,
          id: value.id,
          md5: !(value$1 == null) ? value$1 : undefined,
          data: value.data,
          gameTypeId: value.gameTypeId,
          extraVocalDirectory: value.extraVocalDirectory,
          phonemeVocalDirectory: value.phonemeVocalDirectory,
          wordVocalDirectory: value.wordVocalDirectory,
          letterVocalDirectory: value.letterVocalDirectory
        };
}

function serialize$23(value) {
  var value$1 = value.letterVocalDirectory;
  var value$2 = value.wordVocalDirectory;
  var value$3 = value.phonemeVocalDirectory;
  var value$4 = value.extraVocalDirectory;
  var value$5 = value.gameTypeId;
  var value$6 = value.data;
  var value$7 = value.md5;
  var md5 = value$7 !== undefined ? value$7 : null;
  var value$8 = value.id;
  var value$9 = value.__typename;
  return {
          __typename: value$9,
          id: value$8,
          md5: md5,
          data: value$6,
          gameTypeId: value$5,
          extraVocalDirectory: value$4,
          phonemeVocalDirectory: value$3,
          wordVocalDirectory: value$2,
          letterVocalDirectory: value$1
        };
}

function verifyArgsAndParse$23(_UnsafeDataFragment, value) {
  return parse$23(value);
}

function verifyName$23(param) {
  
}

var UnsafeDataFragment = {
  Raw: Raw$23,
  query: query$23,
  parse: parse$23,
  serialize: serialize$23,
  verifyArgsAndParse: verifyArgsAndParse$23,
  verifyName: verifyName$23
};

export {
  GameAudioFragment ,
  GameExampleSentenceWordFragment ,
  GameExampleSentenceFragment ,
  GameWordFragment ,
  GameWordListFragment ,
  WordGameDataFragment ,
  ContentMCPhonemeFragment ,
  ContentMCWordFragment ,
  MonsterChefDataFragment ,
  ContentPhonemeAudioSourceFragment ,
  ContentWAMResponseFragment ,
  ContentWAMQuestionFragment ,
  WhackAMunkDataFragment ,
  ContentPhonemeWordImageSourceFragment ,
  ContentPhonemeWordAudioSourceFragment ,
  ContentSTWordFragment ,
  ContentSTPhonemeFragment ,
  ContentSTQuestionFragment ,
  ContentSTStationFragment ,
  SoundTrainDataFragment ,
  TupperwareDataFragment ,
  VideoDataFragment ,
  InternalStaticDataFragment ,
  UnsafeDataFragment ,
}
/* query Not a pure module */
