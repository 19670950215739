// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function use(param) {
  var match = React.useState(function (param) {
        return "white";
      });
  var background = {
    background: match[0],
    height: "100%",
    left: "0px",
    position: "absolute",
    top: "0px",
    width: "100%"
  };
  return [
          background,
          match[1]
        ];
}

export {
  use ,
}
/* react Not a pure module */
