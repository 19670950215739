// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as FullscreenIcon$LiteracyplanetTupperware from "./FullscreenIcon.res.mjs";

function FullScreenButton(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.FullScreenButton.className,
              onClick: props.onClick
            }, React.createElement(FullscreenIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.FullScreenButton.FullScreenIcon.className
                }));
}

var make = FullScreenButton;

export {
  make ,
}
/* react Not a pure module */
