// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import ToolbarLayoutJsx from "./ToolbarLayout.jsx";

import 'normalize.css'
;

var make = ToolbarLayoutJsx;

var Global = {
  make: make
};

function className(pageStyles) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.position(Css.absolute),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.top(Css.px(0)),
                        tl: {
                          hd: Css.right(Css.px(0)),
                          tl: {
                            hd: Css.bottom(Css.px(0)),
                            tl: {
                              hd: Css.left(Css.px(0)),
                              tl: {
                                hd: Css.backgroundColor(Css.hex(pageStyles.backgroundColor)),
                                tl: {
                                  hd: Css.zIndex(1),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: "ToolbarPage",
                tl: /* [] */0
              }
            });
}

function ToolbarLayout$ToolbarPage(props) {
  return React.createElement("div", {
              className: className(props.pageStyles)
            }, props.children);
}

var ToolbarPage = {
  className: className,
  make: ToolbarLayout$ToolbarPage
};

function className$1(pageStyles) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.position(pageStyles.cover ? Css.fixed : Css.relative),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.top(Css.px(0)),
                        tl: {
                          hd: Css.height(Css.px(42)),
                          tl: {
                            hd: Css.backgroundColor(Css.white),
                            tl: {
                              hd: Css.borderBottomWidth(Css.px(3)),
                              tl: {
                                hd: Css.borderBottomStyle(Css.solid),
                                tl: {
                                  hd: Css.borderBottomColor(Css.hex(pageStyles.color)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: "Toolbar",
                tl: /* [] */0
              }
            });
}

function ToolbarLayout$Toolbar(props) {
  return React.createElement("div", {
              className: className$1(props.pageStyles)
            }, props.children);
}

var Toolbar = {
  className: className$1,
  make: ToolbarLayout$Toolbar
};

function className$2(pageStyles) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Css.hex(pageStyles.color)),
                    tl: {
                      hd: Css.display(Css.inlineBlock),
                      tl: {
                        hd: Css.height(Css.px(41)),
                        tl: {
                          hd: Css.width(Css.px(75)),
                          tl: {
                            hd: Css.marginBottom(Css.px(1)),
                            tl: {
                              hd: Css.$$float("left"),
                              tl: {
                                hd: Css.verticalAlign("top"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: "ToolbarLogo",
                tl: /* [] */0
              }
            });
}

function ToolbarLayout$ToolbarLogo(props) {
  return React.createElement("div", {
              className: className$2(props.pageStyles)
            }, props.children);
}

var ToolbarLogo = {
  className: className$2,
  make: ToolbarLayout$ToolbarLogo
};

var className$3 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.$$float("left"),
            tl: {
              hd: Css.paddingLeft(Css.px(10)),
              tl: {
                hd: Css.paddingTop(Css.px(10)),
                tl: {
                  hd: Css.fontSize(Css.rem(1.6)),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: "ToolbarTitle",
        tl: /* [] */0
      }
    });

function ToolbarLayout$ToolbarTitle(props) {
  return React.createElement("div", {
              className: className$3
            }, props.children);
}

var ToolbarTitle = {
  className: className$3,
  make: ToolbarLayout$ToolbarTitle
};

function className$4(pageStyles) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.width(Css.px(28)),
                    tl: {
                      hd: Css.height(Css.px(24)),
                      tl: {
                        hd: Css.$$float("right"),
                        tl: {
                          hd: Css.marginRight(Css.px(20)),
                          tl: {
                            hd: Css.marginTop(Css.px(10)),
                            tl: {
                              hd: Css.color(Css.hex(pageStyles.color)),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Css.hex(pageStyles.hoverColor)),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.active({
                                        hd: Css.color(Css.hex(pageStyles.activeColor)),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: "ToolbarExit",
                tl: /* [] */0
              }
            });
}

function ToolbarLayout$ToolbarExit(props) {
  return React.createElement("div", {
              className: className$4(props.pageStyles)
            }, props.children);
}

var ToolbarExit = {
  className: className$4,
  make: ToolbarLayout$ToolbarExit
};

var className$5 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.width(Css.px(15)),
            tl: {
              hd: Css.height(Css.px(15)),
              tl: {
                hd: Css.display(Css.inlineBlock),
                tl: {
                  hd: Css.verticalAlign("top"),
                  tl: {
                    hd: Css.marginRight(Css.px(10)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }),
      tl: {
        hd: "ExitIcon",
        tl: /* [] */0
      }
    });

var svgClassName = Curry._1(Css.style, {
      hd: Css.width(Css.px(28)),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: /* [] */0
      }
    });

function ToolbarLayout$ExitIcon(props) {
  var exitTo = props.exitTo;
  return React.createElement("div", {
              className: className$5,
              onClick: (function (param) {
                  Curry._1(exitTo, undefined);
                })
            }, React.createElement("svg", {
                  className: svgClassName,
                  fill: "currentColor",
                  viewBox: "0 0 500.5 500.5",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", undefined, React.createElement("path", {
                          d: "M249.75-.5C112-.5-.5,111.95-.5,249.75S112,500,\n          249.75,500,500,387.55,500,249.75,387.55-.5,249.75-.5Zm130.6,\n          351.05a21.07,21.07,0,0,1-29.79,29.79L250,279.79,149.45,\n          380.35a21.13,21.13,0,0,1-29.79,0h0a21.13,21.13,0,0,1,\n          0-29.79L220.21,250,119.65,149.45a21.07,21.07,0,0,1,\n          29.79-29.79L250,220.21,350.55,119.65a21.13,21.13,0,\n          0,1,29.79,0h0a21.13,21.13,0,0,1,0,29.79L279.79,250Z",
                          transform: "translate(0.5 0.5)"
                        }))));
}

var ExitIcon = {
  className: className$5,
  svgClassName: svgClassName,
  make: ToolbarLayout$ExitIcon
};

function ToolbarLayout$ToolbarComponent(props) {
  var pageStyles = props.pageStyles;
  return React.createElement(ToolbarLayout$Toolbar, {
              pageStyles: pageStyles,
              children: null
            }, React.createElement(ToolbarLayout$ToolbarLogo, {
                  pageStyles: pageStyles,
                  children: props.icon
                }), React.createElement(ToolbarLayout$ToolbarTitle, {
                  children: props.title
                }), React.createElement(ToolbarLayout$ToolbarExit, {
                  pageStyles: pageStyles,
                  children: React.createElement(ToolbarLayout$ExitIcon, {
                        exitTo: props.exitTo
                      })
                }));
}

var ToolbarComponent = {
  make: ToolbarLayout$ToolbarComponent
};

function ToolbarLayout(props) {
  var pageStyles = props.pageStyles;
  return React.createElement(React.Fragment, {}, React.createElement(make, {}), React.createElement(ToolbarLayout$ToolbarPage, {
                  pageStyles: pageStyles,
                  children: null
                }, React.createElement(ToolbarLayout$ToolbarComponent, {
                      pageStyles: pageStyles,
                      icon: props.icon,
                      title: props.title,
                      exitTo: props.exitTo
                    }), props.children));
}

var make$1 = ToolbarLayout;

var $$default = ToolbarLayout;

export {
  Global ,
  ToolbarPage ,
  Toolbar ,
  ToolbarLogo ,
  ToolbarTitle ,
  ToolbarExit ,
  ExitIcon ,
  ToolbarComponent ,
  make$1 as make,
  $$default as default,
}
/*  Not a pure module */
