// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ModernComponents$LiteracyplanetClientSxEntry from "./ModernComponents.res.mjs";

function ModernNavBarRatio(props) {
  var onClickMyWords = props.onClickMyWords;
  var onClickIntrepizine = props.onClickIntrepizine;
  var lockLibrary = props.lockLibrary;
  var highlightLibrary = props.highlightLibrary;
  var onClickShop = props.onClickShop;
  var onClickArcade = props.onClickArcade;
  var onClickCollections = props.onClickCollections;
  var onClickMissions = props.onClickMissions;
  var match = React.useState(function (param) {
        return false;
      });
  var setSubnavOpen = match[1];
  var closeSubnav = function (param) {
    Curry._1(setSubnavOpen, (function (param) {
            return false;
          }));
  };
  var openSubnav = function (param) {
    Curry._1(setSubnavOpen, (function (param) {
            return true;
          }));
  };
  return React.createElement(ModernComponents$LiteracyplanetClientSxEntry.HeaderBackground.make, {
              children: React.createElement(ModernComponents$LiteracyplanetClientSxEntry.HeaderPanel.make, {
                    children: null
                  }, React.createElement(ModernComponents$LiteracyplanetClientSxEntry.MissionsSlimHeaderItem.make, {
                        highlight: props.highlightMissions,
                        onClick: (function (param) {
                            Curry._1(onClickMissions, undefined);
                            Curry._1(setSubnavOpen, (function (param) {
                                    return false;
                                  }));
                          })
                      }), React.createElement(ModernComponents$LiteracyplanetClientSxEntry.MyWordsSlimHeaderItem.make, {
                        highlight: props.highlightMyWords,
                        onClick: (function (param) {
                            Curry._1(onClickMyWords, undefined);
                            Curry._1(setSubnavOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        lock: props.lockMyWords
                      }), props.showLibraryLink ? React.createElement(ModernComponents$LiteracyplanetClientSxEntry.LibrarySlimHeaderItem.make, {
                          lockLibrary: lockLibrary,
                          highlightLibrary: highlightLibrary,
                          onClickLibrary: props.onClickLibrary,
                          onClickIntrepizine: onClickIntrepizine,
                          subnavOpen: match[0],
                          closeSubnav: closeSubnav,
                          openSubnav: openSubnav
                        }) : React.createElement(ModernComponents$LiteracyplanetClientSxEntry.IntrepizineSlimHeaderItem.make, {
                          highlight: highlightLibrary,
                          onClick: (function (param) {
                              Curry._1(onClickIntrepizine, undefined);
                            }),
                          lock: lockLibrary
                        }), React.createElement(ModernComponents$LiteracyplanetClientSxEntry.CollectionsSlimHeaderItem.make, {
                        highlight: props.highlightCollections,
                        notificationCount: props.collectionNotificationCount,
                        onClick: (function (param) {
                            Curry._1(onClickCollections, undefined);
                            Curry._1(setSubnavOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        lock: props.lockCollections
                      }), props.showArcade ? React.createElement(ModernComponents$LiteracyplanetClientSxEntry.ArcadeSlimHeaderItem.make, {
                          highlight: props.highlightArcade,
                          onClick: (function (param) {
                              Curry._1(onClickArcade, undefined);
                              Curry._1(setSubnavOpen, (function (param) {
                                      return false;
                                    }));
                            }),
                          lock: props.lockArcade
                        }) : null, React.createElement(ModernComponents$LiteracyplanetClientSxEntry.AvatarSlimHeaderItem.make, {
                        highlight: false,
                        onClick: (function (param) {
                            Curry._1(onClickShop, undefined);
                            Curry._1(setSubnavOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        lock: props.lockShop
                      }), props.menu)
            });
}

var make = ModernNavBarRatio;

export {
  make ,
}
/* react Not a pure module */
