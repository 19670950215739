// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import FlarePng from "./assets/flare.png";
import BannerSvg from "./assets/banner.svg";
import Star_blueSvg from "./assets/star_blue.svg";
import Level_guideSvg from "./assets/level_guide.svg";
import Star_yellowSvg from "./assets/star_yellow.svg";
import Button_closeSvg from "./assets/button_close.svg";
import Profile_panelSvg from "./assets/profile_panel.svg";
import Forest_nav_barSvg from "./assets/forest_nav_bar.svg";
import Button_close_hoverSvg from "./assets/button_close_hover.svg";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";
import Button_close_activeSvg from "./assets/button_close_active.svg";
import * as CssUtils$LiteracyplanetClientSxEntry from "./CssUtils.res.mjs";

var brownColor = Css.rgba(85, 48, 19, {
      NAME: "num",
      VAL: 1.0
    });

var whiteColor = Css.rgba(255, 255, 255, {
      NAME: "num",
      VAL: 1.0
    });

var bottomBlueColor = Css.rgba(109, 207, 246, {
      NAME: "num",
      VAL: 1.0
    });

var topBlueColor = Css.rgba(141, 223, 255, {
      NAME: "num",
      VAL: 1.0
    });

var blueColor = Css.rgba(11, 185, 254, {
      NAME: "num",
      VAL: 1.0
    });

var offWhiteColor = Css.rgba(233, 220, 193, {
      NAME: "num",
      VAL: 1.0
    });

var lightOffWhiteColor = Css.rgba(253, 250, 240, {
      NAME: "num",
      VAL: 1.0
    });

var partGreen = Css.rgba(60, 105, 53, {
      NAME: "num",
      VAL: 1.0
    });

var lockedBrown = Css.rgba(96, 73, 48, {
      NAME: "num",
      VAL: 1.0
    });

var numberBrown = Css.rgba(127, 45, 37, {
      NAME: "num",
      VAL: 1.0
    });

var fredokaOneFont = Css.fontFamilies({
      hd: {
        NAME: "custom",
        VAL: "FredokaOneRegular"
      },
      tl: {
        hd: {
          NAME: "custom",
          VAL: "Lato"
        },
        tl: {
          hd: {
            NAME: "custom",
            VAL: "Arial"
          },
          tl: /* [] */0
        }
      }
    });

function truncateWord(str, maxChar) {
  if (str.length <= maxChar) {
    return str;
  }
  var trimmedStr = $$String.sub(str, 0, maxChar - 3 | 0);
  return trimmedStr + "...";
}

function abbreviateNum(num, suffixStr) {
  return Js_math.floor(num).toString() + suffixStr;
}

function formatGemCount(numGems) {
  if (numGems >= 1000000000) {
    return abbreviateNum(numGems / 1000000000 | 0, " bil+");
  } else if (numGems >= 1000000) {
    return abbreviateNum(numGems / 1000000 | 0, " mil+");
  } else if (numGems >= 100000) {
    return abbreviateNum(numGems / 1000 | 0, " k+");
  } else {
    return numGems.toLocaleString();
  }
}

function ForestComponents$Spacer(props) {
  return React.createElement("div", undefined);
}

var Spacer = {
  make: ForestComponents$Spacer
};

var iconCellWidth = 136;

var cols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: iconCellWidth
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: iconCellWidth
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: iconCellWidth
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: iconCellWidth
            },
            tl: /* [] */0
          }
        }
      }
    });

var css = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignContent(Css.center),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.justifyItems(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: cols,
        tl: {
          hd: "BottomIconRow",
          tl: /* [] */0
        }
      }
    });

var BottomIconRow = {
  iconCellWidth: iconCellWidth,
  cols: cols,
  css: css,
  className: className
};

var profilePanel = Profile_panelSvg;

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        488
      ],
      tl: {
        hd: [
          Css.height,
          83
        ],
        tl: /* [] */0
      }
    });

var css$1 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(profilePanel)),
      tl: {
        hd: Css.backgroundRepeat("noRepeat"),
        tl: {
          hd: Css.backgroundPosition(Css.center),
          tl: {
            hd: Css.backgroundSize("contain"),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: r,
        tl: {
          hd: "ProfilePanel",
          tl: /* [] */0
        }
      }
    });

var ProfilePanelStyle = {
  r: r,
  css: css$1,
  className: className$1
};

function ForestComponents$ProfilePanel(props) {
  return React.createElement("div", {
              className: className$1
            }, props.children);
}

var ProfilePanel = {
  make: ForestComponents$ProfilePanel
};

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        468
      ],
      tl: {
        hd: [
          Css.height,
          63
        ],
        tl: {
          hd: [
            Css.margin,
            10
          ],
          tl: /* [] */0
        }
      }
    });

var cols$1 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 310
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 160
        },
        tl: /* [] */0
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: /* [] */0
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: cols$1,
        tl: {
          hd: r$1,
          tl: {
            hd: "ProfileInnerPanel",
            tl: /* [] */0
          }
        }
      }
    });

var ProfileInnerPanelStyle = {
  r: r$1,
  cols: cols$1,
  css: css$2,
  className: className$2
};

function ForestComponents$ProfileInnerPanel(props) {
  return React.createElement("div", {
              className: className$2
            }, props.children);
}

var ProfileInnerPanel = {
  make: ForestComponents$ProfileInnerPanel
};

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        310
      ],
      tl: {
        hd: [
          Css.height,
          63
        ],
        tl: /* [] */0
      }
    });

var rows = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 36
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 24
        },
        tl: /* [] */0
      }
    });

var cols$2 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 40
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: Css.fr(1.0)
        },
        tl: /* [] */0
      }
    });

var css$3 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: /* [] */0
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: rows,
        tl: {
          hd: cols$2,
          tl: {
            hd: r$2,
            tl: {
              hd: "ProfileNamePanel",
              tl: /* [] */0
            }
          }
        }
      }
    });

var ProfileNamePanelStyle = {
  r: r$2,
  rows: rows,
  cols: cols$2,
  css: css$3,
  className: className$3
};

function ForestComponents$ProfileNamePanel(props) {
  return React.createElement("div", {
              className: className$3
            }, props.children);
}

var ProfileNamePanel = {
  make: ForestComponents$ProfileNamePanel
};

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        24
      ],
      tl: /* [] */0
    });

var css$4 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: fredokaOneFont,
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: fredokaOneFont,
              tl: /* [] */0
            }
          }
        }
      }
    });

var stroke = Theme$LiteracyplanetClientFlags.textStroke(1, 1, 2, partGreen);

var className$4 = Curry._1(Css.merge, {
      hd: css$4,
      tl: {
        hd: r$3,
        tl: {
          hd: stroke,
          tl: {
            hd: "ProfileText",
            tl: /* [] */0
          }
        }
      }
    });

var ProfileTextStyle = {
  r: r$3,
  css: css$4,
  stroke: stroke,
  className: className$4
};

function ForestComponents$ProfileText(props) {
  return React.createElement("div", {
              className: className$4
            }, truncateWord(props.userName, 24));
}

var ProfileText = {
  make: ForestComponents$ProfileText
};

var r$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        16
      ],
      tl: /* [] */0
    });

var css$5 = Curry._1(Css.style, {
      hd: Css.color(partGreen),
      tl: {
        hd: fredokaOneFont,
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: fredokaOneFont,
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$5 = Curry._1(Css.merge, {
      hd: css$5,
      tl: {
        hd: r$4,
        tl: {
          hd: "LocationText",
          tl: /* [] */0
        }
      }
    });

var LocationTextStyle = {
  r: r$4,
  css: css$5,
  className: className$5
};

function ForestComponents$LocationText(props) {
  var navigation = props.navigation;
  var tmp;
  tmp = typeof navigation !== "object" ? "Loading..." : truncateWord(navigation._0.location, 35);
  return React.createElement("div", {
              className: className$5
            }, tmp);
}

var LocationText = {
  make: ForestComponents$LocationText
};

var r$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        14
      ],
      tl: /* [] */0
    });

var css$6 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: fredokaOneFont,
        tl: {
          hd: fredokaOneFont,
          tl: /* [] */0
        }
      }
    });

var stroke$1 = Theme$LiteracyplanetClientFlags.textStroke(1, 1, 2, lockedBrown);

var className$6 = Curry._1(Css.merge, {
      hd: r$5,
      tl: {
        hd: css$6,
        tl: {
          hd: stroke$1,
          tl: {
            hd: "ItemText",
            tl: /* [] */0
          }
        }
      }
    });

var ItemTextStyle = {
  r: r$5,
  css: css$6,
  stroke: stroke$1,
  className: className$6
};

function ForestComponents$ItemText(props) {
  return React.createElement("div", {
              className: className$6
            }, props.text);
}

var ItemText = {
  make: ForestComponents$ItemText
};

var r$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        24
      ],
      tl: /* [] */0
    });

var css$7 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: fredokaOneFont,
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.justifyItems("left"),
              tl: {
                hd: fredokaOneFont,
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var stroke$2 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, partGreen);

var className$7 = Curry._1(Css.merge, {
      hd: css$7,
      tl: {
        hd: r$6,
        tl: {
          hd: stroke$2,
          tl: {
            hd: "GemCountText",
            tl: /* [] */0
          }
        }
      }
    });

var GemCountTextStyle = {
  r: r$6,
  css: css$7,
  stroke: stroke$2,
  className: className$7
};

function ForestComponents$GemCountText(props) {
  var navigation = props.navigation;
  var tmp;
  tmp = typeof navigation !== "object" ? "..." : formatGemCount(Caml_format.int_of_string(navigation._0.gems));
  return React.createElement("div", {
              className: className$7
            }, tmp);
}

var GemCountText = {
  make: ForestComponents$GemCountText
};

var r$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        60
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          20
        ],
        tl: {
          hd: [
            Css.top,
            32
          ],
          tl: {
            hd: [
              Css.left,
              15
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$8 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: fredokaOneFont,
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: fredokaOneFont,
              tl: {
                hd: Css.position(Css.absolute),
                tl: {
                  hd: Css.textAlign(Css.center),
                  tl: {
                    hd: Css.zIndex(2),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var stroke$3 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, numberBrown);

var className$8 = Curry._1(Css.merge, {
      hd: css$8,
      tl: {
        hd: r$7,
        tl: {
          hd: stroke$3,
          tl: {
            hd: "StartLevelText",
            tl: /* [] */0
          }
        }
      }
    });

var StartLevelTextStyle = {
  r: r$7,
  css: css$8,
  stroke: stroke$3,
  className: className$8
};

function ForestComponents$StartLevelText(props) {
  return React.createElement("div", {
              className: className$8
            }, props.children);
}

var StartLevelText = {
  make: ForestComponents$StartLevelText
};

var r$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        506
      ],
      tl: /* [] */0
    });

var css$9 = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100.0)),
      tl: /* [] */0
    });

var className$9 = Curry._1(Css.merge, {
      hd: r$8,
      tl: {
        hd: css$9,
        tl: {
          hd: "LevelPanel",
          tl: /* [] */0
        }
      }
    });

var LevelPanelStyle = {
  r: r$8,
  css: css$9,
  className: className$9
};

function ForestComponents$LevelPanel(props) {
  return React.createElement("div", {
              className: className$9
            }, props.children);
}

var LevelPanel = {
  make: ForestComponents$LevelPanel
};

var bannerText = BannerSvg;

var r$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        326
      ],
      tl: {
        hd: [
          Css.height,
          61
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            20
          ],
          tl: {
            hd: [
              Css.top,
              40
            ],
            tl: {
              hd: [
                Css.left,
                124
              ],
              tl: {
                hd: [
                  Theme$LiteracyplanetClientFlags.paddingTop,
                  6
                ],
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var css$10 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(bannerText)),
      tl: {
        hd: Css.backgroundRepeat("noRepeat"),
        tl: {
          hd: Css.backgroundPosition(Css.center),
          tl: {
            hd: Css.backgroundSize("contain"),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: {
                  hd: Css.justifyContent(Css.center),
                  tl: {
                    hd: Css.justifyItems(Css.center),
                    tl: {
                      hd: Css.color(Css.white),
                      tl: {
                        hd: fredokaOneFont,
                        tl: {
                          hd: Css.display(Css.grid),
                          tl: {
                            hd: fredokaOneFont,
                            tl: {
                              hd: Css.textAlign(Css.center),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var stroke$4 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, numberBrown);

var className$10 = Curry._1(Css.merge, {
      hd: css$10,
      tl: {
        hd: r$9,
        tl: {
          hd: stroke$4,
          tl: {
            hd: "BannerText",
            tl: /* [] */0
          }
        }
      }
    });

var BannerTextStyle = {
  r: r$9,
  css: css$10,
  stroke: stroke$4,
  className: className$10
};

function ForestComponents$BannerText(props) {
  return React.createElement("div", {
              className: className$10
            }, props.children);
}

var BannerText = {
  make: ForestComponents$BannerText
};

var r$10 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.top,
        -12
      ],
      tl: /* [] */0
    });

var css$11 = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100.0
          }),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: /* [] */0
          }
        }
      }
    });

var className$11 = Curry._1(Css.merge, {
      hd: r$10,
      tl: {
        hd: css$11,
        tl: {
          hd: "LevelPanelBanner",
          tl: /* [] */0
        }
      }
    });

function ForestComponents$LevelPanelBanner(props) {
  return React.createElement("div", {
              className: className$11
            }, props.children);
}

var LevelPanelBanner = {
  r: r$10,
  css: css$11,
  className: className$11,
  make: ForestComponents$LevelPanelBanner
};

var r$11 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        158
      ],
      tl: /* [] */0
    });

var css$12 = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

var className$12 = Curry._1(Css.merge, {
      hd: r$11,
      tl: {
        hd: css$12,
        tl: {
          hd: "LevelPanelContainer",
          tl: /* [] */0
        }
      }
    });

var LevelPanelContainerStyle = {
  r: r$11,
  css: css$12,
  className: className$12
};

function ForestComponents$LevelPanelContainer(props) {
  return React.createElement("div", {
              className: className$12
            }, props.children);
}

var LevelPanelContainer = {
  make: ForestComponents$LevelPanelContainer
};

var r$12 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        60
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          20
        ],
        tl: {
          hd: [
            Css.top,
            32
          ],
          tl: {
            hd: [
              Css.left,
              448
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$13 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: fredokaOneFont,
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: fredokaOneFont,
              tl: {
                hd: Css.position(Css.absolute),
                tl: {
                  hd: Css.textAlign(Css.center),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var stroke$5 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, numberBrown);

var className$13 = Curry._1(Css.merge, {
      hd: css$13,
      tl: {
        hd: r$12,
        tl: {
          hd: stroke$5,
          tl: {
            hd: "EndLevelText",
            tl: /* [] */0
          }
        }
      }
    });

var EndLevelTextStyle = {
  r: r$12,
  css: css$13,
  stroke: stroke$5,
  className: className$13
};

function ForestComponents$EndLevelText(props) {
  return React.createElement("div", {
              className: className$13
            }, props.children);
}

var EndLevelText = {
  make: ForestComponents$EndLevelText
};

var r$13 = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var cols$3 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 50
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: "auto"
        },
        tl: /* [] */0
      }
    });

var css$14 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignContent(Css.center),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: /* [] */0
        }
      }
    });

var className$14 = Curry._1(Css.merge, {
      hd: css$14,
      tl: {
        hd: cols$3,
        tl: {
          hd: r$13,
          tl: {
            hd: "ProfileGemsPanel",
            tl: /* [] */0
          }
        }
      }
    });

var ProfileGemsPanelStyle = {
  r: r$13,
  cols: cols$3,
  css: css$14,
  className: className$14
};

function ForestComponents$ProfileGemsPanel(props) {
  return React.createElement("div", {
              className: className$14
            }, props.children);
}

var ProfileGemsPanel = {
  make: ForestComponents$ProfileGemsPanel
};

var banner = BannerSvg;

var r$14 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        Theme$LiteracyplanetClientFlags.maximumWidth
      ],
      tl: {
        hd: [
          Css.height,
          768
        ],
        tl: /* [] */0
      }
    });

var css$15 = Curry._1(Css.style, {
      hd: Css.top(Css.zero),
      tl: {
        hd: Css.left(Css.zero),
        tl: {
          hd: Css.backgroundImage(Css.url(banner)),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.backgroundSize("contain"),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$15 = Curry._1(Css.merge, {
      hd: css$15,
      tl: {
        hd: r$14,
        tl: {
          hd: "Banner",
          tl: /* [] */0
        }
      }
    });

var Banner = {
  r: r$14,
  css: css$15,
  className: className$15
};

var css$16 = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.position(Css.fixed),
        tl: {
          hd: Css.top(Css.zero),
          tl: {
            hd: Css.left(Css.zero),
            tl: {
              hd: Css.width({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: {
                hd: Css.height({
                      NAME: "percent",
                      VAL: 100.0
                    }),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.alignContent(Css.center),
                    tl: {
                      hd: Css.alignItems(Css.center),
                      tl: {
                        hd: Css.justifyContent(Css.center),
                        tl: {
                          hd: Css.justifyItems(Css.center),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$16 = Curry._1(Css.merge, {
      hd: css$16,
      tl: {
        hd: "ForestEmptyLayout",
        tl: /* [] */0
      }
    });

var ForestEmptyLayout = {
  css: css$16,
  className: className$16
};

var closeButton = Button_closeSvg;

var closeButtonHover = Button_close_hoverSvg;

var closeButtonActive = Button_close_activeSvg;

var r$15 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        122
      ],
      tl: {
        hd: [
          Css.height,
          46
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            20
          ],
          tl: {
            hd: [
              Css.gridColumnGap,
              10
            ],
            tl: /* [] */0
          }
        }
      }
    });

var rows$1 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "css",
        VAL: Css.fr(1.0)
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 5
        },
        tl: /* [] */0
      }
    });

var css$17 = Curry._1(Css.style, {
      hd: Css.backgroundImage(Css.url(closeButton)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition("bottom"),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.alignContent(Css.center),
                tl: {
                  hd: Css.alignItems(Css.center),
                  tl: {
                    hd: Css.justifyContent(Css.center),
                    tl: {
                      hd: Css.justifyItems("start"),
                      tl: {
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: fredokaOneFont,
                          tl: {
                            hd: Css.color(Css.white),
                            tl: {
                              hd: Css.gridTemplateColumns({
                                    hd: Css.pct(10.0),
                                    tl: {
                                      hd: Css.pct(20.0),
                                      tl: {
                                        hd: Css.pct(70.0),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundImage(Css.url(closeButtonHover)),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.active({
                                        hd: Css.backgroundImage(Css.url(closeButtonActive)),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: CssUtils$LiteracyplanetClientSxEntry.preloadImages({
                                          hd: closeButtonHover,
                                          tl: {
                                            hd: closeButtonActive,
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var stroke$6 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, numberBrown);

var className$17 = Curry._1(Css.merge, {
      hd: rows$1,
      tl: {
        hd: css$17,
        tl: {
          hd: r$15,
          tl: {
            hd: stroke$6,
            tl: {
              hd: "CloseButton",
              tl: /* [] */0
            }
          }
        }
      }
    });

var CloseButtonStyle = {
  r: r$15,
  rows: rows$1,
  css: css$17,
  stroke: stroke$6,
  className: className$17
};

function ForestComponents$CloseButton(props) {
  return React.createElement("div", {
              className: className$17,
              onClick: props.onClick
            }, props.children);
}

var CloseButton = {
  make: ForestComponents$CloseButton
};

var overRideCss = Curry._1(Css.style, {
      hd: Css.gridTemplateColumns({
            hd: "auto",
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var className$18 = Curry._1(Css.merge, {
      hd: className$17,
      tl: {
        hd: overRideCss,
        tl: {
          hd: "PlainButton",
          tl: /* [] */0
        }
      }
    });

function ForestComponents$PlainButton(props) {
  return React.createElement("div", {
              className: className$18,
              onClick: props.onClick
            }, props.children);
}

var PlainButton = {
  overRideCss: overRideCss,
  className: className$18,
  make: ForestComponents$PlainButton
};

function ForestComponents$DownArrow(props) {
  return React.createElement("svg", {
              className: props.className,
              style: props.style,
              height: "10px",
              width: "16px",
              version: "1.1",
              viewBox: "0 0 16 10",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink"
            }, React.createElement("g", {
                  id: "Parent",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("g", {
                      id: "Pie-chart-hover-&amp;-dropdown-fields",
                      fill: "currentColor",
                      fillRule: "nonzero",
                      transform: "translate(-757.000000, -159.000000)"
                    }, React.createElement("g", {
                          id: "down-arrow-(1)",
                          transform: "translate(757.000000, 159.000000)"
                        }, React.createElement("path", {
                              id: "Path",
                              d: "M15.2483674,4.1401254 L9.37999048,9.21328267 C8.98293837,9.55666468 8.49155307,9.72808446 8.00016778,9.72808446 C7.50931544,9.72808446 7.0184631,9.55666468 6.62034508,9.21328267 L0.751968199,4.1401254 C-0.145529455,3.36439669 -0.25531836,1.99520839 0.506808508,1.0816929 C1.26946833,0.168177409 2.6146489,0.0564290769 3.51214655,0.832700256 L8.00016778,4.71188628 L12.487656,0.832700256 C13.3862196,0.0569715445 14.7308672,0.168719876 15.4929941,1.0816929 C16.255121,1.99520839 16.1453321,3.36439669 15.2483674,4.1401254 Z"
                            })))));
}

var DownArrow = {
  make: ForestComponents$DownArrow
};

var className$19 = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "hex",
            VAL: "FFFFFF"
          }),
      tl: {
        hd: Css.margin("auto"),
        tl: {
          hd: Css.height(Css.pct(70.0)),
          tl: {
            hd: Css.width(Css.pct(40.0)),
            tl: /* [] */0
          }
        }
      }
    });

function ForestComponents$DownButton(props) {
  return React.createElement(ForestComponents$PlainButton, {
              children: React.createElement(ForestComponents$DownArrow, {
                    className: className$19
                  }),
              onClick: props.onClick
            });
}

var DownButton = {
  className: className$19,
  make: ForestComponents$DownButton
};

var scrolls = Forest_nav_barSvg;

var r$16 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        774
      ],
      tl: {
        hd: [
          Css.height,
          92
        ],
        tl: {
          hd: [
            Css.top,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var cols$4 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 90
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 90
          },
          tl: /* [] */0
        }
      }
    });

var css$18 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.display("grid"),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.backgroundImage(Css.url(scrolls)),
              tl: {
                hd: Css.backgroundSize("contain"),
                tl: {
                  hd: Css.backgroundRepeat("noRepeat"),
                  tl: {
                    hd: Css.backgroundPosition("bottom"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var className$20 = Curry._1(Css.merge, {
      hd: r$16,
      tl: {
        hd: cols$4,
        tl: {
          hd: css$18,
          tl: {
            hd: "HeaderSubPanel",
            tl: /* [] */0
          }
        }
      }
    });

var HeaderSubPanelStyle = {
  r: r$16,
  cols: cols$4,
  css: css$18,
  className: className$20
};

function ForestComponents$HeaderSubPanel(props) {
  return React.createElement("div", {
              className: className$20
            }, props.children);
}

var HeaderSubPanel = {
  make: ForestComponents$HeaderSubPanel
};

var r$17 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        76
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.gridAutoColumns,
          90
        ],
        tl: /* [] */0
      }
    });

var css$19 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.alignContent(Css.center),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.justifyContent(Css.spaceEvenly),
            tl: {
              hd: Css.justifyItems(Css.center),
              tl: {
                hd: Css.gridAutoFlow("column"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$21 = Curry._1(Css.merge, {
      hd: r$17,
      tl: {
        hd: css$19,
        tl: {
          hd: "HeaderIconPanel",
          tl: /* [] */0
        }
      }
    });

var HeaderIconPanelStyle = {
  r: r$17,
  css: css$19,
  className: className$21
};

function ForestComponents$HeaderIconPanel(props) {
  return React.createElement("div", {
              className: className$21
            }, props.children);
}

var HeaderIconPanel = {
  make: ForestComponents$HeaderIconPanel
};

var blueStar = Star_blueSvg;

var r$18 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        49
      ],
      tl: {
        hd: [
          Css.height,
          50
        ],
        tl: {
          hd: [
            Css.top,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var css$20 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.background(Css.url(blueStar)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.display(Css.grid),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$22 = Curry._1(Css.merge, {
      hd: css$20,
      tl: {
        hd: r$18,
        tl: {
          hd: "BlueStar",
          tl: /* [] */0
        }
      }
    });

var BlueStarStyle = {
  r: r$18,
  css: css$20,
  className: className$22
};

function ForestComponents$BlueStar(props) {
  var xPos = 122 + Math.imul(props.index, 112) | 0;
  var extraR = Theme$LiteracyplanetClientFlags.resize({
        hd: [
          Css.left,
          xPos
        ],
        tl: /* [] */0
      });
  var mergedClass = Curry._1(Css.merge, {
        hd: className$22,
        tl: {
          hd: extraR,
          tl: /* [] */0
        }
      });
  return React.createElement("div", {
              className: mergedClass
            });
}

var BlueStar = {
  make: ForestComponents$BlueStar
};

var r$19 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        49
      ],
      tl: {
        hd: [
          Css.height,
          50
        ],
        tl: {
          hd: [
            Css.top,
            20
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.fontSize,
              20
            ],
            tl: {
              hd: [
                Theme$LiteracyplanetClientFlags.paddingLeft,
                2
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var css$21 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.display(Css.grid),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: {
                  hd: fredokaOneFont,
                  tl: {
                    hd: Css.color(Css.white),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var stroke$7 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, numberBrown);

var className$23 = Curry._1(Css.merge, {
      hd: css$21,
      tl: {
        hd: r$19,
        tl: {
          hd: stroke$7,
          tl: {
            hd: "QuestionStar",
            tl: /* [] */0
          }
        }
      }
    });

var QuestionStarStyle = {
  r: r$19,
  css: css$21,
  stroke: stroke$7,
  className: className$23
};

function ForestComponents$QuestionStar(props) {
  var xPos = 122 + Math.imul(props.index, 112) | 0;
  var extraR = Theme$LiteracyplanetClientFlags.resize({
        hd: [
          Css.left,
          xPos
        ],
        tl: /* [] */0
      });
  var mergedClass = Curry._1(Css.merge, {
        hd: className$23,
        tl: {
          hd: extraR,
          tl: /* [] */0
        }
      });
  return React.createElement("div", {
              className: mergedClass
            }, "?");
}

var QuestionStar = {
  make: ForestComponents$QuestionStar
};

var r$20 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        50
      ],
      tl: {
        hd: [
          Css.top,
          30
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            14
          ],
          tl: /* [] */0
        }
      }
    });

var css$22 = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.textAlign(Css.center),
        tl: {
          hd: fredokaOneFont,
          tl: {
            hd: Css.color(Css.white),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var stroke$8 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, lockedBrown);

var className$24 = Curry._1(Css.merge, {
      hd: css$22,
      tl: {
        hd: r$20,
        tl: {
          hd: stroke$8,
          tl: {
            hd: "StoryPoints",
            tl: /* [] */0
          }
        }
      }
    });

var StoryPointsStyle = {
  r: r$20,
  css: css$22,
  stroke: stroke$8,
  className: className$24
};

function ForestComponents$StoryPoints(props) {
  return React.createElement("div", {
              className: className$24
            }, props.children);
}

var StoryPoints = {
  make: ForestComponents$StoryPoints
};

var r$21 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.top,
        40
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          18
        ],
        tl: /* [] */0
      }
    });

var css$23 = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.textAlign(Css.center),
        tl: {
          hd: fredokaOneFont,
          tl: {
            hd: Css.color(Css.white),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var stroke$9 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, lockedBrown);

var className$25 = Curry._1(Css.merge, {
      hd: css$23,
      tl: {
        hd: r$21,
        tl: {
          hd: stroke$9,
          tl: {
            hd: "MaxLevelText",
            tl: /* [] */0
          }
        }
      }
    });

var MaxLevelTextStyle = {
  r: r$21,
  css: css$23,
  stroke: stroke$9,
  className: className$25
};

function ForestComponents$MaxLevelText(props) {
  return React.createElement("div", {
              className: className$25
            }, "You have reached the highest Level!");
}

var MaxLevelText = {
  make: ForestComponents$MaxLevelText
};

var r$22 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        0
      ],
      tl: {
        hd: [
          Css.left,
          0
        ],
        tl: {
          hd: [
            Css.top,
            120
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.fontSize,
              18
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$24 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.alignContent(Css.center),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: fredokaOneFont,
                tl: {
                  hd: Css.color(Css.white),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.position(Css.absolute),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var stroke$10 = Theme$LiteracyplanetClientFlags.textStroke(2, 2, 4, lockedBrown);

var className$26 = Curry._1(Css.merge, {
      hd: css$24,
      tl: {
        hd: r$22,
        tl: {
          hd: stroke$10,
          tl: {
            hd: "LoadingLevelText",
            tl: /* [] */0
          }
        }
      }
    });

var LoadingLevelTextStyle = {
  r: r$22,
  css: css$24,
  stroke: stroke$10,
  className: className$26
};

function ForestComponents$LoadingLevelText(props) {
  return React.createElement("div", {
              className: className$26
            }, "Loading...");
}

var LoadingLevelText = {
  make: ForestComponents$LoadingLevelText
};

var levelGuide = Level_guideSvg;

var r$23 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        506
      ],
      tl: {
        hd: [
          Css.height,
          87
        ],
        tl: {
          hd: [
            Css.top,
            40
          ],
          tl: /* [] */0
        }
      }
    });

var css$25 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.background(Css.url(levelGuide)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.position(Css.relative),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$27 = Curry._1(Css.merge, {
      hd: css$25,
      tl: {
        hd: r$23,
        tl: {
          hd: "LevelGuide",
          tl: /* [] */0
        }
      }
    });

var LevelGuideStyle = {
  r: r$23,
  css: css$25,
  className: className$27
};

function ForestComponents$LevelGuide(props) {
  return React.createElement("div", {
              className: className$27
            }, props.children);
}

var LevelGuide = {
  make: ForestComponents$LevelGuide
};

var r$24 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        506
      ],
      tl: {
        hd: [
          Css.height,
          87
        ],
        tl: {
          hd: [
            Css.top,
            40
          ],
          tl: /* [] */0
        }
      }
    });

var css$26 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.position(Css.relative),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$28 = Curry._1(Css.merge, {
      hd: css$26,
      tl: {
        hd: r$24,
        tl: {
          hd: "MaxLevelGuide",
          tl: /* [] */0
        }
      }
    });

var MaxLevelGuideStyle = {
  r: r$24,
  css: css$26,
  className: className$28
};

function ForestComponents$MaxLevelGuide(props) {
  return React.createElement("div", {
              className: className$28
            }, props.children);
}

var MaxLevelGuide = {
  make: ForestComponents$MaxLevelGuide
};

var yellowStar = Star_yellowSvg;

var r$25 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        81
      ],
      tl: {
        hd: [
          Css.height,
          87
        ],
        tl: /* [] */0
      }
    });

var css$27 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.background(Css.url(yellowStar)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.zIndex(1),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$29 = Curry._1(Css.merge, {
      hd: css$27,
      tl: {
        hd: r$25,
        tl: {
          hd: "YellowStar",
          tl: /* [] */0
        }
      }
    });

var YellowStarStyle = {
  r: r$25,
  css: css$27,
  className: className$29
};

function ForestComponents$YellowStar(props) {
  return React.createElement("div", {
              className: className$29
            });
}

var YellowStar = {
  make: ForestComponents$YellowStar
};

var flareSpin = Curry._1(Css.keyframes, {
      hd: [
        0,
        {
          hd: Css.transform(Css.rotate(Css.deg(0.0))),
          tl: /* [] */0
        }
      ],
      tl: {
        hd: [
          100,
          {
            hd: Css.transform(Css.rotate(Css.deg(360.0))),
            tl: /* [] */0
          }
        ],
        tl: /* [] */0
      }
    });

var flare = FlarePng;

var r$26 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        160
      ],
      tl: {
        hd: [
          Css.height,
          160
        ],
        tl: {
          hd: [
            Css.top,
            -36
          ],
          tl: {
            hd: [
              Css.left,
              -33
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$28 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.background(Css.url(flare)),
        tl: {
          hd: Css.backgroundSize("contain"),
          tl: {
            hd: Css.backgroundRepeat("noRepeat"),
            tl: {
              hd: Css.backgroundPosition(Css.center),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.position(Css.relative),
                  tl: {
                    hd: Css.animationName(flareSpin),
                    tl: {
                      hd: Css.animationDuration({
                            NAME: "ms",
                            VAL: 10000.0
                          }),
                      tl: {
                        hd: Css.animationIterationCount(Css.infinite),
                        tl: {
                          hd: Css.animationTimingFunction(Css.linear),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$30 = Curry._1(Css.merge, {
      hd: css$28,
      tl: {
        hd: r$26,
        tl: {
          hd: "Flare",
          tl: /* [] */0
        }
      }
    });

var FlareStyle = {
  r: r$26,
  css: css$28,
  className: className$30
};

function ForestComponents$Flare(props) {
  return React.createElement("div", {
              className: className$30
            });
}

var Flare = {
  make: ForestComponents$Flare
};

var r$27 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        1024
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          16
        ],
        tl: {
          hd: [
            Css.marginTop,
            40
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.paddingBottom,
              12
            ],
            tl: {
              hd: [
                Theme$LiteracyplanetClientFlags.borderRadius,
                34
              ],
              tl: {
                hd: [
                  Theme$LiteracyplanetClientFlags.borderWidth,
                  4
                ],
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var css$29 = Curry._1(Css.style, {
      hd: Css.marginLeft("auto"),
      tl: {
        hd: Css.marginRight("auto"),
        tl: {
          hd: Css.backgroundColor({
                NAME: "hex",
                VAL: "906D43"
              }),
          tl: {
            hd: Css.borderColor({
                  NAME: "hex",
                  VAL: "573C36"
                }),
            tl: {
              hd: Css.borderStyle("solid"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var after = Curry._1(Css.style, {
      hd: Css.after({
            hd: Css.backgroundColor({
                  NAME: "rgba",
                  VAL: [
                    0,
                    0,
                    0,
                    {
                      NAME: "num",
                      VAL: 0.5
                    }
                  ]
                }),
            tl: {
              hd: Css.contentRule({
                    NAME: "text",
                    VAL: ""
                  }),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.height(Css.pct(100.0)),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.top("zero"),
                      tl: {
                        hd: Css.left("zero"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }),
      tl: /* [] */0
    });

function className$31(darken) {
  return Curry._1(Css.merge, {
              hd: css$29,
              tl: {
                hd: r$27,
                tl: {
                  hd: darken ? after : "",
                  tl: {
                    hd: "Page",
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Page = {
  r: r$27,
  css: css$29,
  after: after,
  className: className$31
};

var outerPanel = Theme$LiteracyplanetClientFlags.resizeExtra({
      hd: Css.borderColor({
            NAME: "hex",
            VAL: "EBD63E"
          }),
      tl: {
        hd: Css.borderStyle("solid"),
        tl: {
          hd: Css.backgroundColor({
                NAME: "hex",
                VAL: "D7A15E"
              }),
          tl: /* [] */0
        }
      }
    }, "OuterPanel", {
      hd: [
        Theme$LiteracyplanetClientFlags.borderWidth,
        4
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderRadius,
          31
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.padding,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var innerPanel = Theme$LiteracyplanetClientFlags.resizeExtra({
      hd: Css.backgroundColor({
            NAME: "hex",
            VAL: "F6F8C4"
          }),
      tl: {
        hd: Css.borderColor({
              NAME: "hex",
              VAL: "906D43"
            }),
        tl: {
          hd: Css.borderStyle("solid"),
          tl: {
            hd: Css.position("relative"),
            tl: /* [] */0
          }
        }
      }
    }, "InnerPanel", {
      hd: [
        Theme$LiteracyplanetClientFlags.borderWidth,
        4
      ],
      tl: /* [] */0
    });

var contentPanel = Theme$LiteracyplanetClientFlags.resizeExtra({
      hd: Css.borderColor({
            NAME: "hex",
            VAL: "F6F8C4"
          }),
      tl: {
        hd: Css.borderStyle("solid"),
        tl: {
          hd: Css.backgroundColor({
                NAME: "hex",
                VAL: "E9ECC2"
              }),
          tl: /* [] */0
        }
      }
    }, "ContentPanel", {
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        12
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderWidth,
          4
        ],
        tl: /* [] */0
      }
    });

var contentHeaderR = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.paddingLeft,
        5
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.paddingRight,
          5
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomWidth,
            5
          ],
          tl: /* [] */0
        }
      }
    });

var contentHeaderS = Curry._1(Css.style, {
      hd: Css.borderColor({
            NAME: "hex",
            VAL: "9C6D4D"
          }),
      tl: {
        hd: Css.borderBottomColor({
              NAME: "hex",
              VAL: "714D45"
            }),
        tl: {
          hd: Css.borderStyle("solid"),
          tl: {
            hd: Css.borderWidth("zero"),
            tl: {
              hd: Css.backgroundColor({
                    NAME: "hex",
                    VAL: "9C6D4D"
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var contentHeader = Curry._1(Css.merge, {
      hd: contentHeaderS,
      tl: {
        hd: contentHeaderR,
        tl: {
          hd: "ContentHeader",
          tl: /* [] */0
        }
      }
    });

var titleCss = Curry._1(Css.style, {
      hd: Css.fontFamily({
            NAME: "custom",
            VAL: "FredokaOneRegular,Lato,Arial"
          }),
      tl: /* [] */0
    });

var bannerContainer = Theme$LiteracyplanetClientFlags.resizeExtra({
      hd: Css.width({
            NAME: "percent",
            VAL: 100.0
          }),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: /* [] */0
          }
        }
      }
    }, "BannerContainer", {
      hd: [
        Css.top,
        -20
      ],
      tl: /* [] */0
    });

var corner = Theme$LiteracyplanetClientFlags.resizeExtra({
      hd: Css.borderWidth("zero"),
      tl: {
        hd: Css.borderStyle("solid"),
        tl: {
          hd: Css.position("absolute"),
          tl: /* [] */0
        }
      }
    }, undefined, {
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var cornerInnerColors = Curry._1(Css.style, {
      hd: Css.borderColor({
            NAME: "hex",
            VAL: "906D43"
          }),
      tl: {
        hd: Css.background({
              NAME: "hex",
              VAL: "D7A15E"
            }),
        tl: /* [] */0
      }
    });

var cornerOuterColors = Curry._1(Css.style, {
      hd: Css.borderColor({
            NAME: "hex",
            VAL: "F6F8C4"
          }),
      tl: /* [] */0
    });

var cornerTLBorder = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.borderBottomWidth,
        4
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderRightWidth,
          4
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomRightRadius,
            24
          ],
          tl: /* [] */0
        }
      }
    });

var cornerTLInnerPos = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.top,
        -4
      ],
      tl: {
        hd: [
          Css.left,
          -4
        ],
        tl: /* [] */0
      }
    });

var cornerTLOuterPos = Curry._1(Css.style, {
      hd: Css.borderTopWidth("zero"),
      tl: {
        hd: Css.borderLeftWidth("zero"),
        tl: {
          hd: Css.top("zero"),
          tl: {
            hd: Css.left("zero"),
            tl: /* [] */0
          }
        }
      }
    });

var cornerTopLeftI = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerTLBorder,
        tl: {
          hd: cornerInnerColors,
          tl: {
            hd: cornerTLInnerPos,
            tl: {
              hd: "TLinner",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerTopLeftO = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerTLBorder,
        tl: {
          hd: cornerOuterColors,
          tl: {
            hd: cornerTLOuterPos,
            tl: {
              hd: "TLouter",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerTRBorder = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.borderBottomWidth,
        4
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderLeftWidth,
          4
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomLeftRadius,
            24
          ],
          tl: /* [] */0
        }
      }
    });

var cornerTRInnerPos = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.top,
        -4
      ],
      tl: {
        hd: [
          Css.right,
          -4
        ],
        tl: /* [] */0
      }
    });

var cornerTROuterPos = Curry._1(Css.style, {
      hd: Css.borderTopWidth("zero"),
      tl: {
        hd: Css.borderRightWidth("zero"),
        tl: {
          hd: Css.top("zero"),
          tl: {
            hd: Css.right("zero"),
            tl: /* [] */0
          }
        }
      }
    });

var cornerTopRightI = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerTRBorder,
        tl: {
          hd: cornerInnerColors,
          tl: {
            hd: cornerTRInnerPos,
            tl: {
              hd: "TRinner",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerTopRightO = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerTRBorder,
        tl: {
          hd: cornerOuterColors,
          tl: {
            hd: cornerTROuterPos,
            tl: {
              hd: "TRouter",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerBRBorder = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.borderTopWidth,
        4
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderLeftWidth,
          4
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderTopLeftRadius,
            24
          ],
          tl: /* [] */0
        }
      }
    });

var cornerBRInnerPos = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.bottom,
        -4
      ],
      tl: {
        hd: [
          Css.right,
          -4
        ],
        tl: /* [] */0
      }
    });

var cornerBROuterPos = Curry._1(Css.style, {
      hd: Css.borderBottomWidth("zero"),
      tl: {
        hd: Css.borderRightWidth("zero"),
        tl: {
          hd: Css.bottom("zero"),
          tl: {
            hd: Css.right("zero"),
            tl: /* [] */0
          }
        }
      }
    });

var cornerBottomRightI = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerBRBorder,
        tl: {
          hd: cornerInnerColors,
          tl: {
            hd: cornerBRInnerPos,
            tl: {
              hd: "BRinner",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerBottomRightO = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerBRBorder,
        tl: {
          hd: cornerOuterColors,
          tl: {
            hd: cornerBROuterPos,
            tl: {
              hd: "BRouter",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerBLBorder = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.borderTopWidth,
        4
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderRightWidth,
          4
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderTopRightRadius,
            24
          ],
          tl: /* [] */0
        }
      }
    });

var cornerBLInnerPos = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.bottom,
        -4
      ],
      tl: {
        hd: [
          Css.left,
          -4
        ],
        tl: /* [] */0
      }
    });

var cornerBLOuterPos = Curry._1(Css.style, {
      hd: Css.borderBottomWidth("zero"),
      tl: {
        hd: Css.borderLeftWidth("zero"),
        tl: {
          hd: Css.bottom("zero"),
          tl: {
            hd: Css.left("zero"),
            tl: /* [] */0
          }
        }
      }
    });

var cornerBottomLeftI = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerBLBorder,
        tl: {
          hd: cornerInnerColors,
          tl: {
            hd: cornerBLInnerPos,
            tl: {
              hd: "BLinner",
              tl: /* [] */0
            }
          }
        }
      }
    });

var cornerBottomLeftO = Curry._1(Css.merge, {
      hd: corner,
      tl: {
        hd: cornerBLBorder,
        tl: {
          hd: cornerOuterColors,
          tl: {
            hd: cornerBLOuterPos,
            tl: {
              hd: "BLouter",
              tl: /* [] */0
            }
          }
        }
      }
    });

function ForestComponents$ContentPage$HeaderAndContent(props) {
  var __darkenPage = props.darkenPage;
  var darkenPage = __darkenPage !== undefined ? __darkenPage : false;
  var darkerBorderStyle = {
    borderColor: "#714D45"
  };
  return React.createElement("div", {
              className: className$31(darkenPage)
            }, React.createElement("div", {
                  className: outerPanel
                }, React.createElement("div", {
                      className: innerPanel,
                      style: {
                        borderTopColor: "#714D45"
                      }
                    }, React.createElement("div", {
                          className: cornerTopLeftI,
                          style: darkerBorderStyle
                        }), React.createElement("div", {
                          className: cornerTopRightI,
                          style: darkerBorderStyle
                        }), React.createElement("div", {
                          className: cornerBottomLeftI
                        }), React.createElement("div", {
                          className: cornerBottomLeftO
                        }), React.createElement("div", {
                          className: cornerBottomRightI
                        }), React.createElement("div", {
                          className: cornerBottomRightO
                        }), Belt_Option.mapWithDefault(props.title, null, (function (t) {
                            return React.createElement("div", {
                                        className: bannerContainer
                                      }, React.createElement(ForestComponents$BannerText, {
                                            children: t
                                          }));
                          })), React.createElement("div", {
                          className: contentHeader
                        }, props.header), React.createElement("div", {
                          className: contentPanel
                        }, props.content))));
}

var HeaderAndContent = {
  make: ForestComponents$ContentPage$HeaderAndContent
};

function ForestComponents$ContentPage$Content(props) {
  return React.createElement("div", {
              className: className$31(false)
            }, React.createElement("div", {
                  className: outerPanel
                }, React.createElement("div", {
                      className: innerPanel
                    }, React.createElement("div", {
                          className: cornerTopLeftI
                        }), React.createElement("div", {
                          className: cornerTopLeftO
                        }), React.createElement("div", {
                          className: cornerTopRightI
                        }), React.createElement("div", {
                          className: cornerTopRightO
                        }), React.createElement("div", {
                          className: cornerBottomLeftI
                        }), React.createElement("div", {
                          className: cornerBottomLeftO
                        }), React.createElement("div", {
                          className: cornerBottomRightI
                        }), React.createElement("div", {
                          className: cornerBottomRightO
                        }), React.createElement("div", {
                          className: contentPanel
                        }, props.content))));
}

var Content = {
  make: ForestComponents$ContentPage$Content
};

var ContentPage = {
  Page: Page,
  outerPanel: outerPanel,
  innerPanelBorder: 4,
  innerPanelPadding: 5,
  innerPanel: innerPanel,
  contentPanel: contentPanel,
  contentHeaderR: contentHeaderR,
  contentHeaderS: contentHeaderS,
  contentHeader: contentHeader,
  titleCss: titleCss,
  bannerContainer: bannerContainer,
  cornerSize: 20,
  corner: corner,
  cornerInnerColors: cornerInnerColors,
  cornerOuterColors: cornerOuterColors,
  cornerTLBorder: cornerTLBorder,
  cornerTLInnerPos: cornerTLInnerPos,
  cornerTLOuterPos: cornerTLOuterPos,
  cornerTopLeftI: cornerTopLeftI,
  cornerTopLeftO: cornerTopLeftO,
  cornerTRBorder: cornerTRBorder,
  cornerTRInnerPos: cornerTRInnerPos,
  cornerTROuterPos: cornerTROuterPos,
  cornerTopRightI: cornerTopRightI,
  cornerTopRightO: cornerTopRightO,
  cornerBRBorder: cornerBRBorder,
  cornerBRInnerPos: cornerBRInnerPos,
  cornerBROuterPos: cornerBROuterPos,
  cornerBottomRightI: cornerBottomRightI,
  cornerBottomRightO: cornerBottomRightO,
  cornerBLBorder: cornerBLBorder,
  cornerBLInnerPos: cornerBLInnerPos,
  cornerBLOuterPos: cornerBLOuterPos,
  cornerBottomLeftI: cornerBottomLeftI,
  cornerBottomLeftO: cornerBottomLeftO,
  HeaderAndContent: HeaderAndContent,
  Content: Content
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var ratioExt = Theme$LiteracyplanetClientFlags.resizeExtra;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var textStroke = Theme$LiteracyplanetClientFlags.textStroke;

var bottomIconGap = 12;

var bottomIconWidth = 112;

var bottomIconHeight = 103;

export {
  brownColor ,
  whiteColor ,
  bottomBlueColor ,
  topBlueColor ,
  blueColor ,
  offWhiteColor ,
  lightOffWhiteColor ,
  partGreen ,
  lockedBrown ,
  numberBrown ,
  ratio ,
  ratioExt ,
  resizeGridCols ,
  resizeGridRows ,
  maximumWidth ,
  textStroke ,
  bottomIconGap ,
  bottomIconWidth ,
  bottomIconHeight ,
  fredokaOneFont ,
  truncateWord ,
  abbreviateNum ,
  formatGemCount ,
  Spacer ,
  BottomIconRow ,
  profilePanel ,
  ProfilePanelStyle ,
  ProfilePanel ,
  ProfileInnerPanelStyle ,
  ProfileInnerPanel ,
  ProfileNamePanelStyle ,
  ProfileNamePanel ,
  ProfileTextStyle ,
  ProfileText ,
  LocationTextStyle ,
  LocationText ,
  ItemTextStyle ,
  ItemText ,
  GemCountTextStyle ,
  GemCountText ,
  StartLevelTextStyle ,
  StartLevelText ,
  LevelPanelStyle ,
  LevelPanel ,
  bannerText ,
  BannerTextStyle ,
  BannerText ,
  LevelPanelBanner ,
  LevelPanelContainerStyle ,
  LevelPanelContainer ,
  EndLevelTextStyle ,
  EndLevelText ,
  ProfileGemsPanelStyle ,
  ProfileGemsPanel ,
  banner ,
  Banner ,
  ForestEmptyLayout ,
  closeButton ,
  closeButtonHover ,
  closeButtonActive ,
  CloseButtonStyle ,
  CloseButton ,
  PlainButton ,
  DownArrow ,
  DownButton ,
  scrolls ,
  HeaderSubPanelStyle ,
  HeaderSubPanel ,
  HeaderIconPanelStyle ,
  HeaderIconPanel ,
  blueStar ,
  BlueStarStyle ,
  BlueStar ,
  QuestionStarStyle ,
  QuestionStar ,
  StoryPointsStyle ,
  StoryPoints ,
  MaxLevelTextStyle ,
  MaxLevelText ,
  LoadingLevelTextStyle ,
  LoadingLevelText ,
  levelGuide ,
  LevelGuideStyle ,
  LevelGuide ,
  MaxLevelGuideStyle ,
  MaxLevelGuide ,
  yellowStar ,
  YellowStarStyle ,
  YellowStar ,
  flareSpin ,
  flare ,
  FlareStyle ,
  Flare ,
  ContentPage ,
}
/* brownColor Not a pure module */
