// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";

var origSchema = S$RescriptSchema.object(function (s) {
      return {
              id: s.f("id", S$RescriptSchema.string),
              name: s.f("name", S$RescriptSchema.string),
              questionIndex: 0,
              question: s.f("question", S$RescriptSchema.$$int),
              answers: s.f("answers", S$RescriptSchema.string),
              correct: false
            };
    });

var origPhonicsTypeSchema = S$RescriptSchema.object(function (s) {
      return {
              questionIndex: 0,
              question: s.f("question", S$RescriptSchema.$$int),
              answers: s.f("answers", S$RescriptSchema.string),
              correct: false
            };
    });

var schema = S$RescriptSchema.union([
      S$RescriptSchema.to(origSchema, (function (s) {
              return {
                      TAG: "Orig",
                      _0: s
                    };
            })),
      S$RescriptSchema.to(origPhonicsTypeSchema, (function (s) {
              return {
                      TAG: "OrigPhonics",
                      _0: s
                    };
            })),
      S$RescriptSchema.object(function (param) {
            return "NotImplemented";
          }),
      S$RescriptSchema.to(S$RescriptSchema.unknown, (function (param) {
              return "NotImplemented";
            }))
    ]);

function decode(__x) {
  return S$RescriptSchema.parseOrThrow(__x, schema);
}

var Schema = {
  origSchema: origSchema,
  origPhonicsTypeSchema: origPhonicsTypeSchema,
  schema: schema,
  decode: decode
};

function make(json, correct) {
  var x = S$RescriptSchema.parseOrThrow(json, schema);
  if (typeof x !== "object") {
    return "NotImplemented";
  }
  if (x.TAG === "Orig") {
    var x$1 = x._0;
    return {
            TAG: "Orig",
            _0: {
              id: x$1.id,
              name: x$1.name,
              questionIndex: x$1.question - 1 | 0,
              question: x$1.question,
              answers: x$1.answers,
              correct: correct
            }
          };
  }
  var x$2 = x._0;
  return {
          TAG: "OrigPhonics",
          _0: {
            questionIndex: x$2.question + 1 | 0,
            question: x$2.question,
            answers: x$2.answers,
            correct: correct
          }
        };
}

var Decode = {
  make: make
};

function mapQuestionIndexAndAnswers(response, f) {
  if (typeof response !== "object") {
    return ;
  }
  if (response.TAG === "Orig") {
    var match = response._0;
    return f(match.questionIndex, match.answers);
  }
  var match$1 = response._0;
  f(match$1.questionIndex, match$1.answers);
}

var Single = {
  Schema: Schema,
  Decode: Decode,
  mapQuestionIndexAndAnswers: mapQuestionIndexAndAnswers
};

var batmanTypeSchema = S$RescriptSchema.object(function (s) {
      return {
              correct: s.f("correct", S$RescriptSchema.bool),
              answer: s.f("answer", S$RescriptSchema.string),
              questionIndex: 0,
              question: 0
            };
    });

var schema$1 = S$RescriptSchema.union([
      S$RescriptSchema.object(function (s) {
            s.tag("tag", "arrayOfBatman");
            return {
                    TAG: "Batman",
                    _0: s.f("answers", S$RescriptSchema.array(batmanTypeSchema))
                  };
          }),
      S$RescriptSchema.to(S$RescriptSchema.unknown, (function (param) {
              return "NotImplemented";
            }))
    ]);

function decode$1(__x) {
  return S$RescriptSchema.parseOrThrow(__x, schema$1);
}

var Schema$1 = {
  batmanTypeSchema: batmanTypeSchema,
  schema: schema$1,
  decode: decode$1
};

function make$1(json) {
  var answers = S$RescriptSchema.parseOrThrow(json, schema$1);
  if (typeof answers !== "object") {
    return "NotImplemented";
  } else {
    return {
            TAG: "Batman",
            _0: Belt_Array.mapWithIndex(answers._0, (function (i, b) {
                    return {
                            correct: b.correct,
                            answer: b.answer,
                            questionIndex: i,
                            question: i - 1 | 0
                          };
                  }))
          };
  }
}

var Decode$1 = {
  make: make$1
};

var Batch = {
  Schema: Schema$1,
  Decode: Decode$1
};

export {
  Single ,
  Batch ,
}
/* origSchema Not a pure module */
