// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";

var $$Image = {};

function checkFeatureSupport(feature, callback) {
  var img = new Image();
  img.onload = (function (param) {
      var result = img.width > 0.0 && img.height > 0.0;
      callback(feature, result);
    });
  img.onerror = (function (param) {
      callback(feature, false);
    });
  var imageData = feature === "Lossless" ? "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==" : (
      feature === "Lossy" ? "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA" : (
          feature === "Alpha" ? "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==" : "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
        )
    );
  img.src = "data:image/webp;base64," + imageData;
}

function useWithError(features) {
  var match = Error$LiteracyplanetClientErrors.useErrorModal();
  var onSetError = match.onSetError;
  React.useEffect((function (param) {
          Belt_Array.map(features, (function (feature) {
                  checkFeatureSupport(feature, (function (_feature, supported) {
                          if (supported) {
                            return ;
                          } else {
                            return Curry._1(onSetError, "WebpNotSupported");
                          }
                        }));
                }));
        }), [features]);
}

function WebpDetect$CheckSupport(props) {
  useWithError(props.features);
  return null;
}

var CheckSupport = {
  make: WebpDetect$CheckSupport
};

export {
  $$Image ,
  checkFeatureSupport ,
  useWithError ,
  CheckSupport ,
}
/* react Not a pure module */
