// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as V2MultiChoiceContainer$LiteracyplanetTupperware from "./V2MultiChoiceContainer.res.mjs";

function SkilfulMultiChoiceContainer$MultiChoiceMarkStylingContainer(props) {
  var css = Curry._1(Skillet$LiteracyplanetTupperware.SkilfulMultiChoiceContainer.MultiChoiceMarkStylingContainer.className, props.columns);
  return React.createElement("div", {
              className: css
            }, props.children);
}

var MultiChoiceMarkStylingContainer = {
  make: SkilfulMultiChoiceContainer$MultiChoiceMarkStylingContainer
};

function SkilfulMultiChoiceContainer(props) {
  var __showCorrectAnswerBehaviour = props.showCorrectAnswerBehaviour;
  var __fuzzyMark = props.fuzzyMark;
  var fuzzyMark = __fuzzyMark !== undefined ? __fuzzyMark : false;
  var showCorrectAnswerBehaviour = __showCorrectAnswerBehaviour !== undefined ? __showCorrectAnswerBehaviour : false;
  return React.createElement(V2MultiChoiceContainer$LiteracyplanetTupperware.make, {
              correctAnswer: props.correctAnswer,
              fuzzyMark: fuzzyMark,
              questionIndex: props.questionIndex,
              showCorrectAnswerBehaviour: showCorrectAnswerBehaviour,
              children: React.createElement(SkilfulMultiChoiceContainer$MultiChoiceMarkStylingContainer, {
                    columns: props.columns,
                    children: props.children
                  })
            });
}

var make = SkilfulMultiChoiceContainer;

var $$default = SkilfulMultiChoiceContainer;

export {
  MultiChoiceMarkStylingContainer ,
  make ,
  $$default as default,
}
/* react Not a pure module */
