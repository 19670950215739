// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CobraQuestionResponse$LiteracyplanetClientLibsActivity from "./CobraQuestionResponse.res.mjs";

var CurrentStudentEmpty = {};

var CurrentStudent = {};

var ResultsApi = {};

var ResultsLauncherProps = {};

var $$Audio = {};

var Asset = {};

var Vocal = {};

var WordGameContent = {};

var WordGameContent_WordGameData = {};

var WordGameContent_SoundTrainData = {};

var WordGameContent_WhackAMunkData = {};

var WordGameContent_MonsterChefData = {};

var WordGameContent_InternalStaticData = {};

var WordGameContent_UnsafeData = {};

var ActivityLauncherProps = {
  $$Audio: $$Audio,
  Asset: Asset,
  Vocal: Vocal,
  WordGameContent: WordGameContent,
  WordGameContent_WordGameData: WordGameContent_WordGameData,
  WordGameContent_SoundTrainData: WordGameContent_SoundTrainData,
  WordGameContent_WhackAMunkData: WordGameContent_WhackAMunkData,
  WordGameContent_MonsterChefData: WordGameContent_MonsterChefData,
  WordGameContent_InternalStaticData: WordGameContent_InternalStaticData,
  WordGameContent_UnsafeData: WordGameContent_UnsafeData
};

var ActivityLauncherApi = {};

function api(onActivityCompleted, onActivityPaused, onActivityReachedEnd, onActivityReady, onActivityResumed, onActivityStarted, onExit, onFailed, onQuestionFailed, onQuestionPassed, onQuestionStarted, onBatchQuestionsAnswered, onPreloaderReady) {
  return {
          onActivityCompleted: onActivityCompleted,
          onActivityPaused: onActivityPaused,
          onActivityReachedEnd: onActivityReachedEnd,
          onActivityReady: onActivityReady,
          onActivityResumed: onActivityResumed,
          onActivityStarted: onActivityStarted,
          onExit: onExit,
          onFailed: onFailed,
          onQuestionFailed: (function (input) {
              Curry._1(onQuestionFailed, CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.Decode.make(input, false));
            }),
          onQuestionPassed: (function (input) {
              Curry._1(onQuestionPassed, CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.Decode.make(input, true));
            }),
          onQuestionStarted: onQuestionStarted,
          onBatchQuestionsAnswered: (function (input) {
              Curry._1(onBatchQuestionsAnswered, CobraQuestionResponse$LiteracyplanetClientLibsActivity.Batch.Decode.make(input));
            }),
          onPreloaderReady: onPreloaderReady
        };
}

var CobraKindDoesntMatchFlipperTypes = /* @__PURE__ */Caml_exceptions.create("FlipperTypes-LiteracyplanetClientLibsActivity.Cobra.CobraKindDoesntMatchFlipperTypes");

function validate(param, props) {
  switch (param.cobraActivityProps) {
    case "WordGameContent_WordGameData" :
        if (props.TAG === "WordListData") {
          return {
                  TAG: "Ok",
                  _0: props
                };
        } else {
          return {
                  TAG: "Error",
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case "WordGameContent_SoundTrainData" :
        if (props.TAG === "SoundTrainData") {
          return {
                  TAG: "Ok",
                  _0: props
                };
        } else {
          return {
                  TAG: "Error",
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case "WordGameContent_WhackAMunkData" :
        if (props.TAG === "WhackAMunkData") {
          return {
                  TAG: "Ok",
                  _0: props
                };
        } else {
          return {
                  TAG: "Error",
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case "WordGameContent_MonsterChefData" :
        if (props.TAG === "MonsterChefData") {
          return {
                  TAG: "Ok",
                  _0: props
                };
        } else {
          return {
                  TAG: "Error",
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case "WordGameContent_InternalStaticData" :
        if (props.TAG === "InernalStaticData") {
          return {
                  TAG: "Ok",
                  _0: props
                };
        } else {
          return {
                  TAG: "Error",
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case "WordGameContent_UnsafeData" :
        if (props.TAG === "UnsafeData") {
          return {
                  TAG: "Ok",
                  _0: props
                };
        } else {
          return {
                  TAG: "Error",
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    
  }
}

var Cobra = {
  CurrentStudentEmpty: CurrentStudentEmpty,
  CurrentStudent: CurrentStudent,
  ResultsApi: ResultsApi,
  ResultsLauncherProps: ResultsLauncherProps,
  ActivityLauncherProps: ActivityLauncherProps,
  ActivityLauncherApi: ActivityLauncherApi,
  api: api,
  CobraKindDoesntMatchFlipperTypes: CobraKindDoesntMatchFlipperTypes,
  validate: validate
};

export {
  Cobra ,
}
/* CobraQuestionResponse-LiteracyplanetClientLibsActivity Not a pure module */
