// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GraphqlTag from "graphql-tag";
import * as Graphql_ppx_runtime from "@reasonml-community/graphql-ppx/rescript-runtime/graphql_ppx_runtime.res.mjs";
import * as AvatarFragments$LiteracyplanetClientFlags from "./AvatarFragments.res.mjs";
import * as GameContentFragments$LiteracyplanetClientFlags from "./GameContentFragments.res.mjs";
import * as StudentMissionFragments$LiteracyplanetClientFlags from "./StudentMissionFragments.res.mjs";

var Raw = {};

var query = GraphqlTag.gql(["fragment StudentDetailsFragment on Student   {\n__typename  \nuserId  \n}\n"]);

function parse(value) {
  var value$1 = value.userId;
  return {
          __typename: value.__typename,
          userId: !(value$1 == null) ? value$1 : undefined
        };
}

function serialize(value) {
  var value$1 = value.userId;
  var userId = value$1 !== undefined ? value$1 : null;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          userId: userId
        };
}

function verifyArgsAndParse(_StudentDetailsFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var StudentDetailsFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = GraphqlTag.gql([
      "fragment IncompleteVisibleMissionsFragment on Student   {\n__typename  \nincompleteVisibleMissionsNonOptional  {\n...StudentMissionFragment   \n}\n\n}\n",
      ""
    ], StudentMissionFragments$LiteracyplanetClientFlags.StudentMissionFragment.query);

function parse$1(value) {
  var value$1 = value.incompleteVisibleMissionsNonOptional;
  return {
          __typename: value.__typename,
          incompleteVisibleMissionsNonOptional: value$1.map(function (value) {
                return StudentMissionFragments$LiteracyplanetClientFlags.StudentMissionFragment.verifyArgsAndParse("StudentMissionFragment", value);
              })
        };
}

function serialize$1(value) {
  var value$1 = value.incompleteVisibleMissionsNonOptional;
  var incompleteVisibleMissionsNonOptional = value$1.map(function (value) {
        return StudentMissionFragments$LiteracyplanetClientFlags.StudentMissionFragment.serialize(value);
      });
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          incompleteVisibleMissionsNonOptional: incompleteVisibleMissionsNonOptional
        };
}

function verifyArgsAndParse$1(_IncompleteVisibleMissionsFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var IncompleteVisibleMissionsFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

function incompleteVisibleMissions(res) {
  return res.incompleteVisibleMissionsNonOptional;
}

var Raw$2 = {};

var query$2 = GraphqlTag.gql([
      "fragment ActivityContentFragment on Student   {\n__typename  \nactivityContent(studentActivityId: $studentActivityId, locale: $locale, accent: $accent)  {\n__typename\n...on WordGameData   {\n...WordGameDataFragment   \n}\n\n...on ComprehensionPlaceholderData   {\n__typename  \nid  \n}\n\n...on VideoData   {\n...VideoDataFragment   \n}\n\n...on TupperwareData   {\n...TupperwareDataFragment   \n}\n\n...on WhackAMunkData   {\n...WhackAMunkDataFragment   \n}\n\n...on SoundTrainData   {\n...SoundTrainDataFragment   \n}\n\n...on MonsterChefData   {\n...MonsterChefDataFragment   \n}\n\n...on InternalStaticData   {\n...InternalStaticDataFragment   \n}\n\n...on UnsafeData   {\n...UnsafeDataFragment   \n}\n\n...on UnkownGameType   {\n__typename  \nerror  \n}\n\n}\n\n}\n",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ], GameContentFragments$LiteracyplanetClientFlags.UnsafeDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.TupperwareDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.WordGameDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.WhackAMunkDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.VideoDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.MonsterChefDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.InternalStaticDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.SoundTrainDataFragment.query);

function parse$2(value) {
  var value$1 = value.activityContent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1;
    switch (typename) {
      case "ComprehensionPlaceholderData" :
          tmp$1 = {
            NAME: "ComprehensionPlaceholderData",
            VAL: {
              __typename: value$1.__typename,
              id: value$1.id
            }
          };
          break;
      case "InternalStaticData" :
          tmp$1 = {
            NAME: "InternalStaticData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.InternalStaticDataFragment.verifyArgsAndParse("InternalStaticDataFragment", value$1)
          };
          break;
      case "MonsterChefData" :
          tmp$1 = {
            NAME: "MonsterChefData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.MonsterChefDataFragment.verifyArgsAndParse("MonsterChefDataFragment", value$1)
          };
          break;
      case "SoundTrainData" :
          tmp$1 = {
            NAME: "SoundTrainData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.SoundTrainDataFragment.verifyArgsAndParse("SoundTrainDataFragment", value$1)
          };
          break;
      case "TupperwareData" :
          tmp$1 = {
            NAME: "TupperwareData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.TupperwareDataFragment.verifyArgsAndParse("TupperwareDataFragment", value$1)
          };
          break;
      case "UnkownGameType" :
          var value$2 = value$1.error;
          tmp$1 = {
            NAME: "UnkownGameType",
            VAL: {
              __typename: value$1.__typename,
              error: !(value$2 == null) ? value$2 : undefined
            }
          };
          break;
      case "UnsafeData" :
          tmp$1 = {
            NAME: "UnsafeData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.UnsafeDataFragment.verifyArgsAndParse("UnsafeDataFragment", value$1)
          };
          break;
      case "VideoData" :
          tmp$1 = {
            NAME: "VideoData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.VideoDataFragment.verifyArgsAndParse("VideoDataFragment", value$1)
          };
          break;
      case "WhackAMunkData" :
          tmp$1 = {
            NAME: "WhackAMunkData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.WhackAMunkDataFragment.verifyArgsAndParse("WhackAMunkDataFragment", value$1)
          };
          break;
      case "WordGameData" :
          tmp$1 = {
            NAME: "WordGameData",
            VAL: GameContentFragments$LiteracyplanetClientFlags.WordGameDataFragment.verifyArgsAndParse("WordGameDataFragment", value$1)
          };
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$1
        };
    }
    tmp = tmp$1;
  }
  return {
          __typename: value.__typename,
          activityContent: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.activityContent;
  var activityContent;
  if (value$1 !== undefined) {
    var variant = value$1.NAME;
    if (variant === "ComprehensionPlaceholderData") {
      var value$2 = value$1.VAL.id;
      activityContent = {
        __typename: "ComprehensionPlaceholderData",
        id: value$2
      };
    } else if (variant === "SoundTrainData") {
      activityContent = Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.SoundTrainDataFragment.serialize(value$1.VAL), "SoundTrainData");
    } else if (variant === "TupperwareData") {
      activityContent = Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.TupperwareDataFragment.serialize(value$1.VAL), "TupperwareData");
    } else if (variant === "FutureAddedValue") {
      activityContent = value$1.VAL;
    } else if (variant === "VideoData") {
      activityContent = Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.VideoDataFragment.serialize(value$1.VAL), "VideoData");
    } else if (variant === "UnsafeData") {
      activityContent = Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.UnsafeDataFragment.serialize(value$1.VAL), "UnsafeData");
    } else if (variant === "UnkownGameType") {
      var value$3 = value$1.VAL.error;
      var error = value$3 !== undefined ? value$3 : null;
      activityContent = {
        __typename: "UnkownGameType",
        error: error
      };
    } else {
      activityContent = variant === "MonsterChefData" ? Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.MonsterChefDataFragment.serialize(value$1.VAL), "MonsterChefData") : (
          variant === "InternalStaticData" ? Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.InternalStaticDataFragment.serialize(value$1.VAL), "InternalStaticData") : (
              variant === "WhackAMunkData" ? Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.WhackAMunkDataFragment.serialize(value$1.VAL), "WhackAMunkData") : Graphql_ppx_runtime.assign_typename(GameContentFragments$LiteracyplanetClientFlags.WordGameDataFragment.serialize(value$1.VAL), "WordGameData")
            )
        );
    }
  } else {
    activityContent = null;
  }
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          activityContent: activityContent
        };
}

function verifyArgsAndParse$2(_accent, _locale, _studentActivityId, _ActivityContentFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var ActivityContentFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

function activityContent(res) {
  return res.activityContent;
}

var Raw$3 = {};

var query$3 = GraphqlTag.gql([
      "fragment AvatarFragment on Student   {\n__typename  \navatar: avatarB  {\n...PlayerEquippedFragment   \n}\n\n}\n",
      ""
    ], AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.query);

function parse$3(value) {
  var value$1 = value.avatar;
  return {
          __typename: value.__typename,
          avatar: !(value$1 == null) ? AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.verifyArgsAndParse("PlayerEquippedFragment", value$1) : undefined
        };
}

function serialize$3(value) {
  var value$1 = value.avatar;
  var avatar = value$1 !== undefined ? AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.serialize(value$1) : null;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          avatar: avatar
        };
}

function verifyArgsAndParse$3(_AvatarFragment, value) {
  return parse$3(value);
}

function verifyName$3(param) {
  
}

var AvatarFragment = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  verifyArgsAndParse: verifyArgsAndParse$3,
  verifyName: verifyName$3
};

function avatar(res) {
  return res.avatar;
}

var StudentMissionFragment;

var WordGameDataFragment;

var MonsterChefDataFragment;

var WhackAMunkDataFragment;

var SoundTrainDataFragment;

var TupperwareDataFragment;

var VideoDataFragment;

var InternalStaticDataFragment;

var UnsafeDataFragment;

var PlayerEquippedFragment;

export {
  StudentDetailsFragment ,
  StudentMissionFragment ,
  IncompleteVisibleMissionsFragment ,
  incompleteVisibleMissions ,
  WordGameDataFragment ,
  MonsterChefDataFragment ,
  WhackAMunkDataFragment ,
  SoundTrainDataFragment ,
  TupperwareDataFragment ,
  VideoDataFragment ,
  InternalStaticDataFragment ,
  UnsafeDataFragment ,
  ActivityContentFragment ,
  activityContent ,
  PlayerEquippedFragment ,
  AvatarFragment ,
  avatar ,
}
/* query Not a pure module */
