// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FlagsTypes$LiteracyplanetClientFlags from "./FlagsTypes.res.mjs";

function inObj(obj, key) {
  return Belt_Option.getWithDefault(Js_dict.get(obj, key), false);
}

function inArray(items, item) {
  return Belt_Array.some(items, (function (i) {
                return i === item;
              }));
}

function hasFeature(features, feature) {
  return inObj(features, FlagsTypes$LiteracyplanetClientFlags.unleashFeatureToString(feature));
}

function hasFlagInState(state) {
  var partial_arg = state.features;
  var hasF = function (param) {
    return inObj(partial_arg, FlagsTypes$LiteracyplanetClientFlags.unleashFeatureToString(param));
  };
  var partial_arg$1 = state.userRoles;
  var hasU = function (param) {
    return inArray(partial_arg$1, param);
  };
  var partial_arg$2 = state.systemFlags;
  var partial_arg$3 = state.classicFlags;
  var hasC = function (param) {
    return inArray(partial_arg$3, param);
  };
  var hasSystemOveride = function (systemFlag, $$default) {
    return Belt_Option.getWithDefault(Js_dict.get(state.systemFlags, systemFlag), $$default);
  };
  var hasAx = Curry._1(hasU, "admin") && (Curry._1(hasC, "school-product") || Curry._1(hasC, "teacher-product") && Curry._1(hasC, "plg"));
  var getFlag = function (flag) {
    if (typeof flag !== "object") {
      if (flag === "DeveloperTestingIpad") {
        return hasSystemOveride("DeveloperTestingIpad", Curry._1(hasF, flag));
      } else if (flag === "StudentHasArcade") {
        return hasSystemOveride("StudentHasArcade", Curry._1(hasF, flag));
      } else if (flag === "TeacherProductStarterAccess") {
        return hasSystemOveride("TeacherProductStarterAccess", Curry._1(hasC, "starter-access") && Curry._1(hasC, "teacher-product"));
      } else if (flag === "PLGProduct") {
        return hasSystemOveride("PLGProduct", Curry._1(hasC, "plg"));
      } else if (flag === "TeacherHasLiteracyCheck") {
        return hasSystemOveride("TeacherHasLiteracyCheck", Curry._1(hasF, flag));
      } else if (flag === "StudentIsStudentMode") {
        return hasSystemOveride("StudentIsStudentMode", Curry._1(hasC, "is-student-mode"));
      } else if (flag === "IsPlg") {
        return hasSystemOveride("IsPlg", Curry._1(hasC, "plg"));
      } else if (flag === "AuthFullAccess") {
        return hasSystemOveride("AuthFullAccess", Curry._1(hasC, "full-access"));
      } else if (flag === "CanAccessAx") {
        return hasSystemOveride("CanAccessAx", hasAx);
      } else if (flag === "StudentHasSecondary") {
        return hasSystemOveride("StudentHasSecondary", Curry._1(hasF, flag));
      } else if (flag === "CanAccessWordMania") {
        if (hasSystemOveride("WordManiaAvailable", Curry._1(hasC, "wm"))) {
          return !hasSystemOveride("IsParent", Curry._1(hasU, "parent"));
        } else {
          return false;
        }
      } else if (flag === "AuthCanAccessMorphBeta") {
        return hasSystemOveride("AuthCanAccessMorphBeta", Curry._1(hasF, flag));
      } else if (flag === "AuthCanAccessMorphShowcase") {
        return hasSystemOveride("AuthCanAccessMorphShowcase", Curry._1(hasF, flag));
      } else if (flag === "SchoolProductTrial") {
        return hasSystemOveride("SchoolProductTrial", Curry._1(hasC, "in-trial") && Curry._1(hasC, "full-access") && Curry._1(hasC, "school-product"));
      } else if (flag === "StudentHasTileCurrency") {
        return hasSystemOveride("StudentHasTileCurrency", Curry._1(hasF, flag));
      } else if (flag === "AuthProAccess") {
        return hasSystemOveride("AuthProAccess", Curry._1(hasC, "pro-access"));
      } else if (flag === "DeveloperTestingLocales") {
        return hasSystemOveride("DeveloperTestingLocales", Curry._1(hasF, flag));
      } else if (flag === "TrialAvailable") {
        return hasSystemOveride("TrialAvailable", Curry._1(hasC, "trial-available"));
      } else if (flag === "StudentCanAccessWM") {
        return hasSystemOveride("StudentCanAccessWM", Curry._1(hasC, "wm"));
      } else if (flag === "TeacherProductProAccess") {
        return hasSystemOveride("TeacherProductProAccess", Curry._1(hasC, "pro-access") && Curry._1(hasC, "teacher-product"));
      } else if (flag === "InTrial") {
        return hasSystemOveride("InTrial", Curry._1(hasC, "in-trial"));
      } else if (flag === "IsAdmin") {
        return Curry._1(hasU, "admin");
      } else if (flag === "LibraryProduct") {
        return hasSystemOveride("LibraryProduct", Curry._1(hasC, "library-product"));
      } else if (flag === "PlaySoundOnWordClick") {
        return hasSystemOveride("PlaySoundOnWordClick", Curry._1(hasU, "developer"));
      } else if (flag === "StudentHasPrimaryUx") {
        return true;
      } else if (flag === "AccountIsProvisioned") {
        return hasSystemOveride("AccountIsProvisioned", Curry._1(hasC, "account-is-provisioned"));
      } else if (flag === "TeacherProductProTrial") {
        return hasSystemOveride("TeacherProductProTrial", (Curry._1(hasC, "pro-access-trial") || Curry._1(hasC, "in-trial") && Curry._1(hasC, "pro-access")) && Curry._1(hasC, "teacher-product"));
      } else if (flag === "DeveloperTestingLayouts") {
        return hasSystemOveride("DeveloperTestingLayouts", Curry._1(hasF, flag));
      } else if (flag === "StudentHasJourneyIntro") {
        return hasSystemOveride("StudentHasJourneyIntro", Curry._1(hasF, flag));
      } else if (flag === "CanAccessCustomWordLists") {
        return hasSystemOveride("CanViewCustomWordLists", Curry._1(hasF, flag));
      } else if (flag === "AuthCanAccessMorphAlpha") {
        return hasSystemOveride("AuthCanAccessMorphAlpha", Curry._1(hasF, flag));
      } else if (flag === "StudentHasStoryverse") {
        return hasSystemOveride("StudentHasStoryverse", Curry._1(hasF, flag));
      } else if (flag === "SchoolProduct") {
        return hasSystemOveride("SchoolProduct", Curry._1(hasC, "school-product"));
      } else if (flag === "StudentCanUseLibrary") {
        return hasSystemOveride("StudentCanUseLibrary", Curry._1(hasF, flag));
      } else if (flag === "IsParent") {
        return hasSystemOveride("IsParent", Curry._1(hasU, "parent"));
      } else if (flag === "AuthStarterAccess") {
        return hasSystemOveride("AuthStarterAccess", Curry._1(hasC, "starter-access"));
      } else if (flag === "CanAccessAxReports") {
        return hasSystemOveride("CanAccessAxReports", hasAx && Curry._1(hasC, "full-access"));
      } else if (flag === "TeacherCanFilterListsOnAuthor") {
        return hasSystemOveride("TeacherCanFilterListsOnAuthor", Curry._1(hasF, flag));
      } else if (flag === "TeacherCanManageTeachingGroups") {
        return hasSystemOveride("TeacherCanManageTeachingGroups", Curry._1(hasC, "teaching-groups"));
      } else if (flag === "TeacherProduct") {
        return hasSystemOveride("TeacherProduct", Curry._1(hasC, "teacher-product"));
      } else if (flag === "StudentHasLiteracyCheck") {
        return hasSystemOveride("StudentHasLiteracyCheck", Curry._1(hasF, flag));
      } else if (flag === "StudentHasLocking") {
        return hasSystemOveride("StudentHasLocking", Curry._1(hasF, flag));
      } else if (flag === "IsDeveloper") {
        return Curry._1(hasU, "developer");
      } else if (flag === "HomeProduct") {
        return hasSystemOveride("HomeProduct", Curry._1(hasC, "home-product"));
      } else {
        return hasSystemOveride("DeveloperArcadeGames", Curry._1(hasF, flag));
      }
    }
    var variant = flag.NAME;
    if (variant !== "Classic") {
      if (variant === "Unleash") {
        return hasSystemOveride(flag.VAL, Curry._1(hasF, flag));
      } else {
        var param = flag.VAL;
        return inObj(partial_arg$2, param);
      }
    }
    var f = flag.VAL;
    return hasSystemOveride(f, Curry._1(hasC, f));
  };
  var unsafeGetFlag = function (flag) {
    return getFlag(FlagsTypes$LiteracyplanetClientFlags.stringToFlag(flag));
  };
  return {
          getFlag: getFlag,
          unsafeGetFlag: unsafeGetFlag
        };
}

export {
  inObj ,
  inArray ,
  hasFeature ,
  hasFlagInState ,
}
/* No side effect */
