// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

var spinnerRotation = Curry._1(Css.keyframes, {
      hd: [
        0,
        {
          hd: Css.transform(Css.rotate(Css.deg(0.0))),
          tl: /* [] */0
        }
      ],
      tl: {
        hd: [
          100,
          {
            hd: Css.transform(Css.rotate(Css.deg(360.0))),
            tl: /* [] */0
          }
        ],
        tl: /* [] */0
      }
    });

var spinnerStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: /* [] */0
        }
      }
    });

var spinnerClassName = Curry._1(Css.merge, {
      hd: spinnerStyle,
      tl: {
        hd: "Spinner",
        tl: /* [] */0
      }
    });

var smallGlobeStyle = Curry._1(Css.style, {
      hd: Css.opacity(1.0),
      tl: {
        hd: Css.animationName(spinnerRotation),
        tl: {
          hd: Css.animationDuration({
                NAME: "ms",
                VAL: 2000.00
              }),
          tl: {
            hd: Css.width({
                  NAME: "px",
                  VAL: 64
                }),
            tl: {
              hd: Css.height({
                    NAME: "px",
                    VAL: 63
                  }),
              tl: {
                hd: Css.animationIterationCount(Css.infinite),
                tl: {
                  hd: Css.animationTimingFunction(Css.linear),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var smallGlobeClassName = Curry._1(Css.merge, {
      hd: smallGlobeStyle,
      tl: {
        hd: "Spinner-smallGlobe",
        tl: /* [] */0
      }
    });

function Spinner(props) {
  var match = React.useState(function (param) {
        return false;
      });
  var setShow = match[1];
  var smallGlobe = document.getElementById("SiteSpinner").firstElementChild;
  var globeSrc = smallGlobe.src;
  React.useEffect((function (param) {
          var timerId = setTimeout((function (param) {
                  Curry._1(setShow, (function (param) {
                          return true;
                        }));
                }), 100);
          return (function (param) {
                    clearTimeout(timerId);
                  });
        }), []);
  return React.createElement("div", {
              className: spinnerClassName
            }, match[0] ? React.createElement("img", {
                    className: smallGlobeClassName,
                    src: globeSrc
                  }) : null);
}

var make = Spinner;

var $$default = Spinner;

export {
  spinnerRotation ,
  spinnerStyle ,
  spinnerClassName ,
  smallGlobeStyle ,
  smallGlobeClassName ,
  make ,
  $$default as default,
}
/* spinnerRotation Not a pure module */
