// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as BehaviourMultiChoiceGroupedOptionContainer$LiteracyplanetTupperware from "./BehaviourMultiChoiceGroupedOptionContainer.res.mjs";

function SkilfulMultiChoiceMultiResponseContainer(props) {
  return React.createElement(BehaviourMultiChoiceGroupedOptionContainer$LiteracyplanetTupperware.make, {
              questionIndex: props.questionIndex,
              responseOptionId: props.responseOptionId,
              children: props.children
            });
}

var make = SkilfulMultiChoiceMultiResponseContainer;

var $$default = SkilfulMultiChoiceMultiResponseContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
