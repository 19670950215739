// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function MakeGetter(G) {
  var getter = function (param) {
    
  };
  var context = React.createContext(getter);
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = context.Provider;
  return {
          getter: getter,
          context: context,
          makeProps: makeProps,
          make: make
        };
}

function MakeSetter(S) {
  var setter = function (param, param$1) {
    
  };
  var context = React.createContext(setter);
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = context.Provider;
  return {
          setter: setter,
          context: context,
          makeProps: makeProps,
          make: make
        };
}

var Str = {};

function setter(param, param$1) {
  
}

var context = React.createContext(setter);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var StrSetter = {
  setter: setter,
  context: context,
  makeProps: makeProps,
  make: make
};

function getter(param) {
  
}

var context$1 = React.createContext(getter);

function makeProps$1(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make$1 = context$1.Provider;

var StrGetter = {
  getter: getter,
  context: context$1,
  makeProps: makeProps$1,
  make: make$1
};

function InMemoryStore(props) {
  var match = React.useState(function (param) {
        
      });
  var setState = match[1];
  var state = match[0];
  var setter = React.useCallback((function (key, newVal) {
          var $$default = [[
              key,
              newVal
            ]];
          var newState = Belt_Option.mapWithDefault(state, $$default, (function (s) {
                  var has = Belt_Array.some(s, (function (param) {
                          return param[0] === key;
                        }));
                  if (has) {
                    return Belt_Array.reduce(s, [], (function (acc, param) {
                                  var k = param[0];
                                  if (k === key) {
                                    return Belt_Array.concat(acc, [[
                                                  k,
                                                  newVal
                                                ]]);
                                  } else {
                                    return acc;
                                  }
                                }));
                  } else {
                    return Belt_Array.concat(s, $$default);
                  }
                }));
          Curry._1(setState, (function (param) {
                  return newState;
                }));
        }), []);
  var getter = React.useCallback((function (key) {
          return Belt_Option.map(Belt_Option.flatMap(state, (function (s) {
                            return Belt_Array.getBy(s, (function (param) {
                                          return param[0] === key;
                                        }));
                          })), (function (param) {
                        return param[1];
                      }));
        }), [state]);
  return React.createElement(make, {
              value: setter,
              children: React.createElement(make$1, {
                    value: getter,
                    children: props.children
                  })
            });
}

function useGetter(key) {
  var get = React.useContext(context$1);
  return Curry._1(get, key);
}

function useSetter(param) {
  return React.useContext(context);
}

var make$2 = InMemoryStore;

export {
  MakeGetter ,
  MakeSetter ,
  Str ,
  StrSetter ,
  StrGetter ,
  make$2 as make,
  useGetter ,
  useSetter ,
}
/* context Not a pure module */
