// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as GraphqlTag from "graphql-tag";
import * as Graphql_ppx_runtime from "@reasonml-community/graphql-ppx/rescript-runtime/graphql_ppx_runtime.res.mjs";
import * as SpaceX$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SpaceX.res.mjs";
import * as QueryHook2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/QueryHook2.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/LocaleContext.res.mjs";

var Raw = {};

var query = GraphqlTag.gql(["fragment GroupFragment on ContentBookGroup   {\n__typename  \nid  \nname  \n}\n"]);

function parse(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name
        };
}

function serialize(value) {
  var value$1 = value.name;
  var value$2 = value.id;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          id: value$2,
          name: value$1
        };
}

function verifyArgsAndParse(_GroupFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var GroupFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = GraphqlTag.gql([
      "fragment BookFragment on ContentBook   {\n__typename  \nid  \nname  \nthumbnail  \nlexileLevel  \npdf  \ngroup  {\n...GroupFragment   \n}\n\npages  {\n__typename  \npageIndex  \naudioSrc  \n}\n\nlpLevel  \nlpSublevel  \nwidth  \nheight  \n}\n",
      ""
    ], query);

function parse$1(value) {
  var value$1 = value.pages;
  var value$2 = value.lpLevel;
  var value$3 = value.lpSublevel;
  var value$4 = value.width;
  var value$5 = value.height;
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name,
          thumbnail: value.thumbnail,
          lexileLevel: value.lexileLevel,
          pdf: value.pdf,
          group: parse(value.group),
          pages: value$1.map(function (value) {
                var value$1 = value.audioSrc;
                return {
                        __typename: value.__typename,
                        pageIndex: value.pageIndex,
                        audioSrc: !(value$1 == null) ? value$1 : undefined
                      };
              }),
          lpLevel: !(value$2 == null) ? value$2 : undefined,
          lpSublevel: !(value$3 == null) ? value$3 : undefined,
          width: !(value$4 == null) ? value$4 : undefined,
          height: !(value$5 == null) ? value$5 : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.height;
  var height = value$1 !== undefined ? value$1 : null;
  var value$2 = value.width;
  var width = value$2 !== undefined ? value$2 : null;
  var value$3 = value.lpSublevel;
  var lpSublevel = value$3 !== undefined ? value$3 : null;
  var value$4 = value.lpLevel;
  var lpLevel = value$4 !== undefined ? value$4 : null;
  var value$5 = value.pages;
  var pages = value$5.map(function (value) {
        var value$1 = value.audioSrc;
        var audioSrc = value$1 !== undefined ? value$1 : null;
        var value$2 = value.pageIndex;
        var value$3 = value.__typename;
        return {
                __typename: value$3,
                pageIndex: value$2,
                audioSrc: audioSrc
              };
      });
  var value$6 = value.group;
  var group = serialize(value$6);
  var value$7 = value.pdf;
  var value$8 = value.lexileLevel;
  var value$9 = value.thumbnail;
  var value$10 = value.name;
  var value$11 = value.id;
  var value$12 = value.__typename;
  return {
          __typename: value$12,
          id: value$11,
          name: value$10,
          thumbnail: value$9,
          lexileLevel: value$8,
          pdf: value$7,
          group: group,
          pages: pages,
          lpLevel: lpLevel,
          lpSublevel: lpSublevel,
          width: width,
          height: height
        };
}

function verifyArgsAndParse$1(_BookFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var BookFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

function makeGroup(group) {
  return {
          id: group.id,
          name: group.name
        };
}

function makePage(page) {
  return {
          pageIndex: page.pageIndex,
          mp3: page.audioSrc
        };
}

function makeBook(book) {
  return {
          id: book.id,
          name: book.name,
          thumbnail: book.thumbnail,
          level: book.lexileLevel,
          order: book.lexileLevel,
          pdf: book.pdf,
          group: makeGroup(book.group),
          pages: Belt_Array.map(book.pages, makePage),
          lpLevel: book.lpLevel,
          lpSublevel: book.lpSublevel,
          width: book.width,
          height: book.height
        };
}

var Raw$2 = {};

var query$2 = GraphqlTag.gql([
      "fragment FindBookContentFragment on Query   {\nfindBookContent(id: $id, locale: $locale)  {\n...BookFragment   \n}\n\n}\n",
      ""
    ], query$1);

function parse$2(value) {
  return {
          findBookContent: parse$1(value.findBookContent)
        };
}

function serialize$2(value) {
  var value$1 = value.findBookContent;
  var findBookContent = serialize$1(value$1);
  return {
          findBookContent: findBookContent
        };
}

function verifyArgsAndParse$2(_locale, _id, _FindBookContentFragment, value) {
  return {
          findBookContent: parse$1(value.findBookContent)
        };
}

function verifyName$2(param) {
  
}

var FindBookContentFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

function convertFindBookContent(res) {
  return makeBook(res.findBookContent);
}

var Raw$3 = {};

var query$3 = GraphqlTag.gql([
      "query BookQuery($id: ID!, $locale: Locale)  {\ndummy  \n...FindBookContentFragment   \n}\n",
      ""
    ], query$2);

function parse$3(value) {
  var value$1 = value["dummy"];
  return {
          dummy: !(value$1 == null) ? value$1.map(function (value) {
                  if (!(value == null)) {
                    return value;
                  }
                  
                }) : undefined,
          findBookContentFragment: {
            findBookContent: parse$1(value.findBookContent)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.dummy;
  return [serialize$2(value.findBookContentFragment)].reduce(Graphql_ppx_runtime.deepMerge, {
              dummy: value$1 !== undefined ? value$1.map(function (value) {
                      if (value !== undefined) {
                        return value;
                      } else {
                        return null;
                      }
                    }) : null
            });
}

function serializeVariables(inp) {
  var a = inp.locale;
  return {
          id: inp.id,
          locale: a !== undefined ? (
              a === "en_BZ" ? "en_BZ" : (
                  a === "en_CA" ? "en_CA" : (
                      a === "en_CB" ? "en_CB" : (
                          a === "en_GB" ? "en_GB" : (
                              a === "en_IE" ? "en_IE" : (
                                  a === "en_IN" ? "en_IN" : (
                                      a === "en_JM" ? "en_JM" : (
                                          a === "en_NZ" ? "en_NZ" : (
                                              a === "en_PH" ? "en_PH" : (
                                                  a === "en_TT" ? "en_TT" : (
                                                      a === "en_US" ? "en_US" : (
                                                          a === "en_ZA" ? "en_ZA" : (
                                                              a === "en_ZW" ? "en_ZW" : "en_AU"
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined
        };
}

function makeVariables(id, locale, param) {
  return {
          id: id,
          locale: locale
        };
}

var GetBook_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = SpaceX$LiteracyplanetClientFlags.Query.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetBook = {
  GetBook_inner: GetBook_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

function onLoading(param) {
  return "LoadingBook";
}

function handleResponse(res) {
  return {
          TAG: "BookFound",
          _0: makeBook(res.findBookContentFragment.findBookContent)
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

var Q = QueryHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw$3,
      use: use
    });

function useQuery(id, locale) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              id: id,
              locale: locale
            });
}

function use$1(id) {
  var api = LocaleContext$LiteracyplanetClientFlags.use();
  var locale = api.locale;
  return useQuery(id, locale);
}

export {
  GroupFragment ,
  BookFragment ,
  makeGroup ,
  makePage ,
  makeBook ,
  FindBookContentFragment ,
  convertFindBookContent ,
  GetBook ,
  onLoading ,
  handleResponse ,
  onResult ,
  Q ,
  useQuery ,
  use$1 as use,
}
/* query Not a pure module */
