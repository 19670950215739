// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContainerDimensions$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContainerDimensions.res.mjs";

function replaceSpaces(__x) {
  return __x.replace(/ /g, "&nbsp;");
}

var hiddenSpanClass = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.position(Css.absolute),
            tl: {
              hd: Css.left({
                    NAME: "px",
                    VAL: -10000
                  }),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: "ExpandingInput-HiddenSpanClass",
        tl: /* [] */0
      }
    });

function makeWidth(px) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.width({
                          NAME: "px",
                          VAL: px
                        }),
                    tl: /* [] */0
                  }),
              tl: {
                hd: "ExpandingInput-makeWidth",
                tl: /* [] */0
              }
            });
}

function useExpandingInput(className, value) {
  var match = ContainerDimensions$LiteracyplanetClientFlags.useDimsWithContent(value);
  var inputWidthCss = Belt_Option.mapWithDefault(match[1], "", (function (d) {
          return makeWidth((d.width | 0) + 1 | 0);
        }));
  return [
          inputWidthCss,
          React.createElement("span", {
                ref: Caml_option.some(match[0]),
                className: Curry._1(Css.merge, {
                      hd: hiddenSpanClass,
                      tl: {
                        hd: className,
                        tl: /* [] */0
                      }
                    }),
                dangerouslySetInnerHTML: {
                  __html: replaceSpaces(value)
                }
              })
        ];
}

var setInputProps = (function(input) {
    input.autocapitalize = 'off';
    input.autocorrect = false;
    input.autocomplete = 'off';
    input.spellcheck = false;
  });

function ExpandingInput(props) {
  var __autoCapitalize = props.autoCapitalize;
  var __autoComplete = props.autoComplete;
  var __spellCheck = props.spellCheck;
  var __disabled = props.disabled;
  var className = props.className;
  var value = props.value;
  var disabled = __disabled !== undefined ? __disabled : false;
  var spellCheck = __spellCheck !== undefined ? __spellCheck : false;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "off";
  var autoCapitalize = __autoCapitalize !== undefined ? __autoCapitalize : "off";
  var match = useExpandingInput(className, value);
  var onFocus = Belt_Option.getWithDefault(props.onFocus, (function (param) {
          
        }));
  var onBlur = Belt_Option.getWithDefault(props.onBlur, (function (param) {
          
        }));
  var input = React.useRef(null);
  React.useLayoutEffect((function (param) {
          Belt_Option.map(Caml_option.nullable_to_opt(input.current), setInputProps);
        }), [Css.content]);
  return React.createElement(React.Fragment, {}, match[1], React.createElement("input", {
                  ref: Caml_option.some(input),
                  className: Curry._1(Css.merge, {
                        hd: className,
                        tl: {
                          hd: match[0],
                          tl: /* [] */0
                        }
                      }),
                  spellCheck: spellCheck,
                  autoComplete: autoComplete,
                  autoCapitalize: autoCapitalize,
                  disabled: disabled,
                  type: "text",
                  value: value,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  onChange: props.onChange
                }));
}

var cursorWidth = 1;

var make = ExpandingInput;

export {
  replaceSpaces ,
  hiddenSpanClass ,
  makeWidth ,
  cursorWidth ,
  useExpandingInput ,
  setInputProps ,
  make ,
}
/* hiddenSpanClass Not a pure module */
