// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function TickIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              version: "1.1",
              viewBox: "0 0 27 22",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  transform: "translate(-416.000000, -30.000000)"
                }, React.createElement("g", {
                      transform: "translate(416.000000, 28.000000)"
                    }, React.createElement("g", {
                          transform: "translate(0.000000, 2.000000)"
                        }, React.createElement("polygon", {
                              points: "0 12.8898678 8.87142857 22 27 3.58590308 23.4321429 0 9.25714286 13.8590308 4.33928571 8.91629956"
                            })))));
}

var make = TickIcon;

export {
  make ,
}
/* react Not a pure module */
