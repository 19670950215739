// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as V2MultiChoiceContainer$LiteracyplanetTupperware from "./V2MultiChoiceContainer.res.mjs";

function PieceOfCakeMultiChoiceContainer$MultiChoiceMarkStylingContainer(props) {
  var css = Curry._1(Flavor$LiteracyplanetTupperware.PieceOfCakeMultiChoiceContainer.MultiChoiceMarkStylingContainer.className, props.columns);
  return React.createElement("div", {
              className: css
            }, props.children);
}

var MultiChoiceMarkStylingContainer = {
  make: PieceOfCakeMultiChoiceContainer$MultiChoiceMarkStylingContainer
};

function PieceOfCakeMultiChoiceContainer(props) {
  var __fuzzyMark = props.fuzzyMark;
  var fuzzyMark = __fuzzyMark !== undefined ? __fuzzyMark : false;
  return React.createElement(V2MultiChoiceContainer$LiteracyplanetTupperware.make, {
              correctAnswer: props.correctAnswer,
              fuzzyMark: fuzzyMark,
              questionIndex: props.questionIndex,
              children: React.createElement(PieceOfCakeMultiChoiceContainer$MultiChoiceMarkStylingContainer, {
                    columns: props.columns,
                    children: props.children
                  })
            });
}

var make = PieceOfCakeMultiChoiceContainer;

var $$default = PieceOfCakeMultiChoiceContainer;

export {
  MultiChoiceMarkStylingContainer ,
  make ,
  $$default as default,
}
/* react Not a pure module */
