// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.res.mjs";

var partial_arg = localStorage;

function setItem(param, param$1) {
  partial_arg.setItem(param, param$1);
}

var partial_arg$1 = localStorage;

function getItem(param) {
  return Caml_option.null_to_opt(partial_arg$1.getItem(param));
}

var latestKey = "latest";

var lastAssociationKey = "lastAssociation";

function makeKey(nameSpace, key) {
  return nameSpace + (":" + key);
}

function readLast(namespace) {
  return getItem(makeKey(namespace, latestKey));
}

function setLatest(namespace, latestId) {
  setItem(makeKey(namespace, latestKey), latestId);
}

function seialiseAssociations(associations) {
  return JSON.stringify({
              associations: associations
            });
}

function deserialiseAssociations(json) {
  var val;
  try {
    val = JSON.parse(json);
  }
  catch (exn){
    return ;
  }
  return val.associations;
}

function readAssociations(namespace) {
  return Belt_Option.flatMap(getItem(makeKey(namespace, lastAssociationKey)), deserialiseAssociations);
}

function setAssociations(associationsCache, namespace) {
  setItem(makeKey(namespace, lastAssociationKey), JSON.stringify({
            associations: associationsCache
          }));
}

function inCache(cache, latestId) {
  if (cache !== undefined) {
    if (cache === latestId) {
      return "CacheMatch";
    } else {
      return "NoCacheMatch";
    }
  } else {
    return "NoCacheSet";
  }
}

function matches(a, f) {
  return Belt_Option.isSome(Belt_Array.getBy(a, f));
}

function latestMatches(associations, latestId) {
  return Belt_Option.isSome(Belt_Array.getBy(associations, (function (param) {
                    return Caml_obj.equal(param[0], latestId);
                  })));
}

function associationMatches(associations, associationId) {
  return Belt_Option.isSome(Belt_Array.getBy(associations, (function (param) {
                    return Caml_obj.equal(param[1], associationId);
                  })));
}

function associationInCache(cache, latestId, associationId) {
  if (cache === undefined) {
    return "NoCacheSet";
  }
  var match = latestMatches(cache, latestId);
  var match$1 = associationMatches(cache, associationId);
  if (match) {
    if (match$1) {
      return "CacheMatch";
    } else {
      return "NoCacheMatch";
    }
  } else {
    return "NoCacheSet";
  }
}

function updateAssociations(associationsCache, latestId, associationId) {
  if (associationsCache === undefined) {
    return [[
              latestId,
              associationId
            ]];
  }
  var param = Belt_Array.reduce(associationsCache, [
        [],
        false
      ], (function (param, param$1) {
          var savedlatestId = param$1[0];
          var matched = savedlatestId === latestId;
          var pair = Belt_Array.concat(param[0], [matched ? [
                    latestId,
                    associationId
                  ] : [
                    savedlatestId,
                    param$1[1]
                  ]]);
          return [
                  pair,
                  matched ? true : param[1]
                ];
        }));
  var associations = param[0];
  if (param[1]) {
    return associations;
  } else {
    return Belt_Array.concat(associations, [[
                  latestId,
                  associationId
                ]]);
  }
}

function useLatest(userId, namespace) {
  var ns = userId + namespace;
  var latestCache = getItem(makeKey(ns, latestKey));
  var saveLatest = function (id) {
    setLatest(ns, id);
  };
  var inLatestCache = function (id) {
    return inCache(latestCache, id);
  };
  return [
          inLatestCache,
          saveLatest
        ];
}

function useAssociation(userId, namespace) {
  var ns = userId + namespace;
  var associationsCache = readAssociations(ns);
  var saveAssociation = function (id, associationId) {
    setAssociations(updateAssociations(associationsCache, id, associationId), ns);
  };
  var inAssociationCache = function (id, associationId) {
    return associationInCache(associationsCache, id, associationId);
  };
  return [
          inAssociationCache,
          saveAssociation
        ];
}

function useUserContext(namespace) {
  var user = UserContext$LiteracyplanetClientFlags.use();
  var userId = user.userId;
  var match = useLatest(userId, namespace);
  var match$1 = useAssociation(userId, namespace);
  return [
          match[0],
          match[1],
          match$1[0],
          match$1[1]
        ];
}

function useValue(userId, namespace) {
  var ns = userId + namespace;
  var optValue = React.useMemo((function (param) {
          return getItem(makeKey(ns, latestKey));
        }), []);
  var setValue = function (value) {
    setLatest(ns, value);
  };
  return [
          optValue,
          setValue
        ];
}

export {
  setItem ,
  getItem ,
  latestKey ,
  lastAssociationKey ,
  makeKey ,
  readLast ,
  setLatest ,
  seialiseAssociations ,
  deserialiseAssociations ,
  readAssociations ,
  setAssociations ,
  inCache ,
  matches ,
  latestMatches ,
  associationMatches ,
  associationInCache ,
  updateAssociations ,
  useLatest ,
  useAssociation ,
  useUserContext ,
  useValue ,
}
/* partial_arg Not a pure module */
