// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as PlayAudioIcon$LiteracyplanetTupperware from "./PlayAudioIcon.res.mjs";
import * as AudioButtonApi$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/AudioButtonApi.res.mjs";

function AudioPillButton$PillButton(props) {
  var onTogglePlay = props.onTogglePlay;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.AudioPillButton.PillButton.className,
              onClick: (function (ev) {
                  Curry._1(onTogglePlay, undefined);
                  ev.stopPropagation();
                })
            }, props.children);
}

var PillButton = {
  make: AudioPillButton$PillButton
};

function AudioPillButton(props) {
  var onTogglePlay = props.onTogglePlay;
  return React.createElement(AudioButtonApi$LiteracyplanetClientFlags.make, {
              isPlaying: props.isPlaying,
              src: props.src,
              onEnd: onTogglePlay,
              onTogglePlay: onTogglePlay,
              children: (function (state, onTogglePlay) {
                  return React.createElement(AudioPillButton$PillButton, {
                              onTogglePlay: onTogglePlay,
                              children: React.createElement(PlayAudioIcon$LiteracyplanetTupperware.make, {
                                    state: state
                                  })
                            });
                })
            });
}

var make = AudioPillButton;

export {
  PillButton ,
  make ,
}
/* react Not a pure module */
