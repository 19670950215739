// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as V2EventsContext$LiteracyplanetTupperware from "./V2EventsContext.res.mjs";
import * as BehaviourMultiChoiceMarkCallback$LiteracyplanetTupperware from "./BehaviourMultiChoiceMarkCallback.res.mjs";

function BehaviourMultiChoiceContainer(props) {
  var __showCorrectAnswerBehaviour = props.showCorrectAnswerBehaviour;
  var showCorrectAnswerBehaviour = __showCorrectAnswerBehaviour !== undefined ? __showCorrectAnswerBehaviour : false;
  var callbacks = V2EventsContext$LiteracyplanetTupperware.use();
  return React.createElement(BehaviourMultiChoiceMarkCallback$LiteracyplanetTupperware.make, {
              callbacks: callbacks,
              questionIndex: props.questionIndex,
              correctAnswer: props.correctAnswer,
              fuzzyMark: props.fuzzyMark,
              showCorrectAnswerBehaviour: showCorrectAnswerBehaviour,
              children: props.children
            });
}

var make = BehaviourMultiChoiceContainer;

var $$default = BehaviourMultiChoiceContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
