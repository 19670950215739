// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";

function K2AnswerWraps$Answer(props) {
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2AnswerWrap.Answer.className, props.className)
            }, props.children);
}

var Answer = {
  make: K2AnswerWraps$Answer
};

function K2AnswerWraps$Box(props) {
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2AnswerWrap.Box.className, props.className)
            }, props.children);
}

var Box = {
  make: K2AnswerWraps$Box
};

export {
  Answer ,
  Box ,
}
/* react Not a pure module */
