// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function CrossIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              version: "1.1",
              viewBox: "0 0 22 22",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  transform: "translate(-500.000000, -30.000000)"
                }, React.createElement("g", {
                      transform: "translate(499.000000, 28.000000)"
                    }, React.createElement("g", {
                          transform: "translate(0.000000, 2.000000)"
                        }, React.createElement("polygon", {
                              points: "9.55882353 -1.44117647 14.8137255 -1.51960784 14.4411765 23.4411765 9.18627451 23.5196078",
                              transform: "translate(12.000000, 11.000000) rotate(-315.000000) translate(-12.000000, -11.000000) "
                            }), React.createElement("polygon", {
                              points: "9.55882353 -1.44117647 14.8137255 -1.51960784 14.4411765 23.4411765 9.18627451 23.5196078",
                              transform: "translate(12.000000, 11.000000) scale(-1, 1) rotate(-315.000000) translate(-12.000000, -11.000000) "
                            })))));
}

var make = CrossIcon;

export {
  make ,
}
/* react Not a pure module */
