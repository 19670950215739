// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Cobra_launcher from "@literacyplanet/cobra_launcher";
import * as CobraGenericBehaviourContext$LiteracyplanetClientFlags from "./CobraGenericBehaviourContext.res.mjs";

var merge = (function (a, b) {
    return {
      ...a,
      ...b
    }
  });

function CobraContainer(props) {
  var d = props.onDispatch;
  var p = props.props;
  var view = props.view;
  var match = Curry._1(CobraGenericBehaviourContext$LiteracyplanetClientFlags.use, undefined);
  var onDispatch = match.onDispatch;
  var props$1 = match.props;
  return React.useMemo((function (param) {
                return React.createElement(Cobra_launcher.CobraLauncher, {
                            width: "1280",
                            height: "768",
                            view: view,
                            name: view,
                            props: merge(p, props$1),
                            onDispatch: merge(d, onDispatch)
                          });
              }), [
              p,
              props$1,
              view
            ]);
}

var make = CobraContainer;

export {
  merge ,
  make ,
}
/* react Not a pure module */
