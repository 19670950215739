// Generated by ReScript, PLEASE EDIT WITH CARE


function make(s) {
  return s;
}

function get(s) {
  return s;
}

var Md5 = {
  make: make,
  get: get
};

export {
  Md5 ,
  make ,
  get ,
}
/* No side effect */
