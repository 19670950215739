// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ForestHeader$LiteracyplanetClientSxEntry from "./ForestHeader.res.mjs";
import * as ForestLayout$LiteracyplanetClientSxEntry from "./ForestLayout.res.mjs";
import * as PapyrusComponents$LiteracyplanetClientSxEntry from "./PapyrusComponents.res.mjs";
import * as StoryversePanelContext$LiteracyplanetClientSxEntry from "../StoryversePanelContext.res.mjs";
import * as StoryverseNavigationQuery$LiteracyplanetClientSxEntry from "../StoryverseNavigationQuery.res.mjs";

function ForestNavBar(props) {
  var onClickShop = props.onClickShop;
  var lockShop = props.lockShop;
  var onClickArcade = props.onClickArcade;
  var lockArcade = props.lockArcade;
  var onClickCollections = props.onClickCollections;
  var onClickBog = props.onClickBog;
  var match = Curry._1(StoryversePanelContext$LiteracyplanetClientSxEntry.Context.use, undefined);
  var panelOpen = match.panelOpen;
  var navigation = StoryverseNavigationQuery$LiteracyplanetClientSxEntry.use(!panelOpen);
  return React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.ScrollPanel.make, {
              children: null
            }, React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.Spacer.make, {}), React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.HeaderPanel.make, {
                  children: null
                }, React.createElement(ForestHeader$LiteracyplanetClientSxEntry.make, {
                      onClickMissions: props.onClickMissions,
                      onClickIntrepizine: props.onClickIntrepizine,
                      onClickMyWords: props.onClickMyWords,
                      onClickLibrary: props.onClickLibrary,
                      onClickBog: onClickBog,
                      onClickCollections: onClickCollections,
                      lockArcade: lockArcade,
                      onClickArcade: onClickArcade,
                      lockShop: lockShop,
                      onClickShop: onClickShop,
                      showLibraryLink: props.showLibraryLink,
                      showWordManiaLink: props.showWordManiaLink,
                      onClickWordMania: props.onClickWordMania,
                      onClickReports: props.onClickReports,
                      onClickLogout: props.onClickLogout,
                      teacherModeLink: props.teacherModeLink,
                      showTeacherModeWidget: props.showTeacherModeWidget
                    }), panelOpen ? React.createElement(ForestLayout$LiteracyplanetClientSxEntry.make, {
                        userName: props.userName,
                        navigation: navigation,
                        onClickBog: onClickBog,
                        onClickCollections: onClickCollections,
                        lockArcade: lockArcade,
                        onClickArcade: onClickArcade,
                        lockShop: lockShop,
                        onClickShop: onClickShop,
                        closeHeaderPanel: match.closePanel
                      }) : React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.Spacer.make, {})));
}

var make = ForestNavBar;

export {
  make ,
}
/* react Not a pure module */
