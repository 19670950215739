// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function VolumeIcon(props) {
  return React.createElement("svg", {
              className: props.className + " VolumeIcon",
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M357.9,366.9c-5.8,0-11.7-2.1-16.3-6.4c-9.7-9-10.3-24.2-1.3-33.9c17.8-19.3,27.7-44.4,27.7-70.6\n      s-9.8-51.4-27.7-70.6c-9-9.7-8.4-24.9,1.3-33.9c9.7-9,24.9-8.4,33.9,1.3C401.6,180.9,416,217.6,416,256s-14.4,75.1-40.5,103.2\n      C370.8,364.4,364.4,366.9,357.9,366.9z"
                }), React.createElement("path", {
                  d: "M422.2,438.6c-6.2,0-12.3-2.4-17-7.1c-9.4-9.4-9.3-24.6,0-33.9C443.1,359.8,464,309.5,464,256\n      s-20.9-103.8-58.8-141.6c-9.4-9.4-9.4-24.6,0-33.9c9.4-9.4,24.6-9.4,33.9,0c47,46.9,72.9,109.2,72.9,175.6s-25.9,128.7-72.9,175.6\n      C434.5,436.3,428.3,438.6,422.2,438.6z"
                }), React.createElement("path", {
                  d: "M99,160H44c-24.3,0-44,19.7-44,44v104c0,24.3,19.7,44,44,44h55c2.8,0,5-2.2,5-5V165C104,162.2,101.8,160,99,160\n      z"
                }), React.createElement("path", {
                  d: "M280,56h-24c-5.3,0-10.4,1.7-14.6,4.9L138,140.1c-1.2,0.9-2,2.4-2,4v224c0,1.6,0.7,3,2,4l103.5,79.1\n      c4.2,3.2,9.3,4.9,14.6,4.9h24c13.3,0,24-10.7,24-24V80C304,66.7,293.3,56,280,56L280,56z"
                }));
}

var make = VolumeIcon;

var $$default = VolumeIcon;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
