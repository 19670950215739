// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "rescript/lib/es6/array.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as FormSelect$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FormSelect.res.mjs";
import * as ShuffleChildren$LiteracyplanetTupperware from "../ShuffleChildren.res.mjs";
import * as SkilfulResponseMark$LiteracyplanetTupperware from "./SkilfulResponseMark.res.mjs";

function SkilfulDropDown(props) {
  var responseOptions = props.responseOptions;
  var marked = props.marked;
  var selectedValue = props.selectedValue;
  var match = React.useState(function (param) {
        return [];
      });
  var setResponseOptions = match[1];
  React.useEffect((function (param) {
          var options = ShuffleChildren$LiteracyplanetTupperware.shuffle($$Array.of_list(Belt_List.map(responseOptions, (function (param) {
                          return [
                                  param.responseOptionId,
                                  param.value
                                ];
                        }))));
          Curry._1(setResponseOptions, (function (param) {
                  return options;
                }));
        }), []);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulSelect.className
            }, marked === "Unanswered" ? React.createElement(FormSelect$LiteracyplanetClientFlags.make, {
                    selectedValue: selectedValue,
                    options: match[0],
                    onChange: props.onChange,
                    optionClassName: Skillet$LiteracyplanetTupperware.SkilfulOption.className,
                    optionsClassName: Skillet$LiteracyplanetTupperware.SkilfulOptions.className,
                    inputClassName: Skillet$LiteracyplanetTupperware.SkilfulInput.className,
                    inputTextClassName: Skillet$LiteracyplanetTupperware.SkilfulInputText.className,
                    borderClassName: Skillet$LiteracyplanetTupperware.SkilfulBorder.className,
                    iconClassName: Skillet$LiteracyplanetTupperware.SkilfulDropDownIcon.className
                  }) : React.createElement(SkilfulResponseMark$LiteracyplanetTupperware.make, {
                    inputClassName: Skillet$LiteracyplanetTupperware.SkilfulDropDown.className,
                    marked: marked,
                    answerVisible: props.answerVisible,
                    value: Belt_Option.getWithDefault(selectedValue, "")
                  }));
}

var make = SkilfulDropDown;

export {
  make ,
}
/* react Not a pure module */
