// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";

function SkilfulContent(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulContent.className
            }, props.children);
}

var make = SkilfulContent;

var $$default = SkilfulContent;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
