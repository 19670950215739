// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ExerciseStateStore$LiteracyplanetTupperware from "../ExerciseStateStore.res.mjs";

var state = {
  questionStates: /* [] */0,
  markStates: /* [] */0,
  mode: "Activity",
  questionsComplete: 0,
  questionsCorrect: 0,
  questionsIncorrect: 0
};

function initialApi_setState(param) {
  
}

var initialApi = {
  setState: initialApi_setState,
  state: state
};

var context = React.createContext(initialApi);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function V2ExerciseStateStoreContext$Provider(props) {
  var match = React.useState(function (param) {
        return state;
      });
  var setState = match[1];
  var state$1 = match[0];
  var value = React.useMemo((function (param) {
          return {
                  setState: setState,
                  state: state$1
                };
        }), [state$1]);
  return React.createElement(React.Fragment, {
              children: Caml_option.some(React.createElement(make, {
                        value: value,
                        children: props.children
                      }))
            });
}

var Provider = {
  make: V2ExerciseStateStoreContext$Provider
};

function use(param) {
  return React.useContext(context);
}

function useDispatch(param) {
  var match = React.useContext(context);
  var setState = match.setState;
  var dispatch = function (action) {
    Curry._1(setState, (function (state) {
            return ExerciseStateStore$LiteracyplanetTupperware.reducer(state, action);
          }));
  };
  return [
          match.state,
          dispatch
        ];
}

var $$default = V2ExerciseStateStoreContext$Provider;

export {
  state ,
  initialApi ,
  Context ,
  Provider ,
  use ,
  useDispatch ,
  $$default as default,
}
/* context Not a pure module */
