// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";

function s(prim) {
  return prim;
}

var missionsColor = Css.hex("009fe8");

var missionsHoverColor = Css.hex("0dadf7");

var missionsActiveColor = Css.hex("a3e1fe");

var reportsColor = Css.hex("E67F22");

var reportsHoverColor = Css.hex("E1661B");

var reportsActiveColor = Css.hex("BF5717");

var collectionsColor = Css.hex("374d95");

var collectionsHoverColor = Css.hex("475da4");

var collectionsActiveColor = Css.hex("6c83ce");

var libraryColor = Css.hex("75258e");

var libraryHoverColor = Css.hex("8832a3");

var libraryActiveColor = Css.hex("ab30d1");

var avatarColor = Css.hex("a055b7");

var avatarHoverColor = Css.hex("b169c7");

var avatarActiveColor = Css.hex("d091e3");

var lpClassicColor = Css.hex("8dcb46");

var lpClassicHoverColor = Css.hex("84bc40");

var lpClassicActiveColor = Css.hex("78aa3a");

var conversationColor = Css.hex("1BBC9B");

var conversationHoverColor = Css.hex("16AF87");

var conversationActiveColor = Css.hex("139573");

var logOutColor = Css.hex("c52c2c");

var logOutHoverColor = Css.hex("b82e2e");

var logOutActiveColor = Css.hex("a62626");

var iconText = Css.rgb(12, 156, 229);

var closeColor = Css.rgb(255, 255, 255);

var closeHoverColor = Css.rgb(218, 240, 251);

var closeActiveColor = Css.rgb(200, 220, 230);

var menuColor = Css.rgb(255, 255, 255);

var menuLogoutColor = Css.rgb(197, 44, 44);

var defaultFonts = {
  hd: {
    NAME: "custom",
    VAL: "Quicksand"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "Lato"
    },
    tl: {
      hd: {
        NAME: "custom",
        VAL: "Arial"
      },
      tl: /* [] */0
    }
  }
};

var defaultBoxShadow = Css_Legacy_Core.Shadow.box(Css.px(4), Css.px(0), Css.px(19), Css.px(2), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.39
        }));

function notificationCountStatic(notificationCount) {
  return Curry._1(Css.style, {
              hd: Css.contentRule({
                    NAME: "text",
                    VAL: String(notificationCount)
                  }),
              tl: {
                hd: Css.position(Css.absolute),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.fontWeight("bold"),
                          tl: {
                            hd: Css.color(Css.white),
                            tl: {
                              hd: Css.backgroundColor(Css.red),
                              tl: {
                                hd: Css.borderRadius(Css.pct(50.0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var notificationCountDynamic = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        16
      ],
      tl: {
        hd: [
          Css.top,
          -14
        ],
        tl: {
          hd: [
            Css.right,
            14
          ],
          tl: {
            hd: [
              Css.width,
              28
            ],
            tl: {
              hd: [
                Css.height,
                28
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var missionIconStatic = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(Css.rgb(255, 255, 255)),
      tl: /* [] */0
    });

var missionIconDynamic = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        32
      ],
      tl: /* [] */0
    });

var missionIcon = Curry._1(Css.merge, {
      hd: missionIconStatic,
      tl: {
        hd: missionIconDynamic,
        tl: /* [] */0
      }
    });

var css = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.fontWeight("bold"),
                tl: {
                  hd: Css.position("fixed"),
                  tl: {
                    hd: Css.color(iconText),
                    tl: {
                      hd: Css.backgroundColor(Css.white),
                      tl: {
                        hd: Css.borderRadius(Css.pct(50.0)),
                        tl: {
                          hd: Css_Legacy_Core.SVG.fill(iconText),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        22
      ],
      tl: {
        hd: [
          Css.top,
          19
        ],
        tl: {
          hd: [
            Css.right,
            19
          ],
          tl: {
            hd: [
              Css.width,
              70
            ],
            tl: {
              hd: [
                Css.height,
                70
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: r,
        tl: {
          hd: "MenuIcon",
          tl: /* [] */0
        }
      }
    });

var MenuIcon = {
  css: css,
  r: r,
  className: className
};

var className$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        18
      ],
      tl: {
        hd: [
          Css.height,
          18
        ],
        tl: /* [] */0
      }
    });

var MenuOpenIcon = {
  className: className$1
};

var css$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.fontWeight("bold"),
                tl: {
                  hd: Css.position("fixed"),
                  tl: {
                    hd: Css.color(Css.white),
                    tl: {
                      hd: Css.backgroundColor(Css.red),
                      tl: {
                        hd: Css.borderRadius(Css.pct(50.0)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        22
      ],
      tl: {
        hd: [
          Css.top,
          14
        ],
        tl: {
          hd: [
            Css.right,
            19
          ],
          tl: {
            hd: [
              Css.width,
              28
            ],
            tl: {
              hd: [
                Css.height,
                28
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: r$1,
        tl: {
          hd: "NotificationIcon",
          tl: /* [] */0
        }
      }
    });

var NotificationIcon = {
  css: css$1,
  r: r$1,
  className: className$2
};

var css$2 = Curry._1(Css.style, {
      hd: Css.boxSizing("borderBox"),
      tl: {
        hd: Css.borderWidth(Css.px(4)),
        tl: {
          hd: Css.borderColor(Css.white),
          tl: {
            hd: Css.borderStyle("solid"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.position("fixed"),
                tl: {
                  hd: Css.top(Css.zero),
                  tl: {
                    hd: Css.right(Css.zero),
                    tl: {
                      hd: Css.display("grid"),
                      tl: {
                        hd: Css.zIndex(30),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.height(Css.pct(100.0)),
                            tl: {
                              hd: Css.gridGap(Css.px(4)),
                              tl: {
                                hd: Css.gridTemplateColumns({
                                      hd: Css.fr(1.0),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.gridTemplateRows({
                                        hd: Css.px(70),
                                        tl: {
                                          hd: Css.fr(1.0),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.userSelect("none"),
                                    tl: {
                                      hd: Css.backgroundColor(Css.white),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: "NavigationContainer",
        tl: /* [] */0
      }
    });

var NavigationContainer = {
  css: css$2,
  className: className$3
};

var css$3 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.fontSize(Css.px(30)),
        tl: {
          hd: Css.color(Css.white),
          tl: {
            hd: Css.backgroundColor(iconText),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$4 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: "NavigationName",
        tl: /* [] */0
      }
    });

var NavigationName = {
  css: css$3,
  className: className$4
};

var css$4 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.height(Css.pct(100.0)),
          tl: {
            hd: Css.flexWrap("wrap"),
            tl: {
              hd: Css.boxSizing("borderBox"),
              tl: {
                hd: Css.backgroundColor(Css.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$5 = Curry._1(Css.merge, {
      hd: css$4,
      tl: {
        hd: "NavigationLinksContainer",
        tl: /* [] */0
      }
    });

var NavigationLinksContainer = {
  css: css$4,
  className: className$5
};

var css$5 = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.position("fixed"),
        tl: {
          hd: Css.top(Css.zero),
          tl: {
            hd: Css.right(Css.zero),
            tl: {
              hd: Css.width(Css.px(90)),
              tl: {
                hd: Css.height(Css.px(90)),
                tl: {
                  hd: Css.borderBottomLeftRadius(Css.pct(100.0)),
                  tl: {
                    hd: Css.zIndex(50),
                    tl: {
                      hd: Css.backgroundColor(closeColor),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(closeHoverColor),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.active({
                                hd: Css.backgroundColor(closeActiveColor),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$6 = Curry._1(Css.merge, {
      hd: css$5,
      tl: {
        hd: "CloseBtn",
        tl: /* [] */0
      }
    });

var CloseBtn = {
  css: css$5,
  className: className$6
};

var css$6 = Curry._1(Css.style, {
      hd: Css.width(Css.pct(30.0)),
      tl: {
        hd: Css.height(Css.pct(30.0)),
        tl: {
          hd: Css.paddingLeft(Css.pct(45.0)),
          tl: {
            hd: Css.paddingTop(Css.pct(25.0)),
            tl: {
              hd: Css.color(iconText),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$7 = Curry._1(Css.merge, {
      hd: css$6,
      tl: {
        hd: "SmallIcon",
        tl: /* [] */0
      }
    });

var SmallIcon = {
  css: css$6,
  className: className$7
};

var css$7 = Curry._1(Css.style, {
      hd: Css.width(Css.px(100)),
      tl: {
        hd: Css.height("auto"),
        tl: /* [] */0
      }
    });

var className$8 = Curry._1(Css.merge, {
      hd: css$7,
      tl: {
        hd: "LinkIcon",
        tl: /* [] */0
      }
    });

var LinkIcon = {
  css: css$7,
  className: className$8
};

function css$8(bgColor, hoverColor, activeColor) {
  return Curry._1(Css.style, {
              hd: Css.fontSize(Css.px(24)),
              tl: {
                hd: Css.textAlign("center"),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.width(Css.pct(50.0)),
                    tl: {
                      hd: Css.flexGrow(1.0),
                      tl: {
                        hd: Css.minHeight(Css.px(120)),
                        tl: {
                          hd: Css.boxSizing("borderBox"),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.overflow("hidden"),
                              tl: {
                                hd: Css.color(menuColor),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.flexDirection("column"),
                                        tl: {
                                          hd: Css.backgroundColor(bgColor),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.backgroundColor(hoverColor),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.active({
                                                    hd: Css.backgroundColor(activeColor),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.userSelect("none"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function className$9(bgColor, hoverColor, activeColor) {
  return Curry._1(Css.merge, {
              hd: css$8(bgColor, hoverColor, activeColor),
              tl: {
                hd: "MenuLink",
                tl: /* [] */0
              }
            });
}

var MenuLink = {
  css: css$8,
  className: className$9
};

var css$9 = Curry._1(Css.style, {
      hd: Css.borderBottomStyle("solid"),
      tl: {
        hd: Css.borderBottomWidth(Css.px(3)),
        tl: {
          hd: Css.borderBottomColor("transparent"),
          tl: {
            hd: Css.hover({
                  hd: Css.borderBottomColor(menuColor),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: css$9,
      tl: {
        hd: "LinkText",
        tl: /* [] */0
      }
    });

var LinkText = {
  css: css$9,
  className: className$10
};

var css$10 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var className$11 = Curry._1(Css.merge, {
      hd: css$10,
      tl: {
        hd: "IconAndText",
        tl: /* [] */0
      }
    });

var IconAndText = {
  css: css$10,
  className: className$11
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var wordManiaColor = libraryColor;

var wordManiaHoverColor = libraryHoverColor;

var wordManiaActiveColor = libraryActiveColor;

var closeIconColor = iconText;

export {
  ratio ,
  maximumWidth ,
  s ,
  missionsColor ,
  missionsHoverColor ,
  missionsActiveColor ,
  reportsColor ,
  reportsHoverColor ,
  reportsActiveColor ,
  collectionsColor ,
  collectionsHoverColor ,
  collectionsActiveColor ,
  libraryColor ,
  libraryHoverColor ,
  libraryActiveColor ,
  avatarColor ,
  avatarHoverColor ,
  avatarActiveColor ,
  lpClassicColor ,
  lpClassicHoverColor ,
  lpClassicActiveColor ,
  wordManiaColor ,
  wordManiaHoverColor ,
  wordManiaActiveColor ,
  conversationColor ,
  conversationHoverColor ,
  conversationActiveColor ,
  logOutColor ,
  logOutHoverColor ,
  logOutActiveColor ,
  iconText ,
  closeIconColor ,
  closeColor ,
  closeHoverColor ,
  closeActiveColor ,
  menuColor ,
  menuLogoutColor ,
  defaultFonts ,
  defaultBoxShadow ,
  notificationCountStatic ,
  notificationCountDynamic ,
  missionIconStatic ,
  missionIconDynamic ,
  missionIcon ,
  MenuIcon ,
  MenuOpenIcon ,
  NotificationIcon ,
  NavigationContainer ,
  NavigationName ,
  NavigationLinksContainer ,
  CloseBtn ,
  SmallIcon ,
  LinkIcon ,
  MenuLink ,
  LinkText ,
  IconAndText ,
}
/* missionsColor Not a pure module */
