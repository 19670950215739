// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import GemSvg from "./assets/gem.svg";
import Icon_bookSvg from "./assets/icon_book.svg";
import Icon_sackSvg from "./assets/icon_sack.svg";
import Icon_shopSvg from "./assets/icon_shop.svg";
import Icon_closeSvg from "./assets/icon_close.svg";
import Icon_arcadeSvg from "./assets/icon_arcade.svg";
import Icon_profileSvg from "./assets/icon_profile.svg";
import Icon_locationSvg from "./assets/icon_location.svg";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";

var bookIcon = Icon_bookSvg;

var sackIcon = Icon_sackSvg;

var arcadeIcon = Icon_arcadeSvg;

var shopIcon = Icon_shopSvg;

var gem = GemSvg;

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        46
      ],
      tl: {
        hd: [
          Css.height,
          44
        ],
        tl: /* [] */0
      }
    });

var css = Curry._1(Css.style, {
      hd: Css.background(Css.url(gem)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition("right"),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: r,
        tl: {
          hd: "GemIcon",
          tl: /* [] */0
        }
      }
    });

var GemIconStyle = {
  r: r,
  css: css,
  className: className
};

function ForestIcons$GemIcon(props) {
  return React.createElement("div", {
              className: className
            });
}

var GemIcon = {
  make: ForestIcons$GemIcon
};

var profileIcon = Icon_profileSvg;

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          28
        ],
        tl: /* [] */0
      }
    });

var css$1 = Curry._1(Css.style, {
      hd: Css.background(Css.url(profileIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: r$1,
        tl: {
          hd: "Gem",
          tl: /* [] */0
        }
      }
    });

var ProfileIconStyle = {
  r: r$1,
  css: css$1,
  className: className$1
};

function ForestIcons$ProfileIcon(props) {
  return React.createElement("div", {
              className: className$1
            });
}

var ProfileIcon = {
  make: ForestIcons$ProfileIcon
};

var locationIcon = Icon_locationSvg;

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          19
        ],
        tl: /* [] */0
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.background(Css.url(locationIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: r$2,
        tl: {
          hd: "LocationIcon",
          tl: /* [] */0
        }
      }
    });

var LocationIconStyle = {
  r: r$2,
  css: css$2,
  className: className$2
};

function ForestIcons$LocationIcon(props) {
  return React.createElement("div", {
              className: className$2
            });
}

var LocationIcon = {
  make: ForestIcons$LocationIcon
};

var closeIcon = Icon_closeSvg;

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        27
      ],
      tl: {
        hd: [
          Css.height,
          27
        ],
        tl: /* [] */0
      }
    });

var css$3 = Curry._1(Css.style, {
      hd: Css.background(Css.url(closeIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.justifyContent(Css.center),
                tl: {
                  hd: Css.justifyItems(Css.center),
                  tl: {
                    hd: Css.alignContent(Css.center),
                    tl: {
                      hd: Css.alignItems(Css.center),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: r$3,
        tl: {
          hd: "CloseIcon",
          tl: /* [] */0
        }
      }
    });

var CloseIconStyle = {
  r: r$3,
  css: css$3,
  className: className$3
};

function ForestIcons$CloseIcon(props) {
  return React.createElement("div", {
              className: className$3
            });
}

var CloseIcon = {
  make: ForestIcons$CloseIcon
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

export {
  ratio ,
  resizeGridCols ,
  resizeGridRows ,
  maximumWidth ,
  bookIcon ,
  sackIcon ,
  arcadeIcon ,
  shopIcon ,
  gem ,
  GemIconStyle ,
  GemIcon ,
  profileIcon ,
  ProfileIconStyle ,
  ProfileIcon ,
  locationIcon ,
  LocationIconStyle ,
  LocationIcon ,
  closeIcon ,
  CloseIconStyle ,
  CloseIcon ,
}
/* bookIcon Not a pure module */
