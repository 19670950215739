// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";
import * as CobraContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/CobraContainer.res.mjs";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "./BackgroundChanger.res.mjs";
import * as JourneyActivitiesQuery$LiteracyplanetClientSxEntry from "./JourneyActivitiesQuery.res.mjs";

function JourneyActivitiesScreen(props) {
  var studentMissionId = props.studentMissionId;
  var redirect = props.redirect;
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use();
  var setBackground = match[1];
  var missionStates = JourneyActivitiesQuery$LiteracyplanetClientSxEntry.useCurrentUser(studentMissionId);
  var isAssessment = function (studendActivityId) {
    if (typeof missionStates !== "object") {
      return false;
    } else {
      return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(missionStates._0.activities, (function (activity) {
                            return activity.id === studendActivityId;
                          })), (function (a) {
                        return a.assessment;
                      })), false);
    }
  };
  var match$1 = Error$LiteracyplanetClientErrors.Consumer.use();
  var onSetError = match$1.onSetError;
  var onDispatch = {
    onJourneyActivitySelected: (function (studendActivityId) {
        if (typeof missionStates !== "object") {
          return ;
        } else {
          return Curry._1(redirect, {
                      TAG: "JourneyActivity",
                      _0: missionStates._1,
                      _1: missionStates._2,
                      _2: studentMissionId.toString(),
                      _3: studendActivityId,
                      _4: isAssessment(studendActivityId)
                    });
        }
      }),
    onExit: (function (param) {
        Curry._1(redirect, "JourneyMissions");
      }),
    onPreloaderReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      }),
    onJourneyReady: (function (c) {
        Curry._1(setBackground, (function (param) {
                return c.backgroundColor;
              }));
      }),
    onFailed: (function (err) {
        Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError("JourneyActivitiesError", err));
      })
  };
  if (typeof missionStates !== "object") {
    return null;
  } else {
    return React.createElement("div", {
                style: match[0]
              }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                    view: "jas",
                    props: missionStates._0,
                    onDispatch: onDispatch
                  }));
  }
}

var make = JourneyActivitiesScreen;

export {
  make ,
}
/* react Not a pure module */
