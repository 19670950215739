// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as Spinner$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Spinner.res.mjs";

function BookSpinner(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.BookSpinnerStyle.className
            }, React.createElement(Spinner$LiteracyplanetClientFlags.make, {}));
}

var make = BookSpinner;

export {
  make ,
}
/* react Not a pure module */
