// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as K2AnswerWraps$LiteracyplanetTupperware from "./K2AnswerWraps.res.mjs";
import * as K2AnswerMarkIcon$LiteracyplanetTupperware from "./K2AnswerMarkIcon.res.mjs";

function AnswerBoxMed(props) {
  return React.createElement(K2AnswerWraps$LiteracyplanetTupperware.Answer.make, {
              className: Flavor$LiteracyplanetTupperware.AnswerBoxMed.Answer.className,
              children: null
            }, React.createElement(K2AnswerWraps$LiteracyplanetTupperware.Box.make, {
                  className: Flavor$LiteracyplanetTupperware.AnswerBoxMed.Box.className,
                  children: props.children
                }), React.createElement(K2AnswerMarkIcon$LiteracyplanetTupperware.Correct.make, {}), React.createElement(K2AnswerMarkIcon$LiteracyplanetTupperware.Wrong.make, {}));
}

var make = AnswerBoxMed;

var $$default = AnswerBoxMed;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
