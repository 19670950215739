// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as HtmlBody$LiteracyplanetClientFlags from "./HtmlBody.res.mjs";
import * as DropDownIcon$LiteracyplanetClientFlags from "./DropDownIcon.res.mjs";
import * as UseModalState$LiteracyplanetClientFlags from "./UseModalState.res.mjs";
import * as TooltipPosition$LiteracyplanetClientFlags from "./TooltipPosition.res.mjs";
import * as ContainerDimensions$LiteracyplanetClientFlags from "./ContainerDimensions.res.mjs";

var bodyClassName = Curry._1(Css.style, {
      hd: Css.position(Css.relative),
      tl: /* [] */0
    });

function FormSelect$Options(props) {
  var optionClassName = props.optionClassName;
  var onChange = props.onChange;
  var match = TooltipPosition$LiteracyplanetClientFlags.use(props.inputDimensions);
  return React.createElement("div", {
              className: bodyClassName,
              onMouseDown: (function (prim) {
                  prim.stopPropagation();
                })
            }, React.createElement("ul", {
                  ref: Caml_option.some(match[0]),
                  className: Curry._1(Css.merge, {
                        hd: props.optionsClassName,
                        tl: {
                          hd: match[1],
                          tl: /* [] */0
                        }
                      })
                }, Belt_Array.map(props.options, (function (param) {
                        var key = param[0];
                        return React.createElement("li", {
                                    className: optionClassName,
                                    onClick: (function (param) {
                                        Curry._1(onChange, key);
                                      }),
                                    onMouseDown: (function (prim) {
                                        prim.stopPropagation();
                                      })
                                  }, param[1]);
                      }))));
}

var Options = {
  make: FormSelect$Options
};

function useInputBoundingRect(show, selectedValue) {
  var match = ContainerDimensions$LiteracyplanetClientFlags.useDimsWithContent(selectedValue);
  var updateDimensions = match[2];
  React.useLayoutEffect((function (param) {
          Curry._1(updateDimensions, undefined);
        }), [show]);
  return [
          match[0],
          match[1]
        ];
}

function FormSelect(props) {
  var onChange = props.onChange;
  var __defaultValue = props.defaultValue;
  var selectedValue = props.selectedValue;
  var defaultValue = __defaultValue !== undefined ? __defaultValue : "\u00A0";
  var match = UseModalState$LiteracyplanetClientFlags.use();
  var toggleShow = match[1];
  var show = match[0];
  var match$1 = useInputBoundingRect(show, selectedValue);
  var onChangeAndHideOptions = function (key) {
    Curry._1(toggleShow, Curry._1(onChange, key));
  };
  return React.createElement(React.Fragment, {}, React.createElement("div", {
                  ref: Caml_option.some(match$1[0]),
                  className: props.inputClassName,
                  onClick: (function (param) {
                      Curry._1(toggleShow, undefined);
                    })
                }, React.createElement("div", {
                      className: props.inputTextClassName
                    }, Belt_Option.getWithDefault(selectedValue, defaultValue), React.createElement(DropDownIcon$LiteracyplanetClientFlags.make, {
                          className: props.iconClassName
                        })), React.createElement("div", {
                      className: props.borderClassName
                    })), show ? React.createElement(HtmlBody$LiteracyplanetClientFlags.make, {
                    children: React.createElement(FormSelect$Options, {
                          inputDimensions: match$1[1],
                          options: props.options,
                          onChange: onChangeAndHideOptions,
                          optionClassName: props.optionClassName,
                          optionsClassName: props.optionsClassName
                        })
                  }) : null);
}

var make = FormSelect;

export {
  bodyClassName ,
  Options ,
  useInputBoundingRect ,
  make ,
}
/* bodyClassName Not a pure module */
