// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as V2EventsContext$LiteracyplanetTupperware from "./V2EventsContext.res.mjs";
import * as K2ExerciseInSeries$LiteracyplanetTupperware from "./K2ExerciseInSeries.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function K2ExerciseInSeriesContainer(props) {
  var callbacks = V2EventsContext$LiteracyplanetTupperware.use();
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.use();
  var state = match.state;
  return React.createElement(K2ExerciseInSeries$LiteracyplanetTupperware.make, {
              questionCount: props.questionCount,
              questionsComplete: state.questionsComplete,
              callbacks: callbacks,
              setState: match.setState,
              state: state,
              children: props.children
            });
}

var make = K2ExerciseInSeriesContainer;

var $$default = K2ExerciseInSeriesContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
