// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Bucket$LiteracyplanetTupperware from "../Bucket.res.mjs";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";

function SkilfulHighlightBucket(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulHighlightBucket.className
            }, React.createElement(Bucket$LiteracyplanetTupperware.Button.make, {
                  group: props.group,
                  colour: props.colour,
                  children: props.children
                }));
}

var make = SkilfulHighlightBucket;

var $$default = SkilfulHighlightBucket;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
