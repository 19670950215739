// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Luxon from "luxon";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function fromUtcIsoToDateTime(date) {
  return Luxon.DateTime.fromISO(date).toLocal();
}

var decodeDate = (function(dateStr) {
  return new Date(dateStr);
});

function graphqlScalarDateToIso(scalarDate) {
  return decodeDate(scalarDate).toISOString();
}

function graphqlScalarDateToDateTime(scalarDate) {
  return Luxon.DateTime.fromISO(decodeDate(scalarDate).toISOString());
}

function formatDate(date, format) {
  return Belt_Option.mapWithDefault(date, "- - -", (function (d) {
                return Luxon.DateTime.fromISO(decodeDate(d).toISOString()).toFormat(format);
              }));
}

export {
  fromUtcIsoToDateTime ,
  decodeDate ,
  graphqlScalarDateToIso ,
  graphqlScalarDateToDateTime ,
  formatDate ,
}
/* luxon Not a pure module */
