// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Flipper$LiteracyplanetClientSxActivity from "./Flipper.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/LocaleContext.res.mjs";
import * as FlipperCobraWordGameContent$LiteracyplanetClientSxActivity from "./FlipperCobraWordGameContent.res.mjs";

function getMd5(content) {
  if (typeof content !== "object") {
    return ;
  }
  var variant = content.NAME;
  if (variant === "UnsafeData" || variant === "WordGameData") {
    return content.VAL.md5;
  } else if (variant === "WhackAMunkData" || variant === "MonsterChefData" || variant === "SoundTrainData") {
    return ;
  } else if (variant === "TupperwareData") {
    return content.VAL.md5;
  } else if (variant === "InternalStaticData") {
    return content.VAL.md5;
  } else {
    return ;
  }
}

var UnhandledGameContent = /* @__PURE__ */Caml_exceptions.create("Flipper_GameContent-LiteracyplanetClientSxActivity.UnhandledGameContent");

var TupperwareSpellTestParseError = /* @__PURE__ */Caml_exceptions.create("Flipper_GameContent-LiteracyplanetClientSxActivity.TupperwareSpellTestParseError");

function emptyWordGame(__x) {
  return Belt_Result.map(__x, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar);
}

function parse(content) {
  if (typeof content !== "object") {
    return {
            TAG: "Error",
            _0: {
              RE_EXN_ID: UnhandledGameContent
            }
          };
  }
  var variant = content.NAME;
  if (variant === "WordGameData") {
    var __x = FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.parseWordList(content.VAL);
    return Belt_Result.map(Belt_Result.map(__x, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar), (function (t) {
                  return {
                          TAG: "WordListData",
                          _0: t
                        };
                }));
  }
  if (variant === "SoundTrainData") {
    var __x$1 = FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.parseSoundTrain(content.VAL);
    return Belt_Result.map(Belt_Result.map(__x$1, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar), (function (t) {
                  return {
                          TAG: "SoundTrainData",
                          _0: t
                        };
                }));
  }
  if (variant === "UnsafeData") {
    var __x$2 = FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.parseUnsafeData(content.VAL);
    return Belt_Result.map(Belt_Result.map(__x$2, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar), (function (t) {
                  return {
                          TAG: "UnsafeData",
                          _0: t
                        };
                }));
  }
  if (variant === "MonsterChefData") {
    var __x$3 = FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.parseMonsterChef(content.VAL);
    return Belt_Result.map(Belt_Result.map(__x$3, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar), (function (t) {
                  return {
                          TAG: "MonsterChefData",
                          _0: t
                        };
                }));
  }
  if (variant === "InternalStaticData") {
    var __x$4 = FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.parseInternalStatic(content.VAL);
    return Belt_Result.map(Belt_Result.map(__x$4, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar), (function (t) {
                  return {
                          TAG: "InernalStaticData",
                          _0: t
                        };
                }));
  }
  if (variant !== "WhackAMunkData") {
    return {
            TAG: "Error",
            _0: {
              RE_EXN_ID: UnhandledGameContent
            }
          };
  }
  var __x$5 = FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.parseWhackAMunk(content.VAL);
  return Belt_Result.map(Belt_Result.map(__x$5, FlipperCobraWordGameContent$LiteracyplanetClientSxActivity.wordGameAvatar), (function (t) {
                return {
                        TAG: "WhackAMunkData",
                        _0: t
                      };
              }));
}

var Cobra = {
  parse: parse
};

function parseCobra(content, cobraActivity, localeCtx, title) {
  return Belt_Result.map(parse(content), (function (param) {
                return Flipper$LiteracyplanetClientSxActivity.Cobra.mergeCommonProps(cobraActivity, localeCtx, title, param);
              }));
}

function Flipper_GameContent$RenderGameComponent(props) {
  var content = props.content;
  var __hasResultsScreen = props.hasResultsScreen;
  var hasResultsScreen = __hasResultsScreen !== undefined ? __hasResultsScreen : true;
  var localeCtx = LocaleContext$LiteracyplanetClientFlags.use();
  var match = props.game.activityType;
  var match$1 = Curry._1(props.getTitle, props.query);
  if (typeof match !== "object") {
    if (match === "InstructionalVideo") {
      return React.createElement(Flipper$LiteracyplanetClientSxActivity.Video.Render.make, {
                  content: content,
                  title: match$1
                });
    } else {
      return React.createElement(Flipper$LiteracyplanetClientSxActivity.SpellTest.Render.make, {
                  hasResultsScreen: hasResultsScreen,
                  content: content,
                  title: match$1
                });
    }
  }
  if (match.TAG !== "CobraActivity") {
    return React.createElement(Flipper$LiteracyplanetClientSxActivity.Tupperware.Render.make, {
                hasResultsScreen: hasResultsScreen,
                content: content,
                title: match$1
              });
  }
  var cobraActivity = match._0;
  return React.createElement(Flipper$LiteracyplanetClientSxActivity.Cobra.Render.make, {
              props: parseCobra(content, cobraActivity, localeCtx, match$1),
              cobraActivity: cobraActivity,
              title: match$1,
              hasResultsScreen: hasResultsScreen
            });
}

var RenderGameComponent = {
  make: Flipper_GameContent$RenderGameComponent
};

var WordGameDataFragment;

var TupperwareDataFragment;

var InternalStaticDataFragment;

var UnsafeDataFragment;

export {
  WordGameDataFragment ,
  TupperwareDataFragment ,
  InternalStaticDataFragment ,
  UnsafeDataFragment ,
  getMd5 ,
  UnhandledGameContent ,
  TupperwareSpellTestParseError ,
  emptyWordGame ,
  Cobra ,
  parseCobra ,
  RenderGameComponent ,
}
/* react Not a pure module */
