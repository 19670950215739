// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateSerialization$LiteracyplanetTupperware from "../StateSerialization.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function usePersistedState(persistedState, dispatch) {
  var match = React.useState(function (param) {
        return false;
      });
  var setReady = match[1];
  var hydrateExerciseStore = function (stringifiedState) {
    Curry._1(dispatch, {
          TAG: "SetStore",
          _0: StateSerialization$LiteracyplanetTupperware.parseAndDecodeWithDefault(stringifiedState)
        });
    return function (param) {
      Curry._1(dispatch, "ResetStore");
    };
  };
  React.useEffect((function (param) {
          var unsubscribe = Belt_Option.map(persistedState, hydrateExerciseStore);
          Curry._1(setReady, (function (param) {
                  return true;
                }));
          return unsubscribe;
        }), []);
  return match[0];
}

function useModeAndPersistedState(assessment, persistedState) {
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch();
  var dispatch = match[1];
  var ready = usePersistedState(persistedState, dispatch);
  React.useEffect((function (param) {
          if (Belt_Option.isNone(persistedState)) {
            Curry._1(dispatch, {
                  TAG: "SetMode",
                  _0: assessment ? "Assessment" : "Activity"
                });
          }
          
        }), [assessment]);
  return ready;
}

export {
  usePersistedState ,
  useModeAndPersistedState ,
}
/* react Not a pure module */
