// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function InlineStyleClassName(props) {
  return React.createElement("span", {
              className: props.className
            }, props.children);
}

var make = InlineStyleClassName;

var $$default = InlineStyleClassName;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
