// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function VideoIcon(props) {
  return React.createElement("svg", {
              className: "Video-toolbarIcon",
              style: {
                height: "38px",
                marginTop: "2px",
                marginLeft: "20px",
                width: "32px"
              },
              fill: "white",
              viewBox: "0 0 500.35 310.22",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      d: "M480.75,24.14a35.3,35.3,0,0,0-37,3.5L377.48,77.91V61.26L377.37,59A66.25,66.25,0,0,0,311.42,0h-245A66.45,66.45,0,0,0,0,66.37V243.85a66.45,66.45,0,0,0,66.37,66.37h245a66.25,66.25,0,0,0,65.95-59V232.23l66.36,50.35a35.29,35.29,0,0,0,56.61-28.11V55.75A35.28,35.28,0,0,0,480.75,24.14ZM337.09,247.7a25.9,25.9,0,0,1-25.67,22.12h-245a26,26,0,0,1-26-26V66.37a26,26,0,0,1,26-26h245a25.9,25.9,0,0,1,25.67,22.12V247.7Z"
                    })));
}

var make = VideoIcon;

export {
  make ,
}
/* react Not a pure module */
