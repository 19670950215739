// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDnd$LiteracyplanetTupperware from "./ReactDnd.res.mjs";

function V2ClozeChoiceProvider(props) {
  return React.createElement(ReactDnd$LiteracyplanetTupperware.Provider.make, {
              children: props.children
            });
}

var make = V2ClozeChoiceProvider;

var $$default = V2ClozeChoiceProvider;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
