// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GraphqlTag from "graphql-tag";
import * as AvatarFragments$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/AvatarFragments.res.mjs";
import * as CobraAvatarType$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/CobraAvatarType.res.mjs";

var Raw = {};

var query = GraphqlTag.gql(["fragment JourneyStudentMissionFragment on NonOptionalStudentMission   {\n__typename  \nid  \nchest  \naverageAccuracy  \nteacherAssignmentId  \ntitle  \nexpireAt  \nmission  {\n__typename  \nid  \nstrand  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.chest;
  var tmp;
  switch (value$1) {
    case "BLANK" :
        tmp = "BLANK";
        break;
    case "COMMON" :
        tmp = "COMMON";
        break;
    case "GEM" :
        tmp = "GEM";
        break;
    case "MYTHIC" :
        tmp = "MYTHIC";
        break;
    case "RARE" :
        tmp = "RARE";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  var value$2 = value.averageAccuracy;
  var value$3 = value.teacherAssignmentId;
  var value$4 = value.title;
  var value$5 = value.expireAt;
  var value$6 = value.mission;
  return {
          __typename: value.__typename,
          id: value.id,
          chest: tmp,
          averageAccuracy: !(value$2 == null) ? value$2 : undefined,
          teacherAssignmentId: !(value$3 == null) ? value$3 : undefined,
          title: !(value$4 == null) ? value$4 : undefined,
          expireAt: !(value$5 == null) ? value$5 : undefined,
          mission: {
            __typename: value$6.__typename,
            id: value$6.id,
            strand: value$6.strand
          }
        };
}

function serialize(value) {
  var value$1 = value.mission;
  var value$2 = value$1.strand;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var mission = {
    __typename: value$4,
    id: value$3,
    strand: value$2
  };
  var value$5 = value.expireAt;
  var expireAt = value$5 !== undefined ? value$5 : null;
  var value$6 = value.title;
  var title = value$6 !== undefined ? value$6 : null;
  var value$7 = value.teacherAssignmentId;
  var teacherAssignmentId = value$7 !== undefined ? value$7 : null;
  var value$8 = value.averageAccuracy;
  var averageAccuracy = value$8 !== undefined ? value$8 : null;
  var value$9 = value.chest;
  var chest = typeof value$9 === "object" ? value$9.VAL : (
      value$9 === "GEM" ? "GEM" : (
          value$9 === "BLANK" ? "BLANK" : (
              value$9 === "RARE" ? "RARE" : (
                  value$9 === "MYTHIC" ? "MYTHIC" : "COMMON"
                )
            )
        )
    );
  var value$10 = value.id;
  var value$11 = value.__typename;
  return {
          __typename: value$11,
          id: value$10,
          chest: chest,
          averageAccuracy: averageAccuracy,
          teacherAssignmentId: teacherAssignmentId,
          title: title,
          expireAt: expireAt,
          mission: mission
        };
}

function verifyArgsAndParse(_JourneyStudentMissionFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var JourneyStudentMissionFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = GraphqlTag.gql([
      "fragment JourneyCurrentStudentFragment on Student   {\n__typename  \navatar: avatarB  {\n...PlayerEquippedFragment   \n}\n\n}\n",
      ""
    ], AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.query);

function parse$1(value) {
  var value$1 = value.avatar;
  return {
          __typename: value.__typename,
          avatar: !(value$1 == null) ? AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.verifyArgsAndParse("PlayerEquippedFragment", value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.avatar;
  var avatar = value$1 !== undefined ? AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.serialize(value$1) : null;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          avatar: avatar
        };
}

function verifyArgsAndParse$1(_JourneyCurrentStudentFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var JourneyCurrentStudentFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

function makeCurrentStudent(student) {
  return {
          avatar: CobraAvatarType$LiteracyplanetClientFlags.makeOptionalAvatar(student.avatar),
          schoolFont: "aus_default"
        };
}

function chestToString(x) {
  if (typeof x === "object") {
    return x.VAL;
  } else if (x === "GEM") {
    return "GEM";
  } else if (x === "BLANK") {
    return "BLANK";
  } else if (x === "RARE") {
    return "RARE";
  } else if (x === "MYTHIC") {
    return "MYTHIC";
  } else {
    return "COMMON";
  }
}

var PlayerEquippedFragment;

var defaultStudentName = "";

var makeAvatar = CobraAvatarType$LiteracyplanetClientFlags.makeAvatar;

export {
  JourneyStudentMissionFragment ,
  PlayerEquippedFragment ,
  JourneyCurrentStudentFragment ,
  defaultStudentName ,
  makeAvatar ,
  makeCurrentStudent ,
  chestToString ,
}
/* query Not a pure module */
