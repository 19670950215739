// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var defaultSkin = {
  tint: "0xe0e1e3"
};

function makeSkin(skin) {
  return Belt_Option.mapWithDefault(skin, defaultSkin, (function (s) {
                return {
                        tint: s.tint
                      };
              }));
}

function makeSlot(slot) {
  return {
          name: slot.name
        };
}

function makeAvatarItem(item) {
  return {
          textures: item.textures,
          tint: item.tint,
          slot: {
            name: item.slot.name
          }
        };
}

function makeFilteredAvatarItems(items) {
  return Belt_Array.keepMap(items, (function (opt) {
                return Belt_Option.map(opt, makeAvatarItem);
              }));
}

function makeAvatar(avatar) {
  return {
          skin: makeSkin(avatar.skin),
          items: makeFilteredAvatarItems(avatar.items)
        };
}

function makeOptionalAvatar(optAvatar) {
  return Belt_Option.map(optAvatar, makeAvatar);
}

var defaultStudentName = "";

export {
  defaultStudentName ,
  defaultSkin ,
  makeSkin ,
  makeSlot ,
  makeAvatarItem ,
  makeFilteredAvatarItems ,
  makeAvatar ,
  makeOptionalAvatar ,
}
/* No side effect */
