// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FlagsContext$LiteracyplanetClientFlags from "./FlagsContext.res.mjs";

function experienceToString(ex) {
  switch (ex) {
    case "Secondary" :
        return "Secondary";
    case "Journey" :
        return "Journey";
    case "Storyverse" :
        return "Storyverse";
    
  }
}

function navBarToString(ex) {
  switch (ex) {
    case "Modern" :
        return "Modern";
    case "Barless" :
        return "Barless";
    case "Bubble" :
        return "Bubble";
    case "Papyrus" :
        return "Papyrus";
    
  }
}

function menuToString(ex) {
  switch (ex) {
    case "Initials" :
        return "Initials";
    case "JourneyInitials" :
        return "JourneyInitials";
    case "Menuless" :
        return "Menuless";
    
  }
}

function whatExperience(hasFlag) {
  if (Curry._1(hasFlag, "StudentHasSecondary")) {
    return "Secondary";
  } else if (Curry._1(hasFlag, "StudentHasStoryverse")) {
    return "Storyverse";
  } else {
    return "Journey";
  }
}

function whatNav(studentExperience) {
  switch (studentExperience) {
    case "Secondary" :
        return "Modern";
    case "Journey" :
        return "Bubble";
    case "Storyverse" :
        return "Papyrus";
    
  }
}

function whatMenu(studentExperience) {
  switch (studentExperience) {
    case "Secondary" :
        return "Initials";
    case "Journey" :
        return "JourneyInitials";
    case "Storyverse" :
        return "Menuless";
    
  }
}

function api_changeExperience(param) {
  
}

function api_changeMenu(param) {
  
}

function api_changeNavBar(param) {
  
}

var api = {
  whatExperience: "Journey",
  whatMenu: "Menuless",
  whatNavBar: "Barless",
  changeExperience: api_changeExperience,
  changeMenu: api_changeMenu,
  changeNavBar: api_changeNavBar
};

var context = React.createContext(api);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

var changeExperience = whatNav;

function Wrap$Provider(props) {
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var match = React.useReducer((function (state, action) {
          switch (action.TAG) {
            case "SetExperience" :
                var whatExperience = action._0;
                return {
                        whatExperience: whatExperience,
                        whatMenu: whatMenu(whatExperience),
                        whatNavBar: whatNav(whatExperience)
                      };
            case "SetWhatMenu" :
                return {
                        whatExperience: state.whatExperience,
                        whatMenu: action._0,
                        whatNavBar: state.whatNavBar
                      };
            case "SetNavBar" :
                return {
                        whatExperience: state.whatExperience,
                        whatMenu: state.whatMenu,
                        whatNavBar: action._0
                      };
            
          }
        }), {
        whatExperience: whatExperience(hasFlag),
        whatMenu: whatMenu(whatExperience(hasFlag)),
        whatNavBar: whatNav(whatExperience(hasFlag))
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var changeExperience = function (sx) {
    Curry._1(dispatch, {
          TAG: "SetExperience",
          _0: sx
        });
  };
  var changeMenu = function (m) {
    Curry._1(dispatch, {
          TAG: "SetWhatMenu",
          _0: m
        });
  };
  var changeNavBar = function (nb) {
    Curry._1(dispatch, {
          TAG: "SetNavBar",
          _0: nb
        });
  };
  return React.createElement(React.Fragment, {
              children: Caml_option.some(React.createElement(make, {
                        value: {
                          whatExperience: match$1.whatExperience,
                          whatMenu: match$1.whatMenu,
                          whatNavBar: match$1.whatNavBar,
                          changeExperience: changeExperience,
                          changeMenu: changeMenu,
                          changeNavBar: changeNavBar
                        },
                        children: props.children
                      }))
            });
}

var Provider = {
  make: Wrap$Provider
};

function useStudentExperience(param) {
  return React.useContext(context).whatExperience;
}

function useNavBar(param) {
  return React.useContext(context).whatNavBar;
}

function useMenu(param) {
  return React.useContext(context).whatMenu;
}

function useApi(param) {
  return React.useContext(context);
}

var state = {
  whatExperience: "Journey",
  whatMenu: "Menuless",
  whatNavBar: "Barless"
};

export {
  experienceToString ,
  navBarToString ,
  menuToString ,
  whatExperience ,
  whatNav ,
  whatMenu ,
  api ,
  Context ,
  state ,
  changeExperience ,
  Provider ,
  useStudentExperience ,
  useNavBar ,
  useMenu ,
  useApi ,
}
/* context Not a pure module */
