// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import ReactDocumentTitle from "react-document-title";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";
import * as OnError$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/OnError.res.mjs";
import * as Result2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Result2.res.mjs";
import * as MapResult$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/MapResult.res.mjs";
import * as GameTypeData$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/GameTypeData.res.mjs";
import * as SpellTestPage$LiteracyplanetTupperware from "@literacyplanet/tupperware/src/tupperware/v2/SpellTestPage.res.mjs";
import * as ToolbarLayout$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ToolbarLayout.res.mjs";
import * as CobraContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/CobraContainer.res.mjs";
import * as VideoIcon$LiteracyplanetClientSxActivity from "../VideoIcon.res.mjs";
import * as ActivityContainer$LiteracyplanetTupperware from "@literacyplanet/tupperware/src/tupperware/ActivityContainer.res.mjs";
import * as VideoPlayer$LiteracyplanetClientSxActivity from "../VideoPlayer.res.mjs";
import * as FlipperStore$LiteracyplanetClientSxActivity from "./FlipperStore.res.mjs";
import * as FlipperTypes$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/FlipperTypes.res.mjs";
import * as AvatarContext$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/AvatarContext.res.mjs";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SoundProviderEmbedded.res.mjs";
import * as FlipperCobraCommon$LiteracyplanetClientSxActivity from "./FlipperCobraCommon.res.mjs";
import * as FlipperVideoDispatch$LiteracyplanetClientSxActivity from "./FlipperVideoDispatch.res.mjs";
import * as FlipperCobraCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraCallbacks.res.mjs";
import * as FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraResultsCallbacks.res.mjs";
import * as TupperwareContentQueryParser$LiteracyplanetClientLibsActivity from "@literacyplanet/client-libs-activity/src/TupperwareContentQueryParser.res.mjs";

function Flipper$PureComponents$Ui$FullScreen(props) {
  return React.createElement("div", {
              style: {
                background: props.backgroundColor,
                height: "100%",
                overflowX: "hidden",
                position: "absolute",
                width: "100%"
              }
            }, props.children);
}

var FullScreen = {
  make: Flipper$PureComponents$Ui$FullScreen
};

function Flipper$PureComponents$Ui$Show(props) {
  if (props.visible) {
    return props.children;
  } else {
    return null;
  }
}

var Show = {
  make: Flipper$PureComponents$Ui$Show
};

function Flipper$PureComponents$Ui$Visible(props) {
  return React.createElement("div", {
              style: {
                display: props.visible ? "inherit" : "none"
              }
            }, props.children);
}

var Visible = {
  make: Flipper$PureComponents$Ui$Visible
};

function Flipper$PureComponents$Ui$OffScreen(props) {
  return React.createElement("div", {
              style: {
                background: props.backgroundColor,
                height: "100%",
                left: "0px",
                overflow: "hidden",
                position: "absolute",
                top: props.visible ? "0px" : "-10000px",
                width: "100%"
              }
            }, props.children);
}

var OffScreen = {
  make: Flipper$PureComponents$Ui$OffScreen
};

var Ui = {
  FullScreen: FullScreen,
  Show: Show,
  Visible: Visible,
  OffScreen: OffScreen
};

function Flipper$PureComponents$ResultsComponent(props) {
  if (props.loadResults) {
    return React.createElement(Flipper$PureComponents$Ui$OffScreen, {
                visible: props.showResults,
                backgroundColor: "#000000",
                children: React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                      view: "results",
                      props: props.resultsProps,
                      onDispatch: props.resultsDispatch
                    })
              });
  } else {
    return null;
  }
}

var ResultsComponent = {
  make: Flipper$PureComponents$ResultsComponent
};

function Flipper$PureComponents$TupperwareComponent(props) {
  return React.createElement(ReactDocumentTitle, {
              title: props.title,
              children: React.createElement(React.Fragment, {}, React.createElement(Flipper$PureComponents$Ui$FullScreen, {
                        backgroundColor: props.backgroundColor,
                        children: React.createElement(Flipper$PureComponents$Ui$Visible, {
                              visible: props.visible,
                              children: props.children
                            })
                      }), props.results)
            });
}

var TupperwareComponent = {
  make: Flipper$PureComponents$TupperwareComponent
};

function Flipper$PureComponents$CobraComponent(props) {
  return React.createElement(ReactDocumentTitle, {
              title: props.title,
              children: React.createElement(React.Fragment, {}, React.createElement(Flipper$PureComponents$Ui$FullScreen, {
                        backgroundColor: props.activityLoaded ? "#090E12" : "#FFFFFF",
                        children: React.createElement(Flipper$PureComponents$Ui$Show, {
                              visible: props.visible,
                              children: React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                                    view: props.cobraView,
                                    props: props.activityProps,
                                    onDispatch: props.activityDispatch
                                  })
                            })
                      }), props.results)
            });
}

var CobraComponent = {
  make: Flipper$PureComponents$CobraComponent
};

var PureComponents = {
  Ui: Ui,
  ResultsComponent: ResultsComponent,
  TupperwareComponent: TupperwareComponent,
  CobraComponent: CobraComponent
};

var FlipperCantFindGameById = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.CommonUtil.FlipperCantFindGameById");

function makeCobraResults(state) {
  return {
          currentStudent: {},
          results: {
            percentCorrect: state.percentCorrect,
            chest: "GEM",
            needsToRefreshDivOffsets: state.showResults,
            needsToShowMissionComplete: state.needsToShowMissionComplete
          }
        };
}

var CommonUtil = {
  FlipperCantFindGameById: FlipperCantFindGameById,
  makeCobraResults: makeCobraResults
};

function Flipper$CobraResultsContainer(props) {
  var resultsDispatch = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Context.use();
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState();
  var resultsProps = makeCobraResults(state);
  return React.createElement(Flipper$PureComponents$ResultsComponent, {
              loadResults: state.loadResults,
              showResults: state.showResults,
              resultsProps: resultsProps,
              resultsDispatch: resultsDispatch
            });
}

var CobraResultsContainer = {
  make: Flipper$CobraResultsContainer
};

function Flipper$CobraWordGameComponentContainer(props) {
  var activityProps = props.activityProps;
  var activityDispatch = FlipperCobraCallbacks$LiteracyplanetClientSxActivity.Context.use();
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState();
  var avatar = Curry._1(AvatarContext$LiteracyplanetClientLibsActivity.use, undefined);
  var activityProps_currentStudent = {
    avatar: avatar
  };
  var activityProps_questionIndex = state.numComplete;
  var activityProps_numCorrect = state.numCorrect;
  var activityProps_activityMetadata = activityProps.activityMetadata;
  var activityProps_extraVocals = activityProps.extraVocals;
  var activityProps_wordGameContent = activityProps.wordGameContent;
  var activityProps$1 = {
    currentStudent: activityProps_currentStudent,
    questionIndex: activityProps_questionIndex,
    numCorrect: activityProps_numCorrect,
    activityMetadata: activityProps_activityMetadata,
    extraVocals: activityProps_extraVocals,
    wordGameContent: activityProps_wordGameContent
  };
  var cobraView = props.cobraActivity.cobraView;
  if (state.initialised) {
    return React.createElement(Flipper$PureComponents$CobraComponent, {
                title: props.title,
                visible: !state.showResults,
                activityLoaded: state.activityLoaded,
                cobraView: cobraView,
                activityProps: activityProps$1,
                activityDispatch: activityDispatch,
                results: Curry._1(props.renderResultsContainer, undefined)
              });
  } else {
    return null;
  }
}

var CobraWordGameComponentContainer = {
  make: Flipper$CobraWordGameComponentContainer
};

function mergeProps(props, extraVocals, activityMetadata) {
  switch (props.TAG) {
    case "WordListData" :
        var activityProps = props._0;
        return {
                TAG: "WordListData",
                _0: {
                  currentStudent: activityProps.currentStudent,
                  questionIndex: activityProps.questionIndex,
                  numCorrect: activityProps.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps.wordGameContent
                }
              };
    case "SoundTrainData" :
        var activityProps$1 = props._0;
        return {
                TAG: "SoundTrainData",
                _0: {
                  currentStudent: activityProps$1.currentStudent,
                  questionIndex: activityProps$1.questionIndex,
                  numCorrect: activityProps$1.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$1.wordGameContent
                }
              };
    case "WhackAMunkData" :
        var activityProps$2 = props._0;
        return {
                TAG: "WhackAMunkData",
                _0: {
                  currentStudent: activityProps$2.currentStudent,
                  questionIndex: activityProps$2.questionIndex,
                  numCorrect: activityProps$2.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$2.wordGameContent
                }
              };
    case "MonsterChefData" :
        var activityProps$3 = props._0;
        return {
                TAG: "MonsterChefData",
                _0: {
                  currentStudent: activityProps$3.currentStudent,
                  questionIndex: activityProps$3.questionIndex,
                  numCorrect: activityProps$3.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$3.wordGameContent
                }
              };
    case "InernalStaticData" :
        var activityProps$4 = props._0;
        return {
                TAG: "InernalStaticData",
                _0: {
                  currentStudent: activityProps$4.currentStudent,
                  questionIndex: activityProps$4.questionIndex,
                  numCorrect: activityProps$4.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$4.wordGameContent
                }
              };
    case "UnsafeData" :
        var activityProps$5 = props._0;
        return {
                TAG: "UnsafeData",
                _0: {
                  currentStudent: activityProps$5.currentStudent,
                  questionIndex: activityProps$5.questionIndex,
                  numCorrect: activityProps$5.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$5.wordGameContent
                }
              };
    
  }
}

function mergeCommonProps(cobraActivity, localeCtx, title, props) {
  return mergeProps(props, FlipperCobraCommon$LiteracyplanetClientSxActivity.GameInstructions.parse(cobraActivity, localeCtx), {
              title: title
            });
}

function Flipper$Cobra$RenderComponent(props) {
  var hasResultsScreen = props.hasResultsScreen;
  var title = props.title;
  var cobraActivity = props.cobraActivity;
  var props$1 = props.props;
  switch (props$1.TAG) {
    case "WordListData" :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props$1._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case "SoundTrainData" :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props$1._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case "WhackAMunkData" :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props$1._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case "MonsterChefData" :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props$1._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case "InernalStaticData" :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props$1._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case "UnsafeData" :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props$1._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    
  }
}

var RenderComponent = {
  make: Flipper$Cobra$RenderComponent
};

function Flipper$Cobra$Render(props) {
  var __hasResultsScreen = props.hasResultsScreen;
  var title = props.title;
  var cobraActivity = props.cobraActivity;
  var hasResultsScreen = __hasResultsScreen !== undefined ? __hasResultsScreen : true;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: Belt_Result.flatMap(props.props, (function (param) {
                      return FlipperTypes$LiteracyplanetClientLibsActivity.Cobra.validate(cobraActivity, param);
                    })),
              onOk: (function (props) {
                  return React.createElement(Flipper$Cobra$RenderComponent, {
                              props: props,
                              cobraActivity: cobraActivity,
                              title: title,
                              hasResultsScreen: hasResultsScreen
                            });
                })
            });
}

var Render = {
  make: Flipper$Cobra$Render
};

var Cobra = {
  mergeProps: mergeProps,
  mergeCommonProps: mergeCommonProps,
  RenderComponent: RenderComponent,
  Render: Render
};

function Flipper$TupperwareContainer(props) {
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState();
  var visible = !state.showResults;
  var backgroundColor = state.backgroundColor;
  var c = React.createElement(Flipper$PureComponents$TupperwareComponent, {
        title: props.title,
        visible: visible,
        backgroundColor: backgroundColor,
        results: props.results,
        children: React.createElement(ActivityContainer$LiteracyplanetTupperware.make, {
              data: props.data
            })
      });
  return React.createElement(SoundProviderEmbedded$LiteracyplanetClientFlags.make, {
              children: c
            });
}

var TupperwareContainer = {
  make: Flipper$TupperwareContainer
};

var FlipperTupperwareDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.Tupperware.FlipperTupperwareDataNotValid");

function parse(content) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.getTupperwareData(content), {
              RE_EXN_ID: FlipperTupperwareDataNotValid
            });
}

function Flipper$Tupperware$Render(props) {
  var title = props.title;
  var __hasResultsScreen = props.hasResultsScreen;
  var hasResultsScreen = __hasResultsScreen !== undefined ? __hasResultsScreen : true;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: parse(props.content),
              onOk: (function (param) {
                  return React.createElement(Flipper$TupperwareContainer, {
                              data: param[0],
                              title: title,
                              results: hasResultsScreen ? React.createElement(Flipper$CobraResultsContainer, {}) : null
                            });
                })
            });
}

var Render$1 = {
  make: Flipper$Tupperware$Render
};

var Tupperware = {
  FlipperTupperwareDataNotValid: FlipperTupperwareDataNotValid,
  parse: parse,
  isSkilfulLayout: TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.isSkilfulLayout,
  Render: Render$1
};

var pageStyles = {
  backgroundColor: "000",
  color: "0C9CE5",
  activeColor: "723C8D",
  hoverColor: "0A88E0",
  cover: false
};

function Flipper$VideoComponentContainer(props) {
  var title = props.title;
  var callbacks = FlipperVideoDispatch$LiteracyplanetClientSxActivity.Context.use();
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(ToolbarLayout$LiteracyplanetClientFlags.make, {
                    icon: React.createElement(VideoIcon$LiteracyplanetClientSxActivity.make, {}),
                    title: title,
                    exitTo: callbacks.onToolbarExit,
                    pageStyles: pageStyles,
                    children: React.createElement("div", {
                          style: {
                            padding: "1rem"
                          }
                        }, React.createElement(VideoPlayer$LiteracyplanetClientSxActivity.make, {
                              source: props.source,
                              callbacks: callbacks
                            }))
                  })
            });
}

var VideoComponentContainer = {
  pageStyles: pageStyles,
  make: Flipper$VideoComponentContainer
};

var FlipperVideoDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.Video.FlipperVideoDataNotValid");

function Flipper$Video$Render(props) {
  var title = props.title;
  var content = props.content;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: Result2$LiteracyplanetClientFlags.Opt.toResult(typeof content === "object" && content.NAME === "VideoData" ? Belt_Option.map(Belt_Option.flatMap(content.VAL.video, (function (v) {
                                return Belt_Array.get(v.sources, 0);
                              })), (function (v) {
                            return v.source;
                          })) : undefined, {
                    RE_EXN_ID: FlipperVideoDataNotValid
                  }),
              onOk: (function (source) {
                  return React.createElement(Flipper$VideoComponentContainer, {
                              source: source,
                              title: title
                            });
                })
            });
}

var Render$2 = {
  make: Flipper$Video$Render
};

var Video = {
  FlipperVideoDataNotValid: FlipperVideoDataNotValid,
  Render: Render$2
};

function Flipper$SpellTestComponentContainer(props) {
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState();
  var visible = !state.showResults;
  var backgroundColor = state.backgroundColor;
  return React.createElement(Flipper$PureComponents$TupperwareComponent, {
              title: props.title,
              visible: visible,
              backgroundColor: backgroundColor,
              results: props.resultsContainer,
              children: React.createElement(SpellTestPage$LiteracyplanetTupperware.make, {
                    list: props.list
                  })
            });
}

var SpellTestComponentContainer = {
  make: Flipper$SpellTestComponentContainer
};

var FlipperSpellTestDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.SpellTest.FlipperSpellTestDataNotValid");

function Flipper$SpellTest$Render(props) {
  var title = props.title;
  var content = props.content;
  var __hasResultsScreen = props.hasResultsScreen;
  var hasResultsScreen = __hasResultsScreen !== undefined ? __hasResultsScreen : true;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: Result2$LiteracyplanetClientFlags.Opt.toResult(typeof content === "object" && content.NAME === "WordGameData" ? content.VAL.list : undefined, {
                    RE_EXN_ID: FlipperSpellTestDataNotValid
                  }),
              onOk: (function (list) {
                  return React.createElement(Flipper$SpellTestComponentContainer, {
                              resultsContainer: hasResultsScreen ? React.createElement(Flipper$CobraResultsContainer, {}) : null,
                              list: list,
                              title: title
                            });
                })
            });
}

var Render$3 = {
  make: Flipper$SpellTest$Render
};

var SpellTest = {
  FlipperSpellTestDataNotValid: FlipperSpellTestDataNotValid,
  Render: Render$3
};

function Flipper$CallError(props) {
  var e = props.e;
  var match = Error$LiteracyplanetClientErrors.useErrorModal();
  var onSetError = match.onSetError;
  React.useEffect((function (param) {
          console.log(e);
          Curry._1(onSetError, {
                TAG: "Exception",
                _0: e
              });
        }), []);
  return null;
}

var CallError = {
  make: Flipper$CallError
};

function Flipper$Container(props) {
  var onOk = props.onOk;
  var queryState = props.queryState;
  var getGameId = props.getGameId;
  var __onError = props.onError;
  var onError = __onError !== undefined ? __onError : (function (e) {
        return React.createElement(Flipper$CallError, {
                    e: e
                  });
      });
  var tmp;
  if (typeof queryState !== "object") {
    tmp = null;
  } else {
    var query = queryState._0;
    tmp = React.createElement(MapResult$LiteracyplanetClientFlags.make, {
          result: Curry._1(props.getActivity, query),
          onOk: (function (content) {
              return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
                          result: Belt_Result.flatMap(Curry._1(getGameId, query), (function (game) {
                                  return Result2$LiteracyplanetClientFlags.Opt.toResult(GameTypeData$LiteracyplanetClientFlags.findByGameId(game), {
                                              RE_EXN_ID: FlipperCantFindGameById
                                            });
                                })),
                          onOk: (function (game) {
                              return Curry._3(onOk, game, content, query);
                            })
                        });
            })
        });
  }
  return React.createElement(OnError$LiteracyplanetClientFlags.Provider.make, {
              value: onError,
              children: tmp
            });
}

var Container = {
  make: Flipper$Container
};

export {
  PureComponents ,
  CommonUtil ,
  CobraResultsContainer ,
  CobraWordGameComponentContainer ,
  Cobra ,
  TupperwareContainer ,
  Tupperware ,
  VideoComponentContainer ,
  Video ,
  SpellTestComponentContainer ,
  SpellTest ,
  CallError ,
  Container ,
}
/* react Not a pure module */
