// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as OnError$LiteracyplanetClientFlags from "./OnError.res.mjs";

function MapResult(props) {
  var __onOk = props.onOk;
  var result = props.result;
  var onOk = __onOk !== undefined ? __onOk : (function (param) {
        return null;
      });
  var onError = Curry._1(OnError$LiteracyplanetClientFlags.use, undefined);
  if (result.TAG === "Ok") {
    return Curry._1(onOk, result._0);
  } else {
    return Curry._1(onError, result._0);
  }
}

var make = MapResult;

export {
  make ,
}
/* OnError-LiteracyplanetClientFlags Not a pure module */
