// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function OpenBookIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 510 510",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "m42.05 368.101v-221.49c-10.958-.432-21.6-.78-31.75-1.091-5.64-.172-10.3 4.353-10.3 9.996v257.99c0 5.314 4.149 9.7 9.456 9.985 52.861 2.848 122.506 5.886 188.124 21.779-48.97-37.74-102.57-54.79-143.29-62.43-7.1-1.329-12.24-7.529-12.24-14.739z"
                }), React.createElement("path", {
                  d: "m467.95 144.791v223.31c0 7.21-5.14 13.41-12.24 14.74-40.72 7.64-94.32 24.69-143.29 62.43 65.517-15.871 134.984-18.917 188.124-21.78 5.307-.286 9.456-4.671 9.456-9.985v-259.081c0-5.515-4.462-9.992-9.977-10-8.87-.013-19.774.07-32.073.366z"
                }), React.createElement("path", {
                  d: "m72.05 74.729v281.122c49.59 10.9 113.11 34.67 167.95 86.16v-295.02c-34.8-43.752-99.31-71.63-156.098-82.092-6.162-1.135-11.852 3.564-11.852 9.83zm124.64 229.042c-4.315 7.059-13.546 9.299-20.62 4.98-8.11-4.95-28.87-16.78-46.66-19.79-8.17-1.38-13.67-9.12-12.29-17.29s9.12-13.67 17.29-12.29c22.11 3.73 45.39 16.49 57.29 23.77 7.07 4.32 9.3 13.55 4.99 20.62zm.34-94.83c-4.732 6.798-14.104 8.454-20.88 3.73-9.77-6.8-27.87-15.88-46.74-19.07-8.17-1.38-13.67-9.12-12.29-17.29s9.12-13.67 17.29-12.29c23.74 4.01 45.99 15.05 58.89 24.04 6.8 4.73 8.47 14.08 3.73 20.88z"
                }), React.createElement("path", {
                  d: "m426.099 64.9c-56.513 10.423-121.33 38.251-156.099 82.091v295.02c54.84-51.49 118.36-75.26 167.95-86.16v-281.12c0-6.266-5.69-10.967-11.851-9.831zm-45.509 224.061c-17.79 3.01-38.55 14.84-46.66 19.79-7.075 4.32-16.306 2.077-20.62-4.98-4.31-7.07-2.08-16.3 4.99-20.62 11.9-7.28 35.18-20.04 57.29-23.77 8.17-1.39 15.91 4.12 17.29 12.29s-4.12 15.91-12.29 17.29zm0-95.36c-18.87 3.19-36.97 12.27-46.74 19.07-6.77 4.72-16.143 3.074-20.88-3.73-4.74-6.8-3.07-16.15 3.73-20.88 12.9-8.99 35.15-20.03 58.89-24.04 8.17-1.39 15.91 4.12 17.29 12.29s-4.12 15.91-12.29 17.29z"
                }));
}

var make = OpenBookIcon;

export {
  make ,
}
/* react Not a pure module */
