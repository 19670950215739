// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

function useMarkCallback(initialized, setMarkCb, fuzzyMark, correctAnswer, questionIndex, callbacks) {
  React.useEffect((function (param) {
          if (initialized) {
            return Curry._3(setMarkCb, (function (param) {
                          return {
                                  TAG: "MarkQuestion",
                                  _0: fuzzyMark,
                                  _1: correctAnswer,
                                  _2: questionIndex
                                };
                        }), questionIndex, correctAnswer);
          }
          
        }), [initialized]);
}

function BehaviourMultiChoiceMarkCallback(props) {
  var __showCorrectAnswerBehaviour = props.showCorrectAnswerBehaviour;
  var __fuzzyMark = props.fuzzyMark;
  var questionIndex = props.questionIndex;
  var fuzzyMark = __fuzzyMark !== undefined ? __fuzzyMark : false;
  var showCorrectAnswerBehaviour = __showCorrectAnswerBehaviour !== undefined ? __showCorrectAnswerBehaviour : false;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var setShowCorrectAnswerForQuestion = match.setShowCorrectAnswerForQuestion;
  React.useEffect((function (param) {
          Curry._2(setShowCorrectAnswerForQuestion, questionIndex, showCorrectAnswerBehaviour);
        }), []);
  useMarkCallback(true, match.setMarkCb, fuzzyMark, props.correctAnswer, questionIndex, props.callbacks);
  return props.children;
}

var make = BehaviourMultiChoiceMarkCallback;

export {
  useMarkCallback ,
  make ,
}
/* react Not a pure module */
