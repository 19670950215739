// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function api_onClickMissions(param) {
  
}

function api_onClickCollections(param) {
  
}

function api_onClickShop(param) {
  
}

function api_onClickLpClassic(param) {
  
}

function api_onClickWordMania(param) {
  
}

function api_onClickLogout(param) {
  
}

var api = {
  onClickMissions: api_onClickMissions,
  onClickCollections: api_onClickCollections,
  onClickShop: api_onClickShop,
  onClickLpClassic: api_onClickLpClassic,
  onClickWordMania: api_onClickWordMania,
  onClickLogout: api_onClickLogout
};

var context = React.createContext(api);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Layout = {
  api: api,
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function api_onClickActivity(param, param$1, param$2) {
  
}

function api_onClickMissionFilter(param) {
  
}

var api$1 = {
  onClickActivity: api_onClickActivity,
  onClickMissionFilter: api_onClickMissionFilter
};

var context$1 = React.createContext(api$1);

function makeProps$1(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make$1 = context$1.Provider;

function use$1(param) {
  return React.useContext(context$1);
}

var Missions = {
  api: api$1,
  context: context$1,
  makeProps: makeProps$1,
  make: make$1,
  use: use$1
};

export {
  Layout ,
  Missions ,
}
/* context Not a pure module */
