// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function use(param) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var match$1 = V2ExerciseStateStoreContext$LiteracyplanetTupperware.use();
  if (match$1.state.mode === "Assessment") {
    return "AssessmentMode";
  }
  if (match.activityEnded) {
    return "QuestionMarked";
  }
  var tmp = match.currentQuestionStep;
  if (typeof tmp !== "object") {
    return "QuestionNotAttempted";
  } else if (tmp.TAG === "Attempted") {
    return {
            TAG: "QuestionAttempted",
            _0: match.onMarkActivityQuestion
          };
  } else {
    return "QuestionMarked";
  }
}

export {
  use ,
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
