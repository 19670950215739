// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as GameTypeData$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/GameTypeData.res.mjs";

function getLayout(tupperware) {
  return tupperware.layout;
}

function formatAsBlock(block) {
  return "{\"document\":{\"nodes\":[" + block + "]}}";
}

function getContent(tupperware) {
  return formatAsBlock(tupperware.content);
}

function getTupperwareData(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "TupperwareData") {
    return ;
  }
  var tupperware = content.VAL;
  return [
          formatAsBlock(tupperware.content),
          tupperware.layout
        ];
}

function isSpellTest(content) {
  if (typeof content === "object" && content.NAME === "WordGameData") {
    return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(content.VAL.kind, GameTypeData$LiteracyplanetClientFlags.findByGameId), (function (game) {
                      return game.activityType === "TupperwareSpellTestActivity";
                    })), false);
  } else {
    return false;
  }
}

function isSkilfulLayout(content) {
  if (Belt_Option.getWithDefault(Belt_Option.map(getTupperwareData(content), (function (param) {
                return param[1] === "Skilful";
              })), false)) {
    return true;
  } else {
    return isSpellTest(content);
  }
}

function highestAnsweredQuestion(persistedState) {
  var highestQuestionIndex = function (qs) {
    return Js_array.reduce((function (qIdx, questionIndex) {
                  if (questionIndex > qIdx) {
                    return questionIndex;
                  } else {
                    return qIdx;
                  }
                }), 0, qs);
  };
  var questionIndex = S$RescriptSchema.object(function (s) {
        return s.f("questionIndex", S$RescriptSchema.$$int);
      });
  var questionStatesQuestionIndexes = S$RescriptSchema.object(function (s) {
        return s.f("questionStates", S$RescriptSchema.array(questionIndex));
      });
  return Belt_Option.mapWithDefault(persistedState, 1, (function (ps) {
                var tmp;
                try {
                  tmp = S$RescriptSchema.parseJsonStringOrThrow(ps, questionStatesQuestionIndexes);
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  console.log("deserialize error StateSerialization");
                  console.log(e);
                  tmp = [];
                }
                var idx = highestQuestionIndex(tmp);
                return idx + 1 | 0;
              }));
}

var GameContentFragments;

var GameTypeData;

export {
  GameContentFragments ,
  GameTypeData ,
  getLayout ,
  formatAsBlock ,
  getContent ,
  getTupperwareData ,
  isSpellTest ,
  isSkilfulLayout ,
  highestAnsweredQuestion ,
}
/* S-RescriptSchema Not a pure module */
