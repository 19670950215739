// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FireLater$LiteracyplanetClientSxActivity from "./FireLater.res.mjs";
import * as FlipperStoreTypes$LiteracyplanetClientSxActivity from "./FlipperStoreTypes.res.mjs";

var initialState = {
  backgroundColor: "#FFFFFF",
  loadResults: false,
  showResults: false,
  activityLoaded: false,
  numCorrect: 0,
  numComplete: 0,
  exited: false,
  exitedToMissions: false,
  exitedToCollections: false,
  percentCorrect: 0,
  initialised: false,
  needsToShowMissionComplete: false
};

function calcPercent(numerator, denominator) {
  return Math.ceil(numerator / denominator * 100) | 0;
}

function calculateResults(state) {
  return {
          backgroundColor: state.backgroundColor,
          loadResults: state.loadResults,
          showResults: state.showResults,
          activityLoaded: state.activityLoaded,
          numCorrect: state.numCorrect,
          numComplete: state.numComplete,
          exited: state.exited,
          exitedToMissions: state.exitedToMissions,
          exitedToCollections: state.exitedToCollections,
          percentCorrect: calcPercent(state.numCorrect, state.numComplete),
          initialised: state.initialised,
          needsToShowMissionComplete: state.needsToShowMissionComplete
        };
}

function reducer(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "ActivityCompleted" :
          return calculateResults(state);
      case "ActivityReachedEnd" :
          return calculateResults({
                      backgroundColor: state.backgroundColor,
                      loadResults: true,
                      showResults: state.showResults,
                      activityLoaded: state.activityLoaded,
                      numCorrect: state.numCorrect,
                      numComplete: state.numComplete,
                      exited: state.exited,
                      exitedToMissions: state.exitedToMissions,
                      exitedToCollections: state.exitedToCollections,
                      percentCorrect: state.percentCorrect,
                      initialised: state.initialised,
                      needsToShowMissionComplete: state.needsToShowMissionComplete
                    });
      case "ActivityReady" :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: true,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "Exit" :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: true,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "ExitToCollections" :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: true,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "ExitToMissions" :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: true,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "ResultsReady" :
          return calculateResults({
                      backgroundColor: state.backgroundColor,
                      loadResults: state.loadResults,
                      showResults: true,
                      activityLoaded: state.activityLoaded,
                      numCorrect: state.numCorrect,
                      numComplete: state.numComplete,
                      exited: state.exited,
                      exitedToMissions: state.exitedToMissions,
                      exitedToCollections: state.exitedToCollections,
                      percentCorrect: state.percentCorrect,
                      initialised: state.initialised,
                      needsToShowMissionComplete: state.needsToShowMissionComplete
                    });
      default:
        return state;
    }
  } else {
    switch (action.TAG) {
      case "InitialiseStore" :
          return action._0;
      case "QuestionFailed" :
          var question = action._0;
          var numComplete = question > state.numComplete ? question : state.numComplete;
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "QuestionPassed" :
          var question$1 = action._0;
          var numComplete$1 = question$1 > state.numComplete ? question$1 : state.numComplete;
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect + 1 | 0,
                  numComplete: numComplete$1,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "BulkQuestions" :
          var questions = action._0;
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: Belt_Array.reduce(questions, 0, (function (total, correct) {
                          if (correct) {
                            return total + 1 | 0;
                          } else {
                            return total;
                          }
                        })),
                  numComplete: questions.length,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case "PreloaderReady" :
          return {
                  backgroundColor: action._0.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      
    }
  }
}

var Later = FireLater$LiteracyplanetClientSxActivity.Make({});

function useState(param) {
  return Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined)[0];
}

function initaliseState(needsToShowMissionComplete, numComplete, numCorrect) {
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var dispatch = match[1];
  var state = match[0];
  var fire = Curry._1(Later.use, undefined);
  React.useEffect((function (param) {
          Curry._1(dispatch, {
                TAG: "InitialiseStore",
                _0: {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: numCorrect,
                  numComplete: numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: true,
                  needsToShowMissionComplete: needsToShowMissionComplete
                }
              });
        }), []);
  return [
          state,
          (function ($$event) {
              Curry._1(fire, {
                    TAG: "FireSoonish",
                    _0: (function (param) {
                        Curry._1(dispatch, $$event);
                      })
                  });
            })
        ];
}

var Context = {
  useState: useState,
  initaliseState: initaliseState
};

function FlipperStore$Provider(props) {
  var match = React.useReducer(reducer, initialState);
  return React.createElement(Later.make, {
              children: React.createElement(FlipperStoreTypes$LiteracyplanetClientSxActivity.Provider.make, {
                    value: [
                      match[0],
                      match[1]
                    ],
                    children: props.children
                  })
            });
}

var Provider = {
  make: FlipperStore$Provider
};

function FlipperStore$ProviderWithExit$Exit(props) {
  var onExit = props.onExit;
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var state = match[0];
  React.useEffect((function (param) {
          if (state.exited) {
            Curry._1(onExit, undefined);
          }
          
        }), [state.exited]);
  return props.children;
}

var Exit = {
  make: FlipperStore$ProviderWithExit$Exit
};

function FlipperStore$ProviderWithExit(props) {
  return React.createElement(FlipperStore$Provider, {
              children: React.createElement(FlipperStore$ProviderWithExit$Exit, {
                    onExit: props.onExit,
                    children: props.children
                  })
            });
}

var ProviderWithExit = {
  Exit: Exit,
  make: FlipperStore$ProviderWithExit
};

function FlipperStore$ProviderWithMissionExit$Exit(props) {
  var onMaybeGotoCollections = props.onMaybeGotoCollections;
  var onGoToMissions = props.onGoToMissions;
  var onExit = props.onExit;
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var state = match[0];
  React.useEffect((function (param) {
          if (state.exited) {
            Curry._1(onExit, undefined);
          }
          
        }), [state.exited]);
  React.useEffect((function (param) {
          if (state.exitedToMissions) {
            Curry._1(onGoToMissions, undefined);
          }
          
        }), [state.exitedToMissions]);
  React.useEffect((function (param) {
          if (state.exitedToCollections) {
            Curry._1(onMaybeGotoCollections, (function (param) {
                    
                  }));
          }
          
        }), [state.exitedToCollections]);
  return props.children;
}

var Exit$1 = {
  make: FlipperStore$ProviderWithMissionExit$Exit
};

function FlipperStore$ProviderWithMissionExit(props) {
  return React.createElement(FlipperStore$Provider, {
              children: React.createElement(FlipperStore$ProviderWithMissionExit$Exit, {
                    onExit: props.onExit,
                    onGoToMissions: props.onGoToMissions,
                    onMaybeGotoCollections: props.onMaybeGotoCollections,
                    children: props.children
                  })
            });
}

var ProviderWithMissionExit = {
  Exit: Exit$1,
  make: FlipperStore$ProviderWithMissionExit
};

export {
  initialState ,
  calcPercent ,
  calculateResults ,
  reducer ,
  Later ,
  Context ,
  Provider ,
  ProviderWithExit ,
  ProviderWithMissionExit ,
}
/* Later Not a pure module */
