// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as AspectRatio$LiteracyplanetClientFlags from "./AspectRatio.res.mjs";

var primaryColor = Css.hex("0C9CE5");

var hoverColor = Css.hex("0077b3");

var activeColor = Css.hex("005580");

var defaultGreen = Css.hex("1ECC11");

var hoverGreen = Css.hex("00802a");

var activeGreen = Css.hex("006622");

var dims = AspectRatio$LiteracyplanetClientFlags.dims;

var toVh = AspectRatio$LiteracyplanetClientFlags.toVh;

var toVw = AspectRatio$LiteracyplanetClientFlags.toVw;

var dimensions = AspectRatio$LiteracyplanetClientFlags.dimensions;

var dimensionsHover = AspectRatio$LiteracyplanetClientFlags.dimensionsHover;

var dimensionsActive = AspectRatio$LiteracyplanetClientFlags.dimensionsActive;

var textStroke = AspectRatio$LiteracyplanetClientFlags.textStroke;

var mapProps = AspectRatio$LiteracyplanetClientFlags.mapProps;

var resizeList = AspectRatio$LiteracyplanetClientFlags.resizeList;

var resize = AspectRatio$LiteracyplanetClientFlags.resize;

var pl = AspectRatio$LiteracyplanetClientFlags.pl;

var paddingLeft = AspectRatio$LiteracyplanetClientFlags.paddingLeft;

var pr = AspectRatio$LiteracyplanetClientFlags.pr;

var paddingRight = AspectRatio$LiteracyplanetClientFlags.paddingRight;

var pt = AspectRatio$LiteracyplanetClientFlags.pt;

var paddingTop = AspectRatio$LiteracyplanetClientFlags.paddingTop;

var pb = AspectRatio$LiteracyplanetClientFlags.pb;

var paddingBottom = AspectRatio$LiteracyplanetClientFlags.paddingBottom;

var ft = AspectRatio$LiteracyplanetClientFlags.ft;

var fontSize = AspectRatio$LiteracyplanetClientFlags.fontSize;

var pd = AspectRatio$LiteracyplanetClientFlags.pd;

var padding = AspectRatio$LiteracyplanetClientFlags.padding;

var br = AspectRatio$LiteracyplanetClientFlags.br;

var borderRadius = AspectRatio$LiteracyplanetClientFlags.borderRadius;

var bw = AspectRatio$LiteracyplanetClientFlags.bw;

var borderWidth = AspectRatio$LiteracyplanetClientFlags.borderWidth;

var brw = AspectRatio$LiteracyplanetClientFlags.brw;

var borderRightWidth = AspectRatio$LiteracyplanetClientFlags.borderRightWidth;

var lh = AspectRatio$LiteracyplanetClientFlags.lh;

var lineHeight = AspectRatio$LiteracyplanetClientFlags.lineHeight;

var btl = AspectRatio$LiteracyplanetClientFlags.btl;

var borderTopLeftRadius = AspectRatio$LiteracyplanetClientFlags.borderTopLeftRadius;

var btr = AspectRatio$LiteracyplanetClientFlags.btr;

var borderTopRightRadius = AspectRatio$LiteracyplanetClientFlags.borderTopRightRadius;

var bblr = AspectRatio$LiteracyplanetClientFlags.bblr;

var borderBottomLeftRadius = AspectRatio$LiteracyplanetClientFlags.borderBottomLeftRadius;

var bbrr = AspectRatio$LiteracyplanetClientFlags.bbrr;

var borderBottomRightRadius = AspectRatio$LiteracyplanetClientFlags.borderBottomRightRadius;

var gac = AspectRatio$LiteracyplanetClientFlags.gac;

var gridAutoColumns = AspectRatio$LiteracyplanetClientFlags.gridAutoColumns;

var bbw = AspectRatio$LiteracyplanetClientFlags.bbw;

var borderBottomWidth = AspectRatio$LiteracyplanetClientFlags.borderBottomWidth;

var blw = AspectRatio$LiteracyplanetClientFlags.blw;

var borderLeftWidth = AspectRatio$LiteracyplanetClientFlags.borderLeftWidth;

var btw = AspectRatio$LiteracyplanetClientFlags.btw;

var borderTopWidth = AspectRatio$LiteracyplanetClientFlags.borderTopWidth;

var gar = AspectRatio$LiteracyplanetClientFlags.gar;

var gridAutoRows = AspectRatio$LiteracyplanetClientFlags.gridAutoRows;

var grg = AspectRatio$LiteracyplanetClientFlags.grg;

var gridRowGap = AspectRatio$LiteracyplanetClientFlags.gridRowGap;

var resizeExtra = AspectRatio$LiteracyplanetClientFlags.resizeExtra;

var mapRules = AspectRatio$LiteracyplanetClientFlags.mapRules;

var resizeWithMap = AspectRatio$LiteracyplanetClientFlags.resizeWithMap;

var resizeGridRows = AspectRatio$LiteracyplanetClientFlags.resizeGridRows;

var resizeGridCols = AspectRatio$LiteracyplanetClientFlags.resizeGridCols;

var maximumWidth = AspectRatio$LiteracyplanetClientFlags.maximumWidth;

var maximumHeight = AspectRatio$LiteracyplanetClientFlags.maximumHeight;

export {
  dims ,
  toVh ,
  toVw ,
  dimensions ,
  dimensionsHover ,
  dimensionsActive ,
  textStroke ,
  mapProps ,
  resizeList ,
  resize ,
  pl ,
  paddingLeft ,
  pr ,
  paddingRight ,
  pt ,
  paddingTop ,
  pb ,
  paddingBottom ,
  ft ,
  fontSize ,
  pd ,
  padding ,
  br ,
  borderRadius ,
  bw ,
  borderWidth ,
  brw ,
  borderRightWidth ,
  lh ,
  lineHeight ,
  btl ,
  borderTopLeftRadius ,
  btr ,
  borderTopRightRadius ,
  bblr ,
  borderBottomLeftRadius ,
  bbrr ,
  borderBottomRightRadius ,
  gac ,
  gridAutoColumns ,
  bbw ,
  borderBottomWidth ,
  blw ,
  borderLeftWidth ,
  btw ,
  borderTopWidth ,
  gar ,
  gridAutoRows ,
  grg ,
  gridRowGap ,
  resizeExtra ,
  mapRules ,
  resizeWithMap ,
  resizeGridRows ,
  resizeGridCols ,
  maximumWidth ,
  maximumHeight ,
  primaryColor ,
  hoverColor ,
  activeColor ,
  defaultGreen ,
  hoverGreen ,
  activeGreen ,
}
/* primaryColor Not a pure module */
