// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as React from "react";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";

function marginPanel(topMargin) {
  return Theme$LiteracyplanetClientFlags.resize({
              hd: [
                Css.marginTop,
                topMargin
              ],
              tl: /* [] */0
            });
}

function MarginPanel(props) {
  return React.createElement("div", {
              className: marginPanel(props.topMargin),
              id: "marginPanel"
            }, props.children);
}

var make = MarginPanel;

export {
  marginPanel ,
  make ,
}
/* Css Not a pure module */
