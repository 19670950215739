// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as FlagsTypes$LiteracyplanetClientFlags from "./FlagsTypes.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "./FlagsContext.res.mjs";
import * as FlagsReducer$LiteracyplanetClientFlags from "./FlagsReducer.res.mjs";
import * as FeaturesQuery$LiteracyplanetClientFlags from "./FeaturesQuery.res.mjs";

var products = Belt_SetString.fromArray([
      "teacher-product",
      "school-product",
      "home-product",
      "library-product"
    ]);

function getProduct(classicFlags) {
  return Belt_List.head(Belt_SetString.toList(Belt_SetString.intersect(Belt_SetString.fromArray(classicFlags), products)));
}

function FlagsProvider(props) {
  var __customUnleashFeatures = props.customUnleashFeatures;
  var userRoles = props.userRoles;
  var classicFlags = props.classicFlags;
  var customUnleashFeatures = __customUnleashFeatures !== undefined ? __customUnleashFeatures : [];
  var product = getProduct(classicFlags);
  var state = FeaturesQuery$LiteracyplanetClientFlags.use(userRoles, props.userId, props.idp, props.idpAccountId, props.yearLevel, product, Belt_Array.concat(FlagsTypes$LiteracyplanetClientFlags.unleashFeatures, customUnleashFeatures));
  if (typeof state !== "object") {
    return props.loadingComponent;
  } else {
    return React.createElement(FlagsContext$LiteracyplanetClientFlags.Provider.make, {
                hasFlagInState: FlagsReducer$LiteracyplanetClientFlags.hasFlagInState,
                features: state._0,
                classicFlags: classicFlags,
                userRoles: userRoles,
                systemFlags: props.systemFlags,
                children: props.children
              });
  }
}

var unleashFeatures = FlagsTypes$LiteracyplanetClientFlags.unleashFeatures;

var hasFlagInState = FlagsReducer$LiteracyplanetClientFlags.hasFlagInState;

var make = FlagsProvider;

export {
  unleashFeatures ,
  hasFlagInState ,
  products ,
  getProduct ,
  make ,
}
/* products Not a pure module */
