// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Uuid$LiteracyplanetTupperware from "../Uuid.res.mjs";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as AudioPillButton$LiteracyplanetTupperware from "./AudioPillButton.res.mjs";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SoundProviderEmbedded.res.mjs";

function ImageSoundQuestion$ImageSoundQuestionGrid(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.ImageSoundQuestionGrid.className
            }, props.children);
}

var ImageSoundQuestionGrid = {
  make: ImageSoundQuestion$ImageSoundQuestionGrid
};

function ImageSoundQuestion$WithAudioEmbedded(props) {
  var audioId = props.audioId;
  var instance = React.useMemo((function (param) {
          return Uuid$LiteracyplanetTupperware.make();
        }), [audioId]);
  var match = SoundProviderEmbedded$LiteracyplanetClientFlags.use(instance, audioId, false, props.sources);
  return React.createElement(React.Fragment, {}, React.createElement(AudioPillButton$LiteracyplanetTupperware.make, {
                  isPlaying: match[0],
                  src: match[2],
                  onTogglePlay: match[1]
                }), props.children);
}

var WithAudioEmbedded = {
  make: ImageSoundQuestion$WithAudioEmbedded
};

function ImageSoundQuestion$AnswerImage(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.AnswerImage.imageDivClassName
            }, React.createElement("img", {
                  className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.AnswerImage.imageCss,
                  src: props.imageSrc
                }));
}

var AnswerImage = {
  make: ImageSoundQuestion$AnswerImage
};

function ImageSoundQuestion$AnswerText(props) {
  return React.createElement(React.Fragment, {}, props.image, React.createElement("div", {
                  className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.AnswerText.className
                }, props.children));
}

var AnswerText = {
  make: ImageSoundQuestion$AnswerText
};

function ImageSoundQuestion(props) {
  var sources = props.sources;
  var image = Belt_Option.mapWithDefault(props.imageSrc, null, (function (imageSrc) {
          return React.createElement(ImageSoundQuestion$AnswerImage, {
                      imageSrc: imageSrc
                    });
        }));
  var content = React.createElement(ImageSoundQuestion$AnswerText, {
        image: image,
        children: props.children
      });
  return React.createElement(ImageSoundQuestion$ImageSoundQuestionGrid, {
              children: Belt_Option.mapWithDefault(props.audioId, content, (function (audioId) {
                      if (sources !== undefined) {
                        return React.createElement(ImageSoundQuestion$WithAudioEmbedded, {
                                    audioId: audioId,
                                    sources: sources,
                                    children: content
                                  });
                      } else {
                        return content;
                      }
                    }))
            });
}

var make = ImageSoundQuestion;

var $$default = ImageSoundQuestion;

export {
  ImageSoundQuestionGrid ,
  WithAudioEmbedded ,
  AnswerImage ,
  AnswerText ,
  make ,
  $$default as default,
}
/* react Not a pure module */
