// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import GetClickedWord from "./GetClickedWord";
import * as MemoPlay$LiteracyplanetClientFlags from "./MemoPlay.res.mjs";
import * as FindWordsType$LiteracyplanetClientFlags from "./FindWordsType.res.mjs";

function getClickedWord(prim) {
  return GetClickedWord();
}

function ClickWordToHear$HighlightWord(props) {
  var onClick = props.onClick;
  var onClick$1 = function (param) {
    Curry._1(onClick, GetClickedWord());
  };
  return React.createElement("div", {
              style: {
                userSelect: "text"
              },
              onClick: onClick$1
            }, props.children);
}

var HighlightWord = {
  make: ClickWordToHear$HighlightWord
};

function ClickWordToHear$PlayAudioComponent(props) {
  var onEnd = props.onEnd;
  return Belt_Option.mapWithDefault(FindWordsType$LiteracyplanetClientFlags.hasWord(Curry._1(props.useQuery, [props.wordName])), null, (function (word) {
                return React.createElement(MemoPlay$LiteracyplanetClientFlags.make, {
                            src: word.audioSrc,
                            onEnd: onEnd
                          });
              }));
}

var PlayAudioComponent = {
  make: ClickWordToHear$PlayAudioComponent
};

function ClickWordToHear(props) {
  var match = React.useState(function (param) {
        
      });
  var setWord = match[1];
  var wordName = match[0];
  var onClick = function (word) {
    Curry._1(setWord, (function (param) {
            return word;
          }));
  };
  var onEnd = function (param) {
    Curry._1(setWord, (function (param) {
            
          }));
  };
  var wordQuery = wordName !== undefined ? React.createElement(ClickWordToHear$PlayAudioComponent, {
          wordName: wordName,
          onEnd: onEnd,
          useQuery: props.useQuery
        }) : null;
  return React.createElement(React.Fragment, {}, wordQuery, React.createElement(ClickWordToHear$HighlightWord, {
                  onClick: onClick,
                  children: props.children
                }));
}

var make = ClickWordToHear;

export {
  getClickedWord ,
  HighlightWord ,
  PlayAudioComponent ,
  make ,
}
/* react Not a pure module */
