// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Error$LiteracyplanetClientErrors from "@literacyplanet/client-errors/src/Error.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";

function getLatestCheckpoint(currentLevel) {
  return Belt_Array.reduce(currentLevel, undefined, (function (acc, el) {
                if (acc !== undefined && el.points <= acc.points) {
                  return acc;
                } else {
                  return el;
                }
              }));
}

function findNextCheckpoint(levels, userLevel) {
  return Belt_Array.reduce(levels, undefined, (function (acc, el) {
                if (acc !== undefined || el.points <= userLevel.points) {
                  return acc;
                } else {
                  return el;
                }
              }));
}

function hasLeveledUp(level, points) {
  return points >= level.points;
}

function findNextLevel(levelup) {
  if (levelup.currentLevel.length === 0) {
    return Belt_Array.get(levelup.levels, 0);
  } else {
    return Belt_Option.flatMap(getLatestCheckpoint(levelup.currentLevel), (function (userLevel) {
                  return findNextCheckpoint(levelup.levels, userLevel);
                }));
  }
}

function appendLevelup(levelup, level) {
  return {
          points: levelup.points,
          currentLevel: Belt_Array.concat(levelup.currentLevel, [{
                  checkpoint: level.checkpoint,
                  level: level.level,
                  points: level.points
                }]),
          levels: levelup.levels,
          locationIndex: levelup.locationIndex
        };
}

function findAllLevelups(levelup, levels) {
  return Belt_Option.mapWithDefault(findNextLevel(levelup), levels, (function (level) {
                if (levelup.points >= level.points) {
                  return Belt_Array.concat([level], findAllLevelups(appendLevelup(levelup, level), levels));
                } else {
                  return levels;
                }
              }));
}

function allLevelups(levelup) {
  return findAllLevelups(levelup, []);
}

function latestLevelup(levelup) {
  return Belt_Array.get(findAllLevelups(levelup, []), findAllLevelups(levelup, []).length - 1 | 0);
}

function levelupMutation(levelup, insert) {
  var ids = Belt_Array.map(findAllLevelups(levelup, []), (function (param) {
          return param.id;
        }));
  if (ids.length !== 0) {
    return Curry._1(insert, ids);
  }
  
}

function makeUnlockType(param) {
  if (param.hasStory) {
    return "STORY";
  }
  var hasSot = param.hasSot;
  var hasImage = param.hasImage;
  if (hasImage && hasSot) {
    return "COMBI";
  } else if (hasImage) {
    return "BOG";
  } else if (hasSot) {
    return "SOT";
  } else {
    return ;
  }
}

function makeCobraProps(previousXP, unlockType, hasLocationChanged, levelup) {
  var locationIndex = levelup.locationIndex;
  var currentXP = levelup.points;
  var currLevel = Belt_Array.get(levelup.currentLevel, 0);
  var startLevelNum = Belt_Option.mapWithDefault(currLevel, 1, (function (param) {
          return param.level;
        }));
  var minXP = Belt_Option.mapWithDefault(currLevel, 0, (function (param) {
          return param.points;
        }));
  var optNextLevel = Belt_Array.getBy(levelup.levels, (function (param) {
          if (param.level === (startLevelNum + 1 | 0)) {
            return !param.checkpoint;
          } else {
            return false;
          }
        }));
  if (optNextLevel !== undefined) {
    return {
            locationIndex: locationIndex,
            hasLocationChanged: hasLocationChanged,
            startLevelNum: startLevelNum,
            previousXP: previousXP,
            currentXP: currentXP,
            minXP: minXP,
            maxXP: optNextLevel.points,
            unlockType: unlockType
          };
  } else {
    return {
            locationIndex: locationIndex,
            hasLocationChanged: hasLocationChanged,
            startLevelNum: startLevelNum - 1 | 0,
            previousXP: previousXP,
            currentXP: currentXP,
            minXP: minXP,
            maxXP: minXP - 1 | 0,
            unlockType: unlockType
          };
  }
}

function Make(Hooks) {
  var useLevelupMutation = function (queryStateLevelup) {
    var insert = Curry._1(Hooks.useInsertLevelup, undefined);
    React.useState(function (param) {
          return [];
        });
    React.useEffect((function (param) {
            Belt_Option.map(queryStateLevelup, (function (__x) {
                    levelupMutation(__x, insert);
                  }));
          }), [queryStateLevelup]);
  };
  var usePreviousPoints = function (queryStateLevelup, userId) {
    var match = Curry._2(Hooks.useLocalStorage, userId, "storyverse-points");
    var savePoints = match[1];
    var optPoints = match[0];
    var previousXp = React.useMemo((function (param) {
            if (optPoints !== undefined) {
              return Caml_format.int_of_string(optPoints);
            } else {
              return Belt_Option.mapWithDefault(Belt_Option.flatMap(queryStateLevelup, (function (levelup) {
                                return getLatestCheckpoint(levelup.currentLevel);
                              })), 0, (function (param) {
                            return param.points;
                          }));
            }
          }), [queryStateLevelup]);
    React.useEffect((function (param) {
            Belt_Option.map(queryStateLevelup, (function (levelup) {
                    Curry._1(savePoints, String(levelup.points));
                  }));
          }), [
          queryStateLevelup,
          previousXp
        ]);
    return previousXp;
  };
  var useHasLocationChanged = function (userId, queryStateLevelup) {
    var match = Curry._2(Hooks.useLocalStorage, userId, "storyverse-background-location-index");
    var saveLocationIndex = match[1];
    var optStorageLocationIndex = match[0];
    var hasLocationChanged = React.useMemo((function (param) {
            var optDbLocationIndex = Belt_Option.map(queryStateLevelup, (function (levelup) {
                    return levelup.locationIndex;
                  }));
            if (optDbLocationIndex !== undefined && optStorageLocationIndex !== undefined) {
              return optDbLocationIndex !== Caml_format.int_of_string(optStorageLocationIndex);
            } else {
              return true;
            }
          }), [queryStateLevelup]);
    React.useEffect((function (param) {
            Belt_Option.map(queryStateLevelup, (function (levelup) {
                    Curry._1(saveLocationIndex, String(levelup.locationIndex));
                  }));
          }), [queryStateLevelup]);
    return hasLocationChanged;
  };
  var useUnlockType = function (param) {
    var match = React.useState(function (param) {
          return true;
        });
    var setSkipUnlock = match[1];
    var match$1 = React.useState(function (param) {
          return "";
        });
    var setLevelId = match$1[1];
    var queryStateUnlocks = Curry._2(Hooks.useUnlocksQuery, match[0], match$1[0]);
    var runQuery = function (level) {
      Curry._1(setLevelId, (function (param) {
              return level.id;
            }));
      Curry._1(setSkipUnlock, (function (param) {
              return false;
            }));
    };
    return [
            queryStateUnlocks,
            runQuery
          ];
  };
  var useCobraProps = function (userId, queryStateLevelup) {
    var previousXp = usePreviousPoints(queryStateLevelup, userId);
    var hasLocationChanged = useHasLocationChanged(userId, queryStateLevelup);
    Error$LiteracyplanetClientErrors.useErrorModal();
    var match = React.useState(function (param) {
          
        });
    var setCobraProps = match[1];
    var match$1 = useUnlockType();
    var runUnlocksQuery = match$1[1];
    var queryStateUnlocks = match$1[0];
    React.useEffect((function (param) {
            var queriesFinished = function (unlockTypeRecord, levelup) {
              Curry._1(setCobraProps, (function (param) {
                      return makeCobraProps(previousXp, makeUnlockType(unlockTypeRecord), hasLocationChanged, levelup);
                    }));
            };
            var setQueryStateUnlocks = function (levelup) {
              var optLevel = latestLevelup(levelup);
              if (optLevel !== undefined) {
                Curry._1(runUnlocksQuery, optLevel);
              } else {
                queriesFinished({
                      hasImage: false,
                      hasStory: false,
                      hasSot: false
                    }, levelup);
              }
            };
            if (queryStateLevelup !== undefined) {
              if (typeof queryStateUnlocks !== "object") {
                if (queryStateUnlocks === "UnlocksSkipped") {
                  setQueryStateUnlocks(queryStateLevelup);
                }
                
              } else {
                queriesFinished(queryStateUnlocks._0, queryStateLevelup);
              }
            }
            
          }), [
          queryStateLevelup,
          queryStateUnlocks
        ]);
    return match[0];
  };
  var use = function (userId) {
    var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
    var queryStateLevelup = Curry._2(Hooks.useLevelupQuery, !Curry._1(hasFlag, "StudentHasStoryverse"), userId);
    useLevelupMutation(queryStateLevelup);
    return useCobraProps(userId, queryStateLevelup);
  };
  return {
          useLevelupMutation: useLevelupMutation,
          usePreviousPoints: usePreviousPoints,
          useHasLocationChanged: useHasLocationChanged,
          useUnlockType: useUnlockType,
          useCobraProps: useCobraProps,
          use: use
        };
}

export {
  getLatestCheckpoint ,
  findNextCheckpoint ,
  hasLeveledUp ,
  findNextLevel ,
  appendLevelup ,
  findAllLevelups ,
  allLevelups ,
  latestLevelup ,
  levelupMutation ,
  makeUnlockType ,
  makeCobraProps ,
  Make ,
}
/* react Not a pure module */
