// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as BehaviourMultiChoiceGroupedOptionContainer$LiteracyplanetTupperware from "./BehaviourMultiChoiceGroupedOptionContainer.res.mjs";

function PieceOfCakeMultiChoiceSingleResponseContainer(props) {
  return React.createElement(BehaviourMultiChoiceGroupedOptionContainer$LiteracyplanetTupperware.make, {
              questionIndex: props.questionIndex,
              group: "SingleResponse",
              responseOptionId: props.responseOptionId,
              children: props.children
            });
}

var make = PieceOfCakeMultiChoiceSingleResponseContainer;

var $$default = PieceOfCakeMultiChoiceSingleResponseContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
