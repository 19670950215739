// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as V2EventsContext$LiteracyplanetTupperware from "./V2EventsContext.res.mjs";
import * as V2ExerciseStateStoreHooks$LiteracyplanetTupperware from "./V2ExerciseStateStoreHooks.res.mjs";

function useCallbacks(questionIndex, persistedState) {
  var callbacks = V2EventsContext$LiteracyplanetTupperware.use();
  React.useEffect((function (param) {
          Curry._1(callbacks.onActivityStarted, undefined);
          Curry._2(callbacks.onQuestionStarted, questionIndex, Belt_Option.getWithDefault(persistedState, ""));
        }), []);
}

function BehaviourTestActivityPage(props) {
  var persistedState = props.persistedState;
  useCallbacks(props.questionIndex, persistedState);
  var ready = V2ExerciseStateStoreHooks$LiteracyplanetTupperware.useModeAndPersistedState(props.assessment, persistedState);
  if (ready) {
    return props.children;
  } else {
    return null;
  }
}

var make = BehaviourTestActivityPage;

export {
  useCallbacks ,
  make ,
}
/* react Not a pure module */
