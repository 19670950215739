// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import * as MobileDetect$LiteracyplanetClientFlags from "./MobileDetect.res.mjs";

var dims = MobileDetect$LiteracyplanetClientFlags.getDimensions();

function toVh(pixel) {
  return Css.vh(pixel / dims.height * 100.0);
}

function toVw(pixel) {
  return Css.vw(pixel / dims.width * 100.0);
}

function dimensions(pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.width(toVh(pixelWidth)),
              tl: {
                hd: Css.height(toVh(pixelHeight)),
                tl: {
                  hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                        hd: Css.width(toVw(pixelWidth)),
                        tl: {
                          hd: Css.height(toVw(pixelHeight)),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

function dimensionsHover(selectorName, pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.hover({
                    hd: Css.selector("." + selectorName, {
                          hd: Css.width(toVh(pixelWidth)),
                          tl: {
                            hd: Css.height(toVh(pixelHeight)),
                            tl: {
                              hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                                    hd: Css.width(toVw(pixelWidth)),
                                    tl: {
                                      hd: Css.height(toVw(pixelHeight)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            });
}

function dimensionsActive(pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.active({
                    hd: Css.width(toVh(pixelWidth)),
                    tl: {
                      hd: Css.height(toVh(pixelHeight)),
                      tl: {
                        hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                              hd: Css.width(toVw(pixelWidth)),
                              tl: {
                                hd: Css.height(toVw(pixelHeight)),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }),
              tl: /* [] */0
            });
}

function textStroke(borderStroke, shadowX, shadowY, color) {
  return Curry._1(Css.style, {
              hd: Css.textShadows({
                    hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), toVh(-borderStroke | 0), Css.zero, color),
                    tl: {
                      hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), toVh(-borderStroke | 0), Css.zero, color),
                      tl: {
                        hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), toVh(borderStroke), Css.zero, color),
                        tl: {
                          hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), toVh(borderStroke), Css.zero, color),
                          tl: {
                            hd: Css_Legacy_Core.Shadow.text(Css.zero, toVh(-borderStroke | 0), Css.zero, color),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.text(Css.zero, toVh(borderStroke), Css.zero, color),
                              tl: {
                                hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), Css.zero, Css.zero, color),
                                tl: {
                                  hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), Css.zero, Css.zero, color),
                                  tl: {
                                    hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), toVh(shadowY), Css.zero, color),
                                    tl: {
                                      hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), toVh(shadowY), Css.zero, color),
                                      tl: {
                                        hd: Css_Legacy_Core.Shadow.text(Css.zero, toVh(shadowY), Css.zero, color),
                                        tl: {
                                          hd: Css_Legacy_Core.Shadow.text(toVh(shadowX), toVh(borderStroke), Css.zero, color),
                                          tl: {
                                            hd: Css_Legacy_Core.Shadow.text(toVh(shadowX), toVh(-borderStroke | 0), Css.zero, color),
                                            tl: {
                                              hd: Css_Legacy_Core.Shadow.text(toVh(shadowX), Css.zero, Css.zero, color),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                      hd: Css.textShadows({
                            hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), toVw(-borderStroke | 0), Css.zero, color),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), toVw(-borderStroke | 0), Css.zero, color),
                              tl: {
                                hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), toVw(borderStroke), Css.zero, color),
                                tl: {
                                  hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), toVw(borderStroke), Css.zero, color),
                                  tl: {
                                    hd: Css_Legacy_Core.Shadow.text(Css.zero, toVw(-borderStroke | 0), Css.zero, color),
                                    tl: {
                                      hd: Css_Legacy_Core.Shadow.text(Css.zero, toVw(borderStroke), Css.zero, color),
                                      tl: {
                                        hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), Css.zero, Css.zero, color),
                                        tl: {
                                          hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), Css.zero, Css.zero, color),
                                          tl: {
                                            hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), toVw(shadowY), Css.zero, color),
                                            tl: {
                                              hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), toVw(shadowY), Css.zero, color),
                                              tl: {
                                                hd: Css_Legacy_Core.Shadow.text(Css.zero, toVw(shadowY), Css.zero, color),
                                                tl: {
                                                  hd: Css_Legacy_Core.Shadow.text(toVw(shadowX), toVw(borderStroke), Css.zero, color),
                                                  tl: {
                                                    hd: Css_Legacy_Core.Shadow.text(toVw(shadowX), toVw(-borderStroke | 0), Css.zero, color),
                                                    tl: {
                                                      hd: Css_Legacy_Core.Shadow.text(toVw(shadowX), Css.zero, Css.zero, color),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function mapProps(l, f) {
  return Belt_List.map(l, (function (param) {
                return Curry._1(param[0], Curry._1(f, param[1]));
              }));
}

function resizeList(l) {
  return Belt_List.concat(mapProps(l, toVh), {
              hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), mapProps(l, toVw)),
              tl: /* [] */0
            });
}

function resize(l) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, mapProps(l, toVh)),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), mapProps(l, toVw)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function paddingLeft(x) {
  if (x.NAME === "vw") {
    return Css.paddingLeft({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.paddingLeft({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function paddingRight(x) {
  if (x.NAME === "vw") {
    return Css.paddingRight({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.paddingRight({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function paddingTop(x) {
  if (x.NAME === "vw") {
    return Css.paddingTop({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.paddingTop({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function paddingBottom(x) {
  if (x.NAME === "vw") {
    return Css.paddingBottom({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.paddingBottom({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function fontSize(x) {
  if (x.NAME === "vw") {
    return Css.fontSize({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.fontSize({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function padding(x) {
  if (x.NAME === "vw") {
    return Css.padding({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.padding({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderRadius(x) {
  if (x.NAME === "vw") {
    return Css.borderRadius({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderRadius({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderWidth(x) {
  if (x.NAME === "vw") {
    return Css.borderWidth({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderWidth({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderRightWidth(x) {
  if (x.NAME === "vw") {
    return Css.borderRightWidth({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderRightWidth({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function lineHeight(x) {
  if (x.NAME === "vw") {
    return Css.lineHeight({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.lineHeight({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderTopLeftRadius(x) {
  if (x.NAME === "vw") {
    return Css.borderTopLeftRadius({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderTopLeftRadius({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderTopRightRadius(x) {
  if (x.NAME === "vw") {
    return Css.borderTopRightRadius({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderTopRightRadius({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderBottomLeftRadius(x) {
  if (x.NAME === "vw") {
    return Css.borderBottomLeftRadius({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderBottomLeftRadius({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderBottomRightRadius(x) {
  if (x.NAME === "vw") {
    return Css.borderBottomRightRadius({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderBottomRightRadius({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function gridAutoColumns(x) {
  if (x.NAME === "vw") {
    return Css.gridAutoColumns({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.gridAutoColumns({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function gridAutoColumns$1(x) {
  if (x.NAME === "vw") {
    return gridAutoColumns({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return gridAutoColumns({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderBottomWidth(x) {
  if (x.NAME === "vw") {
    return Css.borderBottomWidth({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderBottomWidth({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderLeftWidth(x) {
  if (x.NAME === "vw") {
    return Css.borderLeftWidth({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderLeftWidth({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function borderTopWidth(x) {
  if (x.NAME === "vw") {
    return Css.borderTopWidth({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.borderTopWidth({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function gridAutoRows(x) {
  if (x.NAME === "vw") {
    return Css.gridAutoRows({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.gridAutoRows({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function gridRowGap(x) {
  if (x.NAME === "vw") {
    return Css.gridRowGap({
                NAME: "vw",
                VAL: x.VAL
              });
  } else {
    return Css.gridRowGap({
                NAME: "vh",
                VAL: x.VAL
              });
  }
}

function resizeExtra(extraStyle, className, l) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, mapProps(l, toVh)),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), mapProps(l, toVw)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: extraStyle !== undefined ? Curry._1(Css.style, extraStyle) : Curry._1(Css.style, /* [] */0),
                  tl: {
                    hd: Belt_Option.getWithDefault(className, ""),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function mapRules(l, f) {
  return Belt_List.map(l, (function (t) {
                if (t.NAME === "css") {
                  return t.VAL;
                } else {
                  return Curry._1(f, t.VAL);
                }
              }));
}

function resizeWithMap(f, l) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Curry._1(f, mapRules(l, toVh)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                            hd: Curry._1(f, mapRules(l, toVw)),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function resizeGridRows(l) {
  return resizeWithMap(Css.gridTemplateRows, l);
}

function resizeGridCols(l) {
  return resizeWithMap(Css.gridTemplateColumns, l);
}

var maximumWidth = dims.width;

var maximumHeight = dims.height;

var pl = Css.paddingLeft;

var pr = Css.paddingRight;

var pt = Css.paddingTop;

var pb = Css.paddingBottom;

var ft = Css.fontSize;

var pd = Css.padding;

var br = Css.borderRadius;

var bw = Css.borderWidth;

var brw = Css.borderRightWidth;

var lh = Css.lineHeight;

var btl = Css.borderTopLeftRadius;

var btr = Css.borderTopRightRadius;

var bblr = Css.borderBottomLeftRadius;

var bbrr = Css.borderBottomRightRadius;

var gac = gridAutoColumns;

var bbw = Css.borderBottomWidth;

var blw = Css.borderLeftWidth;

var btw = Css.borderTopWidth;

var gar = Css.gridAutoRows;

var grg = Css.gridRowGap;

export {
  dims ,
  toVh ,
  toVw ,
  dimensions ,
  dimensionsHover ,
  dimensionsActive ,
  textStroke ,
  mapProps ,
  resizeList ,
  resize ,
  pl ,
  paddingLeft ,
  pr ,
  paddingRight ,
  pt ,
  paddingTop ,
  pb ,
  paddingBottom ,
  ft ,
  fontSize ,
  pd ,
  padding ,
  br ,
  borderRadius ,
  bw ,
  borderWidth ,
  brw ,
  borderRightWidth ,
  lh ,
  lineHeight ,
  btl ,
  borderTopLeftRadius ,
  btr ,
  borderTopRightRadius ,
  bblr ,
  borderBottomLeftRadius ,
  bbrr ,
  borderBottomRightRadius ,
  gac ,
  gridAutoColumns$1 as gridAutoColumns,
  bbw ,
  borderBottomWidth ,
  blw ,
  borderLeftWidth ,
  btw ,
  borderTopWidth ,
  gar ,
  gridAutoRows ,
  grg ,
  gridRowGap ,
  resizeExtra ,
  mapRules ,
  resizeWithMap ,
  resizeGridRows ,
  resizeGridCols ,
  maximumWidth ,
  maximumHeight ,
}
/* dims Not a pure module */
