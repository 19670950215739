// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "rescript/lib/es6/list.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ExerciseStateStore$LiteracyplanetTupperware from "./ExerciseStateStore.res.mjs";
import * as StateSerialization$LiteracyplanetTupperware from "./StateSerialization.res.mjs";

function callExternal(state, questionIndex, callbacks) {
  var serializedState = StateSerialization$LiteracyplanetTupperware.encode(state);
  var markState = ExerciseStateStore$LiteracyplanetTupperware.findMarkState(questionIndex, state.markStates);
  if (typeof markState !== "object") {
    return ;
  } else if (markState._0.questionCorrect) {
    return Curry._2(callbacks.onQuestionPassed, questionIndex, serializedState);
  } else {
    return Curry._2(callbacks.onQuestionFailed, questionIndex, serializedState);
  }
}

function runMarkQuestion(callbacks, s, questionIndex, f) {
  if (!ExerciseStateStore$LiteracyplanetTupperware.notMarked(s, questionIndex)) {
    return s;
  }
  var action = Curry._1(f, s);
  var state = ExerciseStateStore$LiteracyplanetTupperware.reducer(s, action);
  callExternal(state, questionIndex, callbacks);
  return state;
}

function markQuestion(callbacks, setState, subscriptions, questionIndex) {
  var f = Belt_List.reduce(subscriptions, undefined, (function (acc, param) {
          if (param[1] === questionIndex) {
            return param[0];
          } else {
            return acc;
          }
        }));
  if (f !== undefined) {
    return Curry._1(setState, (function (state) {
                  return runMarkQuestion(callbacks, state, questionIndex, f);
                }));
  }
  
}

function markActivity(callbacks, setState, subscriptions, param) {
  return Curry._1(setState, (function (state) {
                return Belt_List.reduce(Belt_List.sort(subscriptions, (function (param, param$1) {
                                  return param[1] - param$1[1] | 0;
                                })), state, (function (s, param) {
                              return runMarkQuestion(callbacks, s, param[1], param[0]);
                            }));
              }));
}

function useMarkCompleted(callbacks, state, questionCount, setFinished) {
  var isComplete = state.questionsComplete === questionCount;
  React.useEffect((function (param) {
          if (isComplete) {
            Curry._1(setFinished, undefined);
            Curry._1(callbacks.onActivityCompleted, undefined);
          }
          
        }), [isComplete]);
}

var subscriptions = {
  contents: /* [] */0
};

function setMarkCb(f, questionIndex, correctAnswer) {
  subscriptions.contents = {
    hd: [
      f,
      questionIndex,
      correctAnswer
    ],
    tl: subscriptions.contents
  };
  return function (param) {
    subscriptions.contents = List.filter(function (param) {
            return param[0] !== f;
          })(subscriptions.contents);
  };
}

export {
  callExternal ,
  runMarkQuestion ,
  markQuestion ,
  markActivity ,
  useMarkCompleted ,
  subscriptions ,
  setMarkCb ,
}
/* react Not a pure module */
