// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "@rescript/react/src/React.res.mjs";

var make = React.lazy_(function (param) {
      return import("./layouts/StudentExperienceLayout.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var StudentExperienceLayout = {
  make: make
};

var make$1 = React.lazy_(function (param) {
      return import("@literacyplanet/client-sx-secondary-missions/src/MissionsContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var SecondaryMissions = {
  make: make$1
};

var make$2 = React.lazy_(function (param) {
      return import("@literacyplanet/client-sx-library/src/LibraryContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var Library = {
  make: make$2
};

var make$3 = React.lazy_(function (param) {
      return import("./arcade/ArcadeContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var Arcade = {
  make: make$3
};

var make$4 = React.lazy_(function (param) {
      return import("./arcade/ArcadeGameContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var ArcadeGame = {
  make: make$4
};

var make$5 = React.lazy_(function (param) {
      return import("./arcade/WordManiaGameContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var WordManiaGame = {
  make: make$5
};

var make$6 = React.lazy_(function (param) {
      return import("./shop/ShopContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var AvatarShop = {
  make: make$6
};

var make$7 = React.lazy_(function (param) {
      return import("@literacyplanet/client-sx-activity/src/flipper/FlipperMissionsActivityLauncher.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var FlipperActivity = {
  make: make$7
};

var make$8 = React.lazy_(function (param) {
      return import("@literacyplanet/client-sx-activity/src/flipper/FlipperPlayAnyMissionActivityLauncher.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var FlipperPlayAnyActivity = {
  make: make$8
};

var make$9 = React.lazy_(function (param) {
      return import("./my_words/MyWordsIndexContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var MyWordsIndexContainer = {
  make: make$9
};

var make$10 = React.lazy_(function (param) {
      return import("./my_words/MyWordsEditContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var MyWordsEditContainer = {
  make: make$10
};

var make$11 = React.lazy_(function (param) {
      return import("./my_words/MyWordsAssignContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var MyWordsAssignContainer = {
  make: make$11
};

var make$12 = React.lazy_(function (param) {
      return import("./my_words/MyWordsActivityContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var MyWordsActivityContainer = {
  make: make$12
};

var make$13 = React.lazy_(function (param) {
      return import("./OverviewReportContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var OverviewReportContainer = {
  make: make$13
};

var make$14 = React.lazy_(function (param) {
      return import("./checks/ChecksActivityContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var ChecksActivityContainer = {
  make: make$14
};

var make$15 = React.lazy_(function (param) {
      return import("@literacyplanet/client-flags/src/IntrepizineContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var IntrepizineContainer = {
  make: make$15
};

var make$16 = React.lazy_(function (param) {
      return import("./book_of_goings/BogContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var BogContainer = {
  make: make$16
};

var make$17 = React.lazy_(function (param) {
      return import("./collections/CollectionsContainer.res.mjs").then(function (m) {
                  return m.make;
                });
    });

var CollectionsContainer = {
  make: make$17
};

export {
  StudentExperienceLayout ,
  SecondaryMissions ,
  Library ,
  Arcade ,
  ArcadeGame ,
  WordManiaGame ,
  AvatarShop ,
  FlipperActivity ,
  FlipperPlayAnyActivity ,
  MyWordsIndexContainer ,
  MyWordsEditContainer ,
  MyWordsAssignContainer ,
  MyWordsActivityContainer ,
  OverviewReportContainer ,
  ChecksActivityContainer ,
  IntrepizineContainer ,
  BogContainer ,
  CollectionsContainer ,
}
/* make Not a pure module */
