// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";

function SkilfulDragPanelSticky(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulDragPanelSticky.className
            }, props.children);
}

var make = SkilfulDragPanelSticky;

var $$default = SkilfulDragPanelSticky;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
