// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function call(f) {
  Belt_Option.map(f, (function (f) {
          return Curry._1(f, undefined);
        }));
}

function callWith(f, x) {
  Belt_Option.map(f, (function (f) {
          return Curry._1(f, x);
        }));
}

function wrapApiWithStoreEvents(api, dispatch, onActivityCompleted, onActivityPaused, onActivityReachedEnd, onActivityReady, onActivityResumed, onActivityStarted, onExit, onFailed, onQuestionFailed, onQuestionPassed, onQuestionStarted, onBatchQuestionsAnswered, onPreloaderReady, param) {
  return Curry.app(api, [
              (function (param) {
                  Curry._1(dispatch, "ActivityCompleted");
                  call(onActivityCompleted);
                }),
              (function (param) {
                  Curry._1(dispatch, "ActivityPaused");
                  call(onActivityPaused);
                }),
              (function (param) {
                  Curry._1(dispatch, "ActivityReachedEnd");
                  call(onActivityReachedEnd);
                }),
              (function (param) {
                  Curry._1(dispatch, "ActivityReady");
                  call(onActivityReady);
                }),
              (function (param) {
                  Curry._1(dispatch, "ActivityResumed");
                  call(onActivityResumed);
                }),
              (function (param) {
                  Curry._1(dispatch, "ActivityStarted");
                  call(onActivityStarted);
                }),
              (function (param) {
                  Curry._1(dispatch, "Exit");
                  call(onExit);
                }),
              (function (param) {
                  return callWith(onFailed, param);
                }),
              (function (result) {
                  if (typeof result === "object") {
                    if (result.TAG === "Orig") {
                      Curry._1(dispatch, {
                            TAG: "QuestionFailed",
                            _0: result._0.question
                          });
                    } else {
                      Curry._1(dispatch, {
                            TAG: "QuestionFailed",
                            _0: result._0.question
                          });
                    }
                  }
                  callWith(onQuestionFailed, result);
                }),
              (function (result) {
                  if (typeof result === "object") {
                    if (result.TAG === "Orig") {
                      Curry._1(dispatch, {
                            TAG: "QuestionPassed",
                            _0: result._0.question
                          });
                    } else {
                      Curry._1(dispatch, {
                            TAG: "QuestionPassed",
                            _0: result._0.question
                          });
                    }
                  }
                  callWith(onQuestionPassed, result);
                }),
              (function (q) {
                  Curry._1(dispatch, "QuestionStarted");
                  callWith(onQuestionStarted, q);
                }),
              (function (questions) {
                  if (typeof questions === "object") {
                    Curry._1(dispatch, {
                          TAG: "BulkQuestions",
                          _0: Belt_Array.map(questions._0, (function (param) {
                                  return param.correct;
                                }))
                        });
                  }
                  callWith(onBatchQuestionsAnswered, questions);
                }),
              (function (background) {
                  Curry._1(dispatch, {
                        TAG: "PreloaderReady",
                        _0: background
                      });
                  callWith(onPreloaderReady, background);
                })
            ]);
}

function defaultCb(param) {
  
}

var defaultDispatchables = {
  onActivityCompleted: defaultCb,
  onActivityPaused: defaultCb,
  onActivityReachedEnd: defaultCb,
  onActivityReady: defaultCb,
  onActivityResumed: defaultCb,
  onActivityStarted: defaultCb,
  onExit: defaultCb,
  onFailed: defaultCb,
  onQuestionFailed: defaultCb,
  onQuestionPassed: defaultCb,
  onQuestionStarted: defaultCb,
  onBatchQuestionsAnswered: defaultCb,
  onPreloaderReady: defaultCb
};

var context = React.createContext(defaultDispatchables);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Context = {
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function FlipperCobraCallbacks$Provider(props) {
  return React.createElement(make, {
              value: props.dispachables,
              children: props.children
            });
}

var Provider = {
  make: FlipperCobraCallbacks$Provider
};

export {
  call ,
  callWith ,
  wrapApiWithStoreEvents ,
  defaultCb ,
  defaultDispatchables ,
  Context ,
  Provider ,
}
/* context Not a pure module */
