// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as PageTypes$LiteracyplanetClientSxEntry from "../PageTypes.res.mjs";
import * as ContextMaker$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContextMaker.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";
import * as BlankUserLayout$LiteracyplanetClientSxEntry from "./BlankUserLayout.res.mjs";
import * as WrapSelectorComponent$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/WrapSelectorComponent.res.mjs";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./StudentExperienceWrap.res.mjs";

var LayoutSelectorApi = {
  name: "StudentExperienceLayout"
};

var include = ContextMaker$LiteracyplanetClientFlags.Make(LayoutSelectorApi);

var Provider = include.Provider;

var use = include.use;

var Context_initialContext = include.initialContext;

var Context_context = include.context;

var Context_P = include.P;

var Context_ContextMakerProviderNotFound = include.ContextMakerProviderNotFound;

var Context = {
  LayoutSelectorApi: LayoutSelectorApi,
  initialContext: Context_initialContext,
  context: Context_context,
  P: Context_P,
  Provider: Provider,
  ContextMakerProviderNotFound: Context_ContextMakerProviderNotFound,
  use: use
};

var span = {
  padding: "8px"
};

var noStyle = {};

var selected = {
  background: "white",
  color: "black"
};

var panel = {
  background: "black",
  bottom: "0",
  color: "white",
  cursor: "pointer",
  padding: "5px",
  position: "fixed",
  zIndex: "1000",
  opacity: ".8"
};

function StudentExperienceLayout$LayoutSelector(props) {
  var match = Curry._1(use, undefined);
  var changeLayout = match.setLayout;
  var changeBackground = match.setBackground;
  var layout = match.layout;
  var background = match.background;
  var pageLayoutLink = function (ex) {
    return React.createElement("a", {
                style: ex === layout ? selected : noStyle,
                onClick: (function (param) {
                    Curry._1(changeLayout, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, PageTypes$LiteracyplanetClientSxEntry.layoutToString(ex)));
  };
  var pageBackgroundLink = function (ex) {
    return React.createElement("a", {
                style: ex === background ? selected : noStyle,
                onClick: (function (param) {
                    Curry._1(changeBackground, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, PageTypes$LiteracyplanetClientSxEntry.backgroundToString(ex)));
  };
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var testingLayouts = Curry._1(hasFlag, "DeveloperTestingLayouts");
  if (testingLayouts) {
    return React.createElement("div", {
                style: panel
              }, React.createElement(WrapSelectorComponent$LiteracyplanetClientFlags.make, {}), React.createElement("div", undefined, pageLayoutLink("AuthWithMenu"), pageLayoutLink("AuthWithTitleBar"), pageLayoutLink("AuthWithTitleBarAndHeader"), pageLayoutLink("DarkBackground"), pageLayoutLink("AuthNoMenu")), React.createElement("div", undefined, pageBackgroundLink("MainBackground"), pageBackgroundLink("IntrepizineBackground"), pageBackgroundLink("NoBackground"), pageBackgroundLink("VoidBackground")));
  } else {
    return null;
  }
}

var LayoutSelector = {
  span: span,
  noStyle: noStyle,
  selected: selected,
  panel: panel,
  make: StudentExperienceLayout$LayoutSelector
};

function StudentExperienceLayout(props) {
  var children = props.children;
  var background = props.background;
  var layout = props.layout;
  var match = React.useState(function (param) {
        return layout;
      });
  var setLayout = match[1];
  var layoutOverride = match[0];
  var match$1 = React.useState(function (param) {
        return background;
      });
  var setBackground = match$1[1];
  var bgOverride = match$1[0];
  React.useEffect((function (param) {
          Curry._1(setLayout, (function (param) {
                  return layout;
                }));
        }), [layout]);
  React.useEffect((function (param) {
          Curry._1(setBackground, (function (param) {
                  return background;
                }));
        }), [background]);
  var tmp;
  tmp = layoutOverride === "AuthNoMenu" ? React.createElement(BlankUserLayout$LiteracyplanetClientSxEntry.make, {
          children: children
        }) : React.createElement(StudentExperienceWrap$LiteracyplanetClientSxEntry.make, {
          redirect: props.redirect,
          background: bgOverride,
          layout: layoutOverride,
          page: props.page,
          children: children,
          isPageBlacklisted: props.isPageBlacklisted
        });
  return React.createElement(Provider.make, {
              value: {
                background: bgOverride,
                layout: layoutOverride,
                setBackground: (function (bg) {
                    Curry._1(setBackground, (function (param) {
                            return bg;
                          }));
                  }),
                setLayout: (function (l) {
                    Curry._1(setLayout, (function (param) {
                            return l;
                          }));
                  })
              },
              children: null
            }, React.createElement(StudentExperienceLayout$LayoutSelector, {}), tmp);
}

var make = StudentExperienceLayout;

var $$default = StudentExperienceLayout;

export {
  Context ,
  LayoutSelector ,
  make ,
  $$default as default,
}
/* include Not a pure module */
