// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.res.mjs";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.res.mjs";

function SkilfulClozeCommon$DndIconGrid(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeCommon.DndIconGrid.className
            }, props.children);
}

var DndIconGrid = {
  make: SkilfulClozeCommon$DndIconGrid
};

function SkilfulClozeCommon$IncorrectMarkIcon(props) {
  return React.createElement(SkilfulClozeCommon$DndIconGrid, {
              children: React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.SkilfulClozeCommon.IncorrectMarkIcon.className
                  })
            });
}

var IncorrectMarkIcon = {
  make: SkilfulClozeCommon$IncorrectMarkIcon
};

function SkilfulClozeCommon$CorrectMarkIcon(props) {
  return React.createElement(SkilfulClozeCommon$DndIconGrid, {
              children: React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.SkilfulClozeCommon.CorrectMarkIcon.className
                  })
            });
}

var CorrectMarkIcon = {
  make: SkilfulClozeCommon$CorrectMarkIcon
};

function SkilfulClozeCommon$CorrectResponse(props) {
  return React.createElement("span", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeCommon.CorrectResponse.className
            }, props.children, React.createElement(SkilfulClozeCommon$CorrectMarkIcon, {}));
}

var CorrectResponse = {
  make: SkilfulClozeCommon$CorrectResponse
};

function SkilfulClozeCommon$IncorrectResponse(props) {
  return React.createElement("span", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeCommon.IncorrectResponse.className
            }, props.children, React.createElement(SkilfulClozeCommon$IncorrectMarkIcon, {}));
}

var IncorrectResponse = {
  make: SkilfulClozeCommon$IncorrectResponse
};

export {
  DndIconGrid ,
  IncorrectMarkIcon ,
  CorrectMarkIcon ,
  CorrectResponse ,
  IncorrectResponse ,
}
/* react Not a pure module */
