// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as AudioEmbeddedComponent$LiteracyplanetTupperware from "./AudioEmbeddedComponent.res.mjs";

function AudioQuestionContainer(props) {
  var sources = props.sources;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  if (sources !== undefined) {
    return React.createElement(AudioEmbeddedComponent$LiteracyplanetTupperware.make, {
                audioId: props.audioId,
                sources: sources,
                autoPlay: Curry._1(match.isQuestionVisible, props.questionIndex)
              });
  } else {
    return null;
  }
}

var make = AudioQuestionContainer;

var $$default = AudioQuestionContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
