// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";

function BehaviourExitButton(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var onExit = match.onExit;
  return React.createElement("div", {
              className: className,
              onClick: (function (param) {
                  Curry._1(onExit, undefined);
                })
            }, props.children);
}

var make = BehaviourExitButton;

var $$default = BehaviourExitButton;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
