// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as PrevIcon$LiteracyplanetTupperware from "./PrevIcon.res.mjs";

function PrevPageButton(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.PrevPageButton.className,
              onClick: props.onClick
            }, React.createElement(PrevIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.PrevPageButton.iconStyle
                }));
}

var make = PrevPageButton;

export {
  make ,
}
/* react Not a pure module */
