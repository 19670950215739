// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ContextMaker$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContextMaker.res.mjs";

var Api = {
  name: "StoryversePanelContext"
};

var include = ContextMaker$LiteracyplanetClientFlags.Make(Api);

var Provider = include.Provider;

var Context_initialContext = include.initialContext;

var Context_context = include.context;

var Context_P = include.P;

var Context_ContextMakerProviderNotFound = include.ContextMakerProviderNotFound;

var Context_use = include.use;

var Context = {
  Api: Api,
  initialContext: Context_initialContext,
  context: Context_context,
  P: Context_P,
  Provider: Provider,
  ContextMakerProviderNotFound: Context_ContextMakerProviderNotFound,
  use: Context_use
};

function StoryversePanelContext(props) {
  var match = React.useState(function (param) {
        return false;
      });
  var setPanelOpen = match[1];
  return React.createElement(Provider.make, {
              value: {
                panelOpen: match[0],
                closePanel: (function (param) {
                    Curry._1(setPanelOpen, (function (param) {
                            return false;
                          }));
                  }),
                openPanel: (function (param) {
                    Curry._1(setPanelOpen, (function (param) {
                            return true;
                          }));
                  }),
                togglePanel: (function (param) {
                    Curry._1(setPanelOpen, (function (o) {
                            return !o;
                          }));
                  })
              },
              children: props.children
            });
}

var make = StoryversePanelContext;

export {
  Context ,
  make ,
}
/* include Not a pure module */
