// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";

var mainBackgroundColor = Css.rgb(255, 223, 159);

var boxShadowColor = Css.rgba(0, 0, 0, {
      NAME: "num",
      VAL: 0.5
    });

var sumbitPanelBackgroundColor = Css.rgb(225, 245, 255);

var questionLinkBackgroundColor = Css.rgb(12, 156, 229);

var notPlayingAudioColor = Css.rgb(12, 156, 229);

var playingAudioColor = Css.rgb(14, 69, 120);

var hintLinkBackgroundColor = Css.rgb(9, 127, 187);

var bookLinkBackgroundColor = Css.rgb(14, 69, 120);

var disabledButtonColor = Css.rgb(204, 204, 204);

var incorrectPanelBackgroundColor = Css.rgb(241, 87, 91);

var correctPanelBackgroundColor = Css.rgb(30, 204, 16);

var selectedAnswerBorderColor = Css.rgb(12, 156, 228);

var scroll = Curry._1(Css.style, {
      hd: Css.selector("::-webkit-scrollbar", Theme$LiteracyplanetClientFlags.resizeList({
                hd: [
                  Css.width,
                  24
                ],
                tl: {
                  hd: [
                    Css.height,
                    24
                  ],
                  tl: /* [] */0
                }
              })),
      tl: {
        hd: Css.selector("::-webkit-scrollbar-thumb", Belt_List.concat({
                  hd: Css.background(Css.rgb(0, 154, 232)),
                  tl: {
                    hd: Css.border(Css.px(0), Css.none, Css.black),
                    tl: /* [] */0
                  }
                }, Theme$LiteracyplanetClientFlags.resizeList({
                      hd: [
                        Css.borderRadius,
                        12
                      ],
                      tl: /* [] */0
                    }))),
        tl: {
          hd: Css.selector("::-webkit-scrollbar-corner", {
                hd: Css.background(Css.transparent),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.selector("::-webkit-scrollbar-track", Belt_List.concat({
                      hd: Css.background(Css.rgb(255, 241, 212)),
                      tl: {
                        hd: Css.border(Css.px(0), Css.none, Css.black),
                        tl: /* [] */0
                      }
                    }, Theme$LiteracyplanetClientFlags.resizeList({
                          hd: [
                            Css.borderRadius,
                            12
                          ],
                          tl: /* [] */0
                        }))),
            tl: /* [] */0
          }
        }
      }
    });

var defaultBoxShadow = Css_Legacy_Core.Shadow.box(Css.px(1), Css.px(2), Css.px(8), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.5
        }));

var mainColumn = Curry._1(Css.style, {
      hd: Css.background(mainBackgroundColor),
      tl: {
        hd: Css.boxShadow(defaultBoxShadow),
        tl: {
          hd: Css.height(Css.vh(100.0)),
          tl: {
            hd: Css.display(Css.grid),
            tl: {
              hd: Css.overflowY(Css.auto),
              tl: /* [] */0
            }
          }
        }
      }
    });

var layoutFonts = {
  hd: {
    NAME: "custom",
    VAL: "museo-sans-rounded"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "sans-serif"
    },
    tl: /* [] */0
  }
};

export {
  layoutFonts ,
  mainBackgroundColor ,
  boxShadowColor ,
  sumbitPanelBackgroundColor ,
  questionLinkBackgroundColor ,
  notPlayingAudioColor ,
  playingAudioColor ,
  hintLinkBackgroundColor ,
  bookLinkBackgroundColor ,
  disabledButtonColor ,
  incorrectPanelBackgroundColor ,
  correctPanelBackgroundColor ,
  selectedAnswerBorderColor ,
  scroll ,
  defaultBoxShadow ,
  mainColumn ,
}
/* mainBackgroundColor Not a pure module */
