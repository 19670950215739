// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";
import * as K2Styles$LiteracyplanetTupperware from "./K2Styles.res.mjs";

var primaryColor = Css.hex("ffe09f");

var headerColor = Css.hex("ff6667");

var whiteColor = Css.hex("fff");

var contentHeaderColor = Css.hex("cbeeff");

var blueColor = Css.hex("0c9ce5");

var darkBlue = Css.hex("9addff");

var lightBlue = Css.hex("009ae8");

var greenIcon = Css.hex("1ECC10");

var redIcon = Css.hex("FF6667");

var incorrectBackground = Css.hex("ffd9da");

var correctBackground = Css.hex("e1ffdf");

var clozeDragText = Css.hex("adddf5");

var stimulusTab = Css.hex("cbeeff");

var stimulusTabSelected = Css.hex("009ae8");

var stimulusTabUnselectedColor = Css.hex("558fac");

var defaultBlue = Css.hex("0c9ce5");

var hoverBlue = Css.hex("0077b3");

var activeBlue = Css.hex("005580");

var defaultGreen = Css.hex("1ECC11");

var hoverGreen = Css.hex("00802a");

var activeGreen = Css.hex("006622");

var layoutFonts = {
  hd: {
    NAME: "custom",
    VAL: "museo-sans-rounded"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "sans-serif"
    },
    tl: /* [] */0
  }
};

var css = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: /* [] */0
            }),
        tl: {
          hd: Css.fontFamilies(layoutFonts),
          tl: {
            hd: Css.height({
                  NAME: "vh",
                  VAL: 100.0
                }),
            tl: {
              hd: Css.selector("*", {
                    hd: Css.boxSizing(Css.borderBox),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var gridRows = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 90
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: /* [] */0
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: gridRows,
        tl: {
          hd: "SkilfulLayoutContainer-Layout",
          tl: /* [] */0
        }
      }
    });

var Layout = {
  css: css,
  gridRows: gridRows,
  className: className
};

var css$1 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gridTemplateColumns({
                hd: {
                  NAME: "fr",
                  VAL: 2.0
                },
                tl: {
                  hd: {
                    NAME: "fr",
                    VAL: 1.0
                  },
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.color(whiteColor),
            tl: {
              hd: Css.backgroundColor(headerColor),
              tl: /* [] */0
            }
          }
        }
      }
    });

var gridRows$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        30
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.padding,
          20
        ],
        tl: /* [] */0
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: gridRows$1,
        tl: {
          hd: "SkilfulLayoutContainer-Header",
          tl: /* [] */0
        }
      }
    });

var Header = {
  css: css$1,
  gridRows: gridRows$1,
  className: className$1
};

var css$2 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: {
        hd: Css.justifySelf("end_"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.justifyContent(Css.center),
              tl: {
                hd: Css.height({
                      NAME: "percent",
                      VAL: 100.0
                    }),
                tl: {
                  hd: Css.userSelect(Css.none),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: "SkilfulLayoutContainer-Exit",
        tl: /* [] */0
      }
    });

var Exit = {
  css: css$2,
  className: className$2
};

var iconElement = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 70.0
          }),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(whiteColor),
        tl: /* [] */0
      }
    });

var resizedIcon = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        15
      ],
      tl: /* [] */0
    });

var className$3 = Curry._1(Css.merge, {
      hd: iconElement,
      tl: {
        hd: resizedIcon,
        tl: {
          hd: "ExitIcon",
          tl: /* [] */0
        }
      }
    });

var ExitIcon = {
  iconElement: iconElement,
  resizedIcon: resizedIcon,
  className: className$3
};

var css$3 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.overflowY("hidden"),
        tl: {
          hd: Css.backgroundColor(primaryColor),
          tl: /* [] */0
        }
      }
    });

var className$4 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: "SkilfulLayoutContainer-NoStimulus",
        tl: /* [] */0
      }
    });

var NoStimulus = {
  css: css$3,
  className: className$4
};

var css$4 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.overflowY("hidden"),
        tl: {
          hd: Css.backgroundColor(primaryColor),
          tl: /* [] */0
        }
      }
    });

function gridCols(showSimulus) {
  return Theme$LiteracyplanetClientFlags.resizeGridCols({
              hd: showSimulus ? ({
                    NAME: "ratio",
                    VAL: 500
                  }) : ({
                    NAME: "ratio",
                    VAL: 60
                  }),
              tl: {
                hd: {
                  NAME: "css",
                  VAL: {
                    NAME: "fr",
                    VAL: 1.0
                  }
                },
                tl: /* [] */0
              }
            });
}

function className$5(showSimulus) {
  return Curry._1(Css.merge, {
              hd: css$4,
              tl: {
                hd: gridCols(showSimulus),
                tl: {
                  hd: "SkilfulLayoutContainer-HasStimulus",
                  tl: /* [] */0
                }
              }
            });
}

var HasStimulus = {
  css: css$4,
  gridCols: gridCols,
  className: className$5
};

var css$5 = Curry._1(Css.style, {
      hd: Css.backgroundColor(stimulusTab),
      tl: {
        hd: Css.color(stimulusTabUnselectedColor),
        tl: {
          hd: Css.textAlign(Css.center),
          tl: {
            hd: Css.cursor(Css.pointer),
            tl: /* [] */0
          }
        }
      }
    });

var m = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        10
      ],
      tl: /* [] */0
    });

var className$6 = Curry._1(Css.merge, {
      hd: css$5,
      tl: {
        hd: m,
        tl: {
          hd: "SkilfulStimulusContainer-StimulusTab",
          tl: /* [] */0
        }
      }
    });

var StimulusTab = {
  css: css$5,
  m: m,
  className: className$6
};

var css$6 = Curry._1(Css.style, {
      hd: Css.backgroundColor(stimulusTabSelected),
      tl: {
        hd: Css.color(Css.white),
        tl: /* [] */0
      }
    });

var className$7 = Curry._1(Css.merge, {
      hd: className$6,
      tl: {
        hd: css$6,
        tl: {
          hd: "SkilfulStimulusContainer-StimulusTabSelected",
          tl: /* [] */0
        }
      }
    });

var StimulusTabSelected = {
  css: css$6,
  className: className$7
};

var css$7 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: {
                hd: {
                  NAME: "fr",
                  VAL: 1.0
                },
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var m$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginBottom,
        20
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          22
        ],
        tl: /* [] */0
      }
    });

var className$8 = Curry._1(Css.merge, {
      hd: css$7,
      tl: {
        hd: m$1,
        tl: {
          hd: "SkilfulStimulusContainer-StimulusTabs",
          tl: /* [] */0
        }
      }
    });

var StimulusTabs = {
  css: css$7,
  m: m$1,
  className: className$8
};

var css$8 = Curry._1(Css.style, {
      hd: Css.position(Css.relative),
      tl: {
        hd: Css.display(Css.grid),
        tl: {
          hd: Css.overflow(Css.hidden),
          tl: /* [] */0
        }
      }
    });

function m$2(show) {
  return Theme$LiteracyplanetClientFlags.resize({
              hd: [
                Theme$LiteracyplanetClientFlags.fontSize,
                20
              ],
              tl: {
                hd: [
                  Css.width,
                  show ? 500 : 75
                ],
                tl: /* [] */0
              }
            });
}

var c = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "css",
        VAL: {
          NAME: "fr",
          VAL: 1.0
        }
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 30
        },
        tl: /* [] */0
      }
    });

function className$9(show) {
  return Curry._1(Css.merge, {
              hd: css$8,
              tl: {
                hd: c,
                tl: {
                  hd: m$2(show),
                  tl: {
                    hd: "SkilfulStimulusContainer-StimulusSidebar",
                    tl: /* [] */0
                  }
                }
              }
            });
}

var StimulusSidebar = {
  css: css$8,
  m: m$2,
  c: c,
  className: className$9
};

var css$9 = Curry._1(Css.style, {
      hd: Css.backgroundColor(whiteColor),
      tl: {
        hd: Css.height({
              NAME: "percent",
              VAL: 100.0
            }),
        tl: {
          hd: Css.borderColor(Css.hex("DABB83")),
          tl: {
            hd: Css.borderStyle(Css.solid),
            tl: {
              hd: Css.borderWidth({
                    NAME: "px",
                    VAL: 0
                  }),
              tl: {
                hd: Css.overflowY(Css.auto),
                tl: {
                  hd: Css.selector("img", {
                        hd: Css.maxWidth({
                              NAME: "percent",
                              VAL: 90.0
                            }),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var m$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.borderRightWidth,
        10
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.padding,
          20
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            20
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.lineHeight,
              26
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: css$9,
      tl: {
        hd: m$3,
        tl: {
          hd: "SkilfulStimulusContainer-StimulusPanel",
          tl: /* [] */0
        }
      }
    });

var StimulusPanel = {
  css: css$9,
  m: m$3,
  className: className$10
};

var iconElement$1 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(greenIcon),
      tl: /* [] */0
    });

var className$11 = Curry._1(Css.merge, {
      hd: iconElement$1,
      tl: {
        hd: "CorrectIcon",
        tl: /* [] */0
      }
    });

var CorrectIcon = {
  iconElement: iconElement$1,
  className: className$11
};

var iconElement$2 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(redIcon),
      tl: /* [] */0
    });

var className$12 = Curry._1(Css.merge, {
      hd: iconElement$2,
      tl: {
        hd: "IncorrectIcon",
        tl: /* [] */0
      }
    });

var CrossIcon = {
  iconElement: iconElement$2,
  className: className$12
};

var className$13 = Curry._1(Css.merge, {
      hd: "PulloutIcon",
      tl: /* [] */0
    });

var PulloutIcon = {
  className: className$13
};

var className$14 = Curry._1(Css.merge, {
      hd: "PullinIcon",
      tl: /* [] */0
    });

var PullinIcon = {
  className: className$14
};

var css$10 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: {
        hd: Css.alignSelf(Css.center),
        tl: {
          hd: Css.position(Css.absolute),
          tl: {
            hd: Css_Legacy_Core.SVG.fill(whiteColor),
            tl: {
              hd: Css.backgroundColor(defaultBlue),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(hoverBlue),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.active({
                        hd: Css.backgroundColor(activeBlue),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var m$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        50
      ],
      tl: {
        hd: [
          Css.height,
          80
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderRadius,
            15
          ],
          tl: {
            hd: [
              Css.right,
              10
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$15 = Curry._1(Css.merge, {
      hd: css$10,
      tl: {
        hd: m$4,
        tl: {
          hd: "SkilfulStimulusContainer-StimulusHandle",
          tl: /* [] */0
        }
      }
    });

var StimulusHandle = {
  css: css$10,
  m: m$4,
  className: className$15
};

var css$11 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateRows({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: /* [] */0
            }),
        tl: {
          hd: Css.overflowY("hidden"),
          tl: {
            hd: Css.position(Css.relative),
            tl: /* [] */0
          }
        }
      }
    });

var className$16 = Curry._1(Css.merge, {
      hd: css$11,
      tl: {
        hd: "SkilfulQuestionContent-SkilfulContentContainer",
        tl: /* [] */0
      }
    });

var SkilfulContentContainer = {
  css: css$11,
  className: className$16
};

var css$12 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.top(Css.zero),
        tl: {
          hd: Css.left(Css.zero),
          tl: {
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100.0
                }),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.overflowY("hidden"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var padd = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        20
      ],
      tl: /* [] */0
    });

var gridRows$2 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 70
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 70
          },
          tl: /* [] */0
        }
      }
    });

var className$17 = Curry._1(Css.merge, {
      hd: css$12,
      tl: {
        hd: padd,
        tl: {
          hd: gridRows$2,
          tl: {
            hd: "SkilfulQuestionContent-ContentContainer",
            tl: /* [] */0
          }
        }
      }
    });

var ContentContainer = {
  css: css$12,
  padd: padd,
  gridRows: gridRows$2,
  className: className$17
};

var css$13 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: {
                hd: {
                  NAME: "fr",
                  VAL: 3.0
                },
                tl: {
                  hd: {
                    NAME: "fr",
                    VAL: 1.0
                  },
                  tl: /* [] */0
                }
              }
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(contentHeaderColor),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.paddingLeft,
        30
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderTopLeftRadius,
          35
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderTopRightRadius,
            35
          ],
          tl: /* [] */0
        }
      }
    });

var className$18 = Curry._1(Css.merge, {
      hd: css$13,
      tl: {
        hd: m$5,
        tl: {
          hd: "SkilfulQuestionContent-ContentHeader",
          tl: /* [] */0
        }
      }
    });

var ContentHeader = {
  css: css$13,
  m: m$5,
  className: className$18
};

var iconElement$3 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: {
        hd: Css.height({
              NAME: "percent",
              VAL: 40.0
            }),
        tl: {
          hd: Css_Legacy_Core.SVG.fill(defaultBlue),
          tl: {
            hd: Css.hover({
                  hd: Css_Legacy_Core.SVG.fill(hoverBlue),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.active({
                    hd: Css_Legacy_Core.SVG.fill(activeBlue),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$19 = Curry._1(Css.merge, {
      hd: iconElement$3,
      tl: {
        hd: "SummaryIcon",
        tl: /* [] */0
      }
    });

var SummaryIcon = {
  iconElement: iconElement$3,
  className: className$19
};

var css$14 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: /* [] */0
            }),
        tl: {
          hd: Css.justifyItems("center"),
          tl: /* [] */0
        }
      }
    });

var className$20 = Curry._1(Css.merge, {
      hd: css$14,
      tl: {
        hd: "GridJustifyCenter",
        tl: /* [] */0
      }
    });

var GridJustifyCenter = {
  css: css$14,
  className: className$20
};

var css$15 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: {
                hd: {
                  NAME: "fr",
                  VAL: 1.0
                },
                tl: {
                  hd: {
                    NAME: "fr",
                    VAL: 1.0
                  },
                  tl: /* [] */0
                }
              }
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(contentHeaderColor),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.paddingLeft,
        20
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.paddingRight,
          20
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomLeftRadius,
            35
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.borderBottomRightRadius,
              35
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$21 = Curry._1(Css.merge, {
      hd: css$15,
      tl: {
        hd: m$6,
        tl: {
          hd: "SkilfulQuestionContent-ContentFooter",
          tl: /* [] */0
        }
      }
    });

var ContentFooter = {
  css: css$15,
  m: m$6,
  className: className$21
};

var css$16 = Curry._1(Css.style, {
      hd: Css.backgroundColor(whiteColor),
      tl: {
        hd: Css.overflowY(Css.auto),
        tl: /* [] */0
      }
    });

var m$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        30
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          24
        ],
        tl: /* [] */0
      }
    });

var marking = Curry._1(Css.style, {
      hd: Css.selector(".MarkStatusPanelCorrect", {
            hd: Css.display(Css.none),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.selector(".MarkStatusPanelIncorrect", {
              hd: Css.display(Css.none),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.selector(".correct .MarkStatusPanelCorrect", {
                hd: Css.display(Css.grid),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.selector(".wrong .MarkStatusPanelIncorrect", {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var className$22 = Curry._1(Css.merge, {
      hd: css$16,
      tl: {
        hd: m$7,
        tl: {
          hd: marking,
          tl: {
            hd: "SkilfulQuestionContent-Content",
            tl: /* [] */0
          }
        }
      }
    });

var Content = {
  css: css$16,
  m: m$7,
  marking: marking,
  className: className$22
};

var css$17 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.overflowY("hidden"),
        tl: {
          hd: Css.color(blueColor),
          tl: {
            hd: Css.position(Css.absolute),
            tl: {
              hd: Css.top(Css.zero),
              tl: {
                hd: Css.left(Css.zero),
                tl: {
                  hd: Css.width({
                        NAME: "percent",
                        VAL: 100.0
                      }),
                  tl: {
                    hd: Css.height({
                          NAME: "percent",
                          VAL: 100.0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var resize = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        20
      ],
      tl: /* [] */0
    });

var gridRows$3 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 70
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 70
          },
          tl: /* [] */0
        }
      }
    });

var className$23 = Curry._1(Css.merge, {
      hd: css$17,
      tl: {
        hd: resize,
        tl: {
          hd: gridRows$3,
          tl: {
            hd: "SkilfulQuestionContent-EndScreenContainer",
            tl: /* [] */0
          }
        }
      }
    });

var EndScreenContainer = {
  css: css$17,
  resize: resize,
  gridRows: gridRows$3,
  className: className$23
};

var css$18 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.backgroundColor(contentHeaderColor),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: {
                hd: Css.fontWeight(Css.bold),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var m$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        24
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderTopLeftRadius,
          35
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderTopRightRadius,
            35
          ],
          tl: /* [] */0
        }
      }
    });

var className$24 = Curry._1(Css.merge, {
      hd: css$18,
      tl: {
        hd: m$8,
        tl: {
          hd: "SkilfulQuestionContent-EndScreenHeader",
          tl: /* [] */0
        }
      }
    });

var EndScreenHeader = {
  css: css$18,
  m: m$8,
  className: className$24
};

var css$19 = Curry._1(Css.style, {
      hd: Css.backgroundColor(whiteColor),
      tl: {
        hd: Css.overflowY(Css.auto),
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.alignItems("start"),
              tl: {
                hd: Css.color(Css.black),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var m$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        30
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          22
        ],
        tl: /* [] */0
      }
    });

var className$25 = Curry._1(Css.merge, {
      hd: css$19,
      tl: {
        hd: m$9,
        tl: {
          hd: "SkilfulQuestionContent-EndScreenContent",
          tl: /* [] */0
        }
      }
    });

var EndScreenContent = {
  css: css$19,
  m: m$9,
  className: className$25
};

var css$20 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.marginBottom("zero"),
        tl: {
          hd: Css.fontWeight(Css.bold),
          tl: /* [] */0
        }
      }
    });

var gridCols$1 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 50
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 200
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 200
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: 50
            },
            tl: /* [] */0
          }
        }
      }
    });

var m$10 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        10
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          14
        ],
        tl: /* [] */0
      }
    });

var className$26 = Curry._1(Css.merge, {
      hd: css$20,
      tl: {
        hd: gridCols$1,
        tl: {
          hd: m$10,
          tl: {
            hd: "SkilfulQuestionContent-EndScreenTableHeader",
            tl: /* [] */0
          }
        }
      }
    });

var EndScreenTableHeader = {
  css: css$20,
  gridCols: gridCols$1,
  m: m$10,
  className: className$26
};

var resizedIcon$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginRight,
        10
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var className$27 = Curry._1(Css.merge, {
      hd: className$11,
      tl: {
        hd: resizedIcon$1,
        tl: /* [] */0
      }
    });

var EndScreenCorrectIcon = {
  resizedIcon: resizedIcon$1,
  className: className$27
};

var resizedIcon$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginRight,
        10
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var className$28 = Curry._1(Css.merge, {
      hd: className$12,
      tl: {
        hd: resizedIcon$2,
        tl: /* [] */0
      }
    });

var EndScreenIncorrectIcon = {
  resizedIcon: resizedIcon$2,
  className: className$28
};

var css$21 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.borderColor(blueColor),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.cursor(Css.pointer),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var gridCols$2 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 50
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 200
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 200
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: 50
            },
            tl: /* [] */0
          }
        }
      }
    });

var m$11 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        5
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderWidth,
          1
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderRadius,
            50
          ],
          tl: {
            hd: [
              Css.marginBottom,
              10
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$29 = Curry._1(Css.merge, {
      hd: css$21,
      tl: {
        hd: gridCols$2,
        tl: {
          hd: m$11,
          tl: {
            hd: "SkilfulQuestionContent-EndScreenLink",
            tl: /* [] */0
          }
        }
      }
    });

var EndScreenLink = {
  css: css$21,
  gridCols: gridCols$2,
  m: m$11,
  className: className$29
};

var css$22 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: /* [] */0
      }
    });

var className$30 = Curry._1(Css.merge, {
      hd: css$22,
      tl: {
        hd: "SkilfulQuestionContent-EndScreenMarkingState",
        tl: /* [] */0
      }
    });

var EndScreenMarkingState = {
  css: css$22,
  className: className$30
};

var css$23 = Curry._1(Css.style, {
      hd: Css.fontWeight(Css.bold),
      tl: {
        hd: Css.justifySelf(Css.center),
        tl: /* [] */0
      }
    });

var className$31 = Curry._1(Css.merge, {
      hd: css$23,
      tl: {
        hd: "SkilfulQuestionContent-EndScreenQuestionIndex",
        tl: /* [] */0
      }
    });

var EndScreenQuestionIndex = {
  css: css$23,
  className: className$31
};

var css$24 = Curry._1(Css.style, {
      hd: Css.justifySelf("right"),
      tl: /* [] */0
    });

var m$12 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        40
      ],
      tl: /* [] */0
    });

var className$32 = Curry._1(Css.merge, {
      hd: css$24,
      tl: {
        hd: m$12,
        tl: {
          hd: "SkilfulQuestionContent-EndScreenPlayIcon",
          tl: /* [] */0
        }
      }
    });

var playClassName = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(whiteColor),
      tl: {
        hd: Css.pointerEvents(Css.none),
        tl: /* [] */0
      }
    });

var backgroundClassName = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(defaultGreen),
      tl: {
        hd: Css.hover({
              hd: Css_Legacy_Core.SVG.fill(hoverGreen),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.active({
                hd: Css_Legacy_Core.SVG.fill(activeGreen),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var EndScreenPlayIcon = {
  css: css$24,
  m: m$12,
  className: className$32,
  playClassName: playClassName,
  backgroundClassName: backgroundClassName
};

var css$25 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "fr",
                VAL: 1.0
              },
              tl: /* [] */0
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(contentHeaderColor),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m$13 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.paddingLeft,
        20
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.paddingRight,
          20
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomLeftRadius,
            35
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.borderBottomRightRadius,
              35
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$33 = Curry._1(Css.merge, {
      hd: css$25,
      tl: {
        hd: m$13,
        tl: {
          hd: "SkilfulQuestionContent-EndScreenFooter",
          tl: /* [] */0
        }
      }
    });

var EndScreenFooter = {
  css: css$25,
  m: m$13,
  className: className$33
};

var buttonStyles = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: Css.borderStyle("none"),
        tl: {
          hd: Css.fontFamilies(layoutFonts),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: Css.justifyContent(Css.center),
                tl: {
                  hd: Css.cursor(Css.pointer),
                  tl: {
                    hd: Css.fontWeight(Css.bold),
                    tl: {
                      hd: Css.userSelect(Css.none),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var buttonSize = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        100
      ],
      tl: {
        hd: [
          Css.height,
          50
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            20
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.borderRadius,
              25
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$34 = Curry._1(Css.merge, {
      hd: buttonStyles,
      tl: {
        hd: buttonSize,
        tl: {
          hd: "Button",
          tl: /* [] */0
        }
      }
    });

var Button = {
  buttonStyles: buttonStyles,
  buttonSize: buttonSize,
  className: className$34
};

var buttonStyles$1 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.backgroundColor(defaultBlue),
        tl: {
          hd: Css.hover({
                hd: Css.backgroundColor(hoverBlue),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css.backgroundColor(activeBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var buttonSize$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        120
      ],
      tl: /* [] */0
    });

var className$35 = Curry._1(Css.merge, {
      hd: className$34,
      tl: {
        hd: buttonStyles$1,
        tl: {
          hd: buttonSize$1,
          tl: {
            hd: "MarkButton",
            tl: /* [] */0
          }
        }
      }
    });

var MarkButton = {
  buttonStyles: buttonStyles$1,
  buttonSize: buttonSize$1,
  className: className$35
};

var buttonStyles$2 = Curry._1(Css.style, {
      hd: Css.justifySelf("left"),
      tl: {
        hd: Css.color(defaultBlue),
        tl: {
          hd: Css.hover({
                hd: Css.color(hoverBlue),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css.color(activeBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var className$36 = Curry._1(Css.merge, {
      hd: className$34,
      tl: {
        hd: buttonStyles$2,
        tl: {
          hd: "BackButton",
          tl: /* [] */0
        }
      }
    });

var BackButton = {
  buttonStyles: buttonStyles$2,
  className: className$36
};

var buttonStyles$3 = Curry._1(Css.style, {
      hd: Css.justifySelf("right"),
      tl: {
        hd: Css.color(defaultBlue),
        tl: {
          hd: Css.hover({
                hd: Css.color(hoverBlue),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css.color(activeBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var className$37 = Curry._1(Css.merge, {
      hd: className$34,
      tl: {
        hd: buttonStyles$3,
        tl: {
          hd: "NextButton",
          tl: /* [] */0
        }
      }
    });

var NextButton = {
  buttonStyles: buttonStyles$3,
  className: className$37
};

var iconElement$4 = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 40.0
          }),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(defaultBlue),
        tl: {
          hd: Css.hover({
                hd: Css_Legacy_Core.SVG.fill(hoverBlue),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css_Legacy_Core.SVG.fill(activeBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var resizedIcon$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        15
      ],
      tl: /* [] */0
    });

var className$38 = Curry._1(Css.merge, {
      hd: iconElement$4,
      tl: {
        hd: resizedIcon$3,
        tl: {
          hd: "NextArrowIcon",
          tl: /* [] */0
        }
      }
    });

var NextArrowIcon = {
  iconElement: iconElement$4,
  resizedIcon: resizedIcon$3,
  className: className$38
};

var iconElement$5 = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 40.0
          }),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(defaultBlue),
        tl: {
          hd: Css.hover({
                hd: Css_Legacy_Core.SVG.fill(hoverBlue),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css_Legacy_Core.SVG.fill(activeBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var resizedIcon$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginRight,
        15
      ],
      tl: /* [] */0
    });

var className$39 = Curry._1(Css.merge, {
      hd: iconElement$5,
      tl: {
        hd: resizedIcon$4,
        tl: {
          hd: "BackArrowIcon",
          tl: /* [] */0
        }
      }
    });

var BackArrowIcon = {
  iconElement: iconElement$5,
  resizedIcon: resizedIcon$4,
  className: className$39
};

var el = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 30.0
          }),
      tl: {
        hd: Css.backgroundColor(darkBlue),
        tl: /* [] */0
      }
    });

var resizedIcon$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: {
        hd: [
          Css.width,
          120
        ],
        tl: {
          hd: [
            Css.height,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var className$40 = Curry._1(Css.merge, {
      hd: el,
      tl: {
        hd: resizedIcon$5,
        tl: {
          hd: "ActivityProgressMeter",
          tl: /* [] */0
        }
      }
    });

var ActivityProgressMeter = {
  el: el,
  resizedIcon: resizedIcon$5,
  className: className$40
};

var el$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.width({
                NAME: "percent",
                VAL: 100.0
              }),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: /* [] */0
          }
        }
      }
    });

var className$41 = Curry._1(Css.merge, {
      hd: el$1,
      tl: {
        hd: "MiddleContainer",
        tl: /* [] */0
      }
    });

var MiddleContainer = {
  el: el$1,
  className: className$41
};

var el$2 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.fontWeight(Css.bold),
        tl: {
          hd: Css.color(blueColor),
          tl: {
            hd: Css.userSelect(Css.none),
            tl: /* [] */0
          }
        }
      }
    });

var resizedIcon$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        20
      ],
      tl: /* [] */0
    });

var className$42 = Curry._1(Css.merge, {
      hd: el$2,
      tl: {
        hd: resizedIcon$6,
        tl: {
          hd: "ActivityProgress",
          tl: /* [] */0
        }
      }
    });

var ActivityProgress = {
  el: el$2,
  resizedIcon: resizedIcon$6,
  className: className$42
};

var el$3 = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 100.0
          }),
      tl: {
        hd: Css.backgroundColor(blueColor),
        tl: /* [] */0
      }
    });

function className$43(w) {
  return Curry._1(Css.merge, {
              hd: el$3,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.width({
                            NAME: "percent",
                            VAL: w
                          }),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: "ActivityProgressBar",
                  tl: /* [] */0
                }
              }
            });
}

var ActivityProgressBar = {
  el: el$3,
  className: className$43
};

var el$4 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.unsafe("justifyItems", "center"),
          tl: {
            hd: Css.color(blueColor),
            tl: {
              hd: Css.backgroundColor(whiteColor),
              tl: {
                hd: Css.fontWeight(Css.bold),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var resizedIcon$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        70
      ],
      tl: {
        hd: [
          Css.height,
          40
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var className$44 = Curry._1(Css.merge, {
      hd: el$4,
      tl: {
        hd: resizedIcon$7,
        tl: /* [] */0
      }
    });

var MarkContainer = {
  el: el$4,
  resizedIcon: resizedIcon$7,
  className: className$44
};

var resizedIcon$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        30
      ],
      tl: /* [] */0
    });

var className$45 = Curry._1(Css.merge, {
      hd: className$44,
      tl: {
        hd: resizedIcon$8,
        tl: {
          hd: "CorrectCount",
          tl: /* [] */0
        }
      }
    });

var CorrectCount = {
  resizedIcon: resizedIcon$8,
  className: className$45
};

var resizedIcon$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: /* [] */0
    });

var className$46 = Curry._1(Css.merge, {
      hd: className$44,
      tl: {
        hd: resizedIcon$9,
        tl: {
          hd: "IncorrectCount",
          tl: /* [] */0
        }
      }
    });

var InorrectCount = {
  resizedIcon: resizedIcon$9,
  className: className$46
};

var el$5 = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 50.0
          }),
      tl: /* [] */0
    });

var resizedIcon$10 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: {
        hd: [
          Css.marginRight,
          10
        ],
        tl: /* [] */0
      }
    });

var className$47 = Curry._1(Css.merge, {
      hd: className$11,
      tl: {
        hd: resizedIcon$10,
        tl: {
          hd: el$5,
          tl: /* [] */0
        }
      }
    });

var CorrectCountIcon = {
  el: el$5,
  resizedIcon: resizedIcon$10,
  className: className$47
};

var el$6 = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 50.0
          }),
      tl: /* [] */0
    });

var resizedIcon$11 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: {
        hd: [
          Css.marginRight,
          10
        ],
        tl: /* [] */0
      }
    });

var className$48 = Curry._1(Css.merge, {
      hd: className$12,
      tl: {
        hd: resizedIcon$11,
        tl: {
          hd: el$6,
          tl: /* [] */0
        }
      }
    });

var IncorrectCountIcon = {
  el: el$6,
  resizedIcon: resizedIcon$11,
  className: className$48
};

var el$7 = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100.0
          }),
      tl: {
        hd: Css.display(Css.grid),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.borderStyle(Css.solid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var resized = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        80
      ],
      tl: {
        hd: [
          Css.marginBottom,
          20
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderWidth,
            2
          ],
          tl: /* [] */0
        }
      }
    });

var className$49 = Curry._1(Css.merge, {
      hd: el$7,
      tl: {
        hd: resized,
        tl: /* [] */0
      }
    });

var MarkStatusPanel = {
  el: el$7,
  resized: resized,
  className: className$49
};

var el$8 = Curry._1(Css.style, {
      hd: Css.backgroundColor(correctBackground),
      tl: {
        hd: Css.borderColor(greenIcon),
        tl: /* [] */0
      }
    });

var className$50 = Curry._1(Css.merge, {
      hd: el$8,
      tl: {
        hd: className$49,
        tl: {
          hd: "MarkStatusPanelCorrect",
          tl: /* [] */0
        }
      }
    });

var MarkStatusPanelCorrect = {
  el: el$8,
  className: className$50
};

var el$9 = Curry._1(Css.style, {
      hd: Css.backgroundColor(incorrectBackground),
      tl: {
        hd: Css.borderColor(redIcon),
        tl: /* [] */0
      }
    });

var className$51 = Curry._1(Css.merge, {
      hd: el$9,
      tl: {
        hd: className$49,
        tl: {
          hd: "MarkStatusPanelIncorrect",
          tl: /* [] */0
        }
      }
    });

var MarkStatusPanelIncorrect = {
  el: el$9,
  className: className$51
};

var css$26 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.justifyContent("left"),
          tl: {
            hd: Css.selector(".SkilfulInstruction", {
                  hd: Css.fontWeight(Css.bold),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.selector(".AudioQueryComponentButtonContainer", Theme$LiteracyplanetClientFlags.resizeList({
                        hd: [
                          Css.marginRight,
                          5
                        ],
                        tl: {
                          hd: [
                            Css.height,
                            30
                          ],
                          tl: /* [] */0
                        }
                      })),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m$14 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.paddingLeft,
        10
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          22
        ],
        tl: {
          hd: [
            Css.marginBottom,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var className$52 = Curry._1(Css.merge, {
      hd: css$26,
      tl: {
        hd: m$14,
        tl: {
          hd: "SkilfulStemHeading",
          tl: /* [] */0
        }
      }
    });

var SkilfulStemHeading = {
  css: css$26,
  m: m$14,
  className: className$52
};

function className$53(columns) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.display(Css.grid),
                    tl: {
                      hd: columns !== undefined ? Css.gridTemplateColumns(Belt_List.map(Belt_List.make(columns, 1.0), (function (size) {
                                    return {
                                            NAME: "fr",
                                            VAL: size
                                          };
                                  }))) : Css.gridTemplateColumns({
                              hd: {
                                NAME: "fr",
                                VAL: 1.0
                              },
                              tl: /* [] */0
                            }),
                      tl: {
                        hd: Css.selector(".ResponseOptionContainer", Belt_List.concat(/* [] */0, Theme$LiteracyplanetClientFlags.resizeList({
                                      hd: [
                                        Theme$LiteracyplanetClientFlags.paddingLeft,
                                        10
                                      ],
                                      tl: /* [] */0
                                    }))),
                        tl: {
                          hd: Css.selector(".unselected .SkilfulRadioButtonMultiChoice-RadioSelection", {
                                hd: Css.visibility(Css.hidden),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.selector(".selected .SkilfulRadioButtonMultiChoice-RadioSelection", Belt_List.concat({
                                      hd: Css.visibility(Css.visible),
                                      tl: {
                                        hd: Css.backgroundColor(blueColor),
                                        tl: /* [] */0
                                      }
                                    }, Theme$LiteracyplanetClientFlags.resizeList({
                                          hd: [
                                            Css.width,
                                            10
                                          ],
                                          tl: {
                                            hd: [
                                              Css.height,
                                              10
                                            ],
                                            tl: {
                                              hd: [
                                                Theme$LiteracyplanetClientFlags.borderRadius,
                                                5
                                              ],
                                              tl: /* [] */0
                                            }
                                          }
                                        }))),
                            tl: {
                              hd: Css.selector(".selected.correct .CorrectIcon", {
                                    hd: Css.display(Css.block),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.selector(".selected.wrong .IncorrectIcon", {
                                      hd: Css.display(Css.block),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.selector(".selected .K2AnswerWrap-Answer", Belt_List.concat({
                                            hd: Css.borderStyle(Css.solid),
                                            tl: {
                                              hd: Css.borderColor(Theme$LiteracyplanetClientFlags.primaryColor),
                                              tl: /* [] */0
                                            }
                                          }, Theme$LiteracyplanetClientFlags.resizeList({
                                                hd: [
                                                  Theme$LiteracyplanetClientFlags.borderWidth,
                                                  7
                                                ],
                                                tl: /* [] */0
                                              }))),
                                  tl: {
                                    hd: Css.selector(".selected.correct .ResponseOptionContainer", {
                                          hd: Css.backgroundColor(correctBackground),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.selector(".selected.wrong .ResponseOptionContainer", {
                                            hd: Css.backgroundColor(incorrectBackground),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.selector(".unselected.wrong .CorrectIcon", {
                                              hd: Css.display(Css.block),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: Theme$LiteracyplanetClientFlags.resize({
                      hd: [
                        Css.gridColumnGap,
                        25
                      ],
                      tl: /* [] */0
                    }),
                tl: {
                  hd: "SkilfulMultiChoiceContainer-MultiChoiceMarkStylingContainer",
                  tl: /* [] */0
                }
              }
            });
}

var MultiChoiceMarkStylingContainer = {
  className: className$53
};

var SkilfulMultiChoiceContainer = {
  MultiChoiceMarkStylingContainer: MultiChoiceMarkStylingContainer
};

var displayNone = Curry._1(Css.style, {
      hd: Css.display(Css.none),
      tl: /* [] */0
    });

var el$10 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.cursor(Css.pointer),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: /* [] */0
        }
      }
    });

var resized$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        20
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.paddingTop,
          10
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.paddingBottom,
            10
          ],
          tl: /* [] */0
        }
      }
    });

var gridCols$3 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 30
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 30
          },
          tl: /* [] */0
        }
      }
    });

var className$54 = Curry._1(Css.merge, {
      hd: el$10,
      tl: {
        hd: resized$1,
        tl: {
          hd: gridCols$3,
          tl: {
            hd: "ResponseOptionContainer",
            tl: {
              hd: "SkilfulRadioButtonMultiChoice",
              tl: /* [] */0
            }
          }
        }
      }
    });

var el$11 = Curry._1(Css.style, {
      hd: Css.borderStyle(Css.solid),
      tl: {
        hd: Css.borderColor(blueColor),
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.unsafe("justifyItems", "center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var resized$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderRadius,
            10
          ],
          tl: {
            hd: [
              Css.marginRight,
              10
            ],
            tl: {
              hd: [
                Theme$LiteracyplanetClientFlags.borderWidth,
                2
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$55 = Curry._1(Css.merge, {
      hd: el$11,
      tl: {
        hd: resized$2,
        tl: {
          hd: "SkilfulRadioButtonMultiChoice-Radio",
          tl: /* [] */0
        }
      }
    });

var Radio = {
  el: el$11,
  resized: resized$2,
  className: className$55
};

var resized$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var className$56 = Curry._1(Css.merge, {
      hd: displayNone,
      tl: {
        hd: resized$3,
        tl: {
          hd: className$11,
          tl: {
            hd: "SkilfulRadioButtonMultiChoice-CorrectMarkIcon",
            tl: /* [] */0
          }
        }
      }
    });

var CorrectMarkIcon = {
  resized: resized$3,
  className: className$56
};

var resized$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var className$57 = Curry._1(Css.merge, {
      hd: displayNone,
      tl: {
        hd: resized$4,
        tl: {
          hd: className$12,
          tl: {
            hd: "SkilfulRadioButtonMultiChoice-IncorrectMarkIcon",
            tl: /* [] */0
          }
        }
      }
    });

var IncorrectMarkIcon = {
  resized: resized$4,
  className: className$57
};

var SkilfulRadioButtonMultiChoice = {
  el: el$10,
  resized: resized$1,
  gridCols: gridCols$3,
  className: className$54,
  Radio: Radio,
  CorrectMarkIcon: CorrectMarkIcon,
  IncorrectMarkIcon: IncorrectMarkIcon
};

var SkilfulCheckboxButtonMultiChoice = {
  el: el$10,
  resized: resized$1,
  gridCols: gridCols$3,
  className: className$54,
  Radio: Radio,
  CorrectMarkIcon: CorrectMarkIcon,
  IncorrectMarkIcon: IncorrectMarkIcon
};

var disabledCss = Curry._1(Css.merge, {
      hd: className$35,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.background(K2Styles$LiteracyplanetTupperware.disabledButtonColor),
              tl: {
                hd: Css.hover({
                      hd: Css.background(K2Styles$LiteracyplanetTupperware.disabledButtonColor),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.active({
                        hd: Css.background(K2Styles$LiteracyplanetTupperware.disabledButtonColor),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var BehaviourMarkButton = {
  disabledCss: disabledCss,
  enabledCss: className$35
};

var m$15 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minWidth,
        150
      ],
      tl: /* [] */0
    });

var className$58 = Curry._1(Css.merge, {
      hd: m$15,
      tl: {
        hd: "SkilfulTextInput",
        tl: /* [] */0
      }
    });

var SkilfulTextInput = {
  m: m$15,
  className: className$58
};

var m$16 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minWidth,
        300
      ],
      tl: /* [] */0
    });

var className$59 = Curry._1(Css.merge, {
      hd: m$16,
      tl: {
        hd: "SkilfulTextInputLarge",
        tl: /* [] */0
      }
    });

var SkilfulTextInputLarge = {
  m: m$16,
  className: className$59
};

function inputSizeClass(isLarge) {
  if (isLarge) {
    return className$59;
  } else {
    return className$58;
  }
}

var el$12 = Curry._1(Css.style, {
      hd: Css.display(Css.inlineBlock),
      tl: {
        hd: Css.position(Css.relative),
        tl: /* [] */0
      }
    });

var resized$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: {
        hd: [
          Css.marginRight,
          10
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomWidth,
            1
          ],
          tl: /* [] */0
        }
      }
    });

function className$60(isLarge) {
  return Curry._1(Css.merge, {
              hd: el$12,
              tl: {
                hd: resized$5,
                tl: {
                  hd: isLarge ? className$59 : className$58,
                  tl: {
                    hd: "SkilfulTextInputContainer",
                    tl: /* [] */0
                  }
                }
              }
            });
}

var el$13 = Curry._1(Css.style, {
      hd: Css.borderStyle(Css.none),
      tl: {
        hd: Css.borderBottomStyle(Css.dotted),
        tl: {
          hd: Css.borderBottomColor(blueColor),
          tl: {
            hd: Css.color(Css.black),
            tl: {
              hd: Css.fontFamilies(layoutFonts),
              tl: {
                hd: Css.outlineStyle("none"),
                tl: {
                  hd: Css.padding("zero"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var m$17 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        5
      ],
      tl: /* [] */0
    });

function className$61(isLarge) {
  return Curry._1(Css.merge, {
              hd: el$13,
              tl: {
                hd: m$17,
                tl: {
                  hd: isLarge ? className$59 : className$58,
                  tl: {
                    hd: "SkilfulTextInputContainer-Input",
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Input = {
  el: el$13,
  m: m$17,
  className: className$61
};

var el$14 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.pointerEvents("none"),
        tl: {
          hd: Css.transitionProperty("transform"),
          tl: {
            hd: Css.transitionDuration({
                  NAME: "ms",
                  VAL: 200.0
                }),
            tl: {
              hd: Css.transformOrigin("zero", "zero"),
              tl: {
                hd: Css.color(Css.hex("9C9C9C")),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var m$18 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.left,
        5
      ],
      tl: /* [] */0
    });

var className$62 = Curry._1(Css.merge, {
      hd: el$14,
      tl: {
        hd: m$18,
        tl: {
          hd: "SkilfulTextInputContainer-Label",
          tl: /* [] */0
        }
      }
    });

var Label = {
  el: el$14,
  m: m$18,
  className: className$62
};

var el$15 = Curry._1(Css.style, {
      hd: Css.transforms({
            hd: Css.translateY(Css.px(0)),
            tl: {
              hd: Css.scale(1.0, 1.0),
              tl: /* [] */0
            }
          }),
      tl: /* [] */0
    });

var className$63 = Curry._1(Css.merge, {
      hd: className$62,
      tl: {
        hd: el$15,
        tl: /* [] */0
      }
    });

var LabelLarge = {
  el: el$15,
  className: className$63
};

var el$16 = Curry._1(Css.style, {
      hd: Css.transforms({
            hd: Css.translateY({
                  NAME: "percent",
                  VAL: -30.0
                }),
            tl: {
              hd: Css.scale(0.7, 0.7),
              tl: /* [] */0
            }
          }),
      tl: /* [] */0
    });

var className$64 = Curry._1(Css.merge, {
      hd: className$62,
      tl: {
        hd: el$16,
        tl: /* [] */0
      }
    });

var LabelSmall = {
  el: el$16,
  className: className$64
};

var SkilfulTextInputContainer = {
  el: el$12,
  resized: resized$5,
  className: className$60,
  Input: Input,
  Label: Label,
  LabelLarge: LabelLarge,
  LabelSmall: LabelSmall
};

var el$17 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.width({
              NAME: "percent",
              VAL: 100.0
            }),
        tl: {
          hd: Css.unsafe("justifyItems", "center"),
          tl: {
            hd: Css.position(Css.absolute),
            tl: {
              hd: Css.left(Css.zero),
              tl: {
                hd: Css.top(Css.zero),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$65 = Curry._1(Css.merge, {
      hd: el$17,
      tl: {
        hd: "ResponseIconGrid",
        tl: /* [] */0
      }
    });

var ResponseIconGrid = {
  el: el$17,
  className: className$65
};

var el$18 = Curry._1(Css.style, {
      hd: Css.display(Css.inlineBlock),
      tl: {
        hd: Css.position(Css.relative),
        tl: /* [] */0
      }
    });

var className$66 = Curry._1(Css.merge, {
      hd: el$18,
      tl: {
        hd: "SkilfulResponseMark",
        tl: /* [] */0
      }
    });

var el$19 = Curry._1(Css.style, {
      hd: Css.borderStyle(Css.none),
      tl: {
        hd: Css.color(Css.black),
        tl: {
          hd: Css.disabled({
                hd: Css.color(Css.black),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.fontFamilies(layoutFonts),
            tl: {
              hd: Css.opacity(1.0),
              tl: /* [] */0
            }
          }
        }
      }
    });

var resized$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        5
      ],
      tl: /* [] */0
    });

var className$67 = Curry._1(Css.merge, {
      hd: el$19,
      tl: {
        hd: resized$6,
        tl: {
          hd: "SkilfulResponseMark-Input",
          tl: /* [] */0
        }
      }
    });

var Input$1 = {
  el: el$19,
  resized: resized$6,
  className: className$67
};

var el$20 = Curry._1(Css.style, {
      hd: Css.background(correctBackground),
      tl: /* [] */0
    });

var className$68 = Curry._1(Css.merge, {
      hd: el$20,
      tl: {
        hd: className$67,
        tl: {
          hd: "SkilfulResponseMark-InputCorrect",
          tl: /* [] */0
        }
      }
    });

var InputCorrect = {
  el: el$20,
  className: className$68
};

var el$21 = Curry._1(Css.style, {
      hd: Css.background(incorrectBackground),
      tl: /* [] */0
    });

var className$69 = Curry._1(Css.merge, {
      hd: el$21,
      tl: {
        hd: className$67,
        tl: {
          hd: "SkilfulResponseMark-InputIncorrect",
          tl: /* [] */0
        }
      }
    });

var InputIncorrect = {
  el: el$21,
  className: className$69
};

var className$70 = Curry._1(Css.merge, {
      hd: className$65,
      tl: {
        hd: "SkilfulResponseMark-IconGrid",
        tl: /* [] */0
      }
    });

var IconGrid = {
  className: className$70
};

var className$71 = Curry._1(Css.style, {
      hd: Css.background(correctBackground),
      tl: /* [] */0
    });

var AnswerCorrect = {
  className: className$71
};

var className$72 = Curry._1(Css.style, {
      hd: Css.background(incorrectBackground),
      tl: /* [] */0
    });

var AnswerIncorrect = {
  className: className$72
};

var common = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: /* [] */0
    });

var resizedCommon = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: {
          hd: [
            Css.top,
            -5
          ],
          tl: /* [] */0
        }
      }
    });

var className$73 = Curry._1(Css.merge, {
      hd: common,
      tl: {
        hd: resizedCommon,
        tl: {
          hd: className$11,
          tl: {
            hd: "SkilfulResponseMark-CorrectMarkIcon",
            tl: /* [] */0
          }
        }
      }
    });

var CorrectMarkIcon$1 = {
  className: className$73
};

var className$74 = Curry._1(Css.merge, {
      hd: common,
      tl: {
        hd: resizedCommon,
        tl: {
          hd: className$12,
          tl: {
            hd: "SkilfulResponseMark-IncorrectMarkIcon",
            tl: /* [] */0
          }
        }
      }
    });

var IncorrectMarkIcon$1 = {
  className: className$74
};

var SkilfulResponseMark = {
  el: el$18,
  className: className$66,
  Input: Input$1,
  InputCorrect: InputCorrect,
  InputIncorrect: InputIncorrect,
  IconGrid: IconGrid,
  AnswerCorrect: AnswerCorrect,
  AnswerIncorrect: AnswerIncorrect,
  common: common,
  iconSize: 20,
  resizedCommon: resizedCommon,
  CorrectMarkIcon: CorrectMarkIcon$1,
  IncorrectMarkIcon: IncorrectMarkIcon$1
};

var el$22 = Curry._1(Css.style, {
      hd: Css.userSelect(Css.none),
      tl: /* [] */0
    });

var resized$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        20
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.paddingLeft,
          10
        ],
        tl: {
          hd: [
            Css.marginBottom,
            20
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.lineHeight,
              45
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$75 = Curry._1(Css.merge, {
      hd: el$22,
      tl: {
        hd: resized$7,
        tl: {
          hd: "SkilfulContent",
          tl: /* [] */0
        }
      }
    });

var SkilfulContent = {
  el: el$22,
  resized: resized$7,
  className: className$75
};

var el$23 = Curry._1(Css.style, {
      hd: Css.color(blueColor),
      tl: {
        hd: Css.background(contentHeaderColor),
        tl: {
          hd: Css.borderStyle(Css.solid),
          tl: {
            hd: Css.borderColor(blueColor),
            tl: {
              hd: Css.fontFamilies(layoutFonts),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems(Css.center),
                  tl: {
                    hd: Css.justifyContent(Css.center),
                    tl: {
                      hd: Css.cursor(Css.pointer),
                      tl: {
                        hd: Css.fontWeight(Css.bold),
                        tl: {
                          hd: Css.justifySelf(Css.center),
                          tl: {
                            hd: Css.userSelect(Css.none),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var resized$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        220
      ],
      tl: {
        hd: [
          Css.height,
          50
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.fontSize,
            20
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.borderRadius,
              25
            ],
            tl: {
              hd: [
                Theme$LiteracyplanetClientFlags.borderWidth,
                5
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$76 = Curry._1(Css.merge, {
      hd: el$23,
      tl: {
        hd: resized$8,
        tl: {
          hd: "ShowCorrectAnswerButton",
          tl: /* [] */0
        }
      }
    });

var ShowCorrectAnswerButton = {
  el: el$23,
  resized: resized$8,
  className: className$76
};

var m$19 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minWidth,
        200
      ],
      tl: /* [] */0
    });

var className$77 = Curry._1(Css.merge, {
      hd: m$19,
      tl: {
        hd: "SkilfulDropDown",
        tl: /* [] */0
      }
    });

var SkilfulDropDown = {
  m: m$19,
  className: className$77
};

var css$27 = Curry._1(Css.style, {
      hd: Css.fontFamilies(layoutFonts),
      tl: {
        hd: Css.display(Css.inlineBlock),
        tl: {
          hd: Css.position(Css.relative),
          tl: /* [] */0
        }
      }
    });

var m$20 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minWidth,
        150
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          20
        ],
        tl: {
          hd: [
            Css.marginRight,
            10
          ],
          tl: {
            hd: [
              Css.marginLeft,
              10
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$78 = Curry._1(Css.merge, {
      hd: css$27,
      tl: {
        hd: m$20,
        tl: {
          hd: "SkilfulSelect",
          tl: /* [] */0
        }
      }
    });

var SkilfulSelect = {
  css: css$27,
  m: m$20,
  className: className$78
};

var css$28 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: {
        hd: Css.userSelect(Css.none),
        tl: {
          hd: Css.fontFamilies(layoutFonts),
          tl: /* [] */0
        }
      }
    });

var m$21 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minHeight,
        20
      ],
      tl: /* [] */0
    });

var className$79 = Curry._1(Css.merge, {
      hd: css$28,
      tl: {
        hd: m$21,
        tl: {
          hd: "SkilfulInput",
          tl: /* [] */0
        }
      }
    });

var SkilfulInput = {
  css: css$28,
  m: m$21,
  className: className$79
};

var css$29 = Curry._1(Css.style, {
      hd: Css.alignSelf(Css.center),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.gridTemplateColumns({
                hd: {
                  NAME: "fr",
                  VAL: 1.0
                },
                tl: {
                  hd: "auto",
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var m$22 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.paddingLeft,
        10
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.paddingRight,
          10
        ],
        tl: /* [] */0
      }
    });

var className$80 = Curry._1(Css.merge, {
      hd: css$29,
      tl: {
        hd: m$22,
        tl: {
          hd: "SkilfulInputText",
          tl: /* [] */0
        }
      }
    });

var SkilfulInputText = {
  css: css$29,
  m: m$22,
  className: className$80
};

var css$30 = Curry._1(Css.style, {
      hd: Css.borderBottomStyle(Css.solid),
      tl: {
        hd: Css.borderColor(blueColor),
        tl: {
          hd: Css.position(Css.absolute),
          tl: {
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100.0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var m$23 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minHeight,
        1
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderWidth,
          1
        ],
        tl: {
          hd: [
            Css.bottom,
            7
          ],
          tl: /* [] */0
        }
      }
    });

var className$81 = Curry._1(Css.merge, {
      hd: css$30,
      tl: {
        hd: m$23,
        tl: {
          hd: "SkilfulBorder",
          tl: /* [] */0
        }
      }
    });

var SkilfulBorder = {
  css: css$30,
  m: m$23,
  className: className$81
};

var css$31 = Curry._1(Css.style, {
      hd: Css.fontFamilies(layoutFonts),
      tl: {
        hd: Css.background(K2Styles$LiteracyplanetTupperware.correctPanelBackgroundColor),
        tl: {
          hd: Css.margin(Css.zero),
          tl: {
            hd: Css.cursor(Css.pointer),
            tl: {
              hd: Css.listStyleType("none"),
              tl: {
                hd: Css.background(Css.white),
                tl: {
                  hd: Css.padding(Css.zero),
                  tl: {
                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(1), Css.px(2), Css.px(8), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                                  NAME: "num",
                                  VAL: 0.5
                                }))),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var m$24 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.fontSize,
        20
      ],
      tl: /* [] */0
    });

var className$82 = Curry._1(Css.merge, {
      hd: css$31,
      tl: {
        hd: m$24,
        tl: {
          hd: "SkilfulOptions",
          tl: /* [] */0
        }
      }
    });

var SkilfulOptions = {
  css: css$31,
  m: m$24,
  className: className$82
};

var css$32 = Curry._1(Css.style, {
      hd: Css.margin(Css.zero),
      tl: {
        hd: Css.hover({
              hd: Css.background(Css.rgba(0, 0, 0, {
                        NAME: "num",
                        VAL: 0.08
                      })),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var m$25 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.padding,
        10
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.fontSize,
          20
        ],
        tl: /* [] */0
      }
    });

var className$83 = Curry._1(Css.merge, {
      hd: css$32,
      tl: {
        hd: m$25,
        tl: {
          hd: "SkilfulOption",
          tl: /* [] */0
        }
      }
    });

var SkilfulOption = {
  css: css$32,
  m: m$25,
  className: className$83
};

var css$33 = Curry._1(Css.style, {
      hd: Css.alignSelf("center"),
      tl: /* [] */0
    });

var m$26 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        10
      ],
      tl: {
        hd: [
          Css.height,
          10
        ],
        tl: {
          hd: [
            Css.marginLeft,
            10
          ],
          tl: /* [] */0
        }
      }
    });

var className$84 = Curry._1(Css.merge, {
      hd: css$33,
      tl: {
        hd: m$26,
        tl: {
          hd: "SkilfulDropDownIcon",
          tl: /* [] */0
        }
      }
    });

var SkilfulDropDownIcon = {
  css: css$33,
  m: m$26,
  className: className$84
};

var css$34 = Curry._1(Css.style, {
      hd: Css.position(Css.sticky),
      tl: {
        hd: Css.bottom(Css.zero),
        tl: {
          hd: Css.background(Css.white),
          tl: {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(1), Css.px(2), Css.px(8), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                          NAME: "num",
                          VAL: 0.5
                        }))),
            tl: /* [] */0
          }
        }
      }
    });

var m$27 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginTop,
        20
      ],
      tl: /* [] */0
    });

var className$85 = Curry._1(Css.merge, {
      hd: css$34,
      tl: {
        hd: m$27,
        tl: {
          hd: "SkilfulDragPanelSticky",
          tl: /* [] */0
        }
      }
    });

var SkilfulDragPanelSticky = {
  css: css$34,
  m: m$27,
  className: className$85
};

var css$35 = Curry._1(Css.style, {
      hd: Css.display(Css.inlineGrid),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.lineHeight(Css.normal),
            tl: {
              hd: Css.position(Css.relative),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m$28 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: {
        hd: [
          Css.marginRight,
          10
        ],
        tl: /* [] */0
      }
    });

var className$86 = Curry._1(Css.merge, {
      hd: css$35,
      tl: {
        hd: m$28,
        tl: {
          hd: "SkilfulClozeDragTextContainer-CommonDndResponseContainer",
          tl: /* [] */0
        }
      }
    });

var CommonDndResponseContainer = {
  css: css$35,
  m: m$28,
  className: className$86
};

var css$36 = Curry._1(Css.style, {
      hd: Css.backgroundColor(clozeDragText),
      tl: {
        hd: Css.display(Css.inlineGrid),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.userSelect(Css.none),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m$29 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minWidth,
        150
      ],
      tl: {
        hd: [
          Css.height,
          30
        ],
        tl: /* [] */0
      }
    });

var className$87 = Curry._1(Css.merge, {
      hd: css$36,
      tl: {
        hd: m$29,
        tl: {
          hd: "SkilfulClozeDragTextContainer-CommonDndResponseStyle",
          tl: /* [] */0
        }
      }
    });

var CommonDndResponseStyle = {
  css: css$36,
  m: m$29,
  className: className$87
};

var resized$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.top,
        -12
      ],
      tl: /* [] */0
    });

var className$88 = Curry._1(Css.merge, {
      hd: className$65,
      tl: {
        hd: resized$9,
        tl: {
          hd: "SkilfulClozeCommon-DndIconGrid",
          tl: /* [] */0
        }
      }
    });

var DndIconGrid = {
  resized: resized$9,
  className: className$88
};

var resized$10 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var className$89 = Curry._1(Css.merge, {
      hd: resized$10,
      tl: {
        hd: className$11,
        tl: {
          hd: "SkilfulClozeCommon-CorrectMarkIcon",
          tl: /* [] */0
        }
      }
    });

var CorrectMarkIcon$2 = {
  resized: resized$10,
  className: className$89
};

var resized$11 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: /* [] */0
      }
    });

var className$90 = Curry._1(Css.merge, {
      hd: resized$11,
      tl: {
        hd: className$12,
        tl: {
          hd: "SkilfulClozeCommon-IncorrectMarkIcon",
          tl: /* [] */0
        }
      }
    });

var IncorrectMarkIcon$2 = {
  resized: resized$11,
  className: className$90
};

var css$37 = Curry._1(Css.style, {
      hd: Css.background(correctBackground),
      tl: /* [] */0
    });

var className$91 = Curry._1(Css.merge, {
      hd: className$87,
      tl: {
        hd: css$37,
        tl: {
          hd: "SkilfulClozeCommon-CorrectResponse",
          tl: /* [] */0
        }
      }
    });

var CorrectResponse = {
  css: css$37,
  className: className$91
};

var css$38 = Curry._1(Css.style, {
      hd: Css.background(incorrectBackground),
      tl: /* [] */0
    });

var className$92 = Curry._1(Css.merge, {
      hd: className$87,
      tl: {
        hd: css$38,
        tl: {
          hd: "SkilfulClozeCommon-IncorrectResponse",
          tl: /* [] */0
        }
      }
    });

var IncorrectResponse = {
  css: css$38,
  className: className$92
};

var SkilfulClozeCommon = {
  DndIconGrid: DndIconGrid,
  CorrectMarkIcon: CorrectMarkIcon$2,
  IncorrectMarkIcon: IncorrectMarkIcon$2,
  CorrectResponse: CorrectResponse,
  IncorrectResponse: IncorrectResponse
};

var className$93 = Curry._1(Css.merge, {
      hd: className$86,
      tl: {
        hd: "SkilfulClozeDragTextContainer-ResponseContainer",
        tl: /* [] */0
      }
    });

var ResponseContainer = {
  className: className$93
};

var css$39 = Curry._1(Css.style, {
      hd: Css.cursor(Css.grab),
      tl: /* [] */0
    });

var className$94 = Curry._1(Css.merge, {
      hd: className$87,
      tl: {
        hd: css$39,
        tl: {
          hd: "SkilfulClozeDragTextContainer-Undropped",
          tl: /* [] */0
        }
      }
    });

var Undropped = {
  css: css$39,
  className: className$94
};

var css$40 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: /* [] */0
    });

var className$95 = Curry._1(Css.merge, {
      hd: className$87,
      tl: {
        hd: css$40,
        tl: {
          hd: "SkilfulClozeDragTextContainer-Dropped",
          tl: /* [] */0
        }
      }
    });

var Dropped = {
  css: css$40,
  className: className$95
};

var css$41 = Curry._1(Css.style, {
      hd: Css.opacity(0.3),
      tl: /* [] */0
    });

var className$96 = Curry._1(Css.merge, {
      hd: css$41,
      tl: {
        hd: "SkilfulClozeDragTextContainer-Dimmed",
        tl: /* [] */0
      }
    });

var Dimmed = {
  css: css$41,
  className: className$96
};

var SkilfulClozeDragTextContainer = {
  ResponseContainer: ResponseContainer,
  Undropped: Undropped,
  Dropped: Dropped,
  Dimmed: Dimmed
};

var className$97 = Curry._1(Css.merge, {
      hd: className$86,
      tl: {
        hd: "SkilfulClozeDropTextContainer-ResponseContainer",
        tl: /* [] */0
      }
    });

var ResponseContainer$1 = {
  className: className$97
};

var css$42 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: Css.background(Css.white),
        tl: {
          hd: Css.borderColor(clozeDragText),
          tl: {
            hd: Css.borderStyle(Css.solid),
            tl: /* [] */0
          }
        }
      }
    });

var m$30 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.borderWidth,
        2
      ],
      tl: /* [] */0
    });

var className$98 = Curry._1(Css.merge, {
      hd: className$87,
      tl: {
        hd: css$42,
        tl: {
          hd: "SkilfulClozeDropTextContainer-Undropped",
          tl: /* [] */0
        }
      }
    });

var Undropped$1 = {
  css: css$42,
  m: m$30,
  className: className$98
};

var css$43 = Curry._1(Css.style, {
      hd: Css.background(clozeDragText),
      tl: {
        hd: Css.color(clozeDragText),
        tl: /* [] */0
      }
    });

var className$99 = Curry._1(Css.merge, {
      hd: className$98,
      tl: {
        hd: css$43,
        tl: {
          hd: "SkilfulClozeDropTextContainer-UndroppedIsOver",
          tl: /* [] */0
        }
      }
    });

var UndroppedIsOver = {
  css: css$43,
  className: className$99
};

var css$44 = Curry._1(Css.style, {
      hd: Css.selector(".SkilfulClozeDragTextContainer-Dropped", {
            hd: Css.background(Css.white),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var className$100 = Curry._1(Css.merge, {
      hd: css$44,
      tl: {
        hd: "SkilfulClozeDropTextContainer-DroppedIsOver",
        tl: /* [] */0
      }
    });

var DroppedIsOver = {
  css: css$44,
  className: className$100
};

var className$101 = Curry._1(Css.merge, {
      hd: "SkilfulClozeDropTextContainer-Dropped",
      tl: /* [] */0
    });

var Dropped$1 = {
  className: className$101
};

var css$45 = Curry._1(Css.style, /* [] */0);

var m$31 = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var className$102 = Curry._1(Css.merge, {
      hd: css$45,
      tl: {
        hd: m$31,
        tl: {
          hd: "SkilfulClozeDropTextContainer-ShowingCorrectAnswer",
          tl: /* [] */0
        }
      }
    });

var ShowingCorrectAnswer = {
  css: css$45,
  m: m$31,
  className: className$102
};

var css$46 = Curry._1(Css.style, {
      hd: Css.background(incorrectBackground),
      tl: {
        hd: Css.color(incorrectBackground),
        tl: /* [] */0
      }
    });

var className$103 = Curry._1(Css.merge, {
      hd: className$87,
      tl: {
        hd: css$46,
        tl: {
          hd: "SkilfulClozeDropTextContainer-MarkedNotDropped",
          tl: /* [] */0
        }
      }
    });

var MarkedNotDropped = {
  css: css$46,
  className: className$103
};

var SkilfulClozeDropTextContainer = {
  ResponseContainer: ResponseContainer$1,
  Undropped: Undropped$1,
  UndroppedIsOver: UndroppedIsOver,
  DroppedIsOver: DroppedIsOver,
  Dropped: Dropped$1,
  ShowingCorrectAnswer: ShowingCorrectAnswer,
  MarkedNotDropped: MarkedNotDropped
};

var m$32 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.margin,
        10
      ],
      tl: /* [] */0
    });

var className$104 = Curry._1(Css.merge, {
      hd: m$32,
      tl: {
        hd: "SkilfulHighlightBucket",
        tl: /* [] */0
      }
    });

var SkilfulHighlightBucket = {
  m: m$32,
  className: className$104
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var headerHeightRatio = 90;

var stimulusWidthRatio = 500;

var closedStimulusWidthRatio = 60;

var closedStimulusPanelWidthRatio = 75;

var contentPaddingRatio = 30;

var contentHeadersHeightRatio = 70;

var paddingRatio = 20;

export {
  ratio ,
  primaryColor ,
  headerColor ,
  whiteColor ,
  contentHeaderColor ,
  blueColor ,
  darkBlue ,
  lightBlue ,
  greenIcon ,
  redIcon ,
  incorrectBackground ,
  correctBackground ,
  clozeDragText ,
  stimulusTab ,
  stimulusTabSelected ,
  stimulusTabUnselectedColor ,
  headerHeightRatio ,
  stimulusWidthRatio ,
  closedStimulusWidthRatio ,
  closedStimulusPanelWidthRatio ,
  contentPaddingRatio ,
  contentHeadersHeightRatio ,
  paddingRatio ,
  defaultBlue ,
  hoverBlue ,
  activeBlue ,
  defaultGreen ,
  hoverGreen ,
  activeGreen ,
  layoutFonts ,
  Layout ,
  Header ,
  Exit ,
  ExitIcon ,
  NoStimulus ,
  HasStimulus ,
  StimulusTab ,
  StimulusTabSelected ,
  StimulusTabs ,
  StimulusSidebar ,
  StimulusPanel ,
  CorrectIcon ,
  CrossIcon ,
  PulloutIcon ,
  PullinIcon ,
  StimulusHandle ,
  SkilfulContentContainer ,
  ContentContainer ,
  ContentHeader ,
  SummaryIcon ,
  GridJustifyCenter ,
  ContentFooter ,
  Content ,
  EndScreenContainer ,
  EndScreenHeader ,
  EndScreenContent ,
  EndScreenTableHeader ,
  EndScreenCorrectIcon ,
  EndScreenIncorrectIcon ,
  EndScreenLink ,
  EndScreenMarkingState ,
  EndScreenQuestionIndex ,
  EndScreenPlayIcon ,
  EndScreenFooter ,
  Button ,
  MarkButton ,
  BackButton ,
  NextButton ,
  NextArrowIcon ,
  BackArrowIcon ,
  ActivityProgressMeter ,
  MiddleContainer ,
  ActivityProgress ,
  ActivityProgressBar ,
  MarkContainer ,
  CorrectCount ,
  InorrectCount ,
  CorrectCountIcon ,
  IncorrectCountIcon ,
  MarkStatusPanel ,
  MarkStatusPanelCorrect ,
  MarkStatusPanelIncorrect ,
  SkilfulStemHeading ,
  SkilfulMultiChoiceContainer ,
  displayNone ,
  SkilfulRadioButtonMultiChoice ,
  SkilfulCheckboxButtonMultiChoice ,
  BehaviourMarkButton ,
  SkilfulTextInput ,
  SkilfulTextInputLarge ,
  inputSizeClass ,
  SkilfulTextInputContainer ,
  ResponseIconGrid ,
  SkilfulResponseMark ,
  SkilfulContent ,
  ShowCorrectAnswerButton ,
  SkilfulDropDown ,
  SkilfulSelect ,
  SkilfulInput ,
  SkilfulInputText ,
  SkilfulBorder ,
  SkilfulOptions ,
  SkilfulOption ,
  SkilfulDropDownIcon ,
  SkilfulDragPanelSticky ,
  CommonDndResponseContainer ,
  CommonDndResponseStyle ,
  SkilfulClozeCommon ,
  SkilfulClozeDragTextContainer ,
  SkilfulClozeDropTextContainer ,
  SkilfulHighlightBucket ,
}
/* primaryColor Not a pure module */
