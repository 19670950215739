// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMaker$LiteracyplanetClientFlags from "./ContextMaker.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.res.mjs";

var state_sources = [];

var state = {
  sources: state_sources,
  currentlyPlaying: undefined
};

var Api = {
  name: "SoundProviderEmbedded"
};

var include = ContextMaker$LiteracyplanetClientFlags.Make(Api);

var Provider = include.Provider;

var use = include.use;

function SoundProviderEmbedded(props) {
  var match = React.useState(function (param) {
        return state;
      });
  var setState = match[1];
  var state$1 = match[0];
  var value = React.useMemo((function (param) {
          return {
                  registerSounds: (function (instance, id, sources, autoplay) {
                      Curry._1(setState, (function (s) {
                              return {
                                      sources: Belt_Array.concat(s.sources, sources),
                                      currentlyPlaying: autoplay ? [
                                          instance,
                                          id
                                        ] : s.currentlyPlaying
                                    };
                            }));
                    }),
                  togglePlaySound: (function (instance, id, param) {
                      if (Caml_obj.equal(state$1.currentlyPlaying, [
                              instance,
                              id
                            ])) {
                        return Curry._1(setState, (function (s) {
                                      return {
                                              sources: s.sources,
                                              currentlyPlaying: undefined
                                            };
                                    }));
                      } else {
                        return Curry._1(setState, (function (s) {
                                      return {
                                              sources: s.sources,
                                              currentlyPlaying: [
                                                instance,
                                                id
                                              ]
                                            };
                                    }));
                      }
                    }),
                  isPlaying: (function (instance, id) {
                      return Caml_obj.equal(state$1.currentlyPlaying, [
                                  instance,
                                  id
                                ]);
                    }),
                  getAudioSrc: (function (id, l) {
                      return Belt_Option.flatMap(Caml_option.undefined_to_opt(state$1.sources.find(function (param) {
                                          if (id === param[0]) {
                                            return l === param[1];
                                          } else {
                                            return false;
                                          }
                                        })), (function (param) {
                                    return param[2];
                                  }));
                    })
                };
        }), [state$1]);
  return React.createElement(Provider.make, {
              value: value,
              children: props.children
            });
}

function use$1(instance, id, autoPlay, sources) {
  var api = LocaleContext$LiteracyplanetClientFlags.use();
  var loc = api.locale;
  var api$1 = Curry._1(use, undefined);
  var isPlaying = Curry._2(api$1.isPlaying, instance, id);
  React.useEffect((function (param) {
          var srcs = Belt_Array.map(sources, (function (param) {
                  return [
                          id,
                          param.locale,
                          param.source
                        ];
                }));
          Curry._4(api$1.registerSounds, instance, id, srcs, autoPlay);
        }), [id]);
  React.useEffect((function (param) {
          if (autoPlay) {
            if (isPlaying) {
              
            } else {
              Curry._3(api$1.togglePlaySound, instance, id, undefined);
            }
          } else if (isPlaying) {
            Curry._3(api$1.togglePlaySound, instance, id, undefined);
          }
          
        }), [autoPlay]);
  return [
          isPlaying,
          Curry._2(api$1.togglePlaySound, instance, id),
          Curry._2(api$1.getAudioSrc, id, loc)
        ];
}

var initialContext = include.initialContext;

var context = include.context;

var P = include.P;

var ContextMakerProviderNotFound = include.ContextMakerProviderNotFound;

var make = SoundProviderEmbedded;

export {
  state ,
  Api ,
  initialContext ,
  context ,
  P ,
  Provider ,
  ContextMakerProviderNotFound ,
  make ,
  use$1 as use,
}
/* include Not a pure module */
