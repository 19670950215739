// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ContextMaker$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContextMaker.res.mjs";

var Api = {
  name: "FireLater"
};

function Make($star) {
  var events = {
    contents: /* [] */0
  };
  var pushAsyncEvent = function ($$event) {
    events.contents = Belt_List.concat(events.contents, {
          hd: $$event,
          tl: /* [] */0
        });
  };
  var include = ContextMaker$LiteracyplanetClientFlags.Make(Api);
  var Provider = include.Provider;
  var FireLater$Make = function (props) {
    React.useEffect((function (param) {
            var intervalId = setInterval((function (param) {
                    var match = events.contents;
                    if (match) {
                      Curry._1(match.hd._0, undefined);
                      events.contents = match.tl;
                      return ;
                    }
                    
                  }), 20);
            return (function (param) {
                      clearInterval(intervalId);
                      events.contents = /* [] */0;
                    });
          }), []);
    return React.createElement(Provider.make, {
                value: pushAsyncEvent,
                children: props.children
              });
  };
  return {
          events: events,
          pushAsyncEvent: pushAsyncEvent,
          initialContext: include.initialContext,
          context: include.context,
          P: include.P,
          Provider: Provider,
          ContextMakerProviderNotFound: include.ContextMakerProviderNotFound,
          use: include.use,
          make: FireLater$Make
        };
}

export {
  Api ,
  Make ,
}
/* react Not a pure module */
