// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import WmPng from "./assets/wm.png";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import BookPng from "./assets/book.png";
import LockPng from "./assets/lock.png";
import SackPng from "./assets/sack.png";
import ShopPng from "./assets/shop.png";
import ArcadePng from "./assets/arcade.png";
import ScrollsPng from "./assets/scrolls.png";
import Open_scrollPng from "./assets/open_scroll.png";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.res.mjs";
import * as JourneyHeaderIcons$LiteracyplanetClientSxEntry from "./JourneyHeaderIcons.res.mjs";

var layoutFonts = {
  hd: {
    NAME: "custom",
    VAL: "Mikado"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "sans-serif"
    },
    tl: /* [] */0
  }
};

function s(prim) {
  return prim;
}

var scrolls = ScrollsPng;

var openScroll = Open_scrollPng;

var wmIcon = WmPng;

var bookIcon = BookPng;

var sackIcon = SackPng;

var arcadeIcon = ArcadePng;

var shopIcon = ShopPng;

var lockIcon = LockPng;

function PapyrusComponents$Spacer(props) {
  return React.createElement("div", undefined);
}

var Spacer = {
  make: PapyrusComponents$Spacer
};

var brownColor = Css.rgba(85, 48, 19, {
      NAME: "num",
      VAL: 1.0
    });

var whiteColor = Css.rgba(255, 255, 255, {
      NAME: "num",
      VAL: 1.0
    });

var bottomBlueColor = Css.rgba(109, 207, 246, {
      NAME: "num",
      VAL: 1.0
    });

var topBlueColor = Css.rgba(141, 223, 255, {
      NAME: "num",
      VAL: 1.0
    });

var blueColor = Css.rgba(11, 185, 254, {
      NAME: "num",
      VAL: 1.0
    });

var offWhiteColor = Css.rgba(233, 220, 193, {
      NAME: "num",
      VAL: 1.0
    });

var lightOffWhiteColor = Css.rgba(253, 250, 240, {
      NAME: "num",
      VAL: 1.0
    });

var className = Curry._1(Css.merge, {
      hd: Theme$LiteracyplanetClientFlags.resize({
            hd: [
              Css.width,
              672
            ],
            tl: /* [] */0
          }),
      tl: {
        hd: "LevelBar",
        tl: /* [] */0
      }
    });

var cls1 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(brownColor),
      tl: /* [] */0
    });

var cls2 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(whiteColor),
      tl: /* [] */0
    });

var cls3 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(bottomBlueColor),
      tl: /* [] */0
    });

var cls4 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(topBlueColor),
      tl: /* [] */0
    });

var cls5 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(offWhiteColor),
      tl: /* [] */0
    });

var cls6 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(lightOffWhiteColor),
      tl: /* [] */0
    });

var cls7 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(whiteColor),
      tl: /* [] */0
    });

var cls8 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(blueColor),
      tl: /* [] */0
    });

var cls9 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(bottomBlueColor),
      tl: /* [] */0
    });

var cls10 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(topBlueColor),
      tl: /* [] */0
    });

var cls11 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(blueColor),
      tl: /* [] */0
    });

var cls12 = Curry._1(Css.merge, {
      hd: Theme$LiteracyplanetClientFlags.resize({
            hd: [
              Css.fontSize,
              48
            ],
            tl: /* [] */0
          }),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css_Legacy_Core.SVG.fill(Css.white),
              tl: {
                hd: Css.fontFamilies(layoutFonts),
                tl: {
                  hd: Css.fontWeight(Css.bold),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var cls13 = Curry._1(Css.merge, {
      hd: Theme$LiteracyplanetClientFlags.resize({
            hd: [
              Css.fontSize,
              38
            ],
            tl: /* [] */0
          }),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css_Legacy_Core.SVG.fill(brownColor),
              tl: {
                hd: Css.fontFamilies(layoutFonts),
                tl: {
                  hd: Css.fontWeight(Css.bold),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

function PapyrusComponents$PsLevel(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.PsLevelBar.make, {
              fromText: props.fromText,
              toText: props.toText,
              className: className,
              cls1: cls1,
              cls2: cls2,
              cls3: cls3,
              cls4: cls4,
              cls5: cls5,
              cls6: cls6,
              cls7: cls7,
              cls8: cls8,
              cls9: cls9,
              cls10: cls10,
              cls11: cls11,
              cls12: cls12,
              cls13: cls13
            });
}

var PsLevel = {
  className: className,
  cls1: cls1,
  cls2: cls2,
  cls3: cls3,
  cls4: cls4,
  cls5: cls5,
  cls6: cls6,
  cls7: cls7,
  cls8: cls8,
  cls9: cls9,
  cls10: cls10,
  cls11: cls11,
  cls12: cls12,
  cls13: cls13,
  make: PapyrusComponents$PsLevel
};

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        192
      ],
      tl: /* [] */0
    });

var css = Curry._1(Css.style, /* [] */0);

var className$1 = Curry._1(Css.merge, {
      hd: r,
      tl: {
        hd: css,
        tl: {
          hd: "NameLine",
          tl: /* [] */0
        }
      }
    });

var NameLineStyle = {
  r: r,
  css: css,
  className: className$1
};

function PapyrusComponents$NameLine(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.Line.make, {
              className: className$1
            });
}

var NameLine = {
  make: PapyrusComponents$NameLine
};

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        192
      ],
      tl: /* [] */0
    });

var css$1 = Curry._1(Css.style, /* [] */0);

var className$2 = Curry._1(Css.merge, {
      hd: r$1,
      tl: {
        hd: css$1,
        tl: {
          hd: "NameLineLeft",
          tl: /* [] */0
        }
      }
    });

var NameLineLeftStyle = {
  r: r$1,
  css: css$1,
  className: className$2
};

function PapyrusComponents$NameLineLeft(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.LineLeft.make, {
              className: className$2
            });
}

var NameLineLeft = {
  make: PapyrusComponents$NameLineLeft
};

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: /* [] */0
    });

var className$3 = Curry._1(Css.merge, {
      hd: r$2,
      tl: {
        hd: "Logout",
        tl: /* [] */0
      }
    });

var LogoutStyle = {
  r: r$2,
  className: className$3
};

function PapyrusComponents$Logout(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.Logout.make, {
              className: className$3
            });
}

var Logout = {
  make: PapyrusComponents$Logout
};

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        672
      ],
      tl: /* [] */0
    });

var className$4 = Curry._1(Css.merge, {
      hd: r$3,
      tl: {
        hd: "NameLine",
        tl: /* [] */0
      }
    });

var LevelLineStyle = {
  r: r$3,
  className: className$4
};

function PapyrusComponents$LevelLine(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.LevelBar.make, {
              className: className$4
            });
}

var LevelLine = {
  make: PapyrusComponents$LevelLine
};

var r$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          40
        ],
        tl: /* [] */0
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.background(Css.url(lockIcon)),
            tl: {
              hd: Css.backgroundSize("contain"),
              tl: {
                hd: Css.backgroundRepeat("noRepeat"),
                tl: {
                  hd: Css.gridColumnStart(1),
                  tl: {
                    hd: Css.gridColumnEnd(2),
                    tl: {
                      hd: Css.gridRowStart(1),
                      tl: {
                        hd: Css.gridRowEnd(2),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$5 = Curry._1(Css.merge, {
      hd: r$4,
      tl: {
        hd: css$2,
        tl: {
          hd: "LockIcon",
          tl: /* [] */0
        }
      }
    });

var LockIconStyle = {
  r: r$4,
  css: css$2,
  className: className$5
};

function PapyrusComponents$LockIcon(props) {
  return React.createElement("div", {
              className: className$5
            }, props.children);
}

var LockIcon = {
  make: PapyrusComponents$LockIcon
};

var r$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        160
      ],
      tl: {
        hd: [
          Css.height,
          96
        ],
        tl: /* [] */0
      }
    });

var css$3 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.backgroundSize("contain"),
              tl: {
                hd: Css.backgroundRepeat("noRepeat"),
                tl: {
                  hd: Css.backgroundPosition(Css.center),
                  tl: {
                    hd: Css.gridColumnStart(1),
                    tl: {
                      hd: Css.gridColumnEnd(2),
                      tl: {
                        hd: Css.gridRowStart(1),
                        tl: {
                          hd: Css.gridRowEnd(2),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$6 = Curry._1(Css.merge, {
      hd: r$5,
      tl: {
        hd: css$3,
        tl: {
          hd: "ItemIcon",
          tl: /* [] */0
        }
      }
    });

var ItemIconStyle = {
  r: r$5,
  css: css$3,
  className: className$6
};

function PapyrusComponents$ItemIcon(props) {
  var __locked = props.locked;
  var icon = props.icon;
  var locked = __locked !== undefined ? __locked : false;
  var className$7 = locked ? Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.background(Css.url(icon)),
                tl: {
                  hd: Css.filter({
                        hd: {
                          NAME: "grayscale",
                          VAL: 90.0
                        },
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: className$6,
            tl: /* [] */0
          }
        }) : Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.background(Css.url(icon)),
                tl: /* [] */0
              }),
          tl: {
            hd: className$6,
            tl: /* [] */0
          }
        });
  return React.createElement("div", {
              className: className$7
            }, props.children);
}

var ItemIcon = {
  make: PapyrusComponents$ItemIcon
};

var r$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        28
      ],
      tl: /* [] */0
    });

var css$4 = Curry._1(Css.style, {
      hd: Css.color(brownColor),
      tl: {
        hd: Css.fontFamilies(layoutFonts),
        tl: /* [] */0
      }
    });

var className$7 = Curry._1(Css.merge, {
      hd: r$6,
      tl: {
        hd: css$4,
        tl: {
          hd: "NameText",
          tl: /* [] */0
        }
      }
    });

var NameTextStyle = {
  r: r$6,
  css: css$4,
  className: className$7
};

function PapyrusComponents$NameText(props) {
  return React.createElement("div", {
              className: className$7
            }, props.text);
}

var NameText = {
  make: PapyrusComponents$NameText
};

var r$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        20
      ],
      tl: /* [] */0
    });

var css$5 = Curry._1(Css.style, {
      hd: Css.color(brownColor),
      tl: {
        hd: Css.fontFamilies(layoutFonts),
        tl: /* [] */0
      }
    });

var className$8 = Curry._1(Css.merge, {
      hd: r$7,
      tl: {
        hd: css$5,
        tl: {
          hd: "ButtonText",
          tl: /* [] */0
        }
      }
    });

var ButtonTextStyle = {
  r: r$7,
  css: css$5,
  className: className$8
};

function PapyrusComponents$ButtonText(props) {
  return React.createElement("div", {
              className: className$8
            }, props.text);
}

var ButtonText = {
  make: PapyrusComponents$ButtonText
};

var r$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        20
      ],
      tl: /* [] */0
    });

var css$6 = Curry._1(Css.style, {
      hd: Css.color(brownColor),
      tl: {
        hd: Css.fontFamilies(layoutFonts),
        tl: /* [] */0
      }
    });

var className$9 = Curry._1(Css.merge, {
      hd: r$8,
      tl: {
        hd: css$6,
        tl: {
          hd: "ItemText",
          tl: /* [] */0
        }
      }
    });

var ItemTextStyle = {
  r: r$8,
  css: css$6,
  className: className$9
};

function PapyrusComponents$ItemText(props) {
  return React.createElement("div", {
              className: className$9
            }, props.text);
}

var ItemText = {
  make: PapyrusComponents$ItemText
};

var r$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        160
      ],
      tl: {
        hd: [
          Css.height,
          136
        ],
        tl: {
          hd: [
            Css.fontSize,
            20
          ],
          tl: /* [] */0
        }
      }
    });

var rows = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 96
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 40
        },
        tl: /* [] */0
      }
    });

var css$7 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: r$9,
      tl: {
        hd: rows,
        tl: {
          hd: css$7,
          tl: {
            hd: "ItemPanel",
            tl: /* [] */0
          }
        }
      }
    });

var ItemPanelStyle = {
  r: r$9,
  rows: rows,
  css: css$7,
  className: className$10
};

function PapyrusComponents$ItemPanel(props) {
  var __locked = props.locked;
  var locked = __locked !== undefined ? __locked : false;
  var className$11 = locked ? Curry._1(Css.merge, {
          hd: className$10,
          tl: /* [] */0
        }) : Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.cursor(Css.pointer),
                tl: /* [] */0
              }),
          tl: {
            hd: className$10,
            tl: /* [] */0
          }
        });
  return React.createElement("div", {
              className: className$11,
              onClick: props.onClick
            }, React.createElement(PapyrusComponents$ItemIcon, {
                  children: React.createElement("div", undefined),
                  icon: props.icon,
                  locked: locked
                }), locked ? React.createElement(PapyrusComponents$LockIcon, {
                    children: React.createElement("div", undefined)
                  }) : null, React.createElement(PapyrusComponents$ItemText, {
                  text: props.name
                }));
}

var ItemPanel = {
  make: PapyrusComponents$ItemPanel
};

var r$10 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        46
      ],
      tl: {
        hd: [
          Css.height,
          46
        ],
        tl: {
          hd: [
            Css.margin,
            10
          ],
          tl: /* [] */0
        }
      }
    });

var css$8 = Curry._1(Css.style, {
      hd: Css.background(Css.url(wmIcon)),
      tl: {
        hd: Css.backgroundSize("cover"),
        tl: /* [] */0
      }
    });

var className$11 = Curry._1(Css.merge, {
      hd: r$10,
      tl: {
        hd: css$8,
        tl: {
          hd: "WmIcon",
          tl: /* [] */0
        }
      }
    });

var WmIconStyle = {
  r: r$10,
  css: css$8,
  className: className$11
};

function PapyrusComponents$WmIcon(props) {
  return React.createElement("div", {
              className: className$11,
              onClick: props.onClick
            }, props.children);
}

var WmIcon = {
  make: PapyrusComponents$WmIcon
};

function PapyrusComponents$ArrowUpIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 111 111",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink"
            }, React.createElement("path", {
                  d: "M55.598,10.614 C79.973,10.614 99.733,30.374 99.733,54.749 C99.733,79.125 79.973,98.884 55.598,98.884 C31.223,98.884 11.463,79.125 11.463,54.749 C11.463,30.374 31.223,10.614 55.598,10.614 Z",
                  fill: "rgb(102, 191, 240)",
                  fillRule: "evenodd",
                  stroke: "rgb(0, 0, 0)",
                  strokeWidth: "3px"
                }), React.createElement("path", {
                  d: "M26.508,77.516 L54.498,49.364 L82.491,77.516 L82.491,53.637 L54.498,25.483 L26.508,53.637 L26.508,77.516 Z",
                  fill: "rgb(255, 255, 255)",
                  fillRule: "evenodd",
                  stroke: "rgb(0, 0, 0)",
                  strokeWidth: "3px"
                }));
}

var ArrowUpIcon = {
  make: PapyrusComponents$ArrowUpIcon
};

function PapyrusComponents$ArrowDownIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 111 111",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink"
            }, React.createElement("g", {
                  transform: "rotate(-180 55 55)"
                }, React.createElement("path", {
                      d: "M55.598,10.614 C79.973,10.614 99.733,30.374 99.733,54.749 C99.733,79.125 79.973,98.884 55.598,98.884 C31.223,98.884 11.463,79.125 11.463,54.749 C11.463,30.374 31.223,10.614 55.598,10.614 Z",
                      fill: "rgb(102, 191, 240)",
                      fillRule: "evenodd",
                      stroke: "rgb(0, 0, 0)",
                      strokeWidth: "3px"
                    }), React.createElement("path", {
                      d: "M26.508,77.516 L54.498,49.364 L82.491,77.516 L82.491,53.637 L54.498,25.483 L26.508,53.637 L26.508,77.516 Z",
                      fill: "rgb(255, 255, 255)",
                      fillRule: "evenodd",
                      stroke: "rgb(0, 0, 0)",
                      strokeWidth: "3px"
                    })));
}

var ArrowDownIcon = {
  make: PapyrusComponents$ArrowDownIcon
};

var r$11 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        66
      ],
      tl: {
        hd: [
          Css.height,
          66
        ],
        tl: {
          hd: [
            Css.marginLeft,
            10
          ],
          tl: {
            hd: [
              Css.marginRight,
              10
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$9 = Curry._1(Css.style, /* [] */0);

var className$12 = Curry._1(Css.merge, {
      hd: r$11,
      tl: {
        hd: css$9,
        tl: {
          hd: "NavIcon",
          tl: /* [] */0
        }
      }
    });

var NavIconStyle = {
  r: r$11,
  css: css$9,
  className: className$12
};

function PapyrusComponents$ArrowUpNavIcon(props) {
  return React.createElement(PapyrusComponents$ArrowUpIcon, {
              className: className$12
            });
}

var ArrowUpNavIcon = {
  make: PapyrusComponents$ArrowUpNavIcon
};

function PapyrusComponents$ArrowDownNavIcon(props) {
  return React.createElement(PapyrusComponents$ArrowDownIcon, {
              className: className$12
            });
}

var ArrowDownNavIcon = {
  make: PapyrusComponents$ArrowDownNavIcon
};

function PapyrusComponents$JourneyNavIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Planet.make, {
              className: className$12
            });
}

var JourneyNavIcon = {
  make: PapyrusComponents$JourneyNavIcon
};

function PapyrusComponents$MyWordsNavIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.MyWords.make, {
              className: className$12
            });
}

var MyWordsNavIcon = {
  make: PapyrusComponents$MyWordsNavIcon
};

function PapyrusComponents$LibraryNavIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Book.make, {
              className: className$12
            });
}

var LibraryNavIcon = {
  make: PapyrusComponents$LibraryNavIcon
};

function PapyrusComponents$IntrepizineNavIcon(props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Magazine.make, {
              className: className$12
            });
}

var IntrepizineNavIcon = {
  make: PapyrusComponents$IntrepizineNavIcon
};

var headerPanel = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.marginLeft(Css.auto),
          tl: {
            hd: Css.marginRight(Css.auto),
            tl: {
              hd: Css.justifyContent(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

function PapyrusComponents$HeaderPanel(props) {
  return React.createElement("div", {
              className: headerPanel
            }, props.children);
}

var HeaderPanel = {
  headerPanel: headerPanel,
  make: PapyrusComponents$HeaderPanel
};

var r$12 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        867
      ],
      tl: {
        hd: [
          Css.height,
          76
        ],
        tl: /* [] */0
      }
    });

var cols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 146
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 146
          },
          tl: /* [] */0
        }
      }
    });

var css$10 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.display("grid"),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.background(Css.url(scrolls)),
              tl: {
                hd: Css.backgroundSize("cover"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$13 = Curry._1(Css.merge, {
      hd: r$12,
      tl: {
        hd: cols,
        tl: {
          hd: css$10,
          tl: {
            hd: "HeaderSubPanel",
            tl: /* [] */0
          }
        }
      }
    });

var HeaderSubPanelStyle = {
  r: r$12,
  cols: cols,
  css: css$10,
  className: className$13
};

function PapyrusComponents$HeaderSubPanel(props) {
  return React.createElement("div", {
              className: className$13
            }, props.children);
}

var HeaderSubPanel = {
  make: PapyrusComponents$HeaderSubPanel
};

var r$13 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        867
      ],
      tl: /* [] */0
    });

var rows$1 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 50
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 120
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 40
          },
          tl: {
            hd: {
              NAME: "css",
              VAL: {
                NAME: "fr",
                VAL: 1.0
              }
            },
            tl: /* [] */0
          }
        }
      }
    });

var css$11 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$14 = Curry._1(Css.merge, {
      hd: r$13,
      tl: {
        hd: rows$1,
        tl: {
          hd: css$11,
          tl: {
            hd: "MainPanel",
            tl: /* [] */0
          }
        }
      }
    });

var MainPanelStyle = {
  r: r$13,
  rows: rows$1,
  css: css$11,
  className: className$14
};

function PapyrusComponents$MainPanel(props) {
  return React.createElement("div", {
              className: className$14
            }, props.children);
}

var MainPanel = {
  make: PapyrusComponents$MainPanel
};

var r$14 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        867
      ],
      tl: {
        hd: [
          Css.height,
          200
        ],
        tl: /* [] */0
      }
    });

var cols$1 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 70
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 130
        },
        tl: {
          hd: {
            NAME: "css",
            VAL: {
              NAME: "fr",
              VAL: 1.0
            }
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: 200
            },
            tl: /* [] */0
          }
        }
      }
    });

var css$12 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$15 = Curry._1(Css.merge, {
      hd: r$14,
      tl: {
        hd: cols$1,
        tl: {
          hd: css$12,
          tl: {
            hd: "TitlePanel",
            tl: /* [] */0
          }
        }
      }
    });

var TitlePanelStyle = {
  r: r$14,
  cols: cols$1,
  css: css$12,
  className: className$15
};

function PapyrusComponents$TitlePanel(props) {
  return React.createElement("div", {
              className: className$15
            }, props.children);
}

var TitlePanel = {
  make: PapyrusComponents$TitlePanel
};

var cols$2 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 40
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 90
        },
        tl: /* [] */0
      }
    });

var css$13 = Curry._1(Css.style, {
      hd: Css.color(brownColor),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: {
                  hd: Css.cursor(Css.pointer),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$16 = Curry._1(Css.merge, {
      hd: cols$2,
      tl: {
        hd: css$13,
        tl: {
          hd: "LogoutPanel",
          tl: /* [] */0
        }
      }
    });

var LogoutPanelStyle = {
  cols: cols$2,
  css: css$13,
  className: className$16
};

function PapyrusComponents$LogoutPanel(props) {
  return React.createElement("div", {
              className: className$16,
              onClick: props.onClick
            }, props.children);
}

var LogoutPanel = {
  make: PapyrusComponents$LogoutPanel
};

var r$15 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        867
      ],
      tl: {
        hd: [
          Css.height,
          200
        ],
        tl: /* [] */0
      }
    });

var cols$3 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 0
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 300
        },
        tl: {
          hd: {
            NAME: "css",
            VAL: {
              NAME: "fr",
              VAL: 1.0
            }
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: 300
            },
            tl: {
              hd: {
                NAME: "ratio",
                VAL: 0
              },
              tl: /* [] */0
            }
          }
        }
      }
    });

var css$14 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$17 = Curry._1(Css.merge, {
      hd: r$15,
      tl: {
        hd: cols$3,
        tl: {
          hd: css$14,
          tl: {
            hd: "LocationTitlePanel",
            tl: /* [] */0
          }
        }
      }
    });

var LocationTitlePanelStyle = {
  r: r$15,
  cols: cols$3,
  css: css$14,
  className: className$17
};

function PapyrusComponents$LocationTitlePanel(props) {
  return React.createElement("div", {
              className: className$17
            }, props.children);
}

var LocationTitlePanel = {
  make: PapyrusComponents$LocationTitlePanel
};

var r$16 = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var rows$2 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "css",
        VAL: {
          NAME: "fr",
          VAL: 1.0
        }
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 20
        },
        tl: /* [] */0
      }
    });

var css$15 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$18 = Curry._1(Css.merge, {
      hd: r$16,
      tl: {
        hd: rows$2,
        tl: {
          hd: css$15,
          tl: {
            hd: "LocationPanel",
            tl: /* [] */0
          }
        }
      }
    });

var LocationPanelStyle = {
  r: r$16,
  rows: rows$2,
  css: css$15,
  className: className$18
};

function PapyrusComponents$LocationPanel(props) {
  return React.createElement("div", {
              className: className$18
            }, props.children);
}

var LocationPanel = {
  make: PapyrusComponents$LocationPanel
};

var r$17 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        16
      ],
      tl: /* [] */0
    });

var css$16 = Curry._1(Css.style, {
      hd: Css.color(brownColor),
      tl: {
        hd: Css.fontFamilies(layoutFonts),
        tl: /* [] */0
      }
    });

var className$19 = Curry._1(Css.merge, {
      hd: r$17,
      tl: {
        hd: css$16,
        tl: {
          hd: "LocationText",
          tl: /* [] */0
        }
      }
    });

var LocationTextStyle = {
  r: r$17,
  css: css$16,
  className: className$19
};

function PapyrusComponents$LocationText(props) {
  return React.createElement("div", {
              className: className$19
            }, props.text);
}

var LocationText = {
  make: PapyrusComponents$LocationText
};

var r$18 = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var rows$3 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "css",
        VAL: {
          NAME: "fr",
          VAL: 1.0
        }
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 20
        },
        tl: /* [] */0
      }
    });

var css$17 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$20 = Curry._1(Css.merge, {
      hd: r$18,
      tl: {
        hd: rows$3,
        tl: {
          hd: css$17,
          tl: {
            hd: "NamePanel",
            tl: /* [] */0
          }
        }
      }
    });

var NamePanelStyle = {
  r: r$18,
  rows: rows$3,
  css: css$17,
  className: className$20
};

function PapyrusComponents$NamePanel(props) {
  return React.createElement("div", {
              className: className$20
            }, props.children);
}

var NamePanel = {
  make: PapyrusComponents$NamePanel
};

var r$19 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        867
      ],
      tl: {
        hd: [
          Css.height,
          76
        ],
        tl: /* [] */0
      }
    });

var cols$4 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 80
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "css",
            VAL: {
              NAME: "fr",
              VAL: 1.0
            }
          },
          tl: {
            hd: {
              NAME: "css",
              VAL: {
                NAME: "fr",
                VAL: 1.0
              }
            },
            tl: {
              hd: {
                NAME: "css",
                VAL: {
                  NAME: "fr",
                  VAL: 1.0
                }
              },
              tl: {
                hd: {
                  NAME: "ratio",
                  VAL: 80
                },
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var css$18 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.justifyItems(Css.center),
            tl: {
              hd: Css.alignContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$21 = Curry._1(Css.merge, {
      hd: r$19,
      tl: {
        hd: cols$4,
        tl: {
          hd: css$18,
          tl: {
            hd: "IconPanel",
            tl: /* [] */0
          }
        }
      }
    });

var IconPanelStyle = {
  r: r$19,
  cols: cols$4,
  css: css$18,
  className: className$21
};

function PapyrusComponents$IconPanel(props) {
  return React.createElement("div", {
              className: className$21
            }, props.children);
}

var IconPanel = {
  make: PapyrusComponents$IconPanel
};

var r$20 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        76
      ],
      tl: /* [] */0
    });

var css$19 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: /* [] */0
      }
    });

var className$22 = Curry._1(Css.merge, {
      hd: r$20,
      tl: {
        hd: css$19,
        tl: {
          hd: "HeaderIconPanel",
          tl: /* [] */0
        }
      }
    });

var HeaderIconPanelStyle = {
  r: r$20,
  css: css$19,
  className: className$22
};

function PapyrusComponents$HeaderIconPanel(props) {
  return React.createElement("div", {
              className: className$22
            }, props.children);
}

var HeaderIconPanel = {
  make: PapyrusComponents$HeaderIconPanel
};

var rows$4 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 76
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: /* [] */0
      }
    });

var css$20 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: /* [] */0
    });

var className$23 = Curry._1(Css.merge, {
      hd: rows$4,
      tl: {
        hd: css$20,
        tl: {
          hd: "ScrollPanel",
          tl: /* [] */0
        }
      }
    });

var ScrollPanelStyle = {
  rows: rows$4,
  css: css$20,
  className: className$23
};

function PapyrusComponents$ScrollPanel(props) {
  return React.createElement("div", {
              className: className$23
            }, props.children);
}

var ScrollPanel = {
  make: PapyrusComponents$ScrollPanel
};

var r$21 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        804
      ],
      tl: {
        hd: [
          Css.height,
          593
        ],
        tl: /* [] */0
      }
    });

var rows$5 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 84
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: /* [] */0
      }
    });

var css$21 = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.background(Css.url(openScroll)),
            tl: {
              hd: Css.backgroundSize("cover"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$24 = Curry._1(Css.merge, {
      hd: r$21,
      tl: {
        hd: rows$5,
        tl: {
          hd: css$21,
          tl: {
            hd: "BodyPanel",
            tl: /* [] */0
          }
        }
      }
    });

var BodyPanelStyle = {
  r: r$21,
  rows: rows$5,
  css: css$21,
  className: className$24
};

function PapyrusComponents$BodyPanel(props) {
  return React.createElement("div", {
              className: className$24
            }, props.children);
}

var BodyPanel = {
  make: PapyrusComponents$BodyPanel
};

var css$22 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: /* [] */0
    });

var className$25 = Curry._1(Css.merge, {
      hd: css$22,
      tl: {
        hd: "HeaderIcon",
        tl: /* [] */0
      }
    });

var HeaderIconStyle = {
  css: css$22,
  className: className$25
};

function PapyrusComponents$HeaderIcon(props) {
  return React.createElement("div", {
              className: className$25,
              onClick: props.onClick
            }, props.children);
}

var HeaderIcon = {
  make: PapyrusComponents$HeaderIcon
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

export {
  layoutFonts ,
  ratio ,
  maximumWidth ,
  s ,
  resizeGridCols ,
  resizeGridRows ,
  scrolls ,
  openScroll ,
  wmIcon ,
  bookIcon ,
  sackIcon ,
  arcadeIcon ,
  shopIcon ,
  lockIcon ,
  Spacer ,
  brownColor ,
  whiteColor ,
  bottomBlueColor ,
  topBlueColor ,
  blueColor ,
  offWhiteColor ,
  lightOffWhiteColor ,
  PsLevel ,
  NameLineStyle ,
  NameLine ,
  NameLineLeftStyle ,
  NameLineLeft ,
  LogoutStyle ,
  Logout ,
  LevelLineStyle ,
  LevelLine ,
  LockIconStyle ,
  LockIcon ,
  ItemIconStyle ,
  ItemIcon ,
  NameTextStyle ,
  NameText ,
  ButtonTextStyle ,
  ButtonText ,
  ItemTextStyle ,
  ItemText ,
  ItemPanelStyle ,
  ItemPanel ,
  WmIconStyle ,
  WmIcon ,
  ArrowUpIcon ,
  ArrowDownIcon ,
  NavIconStyle ,
  ArrowUpNavIcon ,
  ArrowDownNavIcon ,
  JourneyNavIcon ,
  MyWordsNavIcon ,
  LibraryNavIcon ,
  IntrepizineNavIcon ,
  HeaderPanel ,
  HeaderSubPanelStyle ,
  HeaderSubPanel ,
  MainPanelStyle ,
  MainPanel ,
  TitlePanelStyle ,
  TitlePanel ,
  LogoutPanelStyle ,
  LogoutPanel ,
  LocationTitlePanelStyle ,
  LocationTitlePanel ,
  LocationPanelStyle ,
  LocationPanel ,
  LocationTextStyle ,
  LocationText ,
  NamePanelStyle ,
  NamePanel ,
  IconPanelStyle ,
  IconPanel ,
  HeaderIconPanelStyle ,
  HeaderIconPanel ,
  ScrollPanelStyle ,
  ScrollPanel ,
  BodyPanelStyle ,
  BodyPanel ,
  HeaderIconStyle ,
  HeaderIcon ,
}
/* scrolls Not a pure module */
