// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Override$LiteracyplanetClientFlags from "./Override.res.mjs";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.res.mjs";
import * as FlagsProvider$LiteracyplanetClientFlags from "./FlagsProvider.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.res.mjs";
import * as OauthContainer$LiteracyplanetClientFlags from "./OauthContainer.res.mjs";
import * as SxGraphqlClient$LiteracyplanetClientFlags from "./SxGraphqlClient.res.mjs";

var isMobile = process.env.MOBILE === "true";

function Auth$UserTypeRedirect(props) {
  var routeReplace = props.routeReplace;
  var isValid = isMobile ? true : (
      props.userType === "student" ? window.location.pathname.includes("sx") : /^\/(tx|ax|sx\/en_)/.test(window.location.pathname)
    );
  React.useEffect((function (param) {
          if (isValid) {
            
          } else {
            Curry._1(routeReplace, "/");
          }
        }), []);
  if (isValid) {
    return props.children;
  } else {
    return null;
  }
}

var UserTypeRedirect = {
  make: Auth$UserTypeRedirect
};

function Auth(props) {
  var children = props.children;
  var customUnleashFeatures = props.customUnleashFeatures;
  var routeReplace = props.routeReplace;
  return React.createElement(OauthContainer$LiteracyplanetClientFlags.make, {
              children: (function (param) {
                  var yearLevel = param.yearLevel;
                  var idpAccountId = param.idpAccountId;
                  var idp = param.idp;
                  var userId = param.userId;
                  var loc = LocaleContext$LiteracyplanetClientFlags.fromClassicLocaleString(param.locale);
                  var accent = LocaleContext$LiteracyplanetClientFlags.localeToAccent(loc);
                  var accentString = LocaleContext$LiteracyplanetClientFlags.toAccentString(accent);
                  var localeString = LocaleContext$LiteracyplanetClientFlags.toLocaleString(loc);
                  var systemFlags = Override$LiteracyplanetClientFlags.loadFlags();
                  return React.createElement(Auth$UserTypeRedirect, {
                              userType: param.userType,
                              routeReplace: routeReplace,
                              children: React.createElement(SxGraphqlClient$LiteracyplanetClientFlags.Provider.make, {
                                    children: React.createElement(FlagsProvider$LiteracyplanetClientFlags.make, {
                                          userId: userId,
                                          classicFlags: param.classicFlags,
                                          systemFlags: systemFlags,
                                          userRoles: param.userRoles,
                                          idp: idp,
                                          idpAccountId: idpAccountId,
                                          yearLevel: yearLevel,
                                          loadingComponent: React.createElement("div", undefined, null),
                                          customUnleashFeatures: customUnleashFeatures,
                                          children: React.createElement(UserContext$LiteracyplanetClientFlags.make, {
                                                value: {
                                                  name: param.name,
                                                  userId: userId,
                                                  accountName: param.accountName,
                                                  givenName: param.givenName,
                                                  familyName: param.familyName,
                                                  accountId: Caml_format.int_of_string(idpAccountId),
                                                  startingPoint: param.startingPoint,
                                                  yearLevel: yearLevel,
                                                  idp: idp,
                                                  email: param.email
                                                },
                                                children: React.createElement(LocaleContext$LiteracyplanetClientFlags.make, {
                                                      value: {
                                                        locale: loc,
                                                        accent: accent,
                                                        localeString: localeString,
                                                        accentString: accentString
                                                      },
                                                      children: children
                                                    })
                                              })
                                        })
                                  })
                            });
                })
            });
}

var make = Auth;

export {
  isMobile ,
  UserTypeRedirect ,
  make ,
}
/* isMobile Not a pure module */
