// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as SecondaryExitIcon$LiteracyplanetTupperware from "./SecondaryExitIcon.res.mjs";
import * as BehaviourExitButton$LiteracyplanetTupperware from "./BehaviourExitButton.res.mjs";
import * as StimulusToggleContext$LiteracyplanetTupperware from "./StimulusToggleContext.res.mjs";
import * as K2ExerciseInSeriesContainer$LiteracyplanetTupperware from "./K2ExerciseInSeriesContainer.res.mjs";

function SkilfulLayoutContainer$Layout(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.Layout.className
            }, props.children);
}

var Layout = {
  make: SkilfulLayoutContainer$Layout
};

function SkilfulLayoutContainer$Exit(props) {
  return React.createElement(BehaviourExitButton$LiteracyplanetTupperware.make, {
              className: Skillet$LiteracyplanetTupperware.Exit.className,
              children: null
            }, React.createElement("div", undefined, "Exit"), React.createElement(SecondaryExitIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.ExitIcon.className
                }));
}

var Exit = {
  make: SkilfulLayoutContainer$Exit
};

function SkilfulLayoutContainer$Header(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.Header.className
            }, React.createElement("div", undefined, props.title), React.createElement(SkilfulLayoutContainer$Exit, {}));
}

var Header = {
  make: SkilfulLayoutContainer$Header
};

function SkilfulLayoutContainer$NoStimulus(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.NoStimulus.className
            }, props.children);
}

var NoStimulus = {
  make: SkilfulLayoutContainer$NoStimulus
};

function SkilfulLayoutContainer$HasStimulus(props) {
  var show = Curry._1(StimulusToggleContext$LiteracyplanetTupperware.useShow, undefined);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.HasStimulus.className(show)
            }, props.children);
}

var HasStimulus = {
  make: SkilfulLayoutContainer$HasStimulus
};

function SkilfulLayoutContainer$HasStimulusContainer(props) {
  return React.createElement(StimulusToggleContext$LiteracyplanetTupperware.make, {
              show: true,
              children: React.createElement(SkilfulLayoutContainer$HasStimulus, {
                    children: props.children
                  })
            });
}

var HasStimulusContainer = {
  make: SkilfulLayoutContainer$HasStimulusContainer
};

function SkilfulLayoutContainer$InnerContainer(props) {
  var children = props.children;
  if (props.stimuliPresent) {
    return React.createElement(SkilfulLayoutContainer$HasStimulusContainer, {
                children: children
              });
  } else {
    return React.createElement(SkilfulLayoutContainer$NoStimulus, {
                children: children
              });
  }
}

var InnerContainer = {
  make: SkilfulLayoutContainer$InnerContainer
};

function SkilfulLayoutContainer(props) {
  return React.createElement(React.Fragment, {}, React.createElement("link", {
                  href: "https://use.typekit.net/jqw0veo.css",
                  rel: "stylesheet"
                }), React.createElement(K2ExerciseInSeriesContainer$LiteracyplanetTupperware.make, {
                  questionCount: props.questionCount,
                  children: React.createElement(SkilfulLayoutContainer$Layout, {
                        children: null
                      }, React.createElement(SkilfulLayoutContainer$Header, {
                            title: props.title
                          }), React.createElement(SkilfulLayoutContainer$InnerContainer, {
                            stimuliPresent: props.stimuliPresent,
                            children: props.children
                          }))
                }));
}

var make = SkilfulLayoutContainer;

var $$default = SkilfulLayoutContainer;

export {
  Layout ,
  Exit ,
  Header ,
  NoStimulus ,
  HasStimulus ,
  HasStimulusContainer ,
  InnerContainer ,
  make ,
  $$default as default,
}
/* react Not a pure module */
