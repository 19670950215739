// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function MinimizeBookIcon(props) {
  return React.createElement("svg", {
              className: props.className,
              version: "1.1",
              viewBox: "0 0 40 40"
            }, React.createElement("g", {
                  transform: "translate(-1315.000000, -120.000000)"
                }, React.createElement("g", {
                      transform: "translate(1315.000000, 120.000000)"
                    }, React.createElement("polygon", {
                          points: "11.1770833 25.625 0 36.8020833 3.15625 39.9375 14.3333333 28.7604167 13.3541667 30.9791667 13.3541667 36.9375 17.4895833 36.9791667 17.4895833 22.46875 3.02083333 22.46875 3 26.6041667 9.0625 26.6041667"
                        }), React.createElement("polygon", {
                          points: "36.9791667 17.4895833 36.9375 13.3541667 30.9791667 13.3541667 28.7604167 14.3333333 39.9375 3.15625 36.8020833 0 25.625 11.1770833 26.6041667 9.0625 26.6041667 3 22.46875 3.02083333 22.46875 17.4895833"
                        }))));
}

var make = MinimizeBookIcon;

export {
  make ,
}
/* react Not a pure module */
