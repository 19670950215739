// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

var ContextTypes = {};

var context = React.createContext({
      saveGroupAndColour: (function (param) {
          
        }),
      selectGroup: (function (param) {
          
        }),
      selectedGroup: undefined,
      lookupColour: (function (param) {
          
        })
    });

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Context = {
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function Bucket$Provider(props) {
  var defaultColour = props.defaultColour;
  var defaultGroup = props.defaultGroup;
  var defaultDict = function (param) {
    if (defaultGroup !== undefined) {
      if (defaultColour !== undefined) {
        return Js_dict.fromArray([[
                      defaultGroup,
                      defaultColour
                    ]]);
      } else {
        return {};
      }
    } else {
      return {};
    }
  };
  var match = React.useReducer((function (state, action) {
          if (action.TAG === "SelectGroup") {
            return {
                    selectedGroup: action._0,
                    colourDict: state.colourDict
                  };
          }
          var colour = action._1;
          var group = action._0;
          var colourDict = state.colourDict;
          if (group !== undefined && colour !== undefined) {
            colourDict[group] = colour;
          }
          return {
                  selectedGroup: state.selectedGroup,
                  colourDict: colourDict
                };
        }), {
        selectedGroup: defaultGroup,
        colourDict: defaultDict()
      });
  var dispatch = match[1];
  var state = match[0];
  return React.createElement(make, {
              value: {
                saveGroupAndColour: (function (param) {
                    Curry._1(dispatch, {
                          TAG: "SaveGroupAndColour",
                          _0: param[0],
                          _1: param[1]
                        });
                  }),
                selectGroup: (function (group) {
                    Curry._1(dispatch, {
                          TAG: "SelectGroup",
                          _0: group
                        });
                  }),
                selectedGroup: state.selectedGroup,
                lookupColour: (function (group) {
                    return Js_dict.get(state.colourDict, group);
                  })
              },
              children: props.children
            });
}

var Provider = {
  make: Bucket$Provider
};

function Bucket$TextContrastProvider(props) {
  var colour = props.colour;
  var tmp;
  switch (colour) {
    case "blue" :
        tmp = {
          background: "#EFD9F8",
          color: "black",
          padding: "5px"
        };
        break;
    case "green" :
        tmp = {
          background: "#FFECCE",
          color: "black",
          padding: "5px"
        };
        break;
    case "magenta" :
        tmp = {
          background: colour,
          color: "green"
        };
        break;
    case "red" :
        tmp = {
          background: "#D7EEED",
          color: "black",
          padding: "5px"
        };
        break;
    case "yellow" :
        tmp = {
          background: colour,
          color: "blue"
        };
        break;
    default:
      tmp = {
        background: "inherit",
        color: "inherit"
      };
  }
  return Curry._1(props.children, tmp);
}

var TextContrastProvider = {
  make: Bucket$TextContrastProvider
};

function Bucket$Consumer(props) {
  return Curry._1(props.children, React.useContext(context));
}

var Consumer = {
  make: Bucket$Consumer
};

function Bucket$Button(props) {
  var children = props.children;
  var colour = props.colour;
  var group = props.group;
  return React.createElement(Bucket$Consumer, {
              children: (function (param) {
                  var selectedGroup = param.selectedGroup;
                  var selectGroup = param.selectGroup;
                  var saveGroupAndColour = param.saveGroupAndColour;
                  React.useEffect((function (param) {
                          return (function (param) {
                                    Curry._1(saveGroupAndColour, [
                                          group,
                                          colour
                                        ]);
                                  });
                        }), [selectedGroup]);
                  return React.createElement(Bucket$TextContrastProvider, {
                              colour: colour,
                              children: (function (style) {
                                  return React.createElement("div", {
                                              className: "GroupedMcCheckboxButton-wrapper",
                                              style: Caml_obj.equal(selectedGroup, group) ? style : ({}),
                                              onClick: (function (param) {
                                                  Curry._1(selectGroup, group);
                                                })
                                            }, children);
                                })
                            });
                })
            });
}

var Button = {
  make: Bucket$Button
};

export {
  ContextTypes ,
  Context ,
  Provider ,
  TextContrastProvider ,
  Consumer ,
  Button ,
}
/* context Not a pure module */
