// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function SpinnerIcon(props) {
  return React.createElement("svg", {
              className: props.className + " SpinnerIcon",
              viewBox: "0 0 80 80",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  id: "spinner",
                  d: "M40,72C22.4,72,8,57.6,8,40C8,22.4,\n      22.4,8,40,8c17.6,0,32,14.4,32,32c0,1.1-0.9,2-2,2\n      s-2-0.9-2-2c0-15.4-12.6-28-28-28S12,24.6,12,40s12.6,\n      28,28,28c1.1,0,2,0.9,2,2S41.1,72,40,72z"
                }, React.createElement("animateTransform", {
                      type: "rotate",
                      attributeName: "transform",
                      attributeType: "xml",
                      dur: "1s",
                      from: "0 40 40",
                      repeatCount: "indefinite",
                      to_: "360 40 40"
                    })));
}

var make = SpinnerIcon;

var $$default = SpinnerIcon;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
