// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.res.mjs";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.res.mjs";
import * as SkilfulMultiChoiceSingleResponseContainer$LiteracyplanetTupperware from "./SkilfulMultiChoiceSingleResponseContainer.res.mjs";

function SkilfulRadioButtonMultiChoice$Radio(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.Radio.className
            }, React.createElement("div", {
                  className: "SkilfulRadioButtonMultiChoice-RadioSelection"
                }));
}

var Radio = {
  make: SkilfulRadioButtonMultiChoice$Radio
};

function SkilfulRadioButtonMultiChoice(props) {
  return React.createElement(SkilfulMultiChoiceSingleResponseContainer$LiteracyplanetTupperware.make, {
              questionIndex: props.questionIndex,
              responseOptionId: props.responseOptionId,
              children: React.createElement("div", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.className
                  }, React.createElement(SkilfulRadioButtonMultiChoice$Radio, {}), props.children, React.createElement("div", undefined, React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.CorrectMarkIcon.className
                          }), React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.IncorrectMarkIcon.className
                          })))
            });
}

var make = SkilfulRadioButtonMultiChoice;

var $$default = SkilfulRadioButtonMultiChoice;

export {
  Radio ,
  make ,
  $$default as default,
}
/* react Not a pure module */
