// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Error_modal from "./error_modal";
import * as Sentry$LiteracyplanetClientErrors from "./Sentry.res.mjs";

function stringifyArray(a) {
  return Js_array.joinWith(", ", a);
}

var exnToString = (function(exn) {
    return JSON.stringify(exn)
  });

function stringify(x) {
  if (typeof x !== "object") {
    switch (x) {
      case "JourneyMissionsError" :
          return "JourneyMissionsError";
      case "JourneyActivitiesError" :
          return "JourneyActivitiesError";
      case "JourneyMissionsSubscriptionsError" :
          return "JourneyMissionsSubscriptionsError";
      case "RhetoricalMutationError" :
          return "RhetoricalMutationError";
      case "RhetoricalQueryError" :
          return "RhetoricalQueryError";
      case "PixiWebGLNotSupported" :
          return "PixiWebGLNotSupported";
      case "WebpNotSupported" :
          return "WebpNotSupported";
      case "SecondaryMissionError" :
          return "SecondaryMissionError";
      case "TeacherError" :
          return "TeacherError";
      case "CustomMissionAssignmentNotFoundTeacherError" :
          return "CustomMissionAssignmentNotFoundTeacherError";
      case "UnknownError" :
          return "UnknownError";
      case "GameTypeLookupFailed" :
          return "GameTypeLookupFailed";
      
    }
  } else {
    switch (x.TAG) {
      case "GraphqlError" :
          return "graphql error, " + (x._0 + (", [" + (Js_array.joinWith(", ", x._1) + "]") + Belt_Option.mapWithDefault(x._2, "", (function (err) {
                            return ", " + err;
                          }))));
      case "PayloadDecodeError" :
          return "error payload decode error , " + x._0 + ", " + x._1;
      case "AssetsMissing" :
          return "failed to load assets " + Js_array.joinWith(", ", x._0);
      case "Exception" :
          return exnToString(x._0);
      case "ErrorWithMessage" :
          var reason = Belt_Option.getWithDefault(x._1, "");
          return "error occurred , " + x._0 + ", " + reason;
      case "CustomModalError" :
          return x._1;
      
    }
  }
}

function parseReason(payload, reason, fallback) {
  switch (reason) {
    case "AssetsMissing" :
        return {
                TAG: "AssetsMissing",
                _0: JSON.parse(payload)
              };
    case "PixiWebGLNotSupported" :
        return "PixiWebGLNotSupported";
    case "WebpNotSupported" :
        return "WebpNotSupported";
    default:
      return fallback;
  }
}

function makeError(fallback, err) {
  var fallbackType = Belt_Option.getWithDefault(fallback, "UnknownError");
  var match = err.payload;
  var match$1 = err.reason;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return parseReason("", match$1, fallbackType);
    } else {
      return fallbackType;
    }
  }
  if (match$1 === undefined) {
    return fallbackType;
  }
  try {
    return parseReason(match, match$1, fallbackType);
  }
  catch (exn){
    return {
            TAG: "PayloadDecodeError",
            _0: "Cobra error payload invalid",
            _1: match$1
          };
  }
}

var JsInterop = {
  parseReason: parseReason,
  makeError: makeError
};

function onSetErrorDefault(_error) {
  
}

function onSetErrorWithMessageDefault(modalMessage, sentryMessage, _error) {
  
}

function state_onClearError(param) {
  
}

var state = {
  onSetError: onSetErrorDefault,
  onSetErrorWithMessage: onSetErrorWithMessageDefault,
  onClearError: state_onClearError,
  error: undefined,
  modalMessage: undefined
};

var context = React.createContext(state);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  state: state,
  context: context,
  makeProps: makeProps,
  make: make
};

function MakeApp(Sentry) {
  var initialState_onClearError = function (param) {
    
  };
  var initialState = {
    onSetError: onSetErrorDefault,
    onSetErrorWithMessage: onSetErrorWithMessageDefault,
    onClearError: initialState_onClearError,
    error: undefined,
    modalMessage: undefined
  };
  var reducer = function (state, action) {
    if (action.TAG === "SetError") {
      return {
              onSetError: state.onSetError,
              onSetErrorWithMessage: state.onSetErrorWithMessage,
              onClearError: state.onClearError,
              error: action._0,
              modalMessage: state.modalMessage
            };
    } else {
      return {
              onSetError: state.onSetError,
              onSetErrorWithMessage: state.onSetErrorWithMessage,
              onClearError: state.onClearError,
              error: action._0,
              modalMessage: action._1
            };
    }
  };
  var Error$MakeApp = function (props) {
    var match = React.useReducer(reducer, initialState);
    var dispatch = match[1];
    var state = match[0];
    var onSetError = function (err) {
      if (typeof err === "object" && err.TAG === "CustomModalError") {
        var sentryMessage = err._0;
        Curry._1(Sentry.send, sentryMessage);
        return Curry._1(dispatch, {
                    TAG: "SetErrorWithMessage",
                    _0: {
                      TAG: "ErrorWithMessage",
                      _0: sentryMessage,
                      _1: undefined
                    },
                    _1: {
                      NAME: "String",
                      VAL: err._1
                    }
                  });
      }
      Curry._1(Sentry.send, stringify(err));
      Curry._1(dispatch, {
            TAG: "SetError",
            _0: err
          });
    };
    var onSetErrorWithMessage = function (modalMessage, sentryMessage, err) {
      Curry._1(Sentry.send, stringify(err) + Belt_Option.mapWithDefault(sentryMessage, "", (function (msg) {
                  return " ::: " + msg;
                })));
      Curry._1(dispatch, {
            TAG: "SetErrorWithMessage",
            _0: err,
            _1: modalMessage
          });
    };
    var onClearError = function (param) {
      Curry._1(dispatch, {
            TAG: "SetErrorWithMessage",
            _0: undefined,
            _1: undefined
          });
    };
    return React.createElement("div", undefined, React.createElement(make, {
                    value: {
                      onSetError: onSetError,
                      onSetErrorWithMessage: onSetErrorWithMessage,
                      onClearError: onClearError,
                      error: state.error,
                      modalMessage: state.modalMessage
                    },
                    children: props.children
                  }));
  };
  return {
          initialState: initialState,
          reducer: reducer,
          make: Error$MakeApp
        };
}

function send(err) {
  Sentry$LiteracyplanetClientErrors.captureException(err);
}

function showReportDialog(param) {
  Sentry$LiteracyplanetClientErrors.showReportDialog();
}

var Sentry = {
  send: send,
  showReportDialog: showReportDialog
};

function initialState_onClearError(param) {
  
}

var initialState = {
  onSetError: onSetErrorDefault,
  onSetErrorWithMessage: onSetErrorWithMessageDefault,
  onClearError: initialState_onClearError,
  error: undefined,
  modalMessage: undefined
};

function reducer(state, action) {
  if (action.TAG === "SetError") {
    return {
            onSetError: state.onSetError,
            onSetErrorWithMessage: state.onSetErrorWithMessage,
            onClearError: state.onClearError,
            error: action._0,
            modalMessage: state.modalMessage
          };
  } else {
    return {
            onSetError: state.onSetError,
            onSetErrorWithMessage: state.onSetErrorWithMessage,
            onClearError: state.onClearError,
            error: action._0,
            modalMessage: action._1
          };
  }
}

function Error$MakeApp(props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var onSetError = function (err) {
    if (typeof err === "object" && err.TAG === "CustomModalError") {
      var sentryMessage = err._0;
      Sentry$LiteracyplanetClientErrors.captureException(sentryMessage);
      return Curry._1(dispatch, {
                  TAG: "SetErrorWithMessage",
                  _0: {
                    TAG: "ErrorWithMessage",
                    _0: sentryMessage,
                    _1: undefined
                  },
                  _1: {
                    NAME: "String",
                    VAL: err._1
                  }
                });
    }
    var err$1 = stringify(err);
    Sentry$LiteracyplanetClientErrors.captureException(err$1);
    Curry._1(dispatch, {
          TAG: "SetError",
          _0: err
        });
  };
  var onSetErrorWithMessage = function (modalMessage, sentryMessage, err) {
    var err$1 = stringify(err) + Belt_Option.mapWithDefault(sentryMessage, "", (function (msg) {
            return " ::: " + msg;
          }));
    Sentry$LiteracyplanetClientErrors.captureException(err$1);
    Curry._1(dispatch, {
          TAG: "SetErrorWithMessage",
          _0: err,
          _1: modalMessage
        });
  };
  var onClearError = function (param) {
    Curry._1(dispatch, {
          TAG: "SetErrorWithMessage",
          _0: undefined,
          _1: undefined
        });
  };
  return React.createElement("div", undefined, React.createElement(make, {
                  value: {
                    onSetError: onSetError,
                    onSetErrorWithMessage: onSetErrorWithMessage,
                    onClearError: onClearError,
                    error: state.error,
                    modalMessage: state.modalMessage
                  },
                  children: props.children
                }));
}

var App = {
  initialState: initialState,
  reducer: reducer,
  make: Error$MakeApp
};

function Error$PixiError(props) {
  return React.createElement(React.Fragment, {}, "Oh no! We need WebGL turned on.", React.createElement("br", undefined), "Learn how at our ", React.createElement("a", {
                  href: "https://hub.literacyplanet.com/s/article/Enable-WebGL"
                }, "Knowledge Hub."));
}

var PixiError = {
  make: Error$PixiError
};

function Error$WebpError(props) {
  return React.createElement(React.Fragment, {}, "Oh no! We need webp media support.", React.createElement("br", undefined), "Learn more at our ", React.createElement("a", {
                  href: "https://hub.literacyplanet.com/s/article/Minimum-Requirements"
                }, "Knowledge Hub."));
}

var WebpError = {
  make: Error$WebpError
};

var make$1 = Error_modal.Error;

var JsErrorModal = {
  make: make$1
};

function jsErrorModalCB(onClickHome, onClearError, message, param) {
  return React.createElement(make$1, {
              onClickHome: onClickHome,
              onClearError: onClearError,
              message: message
            });
}

function Error$Modal(props) {
  var __errorModal = props.errorModal;
  var __message = props.message;
  var message = __message !== undefined ? __message : "We seem to have encountered an error. Please retry or go back to the home page. If this problem persists, please contact our customer support team.";
  var errorModal = __errorModal !== undefined ? __errorModal : jsErrorModalCB;
  var state = React.useContext(context);
  var match = state.error;
  var match$1 = state.modalMessage;
  var errMessage;
  var exit = 0;
  if (match !== undefined && typeof match !== "object") {
    switch (match) {
      case "PixiWebGLNotSupported" :
          if (match$1 !== undefined) {
            exit = 1;
          } else {
            errMessage = React.createElement(Error$PixiError, {});
          }
          break;
      case "WebpNotSupported" :
          if (match$1 !== undefined) {
            exit = 1;
          } else {
            errMessage = React.createElement(Error$WebpError, {});
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    errMessage = match$1 !== undefined ? match$1.VAL : message;
  }
  if (Belt_Option.isNone(state.error)) {
    return Curry._1(props.children, undefined);
  } else {
    return Curry._4(errorModal, props.onClickHome, state.onClearError, errMessage, undefined);
  }
}

var Modal = {
  JsErrorModal: JsErrorModal,
  jsErrorModalCB: jsErrorModalCB,
  make: Error$Modal
};

function useErrorModal(param) {
  return React.useContext(context);
}

function Error$Consumer(props) {
  return Curry._1(props.children, React.useContext(context));
}

var Consumer = {
  use: useErrorModal,
  make: Error$Consumer
};

export {
  stringifyArray ,
  exnToString ,
  stringify ,
  JsInterop ,
  onSetErrorDefault ,
  onSetErrorWithMessageDefault ,
  Provider ,
  MakeApp ,
  Sentry ,
  App ,
  PixiError ,
  WebpError ,
  Modal ,
  useErrorModal ,
  Consumer ,
}
/* context Not a pure module */
