// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "rescript/lib/es6/list.js";
import * as $$Array from "rescript/lib/es6/array.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function responseIdentifiersMatch(responseIdentifier1, responseIdentifier2) {
  switch (responseIdentifier1.TAG) {
    case "SingleId" :
        switch (responseIdentifier2.TAG) {
          case "SingleId" :
              return responseIdentifier1._0 === responseIdentifier2._0;
          case "IdPair" :
          case "Group" :
              return false;
          
        }
    case "IdPair" :
        switch (responseIdentifier2.TAG) {
          case "IdPair" :
              return responseIdentifier1._0 === responseIdentifier2._0;
          case "SingleId" :
          case "Group" :
              return false;
          
        }
    case "Group" :
        switch (responseIdentifier2.TAG) {
          case "SingleId" :
          case "IdPair" :
              return false;
          case "Group" :
              return responseIdentifier1._0 === responseIdentifier2._0;
          
        }
    
  }
}

function responseTypeExists(responses, responsePayload) {
  return $$Array.fold_left((function (acc, response) {
                if (acc) {
                  return acc;
                } else {
                  return responseIdentifiersMatch(responsePayload._0, response._0._0);
                }
              }), false, responses);
}

function toggleIfMatch(responseIdentifier, existingResponsePayload, toggleResponsePayload) {
  var existingResponseIdentifier = existingResponsePayload._0;
  switch (responseIdentifier.TAG) {
    case "SingleId" :
        switch (existingResponseIdentifier.TAG) {
          case "SingleId" :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [];
              } else {
                return [{
                          TAG: "ToggleResponse",
                          _0: existingResponsePayload
                        }];
              }
          case "IdPair" :
          case "Group" :
              return [{
                        TAG: "ToggleResponse",
                        _0: existingResponsePayload
                      }];
          
        }
    case "IdPair" :
        switch (existingResponseIdentifier.TAG) {
          case "IdPair" :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [];
              } else {
                return [{
                          TAG: "ToggleResponse",
                          _0: existingResponsePayload
                        }];
              }
          case "SingleId" :
          case "Group" :
              return [{
                        TAG: "ToggleResponse",
                        _0: existingResponsePayload
                      }];
          
        }
    case "Group" :
        var id1 = responseIdentifier._1;
        var g1 = responseIdentifier._0;
        switch (id1.TAG) {
          case "SingleId" :
              switch (existingResponseIdentifier.TAG) {
                case "SingleId" :
                case "IdPair" :
                    return [{
                              TAG: "ToggleResponse",
                              _0: existingResponsePayload
                            }];
                case "Group" :
                    var id2 = existingResponseIdentifier._1;
                    switch (id2.TAG) {
                      case "SingleId" :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === id2._0) {
                              return [];
                            } else {
                              return [{
                                        TAG: "ToggleResponse",
                                        _0: toggleResponsePayload
                                      }];
                            }
                          } else {
                            return [{
                                      TAG: "ToggleResponse",
                                      _0: existingResponsePayload
                                    }];
                          }
                      case "IdPair" :
                      case "Group" :
                          return [{
                                    TAG: "ToggleResponse",
                                    _0: existingResponsePayload
                                  }];
                      
                    }
                
              }
          case "IdPair" :
              switch (existingResponseIdentifier.TAG) {
                case "SingleId" :
                case "IdPair" :
                    return [{
                              TAG: "ToggleResponse",
                              _0: existingResponsePayload
                            }];
                case "Group" :
                    var match = existingResponseIdentifier._1;
                    switch (match.TAG) {
                      case "IdPair" :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === match._0) {
                              return [];
                            } else {
                              return [{
                                        TAG: "ToggleResponse",
                                        _0: toggleResponsePayload
                                      }];
                            }
                          } else {
                            return [{
                                      TAG: "ToggleResponse",
                                      _0: existingResponsePayload
                                    }];
                          }
                      case "SingleId" :
                      case "Group" :
                          return [{
                                    TAG: "ToggleResponse",
                                    _0: existingResponsePayload
                                  }];
                      
                    }
                
              }
          case "Group" :
              return [{
                        TAG: "ToggleResponse",
                        _0: existingResponsePayload
                      }];
          
        }
    
  }
}

function toggleResponse(responses, toggleResponsePayload) {
  var responseIdentifier = toggleResponsePayload._0;
  if (responseTypeExists(responses, toggleResponsePayload)) {
    return $$Array.fold_left((function (acc, existingResponse) {
                  if (existingResponse.TAG !== "ToggleResponse") {
                    return $$Array.append(acc, [existingResponse]);
                  }
                  var item = toggleIfMatch(responseIdentifier, existingResponse._0, toggleResponsePayload);
                  return $$Array.append(acc, item);
                }), [], responses);
  } else {
    return $$Array.append(responses, [{
                  TAG: "ToggleResponse",
                  _0: toggleResponsePayload
                }]);
  }
}

function setIfMatch(responseIdentifier, existingResponsePayload, responsePayload, existingResponse) {
  var existingResponseIdentifier = existingResponsePayload._0;
  switch (responseIdentifier.TAG) {
    case "SingleId" :
        switch (existingResponseIdentifier.TAG) {
          case "SingleId" :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [{
                          TAG: "SetResponse",
                          _0: responsePayload
                        }];
              } else {
                return [existingResponse];
              }
          case "IdPair" :
          case "Group" :
              return [existingResponse];
          
        }
    case "IdPair" :
        switch (existingResponseIdentifier.TAG) {
          case "IdPair" :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [{
                          TAG: "SetResponse",
                          _0: responsePayload
                        }];
              } else {
                return [existingResponse];
              }
          case "SingleId" :
          case "Group" :
              return [existingResponse];
          
        }
    case "Group" :
        var id1 = responseIdentifier._1;
        var g1 = responseIdentifier._0;
        switch (id1.TAG) {
          case "SingleId" :
              switch (existingResponseIdentifier.TAG) {
                case "SingleId" :
                case "IdPair" :
                    return [existingResponse];
                case "Group" :
                    var id2 = existingResponseIdentifier._1;
                    switch (id2.TAG) {
                      case "SingleId" :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === id2._0) {
                              return [{
                                        TAG: "SetResponse",
                                        _0: responsePayload
                                      }];
                            } else {
                              return [existingResponse];
                            }
                          } else {
                            return [existingResponse];
                          }
                      case "IdPair" :
                      case "Group" :
                          return [existingResponse];
                      
                    }
                
              }
          case "IdPair" :
              switch (existingResponseIdentifier.TAG) {
                case "SingleId" :
                case "IdPair" :
                    return [existingResponse];
                case "Group" :
                    var match = existingResponseIdentifier._1;
                    switch (match.TAG) {
                      case "IdPair" :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === match._0) {
                              return [{
                                        TAG: "SetResponse",
                                        _0: responsePayload
                                      }];
                            } else {
                              return [existingResponse];
                            }
                          } else {
                            return [existingResponse];
                          }
                      case "SingleId" :
                      case "Group" :
                          return [existingResponse];
                      
                    }
                
              }
          case "Group" :
              return [existingResponse];
          
        }
    
  }
}

function setResponse(responses, strResponsePayload) {
  var responseIdentifier = strResponsePayload._0;
  if (responseTypeExists(responses, strResponsePayload)) {
    return $$Array.fold_left((function (acc, existingResponse) {
                  if (existingResponse.TAG === "ToggleResponse") {
                    throw {
                          RE_EXN_ID: "Match_failure",
                          _1: [
                            "Reducer.res",
                            166,
                            10
                          ],
                          Error: new Error()
                        };
                  }
                  var item = setIfMatch(responseIdentifier, existingResponse._0, strResponsePayload, existingResponse);
                  return $$Array.append(acc, item);
                }), [], responses);
  } else {
    return $$Array.append(responses, [{
                  TAG: "SetResponse",
                  _0: strResponsePayload
                }]);
  }
}

function updateQuestion(responses, response) {
  var qs;
  if (responses.length !== 0) {
    var tmp;
    tmp = response.TAG === "ToggleResponse" ? toggleResponse(responses, response._0) : setResponse(responses, response._0);
    qs = {
      TAG: "QuestionAttempted",
      _0: tmp
    };
  } else {
    qs = {
      TAG: "QuestionAttempted",
      _0: [response]
    };
  }
  if (typeof qs !== "object" || !Caml_obj.equal(qs._0, [])) {
    return qs;
  } else {
    return "NotAttempted";
  }
}

function respondToQuestion(questionState, response) {
  if (typeof questionState !== "object") {
    return updateQuestion([], response);
  } else {
    return updateQuestion(questionState._0, response);
  }
}

var splitChar = "<>";

function joinIdPair(id1, id2) {
  return id1 + (splitChar + id2);
}

function extractAnswers(responses) {
  return $$Array.fold_left((function (acc, response) {
                if (response.TAG === "ToggleResponse") {
                  var responseIdentifier = response._0._0;
                  switch (responseIdentifier.TAG) {
                    case "SingleId" :
                        return List.append(acc, {
                                    hd: responseIdentifier._0,
                                    tl: /* [] */0
                                  });
                    case "IdPair" :
                        return List.append(acc, {
                                    hd: joinIdPair(responseIdentifier._0, responseIdentifier._1),
                                    tl: /* [] */0
                                  });
                    case "Group" :
                        var id = responseIdentifier._1;
                        switch (id.TAG) {
                          case "SingleId" :
                              return List.append(acc, {
                                          hd: id._0,
                                          tl: /* [] */0
                                        });
                          case "IdPair" :
                              return List.append(acc, {
                                          hd: joinIdPair(id._0, id._1),
                                          tl: /* [] */0
                                        });
                          case "Group" :
                              return acc;
                          
                        }
                    
                  }
                } else {
                  var responsePayload = response._0;
                  if (responsePayload.TAG === "BoolResponsePayload") {
                    var responseIdentifier$1 = responsePayload._0;
                    switch (responseIdentifier$1.TAG) {
                      case "SingleId" :
                          return List.append(acc, {
                                      hd: responseIdentifier$1._0,
                                      tl: /* [] */0
                                    });
                      case "IdPair" :
                          return List.append(acc, {
                                      hd: joinIdPair(responseIdentifier$1._0, responseIdentifier$1._1),
                                      tl: /* [] */0
                                    });
                      case "Group" :
                          var id$1 = responseIdentifier$1._1;
                          switch (id$1.TAG) {
                            case "SingleId" :
                                return List.append(acc, {
                                            hd: id$1._0,
                                            tl: /* [] */0
                                          });
                            case "IdPair" :
                                return List.append(acc, {
                                            hd: joinIdPair(id$1._0, id$1._1),
                                            tl: /* [] */0
                                          });
                            case "Group" :
                                return acc;
                            
                          }
                      
                    }
                  } else {
                    var responseIdentifier$2 = responsePayload._0;
                    switch (responseIdentifier$2.TAG) {
                      case "SingleId" :
                          return List.append(acc, {
                                      hd: responseIdentifier$2._0 + (":" + $$String.trim(responsePayload._1)),
                                      tl: /* [] */0
                                    });
                      case "IdPair" :
                      case "Group" :
                          return acc;
                      
                    }
                  }
                }
              }), /* [] */0, responses);
}

function diff(xs, xss) {
  return List.fold_left((function (acc, el) {
                if (List.exists((function (row) {
                          return Caml_obj.equal(row, el);
                        }), xss)) {
                  return acc;
                } else {
                  return List.append(acc, {
                              hd: el,
                              tl: /* [] */0
                            });
                }
              }), /* [] */0, xs);
}

function markQuestion(questionState, correctAnswer) {
  if (typeof questionState !== "object") {
    var answer = correctAnswer._0;
    return [
            false,
            {
              TAG: "BoolMarkState",
              _0: {
                questionCorrect: false,
                answer: answer,
                incorrectResponses: answer
              }
            }
          ];
  }
  var responses = questionState._0;
  if (correctAnswer.TAG === "AnswerExactMatch") {
    var answer$1 = correctAnswer._0;
    var usersAnswer = extractAnswers(responses);
    var incorrectResponses = List.append(diff(answer$1, usersAnswer), diff(usersAnswer, answer$1));
    var questionCorrect = List.length(incorrectResponses) === 0;
    return [
            questionCorrect,
            {
              TAG: "BoolMarkState",
              _0: {
                questionCorrect: questionCorrect,
                answer: answer$1,
                incorrectResponses: incorrectResponses
              }
            }
          ];
  }
  var answer$2 = correctAnswer._0;
  var usersAnswer$1 = extractAnswers(responses);
  var lowercaseUserAnswer = Belt_List.map(usersAnswer$1, $$String.lowercase_ascii);
  var lowercaseAnswers = Belt_List.map(answer$2, $$String.lowercase_ascii);
  var incorrectResponses$1 = List.append(diff(lowercaseAnswers, lowercaseUserAnswer), diff(lowercaseUserAnswer, lowercaseAnswers));
  var questionCorrect$1 = List.length(incorrectResponses$1) === 0;
  return [
          questionCorrect$1,
          {
            TAG: "BoolMarkState",
            _0: {
              questionCorrect: questionCorrect$1,
              answer: answer$2,
              incorrectResponses: incorrectResponses$1
            }
          }
        ];
}

function getMarkType(step, responseOptionId) {
  if (typeof step !== "object" || step.TAG === "Attempted") {
    return "Unanswered";
  } else {
    var markState = step._0;
    if (typeof markState !== "object") {
      return "Unanswered";
    } else {
      var param = markState._0;
      if (List.exists((function (x) {
                return x === responseOptionId;
              }), param.incorrectResponses)) {
        return "Wrong";
      } else {
        return "Correct";
      }
    }
  }
}

function getMarkTypeWip(step, responseOptionId) {
  var compareToLeft = function (re, a) {
    var match = Js_string.splitByRe(re, a);
    if (match.length !== 2) {
      return false;
    }
    var left = match[0];
    if (left !== undefined) {
      return left === responseOptionId;
    } else {
      return false;
    }
  };
  var setComparators = function (ra) {
    return Belt_Array.map(ra, (function (r) {
                  var _ri = r._0._0;
                  while(true) {
                    var ri = _ri;
                    switch (ri.TAG) {
                      case "SingleId" :
                          return function (x) {
                            return compareToLeft(/\:/, x);
                          };
                      case "IdPair" :
                          return function (x) {
                            return compareToLeft(/\<\>/, x);
                          };
                      case "Group" :
                          _ri = ri._1;
                          continue ;
                      
                    }
                  };
                }));
  };
  if (typeof step !== "object") {
    return "Unanswered";
  }
  if (step.TAG === "Attempted") {
    return "Unanswered";
  }
  var ra = step._1;
  if (typeof ra !== "object") {
    return "Wrong";
  } else {
    var ms = step._0;
    var ra$1 = ra._0;
    if (typeof ms !== "object") {
      return "Unanswered";
    } else {
      var param = ms._0;
      var incorrectResponses = param.incorrectResponses;
      var comparators = setComparators(ra$1);
      if (List.length(incorrectResponses) === 0) {
        return "Correct";
      } else {
        return List.fold_left((function (acc, ir) {
                      switch (acc) {
                        case "Wrong" :
                            return acc;
                        case "Correct" :
                        case "Unanswered" :
                            break;
                        
                      }
                      if ($$Array.fold_left((function (acc, comparator) {
                                return acc ? acc : Curry._1(comparator, ir);
                              }), false, comparators)) {
                        return "Wrong";
                      } else {
                        return "Correct";
                      }
                    }), "Correct", incorrectResponses);
      }
    }
  }
}

function buildResponseType(groupName, responseOptionId) {
  return {
          TAG: "ToggleResponse",
          _0: {
            TAG: "BoolResponsePayload",
            _0: groupName !== undefined ? ({
                  TAG: "Group",
                  _0: groupName,
                  _1: {
                    TAG: "SingleId",
                    _0: responseOptionId
                  }
                }) : ({
                  TAG: "SingleId",
                  _0: responseOptionId
                })
          }
        };
}

function checkStep(questionState, markState) {
  if (typeof questionState !== "object") {
    if (typeof markState !== "object") {
      return "WaitingForUser";
    } else {
      return {
              TAG: "Marked",
              _0: markState,
              _1: questionState
            };
    }
  } else if (typeof markState !== "object") {
    return {
            TAG: "Attempted",
            _0: questionState
          };
  } else {
    return {
            TAG: "Marked",
            _0: markState,
            _1: questionState
          };
  }
}

function isMarked(step) {
  if (typeof step !== "object" || step.TAG === "Attempted") {
    return false;
  } else {
    return true;
  }
}

function getSingleId(responseIdentifier, text, responseOptionId) {
  switch (responseIdentifier.TAG) {
    case "SingleId" :
        if (responseIdentifier._0 === responseOptionId) {
          return Caml_option.some(text);
        } else {
          return ;
        }
    case "IdPair" :
    case "Group" :
        return ;
    
  }
}

function getSetResponsePayload(responsePayload, responseOptionId) {
  if (responsePayload.TAG === "BoolResponsePayload") {
    return ;
  } else {
    return getSingleId(responsePayload._0, responsePayload._1, responseOptionId);
  }
}

function getSetResponse(response, responseOptionId) {
  if (response.TAG === "ToggleResponse") {
    return ;
  } else {
    return getSetResponsePayload(response._0, responseOptionId);
  }
}

function getResponse(responseArray, responseOptionId) {
  return Belt_Array.reduce(responseArray, "", (function (acc, response) {
                return Belt_Option.getWithDefault(getSetResponse(response, responseOptionId), acc);
              }));
}

function getAttempt(questionState, responseOptionId) {
  if (typeof questionState !== "object") {
    return "";
  } else {
    return getResponse(questionState._0, responseOptionId);
  }
}

function getStepResponseValue(step, responseOptionId) {
  if (typeof step !== "object") {
    return "";
  } else if (step.TAG === "Attempted") {
    return getAttempt(step._0, responseOptionId);
  } else {
    return getAttempt(step._1, responseOptionId);
  }
}

function findIdPair(test, questionState) {
  if (typeof questionState !== "object") {
    return ;
  } else {
    return Belt_Array.reduce(questionState._0, undefined, (function (acc, response) {
                  var responsePayload = response._0;
                  if (responsePayload.TAG === "BoolResponsePayload") {
                    var match = responsePayload._0;
                    switch (match.TAG) {
                      case "IdPair" :
                          var resId2 = match._1;
                          var resId1 = match._0;
                          if (Curry._2(test, resId1, resId2)) {
                            return [
                                    resId1,
                                    resId2
                                  ];
                          } else {
                            return acc;
                          }
                      case "SingleId" :
                      case "Group" :
                          break;
                      
                    }
                  }
                  throw {
                        RE_EXN_ID: "Match_failure",
                        _1: [
                          "Reducer.res",
                          436,
                          8
                        ],
                        Error: new Error()
                      };
                }));
  }
}

function findQuestionState(step) {
  if (typeof step !== "object") {
    return ;
  } else if (step.TAG === "Attempted") {
    return step._0;
  } else {
    return step._1;
  }
}

function mapIdPair(step, f) {
  return Belt_Option.flatMap(findQuestionState(step), (function (param) {
                return findIdPair(f, param);
              }));
}

function mapDropId(idPair) {
  return Belt_Option.flatMap(idPair, (function (param) {
                return param[0];
              }));
}

function filterResponseIdentifier(questionState, identifier) {
  if (typeof questionState === "object") {
    return {
            TAG: "QuestionAttempted",
            _0: Belt_Array.reduce(questionState._0, [], (function (acc, response) {
                    if (Caml_obj.equal(response._0._0, identifier)) {
                      return acc;
                    } else {
                      return Belt_Array.concat(acc, [response]);
                    }
                  }))
          };
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "Reducer.res",
          469,
          2
        ],
        Error: new Error()
      };
}

export {
  responseIdentifiersMatch ,
  responseTypeExists ,
  toggleIfMatch ,
  toggleResponse ,
  setIfMatch ,
  setResponse ,
  updateQuestion ,
  respondToQuestion ,
  splitChar ,
  joinIdPair ,
  extractAnswers ,
  diff ,
  markQuestion ,
  getMarkType ,
  getMarkTypeWip ,
  buildResponseType ,
  checkStep ,
  isMarked ,
  getSingleId ,
  getSetResponsePayload ,
  getSetResponse ,
  getResponse ,
  getAttempt ,
  getStepResponseValue ,
  findIdPair ,
  findQuestionState ,
  mapIdPair ,
  mapDropId ,
  filterResponseIdentifier ,
}
/* No side effect */
