// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var responseIdentifier = S$RescriptSchema.recursive(function (responseIdentifierSchema) {
      return S$RescriptSchema.union([
                  S$RescriptSchema.object(function (s) {
                        s.tag("responseIdentifier", "Group");
                        return {
                                TAG: "Group",
                                _0: s.f("groupName", S$RescriptSchema.string),
                                _1: s.f("link", responseIdentifierSchema)
                              };
                      }),
                  S$RescriptSchema.object(function (s) {
                        s.tag("responseIdentifier", "IdPair");
                        return {
                                TAG: "IdPair",
                                _0: s.f("responseId", S$RescriptSchema.string),
                                _1: s.f("responseId2", S$RescriptSchema.string)
                              };
                      }),
                  S$RescriptSchema.object(function (s) {
                        s.tag("responseIdentifier", "SingleId");
                        return {
                                TAG: "SingleId",
                                _0: s.f("responseId", S$RescriptSchema.string)
                              };
                      })
                ]);
    });

var responsePayload = S$RescriptSchema.union([
      S$RescriptSchema.object(function (s) {
            s.tag("responsePayload", "BoolResponsePayload");
            return {
                    TAG: "BoolResponsePayload",
                    _0: s.f("responseIdentifier", responseIdentifier)
                  };
          }),
      S$RescriptSchema.object(function (s) {
            s.tag("responsePayload", "StrResponsePayload");
            return {
                    TAG: "StrResponsePayload",
                    _0: s.f("responseIdentifier", responseIdentifier),
                    _1: s.f("responseText", S$RescriptSchema.string)
                  };
          })
    ]);

var responseType = S$RescriptSchema.union([
      S$RescriptSchema.object(function (s) {
            s.tag("responseType", "SetResponse");
            return {
                    TAG: "SetResponse",
                    _0: s.f("responsePayload", responsePayload)
                  };
          }),
      S$RescriptSchema.object(function (s) {
            s.tag("responseType", "ToggleResponse");
            return {
                    TAG: "ToggleResponse",
                    _0: s.f("responsePayload", responsePayload)
                  };
          })
    ]);

var responseArray = S$RescriptSchema.array(responseType);

var questionIndex = S$RescriptSchema.object(function (s) {
      return s.f("questionIndex", S$RescriptSchema.$$int);
    });

var questionStateType = S$RescriptSchema.union([
      S$RescriptSchema.object(function (s) {
            s.tag("questionStateType", "QuestionAttempted");
            return {
                    TAG: "QuestionAttempted",
                    _0: s.f("responseArray", responseArray)
                  };
          }),
      S$RescriptSchema.object(function (s) {
            s.tag("questionStateType", "NotAttempted");
            return "NotAttempted";
          })
    ]);

var answerShape = S$RescriptSchema.object(function (s) {
      return {
              questionCorrect: s.f("questionCorrect", S$RescriptSchema.bool),
              answer: s.f("answer", S$RescriptSchema.list(S$RescriptSchema.string)),
              incorrectResponses: s.f("incorrectResponses", S$RescriptSchema.list(S$RescriptSchema.string))
            };
    });

var markStateType = S$RescriptSchema.union([
      S$RescriptSchema.object(function (s) {
            s.tag("markStateType", "BoolMarkState");
            return {
                    TAG: "BoolMarkState",
                    _0: s.f("answerShape", answerShape)
                  };
          }),
      S$RescriptSchema.object(function (s) {
            s.tag("markStateType", "NotMarked");
            return "NotMarked";
          })
    ]);

var state = S$RescriptSchema.object(function (s) {
      return {
              questionStates: /* [] */0,
              markStates: /* [] */0,
              mode: s.f("mode", S$RescriptSchema.$$enum([
                        "Activity",
                        "Assessment"
                      ])),
              questionsComplete: s.f("questionsComplete", S$RescriptSchema.$$int),
              questionsCorrect: s.fieldOr("questionsCorrect", S$RescriptSchema.$$int, 0),
              questionsIncorrect: s.fieldOr("questionsIncorrect", S$RescriptSchema.$$int, 0)
            };
    });

var questionStates = S$RescriptSchema.object(function (s) {
      return s.f("questionStates", S$RescriptSchema.list(questionStateType));
    });

var markStates = S$RescriptSchema.object(function (s) {
      return s.f("markStates", S$RescriptSchema.list(markStateType));
    });

var questionStatesQuestionIndexes = S$RescriptSchema.object(function (s) {
      return s.f("questionStates", S$RescriptSchema.list(questionIndex));
    });

var markStatesQuestionIndexes = S$RescriptSchema.object(function (s) {
      return s.f("markStates", S$RescriptSchema.list(questionIndex));
    });

function decodeQuestionStates(json) {
  var match = S$RescriptSchema.parseJsonStringOrThrow(json, questionStates);
  var match$1 = S$RescriptSchema.parseJsonStringOrThrow(json, questionStatesQuestionIndexes);
  return Belt_List.zip(match, match$1);
}

function decodeMarkStates(json) {
  var match = S$RescriptSchema.parseJsonStringOrThrow(json, markStates);
  var match$1 = S$RescriptSchema.parseJsonStringOrThrow(json, markStatesQuestionIndexes);
  return Belt_List.zip(match, match$1);
}

function parseAndDecode(json) {
  var match = S$RescriptSchema.parseJsonStringOrThrow(json, state);
  var match$1 = decodeMarkStates(json);
  var match$2 = decodeQuestionStates(json);
  return {
          questionStates: match$2,
          markStates: match$1,
          mode: match.mode,
          questionsComplete: match.questionsComplete,
          questionsCorrect: match.questionsCorrect,
          questionsIncorrect: match.questionsIncorrect
        };
}

function parseAndDecodeWithDefault(json) {
  try {
    return parseAndDecode(json);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === S$RescriptSchema.Raised) {
      return {
              questionStates: /* [] */0,
              markStates: /* [] */0,
              mode: "Activity",
              questionsComplete: 0,
              questionsCorrect: 0,
              questionsIncorrect: 0
            };
    }
    throw exn;
  }
}

function encodeQuestionStates(qs) {
  return Belt_List.toArray(Belt_List.map(qs, (function (q) {
                    var ra = q[0];
                    if (typeof ra !== "object") {
                      return {
                              questionIndex: q[1],
                              questionStateType: "NotAttempted"
                            };
                    } else {
                      return {
                              questionIndex: q[1],
                              questionStateType: "QuestionAttempted",
                              responseArray: S$RescriptSchema.reverseConvertToJsonOrThrow(ra._0, responseArray)
                            };
                    }
                  })));
}

function encodeMarkStates(qs) {
  return Belt_List.toArray(Belt_List.map(qs, (function (q) {
                    var ansShpe = q[0];
                    if (typeof ansShpe !== "object") {
                      return {
                              questionIndex: q[1],
                              markStateType: "NotMarked"
                            };
                    } else {
                      return {
                              questionIndex: q[1],
                              markStateType: "BoolMarkState",
                              answerShape: S$RescriptSchema.reverseConvertToJsonOrThrow(ansShpe._0, answerShape)
                            };
                    }
                  })));
}

function encodeState(state) {
  return {
          questionStates: encodeQuestionStates(state.questionStates),
          markStates: encodeMarkStates(state.markStates),
          mode: state.mode,
          questionsComplete: state.questionsComplete,
          questionsCorrect: state.questionsCorrect,
          questionsIncorrect: state.questionsIncorrect
        };
}

function encode(s) {
  return JSON.stringify(encodeState(s));
}

export {
  responseIdentifier ,
  responsePayload ,
  responseType ,
  responseArray ,
  questionIndex ,
  questionStateType ,
  answerShape ,
  markStateType ,
  state ,
  questionStates ,
  markStates ,
  questionStatesQuestionIndexes ,
  markStatesQuestionIndexes ,
  decodeQuestionStates ,
  decodeMarkStates ,
  parseAndDecode ,
  parseAndDecodeWithDefault ,
  encodeQuestionStates ,
  encodeMarkStates ,
  encodeState ,
  encode ,
}
/* responseIdentifier Not a pure module */
