// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Wrap$LiteracyplanetClientFlags from "./Wrap.res.mjs";

var span = {
  padding: "5px"
};

var noStyle = {};

var selected = {
  background: "white",
  color: "black"
};

function WrapSelectorComponent(props) {
  var api = Wrap$LiteracyplanetClientFlags.useApi();
  var experienceLink = function (ex) {
    return React.createElement("a", {
                style: ex === api.whatExperience ? selected : noStyle,
                onClick: (function (param) {
                    Curry._1(api.changeExperience, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, Wrap$LiteracyplanetClientFlags.experienceToString(ex)));
  };
  var navLink = function (ex) {
    return React.createElement("a", {
                style: ex === api.whatNavBar ? selected : noStyle,
                onClick: (function (param) {
                    Curry._1(api.changeNavBar, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, Wrap$LiteracyplanetClientFlags.navBarToString(ex)));
  };
  var menuLink = function (ex) {
    return React.createElement("a", {
                style: ex === api.whatMenu ? selected : noStyle,
                onClick: (function (param) {
                    Curry._1(api.changeMenu, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, Wrap$LiteracyplanetClientFlags.menuToString(ex)));
  };
  return React.createElement(React.Fragment, {}, React.createElement("div", undefined, experienceLink("Journey"), experienceLink("Storyverse"), experienceLink("Secondary")), React.createElement("div", undefined, menuLink("Menuless"), menuLink("JourneyInitials"), menuLink("Initials")), React.createElement("div", undefined, navLink("Barless"), navLink("Bubble"), navLink("Modern"), navLink("Papyrus")));
}

var make = WrapSelectorComponent;

export {
  span ,
  noStyle ,
  selected ,
  make ,
}
/* react Not a pure module */
