// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as OauthContainer$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/OauthContainer.res.mjs";

function Main$RedirectTo(props) {
  var routeReplace = props.routeReplace;
  var userType = props.userType;
  React.useEffect((function (param) {
          if (userType === "student") {
            Curry._1(routeReplace, "/sx");
          } else {
            Curry._1(routeReplace, "/tx");
          }
        }), []);
  return null;
}

var RedirectTo = {
  make: Main$RedirectTo
};

function Main(props) {
  var router = props.router;
  var routeReplace = function (path) {
    Curry._1(router.replace, path);
  };
  return React.createElement(OauthContainer$LiteracyplanetClientFlags.make, {
              children: (function (param) {
                  return React.createElement(Main$RedirectTo, {
                              userType: param.userType,
                              routeReplace: routeReplace
                            });
                })
            });
}

var make = Main;

var $$default = Main;

export {
  RedirectTo ,
  make ,
  $$default as default,
}
/* react Not a pure module */
