// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ToggleContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ToggleContext.res.mjs";

var include = ToggleContext$LiteracyplanetClientFlags.Make({});

var Ctx = include.Ctx;

var Provider = include.Provider;

var make = include.make;

var use = include.use;

var useShow = include.useShow;

var useState = include.useState;

export {
  Ctx ,
  Provider ,
  make ,
  use ,
  useShow ,
  useState ,
}
/* include Not a pure module */
