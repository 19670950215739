// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function BlockStyleClassName(props) {
  return React.createElement("div", {
              className: props.className
            }, props.children);
}

var make = BlockStyleClassName;

var $$default = BlockStyleClassName;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
