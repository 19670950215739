// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as StimulusLayout$LiteracyplanetTupperware from "./StimulusLayout.res.mjs";
import * as K2ExerciseInSeriesContainer$LiteracyplanetTupperware from "./K2ExerciseInSeriesContainer.res.mjs";

function PieceOfCakeLayoutContainer$Layout(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.Layout.className
            }, props.children);
}

var Layout = {
  make: PieceOfCakeLayoutContainer$Layout
};

function PieceOfCakeLayoutContainer(props) {
  return React.createElement(React.Fragment, {}, React.createElement("link", {
                  href: "https://use.typekit.net/jqw0veo.css",
                  rel: "stylesheet"
                }), React.createElement(K2ExerciseInSeriesContainer$LiteracyplanetTupperware.make, {
                  questionCount: props.questionCount,
                  children: React.createElement(StimulusLayout$LiteracyplanetTupperware.make, {
                        stimuliPresent: true,
                        stimulusId: props.stimulusId,
                        stimulusType: props.stimulusType,
                        children: React.createElement(PieceOfCakeLayoutContainer$Layout, {
                              children: props.children
                            })
                      })
                }));
}

var make = PieceOfCakeLayoutContainer;

var $$default = PieceOfCakeLayoutContainer;

export {
  Layout ,
  make ,
  $$default as default,
}
/* react Not a pure module */
