// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GraphqlTag from "graphql-tag";

var Raw = {};

var query = GraphqlTag.gql(["fragment ContentWordFragment on ContentWord   {\n__typename  \nid  \nname  \naudio  {\n__typename  \nsource  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.name;
  var value$2 = value.audio;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.source;
    tmp = {
      __typename: value$2.__typename,
      source: !(value$3 == null) ? value$3 : undefined
    };
  }
  return {
          __typename: value.__typename,
          id: value.id,
          name: !(value$1 == null) ? value$1 : undefined,
          audio: tmp
        };
}

function serialize(value) {
  var value$1 = value.audio;
  var audio;
  if (value$1 !== undefined) {
    var value$2 = value$1.source;
    var source = value$2 !== undefined ? value$2 : null;
    var value$3 = value$1.__typename;
    audio = {
      __typename: value$3,
      source: source
    };
  } else {
    audio = null;
  }
  var value$4 = value.name;
  var name = value$4 !== undefined ? value$4 : null;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          name: name,
          audio: audio
        };
}

function verifyArgsAndParse(_ContentWordFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ContentWordFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = GraphqlTag.gql([
      "fragment FindWordsContentFragment on Query   {\nwords: findWords(names: $names, locale: $locale, accent: $accent)  {\n...ContentWordFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$1(value) {
  var value$1 = value.words;
  return {
          words: value$1.map(parse)
        };
}

function serialize$1(value) {
  var value$1 = value.words;
  var words = value$1.map(serialize);
  return {
          words: words
        };
}

function verifyArgsAndParse$1(_accent, _locale, _names, _FindWordsContentFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var FindWordsContentFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

function makeOptionalWord(word) {
  var match = word.audio;
  var match$1 = word.name;
  if (match === undefined) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  var src = match.source;
  if (src !== undefined) {
    return {
            name: match$1,
            audioSrc: src
          };
  }
  
}

function identity(word) {
  return word;
}

function makeProps(words) {
  return Belt_Array.keepMap(Belt_Array.map(words.words, makeOptionalWord), identity);
}

function hasWord(x) {
  if (typeof x !== "object") {
    return ;
  }
  var words = x._0;
  if (words.length > 1) {
    return ;
  } else {
    return Belt_Array.get(words, 0);
  }
}

var map = Belt_Array.map;

var keepMap = Belt_Array.keepMap;

export {
  ContentWordFragment ,
  FindWordsContentFragment ,
  map ,
  keepMap ,
  makeOptionalWord ,
  identity ,
  makeProps ,
  hasWord ,
}
/* query Not a pure module */
