// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function NextArrow(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 525.3 460.6",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M515.5,222.4L302,9.6c-3.1-3-7.6-3.9-11.6-2.3c-4,1.7-6.6,5.5-6.6,9.9v157.3H17.2c-5.9,0-10.7,4.8-10.7,10.7\n        v90.7c0,5.9,4.8,10.7,10.7,10.7h266.7v156.9c0,4.3,2.6,8.2,6.6,9.9c1.3,0.6,2.7,0.8,4.1,0.8c2.8,0,5.5-1.1,7.6-3.1l213.5-213.4\n        c2-2,3.1-4.7,3.1-7.6C518.7,227.1,517.5,224.4,515.5,222.4z"
                }));
}

var make = NextArrow;

export {
  make ,
}
/* react Not a pure module */
