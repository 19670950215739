// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function initialState_getMarkState(param) {
  return "NotMarked";
}

function initialState_getQuestionState(param) {
  return "NotAttempted";
}

function initialState_getStep(param) {
  return "WaitingForUser";
}

function initialState_isQuestionVisible(param) {
  return false;
}

function initialState_isResponseSelected(param, param$1) {
  return false;
}

function initialState_setMarkCb(param, param$1, param$2, param$3) {
  
}

function initialState_onMarkActivityQuestion(param) {
  
}

function initialState_onMarkActivity(param) {
  
}

function initialState_onPreviousQuestion(param) {
  
}

function initialState_onNextQuestion(param) {
  
}

function initialState_onSetActivityEnd(param) {
  
}

function initialState_onExit(param) {
  
}

function initialState_onSetQuestionTab(param) {
  
}

function initialState_onGotoEndScreen(param) {
  
}

function initialState_onChangeQuestion(param) {
  
}

function initialState_getCorrectAnswer(param) {
  
}

function initialState_onToggleShowCorrectAnswer(param) {
  
}

function initialState_isCorrectAnswerVisible(param) {
  
}

function initialState_setShowCorrectAnswerForQuestion(param, param$1) {
  
}

var initialState = {
  getMarkState: initialState_getMarkState,
  getQuestionState: initialState_getQuestionState,
  getStep: initialState_getStep,
  currentQuestionStep: "WaitingForUser",
  isQuestionVisible: initialState_isQuestionVisible,
  isResponseSelected: initialState_isResponseSelected,
  setMarkCb: initialState_setMarkCb,
  onMarkActivityQuestion: initialState_onMarkActivityQuestion,
  onMarkActivity: initialState_onMarkActivity,
  onPreviousQuestion: initialState_onPreviousQuestion,
  onNextQuestion: initialState_onNextQuestion,
  onSetActivityEnd: initialState_onSetActivityEnd,
  onExit: initialState_onExit,
  activityEnded: false,
  finishedLastQuestion: false,
  currentQuestionIndex: 0,
  onSetQuestionTab: initialState_onSetQuestionTab,
  questionTab: "QuestionTab",
  questionCount: 0,
  questionsCorrect: 0,
  questionsIncorrect: 0,
  isEndscreenShowing: false,
  onGotoEndScreen: initialState_onGotoEndScreen,
  onChangeQuestion: initialState_onChangeQuestion,
  getCorrectAnswer: initialState_getCorrectAnswer,
  onToggleShowCorrectAnswer: initialState_onToggleShowCorrectAnswer,
  isCorrectAnswerVisible: initialState_isCorrectAnswerVisible,
  setShowCorrectAnswerForQuestion: initialState_setShowCorrectAnswerForQuestion
};

var context = React.createContext(initialState);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

export {
  initialState ,
  context ,
  makeProps ,
  make ,
  use ,
}
/* context Not a pure module */
