// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as FlipperStoreTypes$LiteracyplanetClientSxActivity from "./FlipperStoreTypes.res.mjs";

function useResultsDispatch(param) {
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var dispatch = match[1];
  var state = match[0];
  return {
          onResultReady: (function (param) {
              Curry._1(dispatch, "ResultsReady");
            }),
          onExit: (function (param) {
              Curry._1(dispatch, state.needsToShowMissionComplete ? "ExitToMissions" : "Exit");
            }),
          onFailed: (function (param) {
              
            }),
          onRetryActivity: (function (param) {
              location.reload();
              Curry._1(dispatch, "Exit");
            }),
          onGoToCollections: (function (param) {
              Curry._1(dispatch, "ExitToCollections");
            })
        };
}

function defaultCb(param) {
  
}

var defaultResultDispatchables = {
  onResultReady: defaultCb,
  onExit: defaultCb,
  onFailed: defaultCb,
  onRetryActivity: defaultCb,
  onGoToCollections: defaultCb
};

var context = React.createContext(defaultResultDispatchables);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Context = {
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function FlipperCobraResultsCallbacks$Provider(props) {
  return React.createElement(make, {
              value: props.dispachables,
              children: props.children
            });
}

var Provider = {
  make: FlipperCobraResultsCallbacks$Provider
};

export {
  useResultsDispatch ,
  defaultCb ,
  defaultResultDispatchables ,
  Context ,
  Provider ,
}
/* context Not a pure module */
