// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GraphqlTag from "graphql-tag";
import * as SpaceX$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/SpaceX.res.mjs";
import * as HasuraTypes$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/HasuraTypes.res.mjs";
import * as MutationHook2$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/MutationHook2.res.mjs";

var Raw = {};

var query = GraphqlTag.gql(["mutation InsertStoryverseLevel($objects: [storyverse_user_level_insert_input!]!)  {\ninsert_storyverse_user_level(objects: $objects)  {\n__typename  \naffected_rows  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.insert_storyverse_user_level;
  return {
          insert_storyverse_user_level: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                affected_rows: value$1.affected_rows
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.insert_storyverse_user_level;
  var insert_storyverse_user_level;
  if (value$1 !== undefined) {
    var value$2 = value$1.affected_rows;
    var value$3 = value$1.__typename;
    insert_storyverse_user_level = {
      __typename: value$3,
      affected_rows: value$2
    };
  } else {
    insert_storyverse_user_level = null;
  }
  return {
          insert_storyverse_user_level: insert_storyverse_user_level
        };
}

function serializeInputObjectstoryverse_user_level_insert_input(inp) {
  var a = inp.created_at;
  var a$1 = inp.id;
  var a$2 = inp.storyverseLevelId;
  var a$3 = inp.updated_at;
  var a$4 = inp.userId;
  return {
          created_at: a !== undefined ? a : undefined,
          id: a$1 !== undefined ? a$1 : undefined,
          storyverseLevelId: a$2 !== undefined ? a$2 : undefined,
          updated_at: a$3 !== undefined ? a$3 : undefined,
          userId: a$4 !== undefined ? a$4 : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.objects;
  return {
          objects: a.map(serializeInputObjectstoryverse_user_level_insert_input)
        };
}

function makeVariables(objects, param) {
  return {
          objects: objects
        };
}

function makeInputObjectstoryverse_user_level_insert_input(created_at, id, storyverseLevelId, updated_at, userId, param) {
  return {
          created_at: created_at,
          id: id,
          storyverseLevelId: storyverseLevelId,
          updated_at: updated_at,
          userId: userId
        };
}

var StoryverseLevelupMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectstoryverse_user_level_insert_input: serializeInputObjectstoryverse_user_level_insert_input,
  makeVariables: makeVariables,
  makeInputObjectstoryverse_user_level_insert_input: makeInputObjectstoryverse_user_level_insert_input
};

var include = SpaceX$LiteracyplanetClientFlags.Mutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var StoryverseLevelupMutation = {
  StoryverseLevelupMutation_inner: StoryverseLevelupMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectstoryverse_user_level_insert_input: serializeInputObjectstoryverse_user_level_insert_input,
  makeVariables: makeVariables,
  makeInputObjectstoryverse_user_level_insert_input: makeInputObjectstoryverse_user_level_insert_input,
  use: use
};

var M = MutationHook2$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function use$1(param) {
  var match = Curry._1(M.useWithWordMonsterError, "StoryverseLevelupMutation");
  var mutate = match[0];
  return function (ids) {
    Curry._1(mutate, {
          objects: Belt_Array.map(ids, (function (id) {
                  return {
                          created_at: undefined,
                          id: undefined,
                          storyverseLevelId: HasuraTypes$LiteracyplanetClientFlags.encode(id),
                          updated_at: undefined,
                          userId: undefined
                        };
                }))
        });
  };
}

var encode = HasuraTypes$LiteracyplanetClientFlags.encode;

export {
  StoryverseLevelupMutation ,
  M ,
  encode ,
  use$1 as use,
}
/* query Not a pure module */
