// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.res.mjs";
import * as DropDownTypes$LiteracyplanetTupperware from "../DropDownTypes.res.mjs";
import * as SkilfulDropDown$LiteracyplanetTupperware from "./SkilfulDropDown.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function convertOpts(opts) {
  return Belt_List.map(Belt_List.fromArray(opts), (function (obj) {
                return {
                        responseOptionId: obj.responseOptionId,
                        value: obj.value
                      };
              }));
}

function makeRespond(questionIndex, group, step, dispatch, value) {
  if (typeof step === "object" && step.TAG !== "Attempted") {
    return ;
  }
  Curry._1(dispatch, {
        TAG: "SetQuestionState",
        _0: questionIndex,
        _1: Reducer$LiteracyplanetTupperware.buildResponseType(group, value)
      });
}

function SkilfulDropDownOptionContainer(props) {
  var group = props.group;
  var questionIndex = props.questionIndex;
  var opts = convertOpts(props.opts);
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var match$1 = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch();
  var dispatch = match$1[1];
  var step = Curry._1(match.getStep, questionIndex);
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, questionIndex);
  var correctAnswer = Curry._1(match.getCorrectAnswer, questionIndex);
  var onChange = function (param) {
    return makeRespond(questionIndex, group, step, dispatch, param);
  };
  var optionalResponseId = Caml_obj.equal(answerVisible, true) ? DropDownTypes$LiteracyplanetTupperware.find(opts, correctAnswer) : DropDownTypes$LiteracyplanetTupperware.getSelectedDropDownValue(step, opts);
  var selectedValue = Belt_Option.map(Belt_List.getBy(opts, (function (param) {
              return param.responseOptionId === Belt_Option.getWithDefault(optionalResponseId, "");
            })), (function (param) {
          return param.value;
        }));
  var marked = DropDownTypes$LiteracyplanetTupperware.getMarkTypeForDropDown(optionalResponseId, step, opts);
  return React.createElement(SkilfulDropDown$LiteracyplanetTupperware.make, {
              selectedValue: selectedValue,
              marked: marked,
              answerVisible: answerVisible,
              responseOptions: opts,
              onChange: onChange
            });
}

var make = SkilfulDropDownOptionContainer;

var $$default = SkilfulDropDownOptionContainer;

export {
  convertOpts ,
  makeRespond ,
  make ,
  $$default as default,
}
/* react Not a pure module */
