// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ContextMaker$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContextMaker.res.mjs";

var include = ContextMaker$LiteracyplanetClientFlags.Make({
      name: "FlipperStoreTypes"
    });

var initialContext = include.initialContext;

var context = include.context;

var P = include.P;

var Provider = include.Provider;

var ContextMakerProviderNotFound = include.ContextMakerProviderNotFound;

var use = include.use;

export {
  initialContext ,
  context ,
  P ,
  Provider ,
  ContextMakerProviderNotFound ,
  use ,
}
/* include Not a pure module */
