// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function MakePair(C) {
  var state = {
    contents: C.defaultValue
  };
  var subscriptions = {
    contents: []
  };
  var addSubscription = function (f) {
    subscriptions.contents = Js_array.concat([f], subscriptions.contents);
    return function (param) {
      subscriptions.contents = Js_array.filter((function (sub) {
              return sub !== f;
            }), subscriptions.contents);
    };
  };
  var updateState = function (newStateOpt) {
    var newState = newStateOpt !== undefined ? Caml_option.valFromOption(newStateOpt) : C.defaultValue;
    state.contents = newState;
    Js_array.forEach((function (f) {
            Curry._1(f, newState);
          }), subscriptions.contents);
  };
  var Context$MakePair$Provider = function (props) {
    var value = props.value;
    React.useEffect((function (param) {
            updateState(value);
          }), [value]);
    return props.children;
  };
  var Provider = {
    make: Context$MakePair$Provider
  };
  var Context$MakePair$Consumer = function (props) {
    var match = React.useReducer((function (param, action) {
            return action._0;
          }), state.contents);
    var send = match[1];
    React.useEffect((function (param) {
            return addSubscription(function (newState) {
                        Curry._1(send, {
                              TAG: "ChangeState",
                              _0: newState
                            });
                      });
          }), []);
    return Curry._1(props.children, match[0]);
  };
  var Consumer = {
    make: Context$MakePair$Consumer
  };
  return {
          state: state,
          subscriptions: subscriptions,
          addSubscription: addSubscription,
          updateState: updateState,
          Provider: Provider,
          Consumer: Consumer
        };
}

export {
  MakePair ,
}
/* react Not a pure module */
