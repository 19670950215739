// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphqlClientCtx$LiteracyplanetClientFlags from "./GraphqlClientCtx.res.mjs";

function idleState(param) {
  return {
          loading: true,
          data: undefined,
          error: undefined
        };
}

var print = (function (q) {
    return q.definitions[0].name.value
  });

function Extend(Q) {
  var use = function (skip, variables) {
    var match = Curry._1(GraphqlClientCtx$LiteracyplanetClientFlags.use, undefined);
    var queryGraphql = match.queryGraphql;
    var match$1 = React.useState(function (param) {
          return {
                  loading: true,
                  data: undefined,
                  error: undefined
                };
        });
    var setResult = match$1[1];
    var result = match$1[0];
    var v = JSON.stringify(variables);
    var $$fetch = React.useCallback((function (param) {
            return new Promise((function (resolve, reject) {
                          if (result.loading) {
                            
                          } else {
                            Curry._1(setResult, (function (r) {
                                    return {
                                            loading: true,
                                            data: r.data,
                                            error: r.error
                                          };
                                  }));
                          }
                          Curry._3(queryGraphql, Q.query, Curry._1(Q.serializeVariables, variables), (function (param) {
                                  var error = param.error;
                                  var data = param.data;
                                  Curry._1(setResult, (function (param) {
                                          return {
                                                  loading: false,
                                                  data: Belt_Option.map(data, (function (d) {
                                                          return Curry._1(Q.parse, d);
                                                        })),
                                                  error: error
                                                };
                                        }));
                                  resolve();
                                }));
                        }));
          }), [
          skip,
          v
        ]);
    React.useEffect((function (param) {
            if (Belt_Option.getWithDefault(skip, false)) {
              Curry._1(setResult, (function (param) {
                      return {
                              loading: true,
                              data: undefined,
                              error: undefined
                            };
                    }));
            } else {
              Curry._1($$fetch, undefined);
            }
          }), [
          skip,
          v
        ]);
    return [
            result,
            $$fetch
          ];
  };
  return {
          use: use
        };
}

var Query = {
  idleState: idleState,
  print: print,
  Extend: Extend
};

function Extend$1(M) {
  var use = function (param) {
    var match = Curry._1(GraphqlClientCtx$LiteracyplanetClientFlags.use, undefined);
    var queryGraphql = match.queryGraphql;
    var match$1 = React.useState(function (param) {
          return {
                  called: false,
                  loading: false,
                  data: undefined,
                  error: undefined
                };
        });
    var setResult = match$1[1];
    var mutation = function (variables) {
      return new Promise((function (resolve, reject) {
                    Curry._1(setResult, (function (r) {
                            return {
                                    called: true,
                                    loading: true,
                                    data: r.data,
                                    error: r.error
                                  };
                          }));
                    Curry._3(queryGraphql, M.query, Curry._1(M.serializeVariables, variables), (function (param) {
                            var error = param.error;
                            var data = param.data;
                            Curry._1(setResult, (function (param) {
                                    return {
                                            called: true,
                                            loading: false,
                                            data: Belt_Option.map(data, (function (d) {
                                                    return Curry._1(M.parse, d);
                                                  })),
                                            error: error
                                          };
                                  }));
                            resolve();
                          }));
                  }));
    };
    return [
            mutation,
            match$1[0]
          ];
  };
  return {
          use: use
        };
}

var Mutation = {
  Extend: Extend$1
};

function Extend$2(S) {
  var use = function (_variables) {
    return {
            loading: true,
            data: undefined,
            error: undefined
          };
  };
  return {
          use: use
        };
}

var Subscription = {
  Extend: Extend$2
};

export {
  Query ,
  Mutation ,
  Subscription ,
}
/* react Not a pure module */
