// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.res.mjs";
import * as SecondaryHeaderIcons$LiteracyplanetClientSxEntry from "./SecondaryHeaderIcons.res.mjs";
import * as SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry from "./SecondaryHeaderStylesRatio.res.mjs";

function InitialsMenuRatio$CloseIcon(props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.CloseIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.SmallIcon.className
            });
}

var CloseIcon = {
  make: InitialsMenuRatio$CloseIcon
};

function InitialsMenuRatio$CloseBtnComponent(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.CloseBtn.className,
              onClick: props.onClick
            }, React.createElement(InitialsMenuRatio$CloseIcon, {}));
}

var CloseBtnComponent = {
  make: InitialsMenuRatio$CloseBtnComponent
};

function InitialsMenuRatio$IconAndText(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.IconAndText.className
            }, props.children);
}

var IconAndText = {
  make: InitialsMenuRatio$IconAndText
};

function InitialsMenuRatio$MouseLinkButton(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuLink.className(props.bgColor, props.hoverColor, props.activeColor),
              onClick: props.onClick
            }, React.createElement(InitialsMenuRatio$IconAndText, {
                  children: props.children
                }));
}

var MouseLinkButton = {
  make: InitialsMenuRatio$MouseLinkButton
};

function InitialsMenuRatio$MouseLinkButtonHref(props) {
  return React.createElement("a", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuLink.className(props.bgColor, props.hoverColor, props.activeColor),
              href: props.href
            }, React.createElement(InitialsMenuRatio$IconAndText, {
                  children: props.children
                }));
}

var MouseLinkButtonHref = {
  make: InitialsMenuRatio$MouseLinkButtonHref
};

function InitialsMenuRatio$WordManiaLinkIcon(props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.WordManiaIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.LinkIcon.className
            });
}

var WordManiaLinkIcon = {
  make: InitialsMenuRatio$WordManiaLinkIcon
};

function InitialsMenuRatio$LogOutLinkIcon(props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.LogoutIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.LinkIcon.className
            });
}

var LogOutLinkIcon = {
  make: InitialsMenuRatio$LogOutLinkIcon
};

function InitialsMenuRatio$WordManiaLinkButton(props) {
  return React.createElement(InitialsMenuRatio$MouseLinkButton, {
              children: null,
              onClick: props.onClick,
              bgColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.wordManiaColor,
              hoverColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.wordManiaHoverColor,
              activeColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.wordManiaActiveColor
            }, React.createElement(InitialsMenuRatio$WordManiaLinkIcon, {}), SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s("Word Mania"));
}

var WordManiaLinkButton = {
  make: InitialsMenuRatio$WordManiaLinkButton
};

function InitialsMenuRatio$LogOutLinkButton(props) {
  return React.createElement(InitialsMenuRatio$MouseLinkButton, {
              children: null,
              onClick: props.onClick,
              bgColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.logOutColor,
              hoverColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.logOutHoverColor,
              activeColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.logOutActiveColor
            }, React.createElement(InitialsMenuRatio$LogOutLinkIcon, {}), SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s("Exit"));
}

var LogOutLinkButton = {
  make: InitialsMenuRatio$LogOutLinkButton
};

function InitialsMenuRatio$MenuIcon(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuIcon.className,
              onClick: props.onClick
            }, props.children);
}

var MenuIcon = {
  make: InitialsMenuRatio$MenuIcon
};

function InitialsMenuRatio$NavigationContainer(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NavigationContainer.className
            }, props.children);
}

var NavigationContainer = {
  make: InitialsMenuRatio$NavigationContainer
};

function InitialsMenuRatio$NavigationName(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NavigationName.className
            }, props.children);
}

var NavigationName = {
  make: InitialsMenuRatio$NavigationName
};

function InitialsMenuRatio$NavigationLinksContainer(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NavigationLinksContainer.className
            }, props.children);
}

var NavigationLinksContainer = {
  make: InitialsMenuRatio$NavigationLinksContainer
};

function InitialsMenuRatio$NotificationIcon(props) {
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NotificationIcon.className
            }, props.children);
}

var NotificationIcon = {
  make: InitialsMenuRatio$NotificationIcon
};

function InitialsMenuRatio$MenuOpenIcon(props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.MenuOpenIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuOpenIcon.className
            });
}

var MenuOpenIcon = {
  make: InitialsMenuRatio$MenuOpenIcon
};

function InitialsMenuRatio$LoginLinkIcon(props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.LoginIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.LinkIcon.className
            });
}

var LoginLinkIcon = {
  make: InitialsMenuRatio$LoginLinkIcon
};

function InitialsMenuRatio$TeacherModeButton(props) {
  return React.createElement(InitialsMenuRatio$MouseLinkButtonHref, {
              children: null,
              href: props.href,
              bgColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.missionsColor,
              hoverColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.missionsHoverColor,
              activeColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.missionsActiveColor
            }, React.createElement(InitialsMenuRatio$LoginLinkIcon, {}), SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s("Teacher Mode"));
}

var TeacherModeButton = {
  make: InitialsMenuRatio$TeacherModeButton
};

function InitialsMenuRatio(props) {
  var onClickLogout = props.onClickLogout;
  var onClickWordMania = props.onClickWordMania;
  var collectionNotificationCount = props.collectionNotificationCount;
  var match = React.useState(function (param) {
        return false;
      });
  var setIsOpen = match[1];
  return React.createElement(React.Fragment, {
              children: Caml_option.some(match[0] ? React.createElement(InitialsMenuRatio$NavigationContainer, {
                          children: null
                        }, React.createElement(InitialsMenuRatio$NavigationName, {
                              children: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s(props.userName)
                            }), React.createElement(InitialsMenuRatio$NavigationLinksContainer, {
                              children: null
                            }, props.showWordManiaLink ? React.createElement(InitialsMenuRatio$WordManiaLinkButton, {
                                    onClick: (function (param) {
                                        Curry._1(onClickWordMania, undefined);
                                      })
                                  }) : null, props.showTeacherModeWidget ? React.createElement(InitialsMenuRatio$TeacherModeButton, {
                                    href: props.teacherModeLink
                                  }) : null, React.createElement(InitialsMenuRatio$LogOutLinkButton, {
                                  onClick: (function (param) {
                                      Curry._1(onClickLogout, undefined);
                                    })
                                })), React.createElement(InitialsMenuRatio$CloseBtnComponent, {
                              onClick: (function (param) {
                                  Curry._1(setIsOpen, (function (previousValue) {
                                          return !previousValue;
                                        }));
                                })
                            })) : React.createElement(React.Fragment, {}, React.createElement(InitialsMenuRatio$MenuIcon, {
                              onClick: (function (param) {
                                  Curry._1(setIsOpen, (function (previousValue) {
                                          return !previousValue;
                                        }));
                                }),
                              children: null
                            }, SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s(props.userInitials), React.createElement(InitialsMenuRatio$MenuOpenIcon, {})), collectionNotificationCount > 0 ? React.createElement(InitialsMenuRatio$NotificationIcon, {
                                children: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s(String(collectionNotificationCount))
                              }) : null))
            });
}

var make = InitialsMenuRatio;

export {
  CloseIcon ,
  CloseBtnComponent ,
  IconAndText ,
  MouseLinkButton ,
  MouseLinkButtonHref ,
  WordManiaLinkIcon ,
  LogOutLinkIcon ,
  WordManiaLinkButton ,
  LogOutLinkButton ,
  MenuIcon ,
  NavigationContainer ,
  NavigationName ,
  NavigationLinksContainer ,
  NotificationIcon ,
  MenuOpenIcon ,
  LoginLinkIcon ,
  TeacherModeButton ,
  make ,
}
/* react Not a pure module */
