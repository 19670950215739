// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TabContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/TabContext.res.mjs";

var include = TabContext$LiteracyplanetClientFlags.Make({});

var Ctx = include.Ctx;

var Provider = include.Provider;

var make = include.make;

var use = include.use;

var useTabContent = include.useTabContent;

var useTabLink = include.useTabLink;

var useIsTabSelected = include.useIsTabSelected;

export {
  Ctx ,
  Provider ,
  make ,
  use ,
  useTabContent ,
  useTabLink ,
  useIsTabSelected ,
}
/* include Not a pure module */
