// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Routes$LiteracyplanetClientSxEntry from "./Routes.res.mjs";
import * as Locking$LiteracyplanetClientSxEntry from "./Locking.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";
import * as LockingQuery$LiteracyplanetClientSxEntry from "./LockingQuery.res.mjs";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceWrap.res.mjs";

function LockingProvider$Lock(props) {
  var r = props.r;
  var locked = props.locked;
  var isPageBlacklisted = function (param) {
    return Locking$LiteracyplanetClientSxEntry.isPageBlacklisted(locked, param);
  };
  var isItemBlacklisted = function (param) {
    return Locking$LiteracyplanetClientSxEntry.isItemBlacklisted(locked, param);
  };
  var redirectToMissions = StudentExperienceWrap$LiteracyplanetClientSxEntry.useStudentRedirectToMissions(r, isPageBlacklisted);
  var match = Locking$LiteracyplanetClientSxEntry.use(locked, props.page, redirectToMissions, r);
  return Curry._5(props.children, match[0], match[1], match[2], isPageBlacklisted, isItemBlacklisted);
}

var Lock = {
  make: LockingProvider$Lock
};

function LockingProvider(props) {
  var page = props.page;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var hasLockedFeature = Curry._1(hasFlag, "StudentHasLocking");
  var pagePath = Routes$LiteracyplanetClientSxEntry.pageToUrl(page);
  var state = LockingQuery$LiteracyplanetClientSxEntry.use(pagePath);
  var match = React.useState(function (param) {
        return false;
      });
  var setLocked = match[1];
  React.useEffect((function (param) {
          if (hasLockedFeature && typeof state === "object") {
            var locked = state._0;
            Curry._1(setLocked, (function (param) {
                    return locked;
                  }));
          }
          
        }), [
        hasLockedFeature,
        state
      ]);
  return React.createElement(LockingProvider$Lock, {
              locked: match[0],
              r: props.redirect,
              page: page,
              children: props.children
            });
}

var make = LockingProvider;

export {
  Lock ,
  make ,
}
/* react Not a pure module */
