// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function mapWithNull(opt, fn) {
  return Belt_Option.mapWithDefault(opt, null, fn);
}

export {
  mapWithNull ,
}
/* No side effect */
