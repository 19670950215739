// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Make(Config) {
  var useLocalStorageWithNamespace = function (namespace) {
    var key = namespace === "" ? Config.key : namespace + ":" + Config.key;
    var match = React.useState(function (param) {
          return Caml_option.null_to_opt(localStorage.getItem(key));
        });
    var setState = match[1];
    var setValue = function (value) {
      localStorage.setItem(key, Curry._1(Config.toString, value));
      Curry._1(setState, (function (param) {
              return Caml_option.null_to_opt(localStorage.getItem(key));
            }));
    };
    return [
            Curry._1(Config.fromString, match[0]),
            setValue
          ];
  };
  var useLocalStorage = function (param) {
    return useLocalStorageWithNamespace("");
  };
  return {
          useLocalStorageWithNamespace: useLocalStorageWithNamespace,
          useLocalStorage: useLocalStorage
        };
}

export {
  Make ,
}
/* react Not a pure module */
