// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as OpenBookIcon$LiteracyplanetTupperware from "./OpenBookIcon.res.mjs";

function OpenBookButton(props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.OpenBookButton.className,
              onClick: props.onClick
            }, React.createElement(OpenBookIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.OpenBookButton.iconStyle
                }));
}

var make = OpenBookButton;

export {
  make ,
}
/* react Not a pure module */
