// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FireLater$LiteracyplanetClientSxActivity from "./FireLater.res.mjs";

var stateMachine = {
  contents: "Initial"
};

function isAnythingInTransit(param) {
  var match = stateMachine.contents;
  if (typeof match === "object") {
    if (match.TAG === "QuestionPersisting") {
      return true;
    } else {
      return false;
    }
  }
  switch (match) {
    case "ProvisioningNewAttempt" :
    case "MarkingComplete" :
        return true;
    default:
      return false;
  }
}

function useTryBack(onBack) {
  var match = React.useState(function (param) {
        return 0;
      });
  var setTryBack = match[1];
  var tryBack = match[0];
  var onTryBack = function (param) {
    Curry._1(setTryBack, (function (param) {
            return 1;
          }));
  };
  React.useEffect((function (param) {
          if (tryBack <= 0) {
            return ;
          }
          if (isAnythingInTransit()) {
            var timeoutId = setTimeout((function (param) {
                    console.log("still waiting for questions");
                    Curry._1(setTryBack, (function (i) {
                            return i + 1 | 0;
                          }));
                  }), 100);
            return (function (param) {
                      clearTimeout(timeoutId);
                    });
          }
          Curry._1(onBack, undefined);
        }), [tryBack]);
  return onTryBack;
}

var Later = FireLater$LiteracyplanetClientSxActivity.Make({});

function useMutationApi(contentMd5, insertAttempt, insertedAttemptId, existingAttemptId, updateActivityCompleted, insertResponse, insertedResponseId, isMarkCompleteDone) {
  var match = React.useState(function (param) {
        return /* [] */0;
      });
  var setEvents = match[1];
  var events = match[0];
  var fire = Curry._1(Later.use, undefined);
  var pushAsyncActivityEvent = function ($$event) {
    Curry._1(fire, {
          TAG: "FireSoonish",
          _0: (function (param) {
              Curry._1(setEvents, (function (e) {
                      return Belt_List.concat(e, {
                                  hd: $$event,
                                  tl: /* [] */0
                                });
                    }));
            })
        });
  };
  React.useEffect((function (param) {
          if (existingAttemptId !== undefined) {
            stateMachine.contents = {
              TAG: "AttemptFound",
              _0: existingAttemptId
            };
          } else {
            stateMachine.contents = "ProvisioningNewAttempt";
            Curry._1(insertAttempt, undefined);
          }
        }), [existingAttemptId]);
  React.useEffect((function (param) {
          if (insertedAttemptId !== undefined) {
            stateMachine.contents = {
              TAG: "AttemptFound",
              _0: insertedAttemptId
            };
          }
          
        }), [insertedAttemptId]);
  React.useEffect((function (param) {
          var processNext = function (studentActivityAttemptId, prevResponseId) {
            if (!events) {
              stateMachine.contents = {
                TAG: "QuestionWaiting",
                _0: studentActivityAttemptId,
                _1: prevResponseId
              };
              return ;
            }
            var match = events.hd;
            if (typeof match !== "object") {
              var tail = events.tl;
              stateMachine.contents = "MarkingComplete";
              Curry._1(updateActivityCompleted, studentActivityAttemptId);
              return Curry._1(setEvents, (function (param) {
                            return tail;
                          }));
            }
            var tail$1 = events.tl;
            var match$1 = match._0;
            stateMachine.contents = {
              TAG: "QuestionPersisting",
              _0: studentActivityAttemptId,
              _1: prevResponseId
            };
            Curry._6(insertResponse, studentActivityAttemptId, match$1[2], match$1[3] ? 1 : 0, match$1[0], match$1[1], contentMd5);
            Curry._1(setEvents, (function (param) {
                    return tail$1;
                  }));
          };
          var studentActivityAttemptId = stateMachine.contents;
          if (typeof studentActivityAttemptId !== "object") {
            if (studentActivityAttemptId === "MarkingComplete") {
              if (isMarkCompleteDone) {
                stateMachine.contents = "Finished";
              }
              
            }
            
          } else {
            switch (studentActivityAttemptId.TAG) {
              case "AttemptFound" :
                  processNext(studentActivityAttemptId._0, "initial");
                  break;
              case "QuestionPersisting" :
                  if (insertedResponseId !== undefined && insertedResponseId !== studentActivityAttemptId._1) {
                    processNext(studentActivityAttemptId._0, insertedResponseId);
                  }
                  break;
              case "QuestionWaiting" :
                  processNext(studentActivityAttemptId._0, studentActivityAttemptId._1);
                  break;
              
            }
          }
        }), [
        events,
        insertedAttemptId,
        insertedResponseId,
        isMarkCompleteDone
      ]);
  React.useEffect((function (param) {
          return (function (param) {
                    stateMachine.contents = "Initial";
                  });
        }), []);
  var onQuestionFailed = function (question, state, answer) {
    pushAsyncActivityEvent({
          TAG: "QuestionAnswered",
          _0: [
            question,
            state,
            answer,
            false
          ]
        });
  };
  var onQuestionPassed = function (question, state, answer) {
    pushAsyncActivityEvent({
          TAG: "QuestionAnswered",
          _0: [
            question,
            state,
            answer,
            true
          ]
        });
  };
  var onActivityCompleted = function (param) {
    pushAsyncActivityEvent("ActivityCompleted");
  };
  return [
          onQuestionPassed,
          onQuestionFailed,
          onActivityCompleted
        ];
}

export {
  stateMachine ,
  isAnythingInTransit ,
  useTryBack ,
  Later ,
  useMutationApi ,
}
/* Later Not a pure module */
