// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ContextMaker$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/ContextMaker.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/FlagsContext.res.mjs";
import * as GameTypeData$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/GameTypeData.res.mjs";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceWrap.res.mjs";

function blacklistItem(item) {
  if (typeof item !== "object") {
    return true;
  } else if (item.TAG === "MyWordsItem") {
    return !Belt_SetString.has(GameTypeData$LiteracyplanetClientFlags.starterAccessMyWordsActivities, item._0);
  } else {
    return !Belt_SetString.has(GameTypeData$LiteracyplanetClientFlags.starterAccessArcadeGames, item._0);
  }
}

function blacklist(page) {
  if (typeof page !== "object") {
    if (page === "WordManiaGame") {
      return true;
    } else {
      return false;
    }
  }
  switch (page.TAG) {
    case "ArcadeGame" :
        return blacklistItem({
                    TAG: "ArcadeItem",
                    _0: page._0
                  });
    case "MyWordsActivity" :
        return blacklistItem({
                    TAG: "MyWordsItem",
                    _0: page._1
                  });
    default:
      return false;
  }
}

function isProRequiredForPage(limited, page) {
  if (limited) {
    return blacklist(page);
  } else {
    return false;
  }
}

function isProRequiredForItem(limited, item) {
  if (limited) {
    return blacklistItem(item);
  } else {
    return false;
  }
}

function useForMain(limited, page, redirect, modalMsg) {
  var isProRequired = function (param) {
    return isProRequiredForPage(limited, param);
  };
  var match = React.useState(function (param) {
        
      });
  var setProRequiredModal = match[1];
  var showProRequiredModal = match[0];
  var onOpenModal = function (param) {
    Curry._1(setProRequiredModal, (function (param) {
            return Caml_option.some(modalMsg);
          }));
  };
  var onToggleModal = function (param) {
    if (showProRequiredModal !== undefined) {
      return Curry._1(setProRequiredModal, (function (param) {
                    
                  }));
    } else {
      return Curry._1(setProRequiredModal, (function (param) {
                    return Caml_option.some(modalMsg);
                  }));
    }
  };
  var redirectToMissions = StudentExperienceWrap$LiteracyplanetClientSxEntry.useStudentRedirectToMissions(redirect, isProRequired);
  React.useEffect((function (param) {
          if (isProRequiredForPage(limited, page)) {
            Curry._1(redirectToMissions, undefined);
          }
          
        }), [
        limited,
        page
      ]);
  return [
          (function (param) {
              if (limited && isProRequiredForPage(limited, param)) {
                return Curry._1(onOpenModal, undefined);
              } else {
                return Curry._1(redirect, param);
              }
            }),
          onToggleModal,
          showProRequiredModal
        ];
}

var context = React.createContext(function (param) {
      return false;
    });

function use(param) {
  return React.useContext(context);
}

var Api = {
  name: "ProRequired"
};

var Ctx = ContextMaker$LiteracyplanetClientFlags.Make(Api);

function ProRequired(props) {
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var limited = Curry._1(hasFlag, "AuthStarterAccess");
  var match = useForMain(limited, props.page, props.redirect, "Talk with your teacher about this LiteracyPlanet Pro feature.");
  return React.createElement(Ctx.Provider.make, {
              value: (function (param) {
                  return isProRequiredForItem(limited, param);
                }),
              children: Curry._3(props.children, match[0], match[1], match[2])
            });
}

var make = ProRequired;

export {
  blacklistItem ,
  blacklist ,
  isProRequiredForPage ,
  isProRequiredForItem ,
  useForMain ,
  context ,
  use ,
  Api ,
  Ctx ,
  make ,
}
/* context Not a pure module */
