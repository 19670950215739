// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import * as Theme$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/Theme.res.mjs";
import * as MobileDetect$LiteracyplanetClientFlags from "@literacyplanet/client-flags/src/MobileDetect.res.mjs";

function s(prim) {
  return prim;
}

var dropShadowColor = Css.hex("000000");

var planetColor = Css.rgba(121, 206, 255, {
      NAME: "num",
      VAL: 0.7
    });

var chestColor = Css.rgba(255, 236, 168, {
      NAME: "num",
      VAL: 0.7
    });

var avatarColor = Css.rgba(205, 252, 137, {
      NAME: "num",
      VAL: 0.7
    });

var bookColor = Css.rgba(186, 220, 186, {
      NAME: "num",
      VAL: 0.7
    });

var letteraColor = Css.rgba(181, 224, 255, {
      NAME: "num",
      VAL: 0.7
    });

var dropdownColor = Css.rgba(13, 156, 229, {
      NAME: "num",
      VAL: 0.9
    });

var dropdownDividerColor = Css.rgba(2, 58, 184, {
      NAME: "num",
      VAL: 0.1
    });

var dropdownHoverColor = Css.hex("1AACF6");

var primaryBlue = Css.rgba(13, 156, 229, {
      NAME: "num",
      VAL: 1.0
    });

var darkBlue = Css.rgba(25, 79, 120, {
      NAME: "num",
      VAL: 1.0
    });

var defaultFonts = {
  hd: {
    NAME: "custom",
    VAL: "Quicksand"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "Lato"
    },
    tl: {
      hd: {
        NAME: "custom",
        VAL: "Arial"
      },
      tl: /* [] */0
    }
  }
};

var dims = MobileDetect$LiteracyplanetClientFlags.getDimensions();

function toVh(pixel) {
  return Css.vh(pixel / dims.height * 100.0);
}

function toVw(pixel) {
  return Css.vw(pixel / dims.width * 100.0);
}

var r = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var css = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: /* [] */0
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: r,
      tl: {
        hd: css,
        tl: {
          hd: "HeaderPanel",
          tl: /* [] */0
        }
      }
    });

var HeaderPanel = {
  r: r,
  css: css,
  className: className
};

var r$1 = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var css$1 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.display(Css.inlineBlock),
        tl: /* [] */0
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: r$1,
      tl: {
        hd: css$1,
        tl: {
          hd: "HeaderSubPanel",
          tl: /* [] */0
        }
      }
    });

var HeaderSubPanel = {
  r: r$1,
  css: css$1,
  className: className$1
};

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        20
      ],
      tl: {
        hd: [
          Css.marginRight,
          20
        ],
        tl: /* [] */0
      }
    });

var css$2 = Curry._1(Css.style, /* [] */0);

var className$2 = Curry._1(Css.merge, {
      hd: r$2,
      tl: {
        hd: css$2,
        tl: {
          hd: "HeaderSubSubPanel",
          tl: /* [] */0
        }
      }
    });

var HeaderSubSubPanel = {
  r: r$2,
  css: css$2,
  className: className$2
};

var css$3 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.position(Css.relative),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: /* [] */0
          }
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: "HeaderCenter",
        tl: /* [] */0
      }
    });

var HeaderCenter = {
  css: css$3,
  className: className$3
};

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.left,
        0
      ],
      tl: {
        hd: [
          Css.bottom,
          0
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderRadius,
            22
          ],
          tl: {
            hd: [
              Css.height,
              45
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$4 = Curry._1(Css.style, {
      hd: Css.zIndex(2),
      tl: {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.backgroundColor(primaryBlue),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridAutoFlow(Css.column),
                tl: {
                  hd: Css.gridAutoColumns(Css.fr(1.0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var className$4 = Curry._1(Css.merge, {
      hd: r$3,
      tl: {
        hd: css$4,
        tl: {
          hd: "HeaderBar",
          tl: /* [] */0
        }
      }
    });

var HeaderBar = {
  r: r$3,
  css: css$4,
  className: className$4
};

var r$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Theme$LiteracyplanetClientFlags.gridAutoColumns,
        92
      ],
      tl: {
        hd: [
          Css.height,
          100
        ],
        tl: /* [] */0
      }
    });

var css$5 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridAutoFlow(Css.column),
        tl: /* [] */0
      }
    });

var className$5 = Curry._1(Css.merge, {
      hd: r$4,
      tl: {
        hd: css$5,
        tl: {
          hd: "HeaderList",
          tl: /* [] */0
        }
      }
    });

var HeaderList = {
  r: r$4,
  css: css$5,
  className: className$5
};

function rows(large) {
  return Theme$LiteracyplanetClientFlags.resizeGridRows({
              hd: large ? ({
                    NAME: "ratio",
                    VAL: 72
                  }) : ({
                    NAME: "ratio",
                    VAL: 60
                  }),
              tl: {
                hd: {
                  NAME: "css",
                  VAL: Css.fr(1.0)
                },
                tl: /* [] */0
              }
            });
}

function r$5(large) {
  return Theme$LiteracyplanetClientFlags.resize({
              hd: [
                Theme$LiteracyplanetClientFlags.fontSize,
                large ? 14 : 12
              ],
              tl: {
                hd: [
                  Css.height,
                  100
                ],
                tl: {
                  hd: [
                    Css.width,
                    92
                  ],
                  tl: {
                    hd: [
                      Theme$LiteracyplanetClientFlags.paddingTop,
                      large ? 0 : 10
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function css$6(large, highlightColor) {
  return Curry._1(Css.style, {
              hd: Css.position(Css.relative),
              tl: {
                hd: Css.fontFamilies(defaultFonts),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.fontWeight(Css.bold),
                    tl: {
                      hd: Css.justifyContent(Css.center),
                      tl: {
                        hd: Css.textAlign(Css.center),
                        tl: {
                          hd: Css.zIndex(10),
                          tl: {
                            hd: Css.color(Css.white),
                            tl: {
                              hd: Css.boxSizing(Css.borderBox),
                              tl: {
                                hd: large ? Css.selector("& svg", {
                                        hd: Css.filter({
                                              hd: {
                                                NAME: "dropShadow",
                                                VAL: [
                                                  Css.vh(0.0),
                                                  Css.vh(0.1),
                                                  Css.vh(0.5),
                                                  dropShadowColor
                                                ]
                                              },
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }) : Css.selector("& svg", {
                                        hd: Css.filter({
                                              hd: {
                                                NAME: "dropShadow",
                                                VAL: [
                                                  Css.vh(0.0),
                                                  Css.vh(0.0),
                                                  Css.vh(0.0),
                                                  dropShadowColor
                                                ]
                                              },
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.cursor(Css.pointer),
                                        tl: {
                                          hd: large ? Css.selector("& svg", {
                                                  hd: Css.filter({
                                                        hd: {
                                                          NAME: "dropShadow",
                                                          VAL: [
                                                            Css.vh(0.0),
                                                            Css.vh(0.1),
                                                            Css.vh(0.5),
                                                            dropShadowColor
                                                          ]
                                                        },
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }) : Css.selector("& svg", {
                                                  hd: Css.filter({
                                                        hd: {
                                                          NAME: "dropShadow",
                                                          VAL: [
                                                            Css.vh(0.0),
                                                            Css.vh(0.0),
                                                            Css.vh(0.5),
                                                            highlightColor
                                                          ]
                                                        },
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function className$6(large, highlightColor) {
  return Curry._1(Css.merge, {
              hd: rows(large),
              tl: {
                hd: r$5(large),
                tl: {
                  hd: css$6(large, highlightColor),
                  tl: {
                    hd: "HeaderItem",
                    tl: /* [] */0
                  }
                }
              }
            });
}

var HeaderItem = {
  rows: rows,
  r: r$5,
  css: css$6,
  className: className$6
};

var css$7 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.top(Css.zero),
        tl: {
          hd: Css.right(Css.zero),
          tl: {
            hd: Css.height(Css.pct(100.0)),
            tl: {
              hd: Css.paddingTop(Css.pct(8.0)),
              tl: {
                hd: Css.paddingRight(Css.pct(12.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$7 = Curry._1(Css.merge, {
      hd: css$7,
      tl: {
        hd: "HeaderLockOverlay",
        tl: /* [] */0
      }
    });

var HeaderLockOverlay = {
  css: css$7,
  className: className$7
};

var css$8 = Curry._1(Css.style, {
      hd: Css.height(Css.pct(25.0)),
      tl: /* [] */0
    });

var className$8 = Curry._1(Css.merge, {
      hd: css$8,
      tl: {
        hd: "LockSmallIcon",
        tl: /* [] */0
      }
    });

var LockSmallIcon = {
  css: css$8,
  className: className$8
};

var css$9 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.height(Css.pct(100.0)),
        tl: /* [] */0
      }
    });

var className$9 = Curry._1(Css.merge, {
      hd: css$9,
      tl: {
        hd: "MissionIcon",
        tl: /* [] */0
      }
    });

var MissionIcon = {
  css: css$9,
  className: className$9
};

var css$10 = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: Css.fontSize(Css.em(1.0)),
        tl: /* [] */0
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: css$10,
      tl: {
        hd: "MissionText",
        tl: /* [] */0
      }
    });

var MissionText = {
  css: css$10,
  className: className$10
};

var r$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginTop,
        32
      ],
      tl: {
        hd: [
          Css.marginLeft,
          (Theme$LiteracyplanetClientFlags.maximumWidth / 2 | 0) - 86 | 0
        ],
        tl: /* [] */0
      }
    });

var css$11 = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.position(Css.fixed),
        tl: {
          hd: Css.left(Css.pct(50.0)),
          tl: /* [] */0
        }
      }
    });

var className$11 = Curry._1(Css.merge, {
      hd: r$6,
      tl: {
        hd: css$11,
        tl: {
          hd: "MenuPanel",
          tl: /* [] */0
        }
      }
    });

var MenuPanel = {
  r: r$6,
  css: css$11,
  className: className$11
};

var r$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        92
      ],
      tl: {
        hd: [
          Theme$LiteracyplanetClientFlags.borderBottomLeftRadius,
          5
        ],
        tl: {
          hd: [
            Theme$LiteracyplanetClientFlags.borderBottomRightRadius,
            5
          ],
          tl: /* [] */0
        }
      }
    });

var dropdown = Curry._1(Css.keyframes, {
      hd: [
        0,
        {
          hd: Css.transform(Css.scaleY(0.0)),
          tl: /* [] */0
        }
      ],
      tl: {
        hd: [
          100,
          {
            hd: Css.transform(Css.scaleY(1.0)),
            tl: /* [] */0
          }
        ],
        tl: /* [] */0
      }
    });

var css$12 = Curry._1(Css.style, {
      hd: Css.height(Css.auto),
      tl: {
        hd: Css.animationName(dropdown),
        tl: {
          hd: Css.animationDuration({
                NAME: "ms",
                VAL: 300.0
              }),
          tl: {
            hd: Css.animationTimingFunction(Css.easeInOut),
            tl: {
              hd: Css.transformOrigin(Css.px(0), Css.px(0)),
              tl: {
                hd: Css.position(Css.relative),
                tl: {
                  hd: Css.zIndex(5),
                  tl: {
                    hd: Css.display(Css.grid),
                    tl: {
                      hd: Css.gridAutoFlow(Css.row),
                      tl: {
                        hd: Css.backgroundColor(dropdownColor),
                        tl: {
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(2), Css.px(6), undefined, undefined, Css.rgba(0, 0, 0, {
                                        NAME: "num",
                                        VAL: 0.1
                                      }))),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$12 = Curry._1(Css.merge, {
      hd: r$7,
      tl: {
        hd: css$12,
        tl: {
          hd: "DropdownHeaderPanel",
          tl: /* [] */0
        }
      }
    });

var DropdownHeaderPanel = {
  r: r$7,
  dropdown: dropdown,
  css: css$12,
  className: className$12
};

var r$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        1
      ],
      tl: {
        hd: [
          Css.width,
          61
        ],
        tl: {
          hd: [
            Css.margin,
            0
          ],
          tl: /* [] */0
        }
      }
    });

var css$13 = Curry._1(Css.style, {
      hd: Css.opacity(0.1),
      tl: {
        hd: Css.backgroundColor(dropdownDividerColor),
        tl: {
          hd: Css.alignSelf("start"),
          tl: /* [] */0
        }
      }
    });

var className$13 = Curry._1(Css.merge, {
      hd: r$8,
      tl: {
        hd: css$13,
        tl: {
          hd: "DropdownDivider",
          tl: /* [] */0
        }
      }
    });

var DropdownDivider = {
  r: r$8,
  css: css$13,
  className: className$13
};

var rows$1 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "css",
        VAL: Css.fr(1.0)
      },
      tl: /* [] */0
    });

var cols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 18
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 53
        },
        tl: /* [] */0
      }
    });

var r$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        92
      ],
      tl: {
        hd: [
          Css.height,
          36
        ],
        tl: {
          hd: [
            Css.gridColumnGap,
            5
          ],
          tl: {
            hd: [
              Theme$LiteracyplanetClientFlags.paddingLeft,
              8
            ],
            tl: {
              hd: [
                Theme$LiteracyplanetClientFlags.paddingRight,
                8
              ],
              tl: {
                hd: [
                  Theme$LiteracyplanetClientFlags.paddingTop,
                  5
                ],
                tl: {
                  hd: [
                    Theme$LiteracyplanetClientFlags.paddingBottom,
                    5
                  ],
                  tl: {
                    hd: [
                      Theme$LiteracyplanetClientFlags.fontSize,
                      10
                    ],
                    tl: {
                      hd: [
                        Theme$LiteracyplanetClientFlags.borderTopWidth,
                        1
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var css$14 = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.hover({
                  hd: Theme$LiteracyplanetClientFlags.borderBottomRightRadius(toVh(5)),
                  tl: {
                    hd: Theme$LiteracyplanetClientFlags.borderBottomLeftRadius(toVh(5)),
                    tl: {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                            hd: Theme$LiteracyplanetClientFlags.borderBottomRightRadius(toVw(5)),
                            tl: {
                              hd: Theme$LiteracyplanetClientFlags.borderBottomLeftRadius(toVw(5)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display(Css.grid),
        tl: {
          hd: Css.position(Css.relative),
          tl: {
            hd: Css.boxSizing(Css.borderBox),
            tl: {
              hd: Css.fontWeight(Css.bold),
              tl: {
                hd: Css.textAlign("left"),
                tl: {
                  hd: Css.alignItems(Css.center),
                  tl: {
                    hd: Css.justifyContent(Css.center),
                    tl: {
                      hd: Css.fontFamilies(defaultFonts),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(dropdownHoverColor),
                              tl: {
                                hd: Css.cursor(Css.pointer),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.overflow(Css.hidden),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$14 = Curry._1(Css.merge, {
      hd: rows$1,
      tl: {
        hd: cols,
        tl: {
          hd: r$9,
          tl: {
            hd: css$14,
            tl: {
              hd: "DropdownHeaderItem",
              tl: /* [] */0
            }
          }
        }
      }
    });

var DropdownHeaderItem = {
  rows: rows$1,
  cols: cols,
  r: r$9,
  css: css$14,
  className: className$14
};

var divider = Curry._1(Css.style, {
      hd: Css.width(Css.pct(70.0)),
      tl: {
        hd: Css.borderColor(dropdownDividerColor),
        tl: {
          hd: Css.margin(Css.auto),
          tl: /* [] */0
        }
      }
    });

var ratio = Theme$LiteracyplanetClientFlags.resize;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

export {
  ratio ,
  maximumWidth ,
  s ,
  resizeGridCols ,
  resizeGridRows ,
  dropShadowColor ,
  planetColor ,
  chestColor ,
  avatarColor ,
  bookColor ,
  letteraColor ,
  dropdownColor ,
  dropdownDividerColor ,
  dropdownHoverColor ,
  primaryBlue ,
  darkBlue ,
  defaultFonts ,
  dims ,
  toVh ,
  toVw ,
  HeaderPanel ,
  HeaderSubPanel ,
  HeaderSubSubPanel ,
  HeaderCenter ,
  HeaderBar ,
  HeaderList ,
  HeaderItem ,
  HeaderLockOverlay ,
  LockSmallIcon ,
  MissionIcon ,
  MissionText ,
  MenuPanel ,
  DropdownHeaderPanel ,
  DropdownDivider ,
  DropdownHeaderItem ,
  divider ,
}
/* dropShadowColor Not a pure module */
