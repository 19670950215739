// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Override$LiteracyplanetClientFlags from "./Override.res.mjs";
import * as FlagsContext$LiteracyplanetClientFlags from "./FlagsContext.res.mjs";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.res.mjs";

var span = {
  padding: "8px"
};

var noStyle = {};

var selected = {
  background: "white",
  color: "black"
};

var panel = {
  background: "black",
  color: "white",
  cursor: "pointer",
  padding: "5px",
  position: "fixed",
  right: "0",
  top: "0",
  zIndex: "10000",
  opacity: ".8"
};

function LocaleOverride$LocaleSelector(props) {
  var changeLocale = props.changeLocale;
  var locale = props.locale;
  var localeLink = function (l) {
    return React.createElement("a", {
                style: l === locale ? selected : noStyle,
                onClick: (function (param) {
                    Curry._1(changeLocale, l);
                  })
              }, React.createElement("span", {
                    style: span
                  }, LocaleContext$LiteracyplanetClientFlags.toLocaleString(l)));
  };
  return React.createElement("div", {
              style: panel
            }, React.createElement("div", undefined, localeLink("en_AU"), localeLink("en_GB"), localeLink("en_US")));
}

var LocaleSelector = {
  span: span,
  noStyle: noStyle,
  selected: selected,
  panel: panel,
  make: LocaleOverride$LocaleSelector
};

function getLocaleOverride(param) {
  var api = LocaleContext$LiteracyplanetClientFlags.use();
  var settings = Override$LiteracyplanetClientFlags.loadSettings();
  return Belt_Option.mapWithDefault(Js_dict.get(settings, "locale"), api.locale, LocaleContext$LiteracyplanetClientFlags.fromLocaleString);
}

function LocaleOverride$OverrideLocale(props) {
  var localeOverride = getLocaleOverride();
  var match = React.useState(function (param) {
        return localeOverride;
      });
  var setLocale = match[1];
  var locale = match[0];
  var changeLocale = function (l) {
    Curry._1(setLocale, (function (param) {
            return l;
          }));
    Override$LiteracyplanetClientFlags.saveSettings("locale", LocaleContext$LiteracyplanetClientFlags.toLocaleString(l));
  };
  var accent = LocaleContext$LiteracyplanetClientFlags.localeToAccent(locale);
  var accentString = LocaleContext$LiteracyplanetClientFlags.toAccentString(accent);
  var localeString = LocaleContext$LiteracyplanetClientFlags.toLocaleString(locale);
  return React.createElement(LocaleContext$LiteracyplanetClientFlags.make, {
              value: {
                locale: locale,
                accent: accent,
                localeString: localeString,
                accentString: accentString
              },
              children: null
            }, React.createElement(LocaleOverride$LocaleSelector, {
                  locale: locale,
                  changeLocale: changeLocale
                }), props.children);
}

var OverrideLocale = {
  make: LocaleOverride$OverrideLocale
};

function LocaleOverride(props) {
  var children = props.children;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use();
  var testingLocales = Curry._1(hasFlag, "DeveloperTestingLocales");
  if (testingLocales) {
    return React.createElement(LocaleOverride$OverrideLocale, {
                children: children
              });
  } else {
    return children;
  }
}

var make = LocaleOverride;

export {
  LocaleSelector ,
  getLocaleOverride ,
  OverrideLocale ,
  make ,
}
/* react Not a pure module */
