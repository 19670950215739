// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.res.mjs";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.res.mjs";
import * as NextArrow$LiteracyplanetTupperware from "./NextArrow.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as NextButtonStatesHook$LiteracyplanetTupperware from "./NextButtonStatesHook.res.mjs";
import * as K2MarkButtonStatesHook$LiteracyplanetTupperware from "./K2MarkButtonStatesHook.res.mjs";
import * as BehaviourActivityMarkClassNames$LiteracyplanetTupperware from "./BehaviourActivityMarkClassNames.res.mjs";

function K2Answers$Answers(props) {
  return React.createElement("div", {
              ref: Caml_option.some(props.divRef),
              className: Flavor$LiteracyplanetTupperware.K2Answers.Answers.className
            }, props.children);
}

var Answers = {
  make: K2Answers$Answers
};

function K2Answers$SubmitCheckbox(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 512 445",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "202.6,445 0,258.4 70.5,181.8 192,293.7 431.4,1 512,66.9 "
                }));
}

var SubmitCheckbox = {
  make: K2Answers$SubmitCheckbox
};

function K2Answers$Tick(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 292 254",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "115.7 253.1 0.6 147.1 40.6 103.6 109.6 167.1 245.6 0.9 291.3 38.3"
                }));
}

var Tick = {
  make: K2Answers$Tick
};

function K2Answers$Cross(props) {
  return React.createElement("svg", {
              className: props.className,
              viewBox: "0 0 244 244",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "243.2 202.3 202.3 243.2 122 162.9 41.7 243.2 0.8 202.3 81.1 122 0.8 41.7 41.7 0.8 122 81.1 202.3 0.8 243.2 41.7 162.9 122"
                }));
}

var Cross = {
  make: K2Answers$Cross
};

function K2Answers$MarkStatusPanel(props) {
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.MarkStatusPanel.className, props.className)
            }, props.children);
}

var MarkStatusPanel = {
  make: K2Answers$MarkStatusPanel
};

function K2Answers$Button(props) {
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.Button.className, props.className),
              onClick: props.onClick
            }, props.children);
}

var Button = {
  make: K2Answers$Button
};

function K2Answers$MarkButton(props) {
  return React.createElement(K2Answers$Button, {
              onClick: props.onClick,
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.MarkButton.className, props.className),
              children: null
            }, React.createElement("div", undefined, "Submit"), React.createElement(K2Answers$SubmitCheckbox, {
                  className: Flavor$LiteracyplanetTupperware.K2Answers.iconCss
                }));
}

var MarkButton = {
  make: K2Answers$MarkButton
};

var enabledCss = Flavor$LiteracyplanetTupperware.K2Answers.BehaviourMarkButton.enabledCss;

function K2Answers$BehaviourMarkButton(props) {
  var markState = K2MarkButtonStatesHook$LiteracyplanetTupperware.use();
  if (typeof markState !== "object") {
    if (markState === "QuestionNotAttempted") {
      return React.createElement(K2Answers$MarkButton, {
                  className: Flavor$LiteracyplanetTupperware.K2Answers.disabledCss
                });
    } else {
      return null;
    }
  }
  markState.TAG === "QuestionAttempted";
  var cb = markState._0;
  return React.createElement(K2Answers$MarkButton, {
              onClick: (function (param) {
                  Curry._1(cb, undefined);
                }),
              className: enabledCss
            });
}

var BehaviourMarkButton = {
  enabledCss: enabledCss,
  make: K2Answers$BehaviourMarkButton
};

function K2Answers$NextButton(props) {
  return React.createElement(K2Answers$Button, {
              onClick: props.onClick,
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.NextButton.className, props.className),
              children: null
            }, React.createElement("div", undefined, props.label), React.createElement(NextArrow$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.K2Answers.iconCss
                }));
}

var NextButton = {
  make: K2Answers$NextButton
};

var enabledCss$1 = Flavor$LiteracyplanetTupperware.K2Answers.BehaviourNextButton.enabledCss;

function K2Answers$BehaviourNextButton(props) {
  var markState = NextButtonStatesHook$LiteracyplanetTupperware.use();
  if (typeof markState !== "object") {
    if (markState === "ActivityEnded") {
      return React.createElement(K2Answers$NextButton, {
                  label: "Complete",
                  className: Flavor$LiteracyplanetTupperware.K2Answers.disabledCss
                });
    } else {
      return null;
    }
  }
  if (markState.TAG === "FinishActivity") {
    var cb = markState._0;
    return React.createElement(K2Answers$NextButton, {
                label: "Complete",
                onClick: (function (param) {
                    Curry._1(cb, undefined);
                  }),
                className: enabledCss$1
              });
  }
  var cb$1 = markState._0;
  return React.createElement(K2Answers$NextButton, {
              label: "Next",
              onClick: (function (param) {
                  Curry._1(cb$1, undefined);
                }),
              className: enabledCss$1
            });
}

var BehaviourNextButton = {
  enabledCss: enabledCss$1,
  make: K2Answers$BehaviourNextButton
};

var setScrollTop = ((el, num) => el.scrollTo(0, num));

function useSetScrollOnMarkedAndNewQuestion(param) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var divRef = React.useRef(null);
  var marked = Reducer$LiteracyplanetTupperware.isMarked(match.currentQuestionStep);
  React.useEffect((function (param) {
          Belt_Option.map(Caml_option.nullable_to_opt(divRef.current), (function (div) {
                  if (marked) {
                    return setScrollTop(div, 100000);
                  } else {
                    return setScrollTop(div, 0);
                  }
                }));
        }), [
        match.currentQuestionIndex,
        marked
      ]);
  return divRef;
}

var correctClassName = Flavor$LiteracyplanetTupperware.K2Answers.correctClassName;

var incorrectClassName = Flavor$LiteracyplanetTupperware.K2Answers.incorrectClassName;

var markClassname = Flavor$LiteracyplanetTupperware.K2Answers.markClassname;

var textLabelClassname = Flavor$LiteracyplanetTupperware.K2Answers.textLabelClassname;

var submitAnswerPanel = Flavor$LiteracyplanetTupperware.K2Answers.submitAnswerPanel;

function K2Answers(props) {
  var divRef = useSetScrollOnMarkedAndNewQuestion();
  return React.createElement(K2Answers$Answers, {
              divRef: divRef,
              children: null
            }, props.children, React.createElement(BehaviourActivityMarkClassNames$LiteracyplanetTupperware.make, {
                  children: null
                }, React.createElement(K2Answers$MarkStatusPanel, {
                      className: correctClassName,
                      children: null
                    }, React.createElement(K2Answers$Tick, {
                          className: markClassname
                        }), React.createElement("div", {
                          className: textLabelClassname
                        }, "Correct")), React.createElement(K2Answers$MarkStatusPanel, {
                      className: incorrectClassName,
                      children: null
                    }, React.createElement(K2Answers$Cross, {
                          className: markClassname
                        }), React.createElement("div", {
                          className: textLabelClassname
                        }, "Incorrect")), React.createElement("div", {
                      className: submitAnswerPanel
                    }, React.createElement(K2Answers$BehaviourMarkButton, {}), React.createElement(K2Answers$BehaviourNextButton, {}))));
}

var make = K2Answers;

var $$default = K2Answers;

export {
  Answers ,
  SubmitCheckbox ,
  Tick ,
  Cross ,
  MarkStatusPanel ,
  Button ,
  MarkButton ,
  BehaviourMarkButton ,
  NextButton ,
  BehaviourNextButton ,
  setScrollTop ,
  useSetScrollOnMarkedAndNewQuestion ,
  correctClassName ,
  incorrectClassName ,
  markClassname ,
  textLabelClassname ,
  submitAnswerPanel ,
  make ,
  $$default as default,
}
/* react Not a pure module */
