// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BehaviourQuestionVisible$LiteracyplanetTupperware from "./BehaviourQuestionVisible.res.mjs";

var make = BehaviourQuestionVisible$LiteracyplanetTupperware.make;

var $$default = BehaviourQuestionVisible$LiteracyplanetTupperware.make;

export {
  make ,
  $$default as default,
}
/* BehaviourQuestionVisible-LiteracyplanetTupperware Not a pure module */
