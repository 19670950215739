// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.res.mjs";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.res.mjs";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.res.mjs";
import * as NextArrow$LiteracyplanetTupperware from "./NextArrow.res.mjs";
import * as PrevArrow$LiteracyplanetTupperware from "./PrevArrow.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as MarkButtonStatesHook$LiteracyplanetTupperware from "./MarkButtonStatesHook.res.mjs";
import * as SecondarySummaryIcon$LiteracyplanetTupperware from "./SecondarySummaryIcon.res.mjs";
import * as SkilfulEndScreenContainer$LiteracyplanetTupperware from "./SkilfulEndScreenContainer.res.mjs";
import * as BehaviourActivityMarkClassNames$LiteracyplanetTupperware from "./BehaviourActivityMarkClassNames.res.mjs";

function SkilfulQuestionContent$SecondarySummaryButton(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var onGotoEndScreen = match.onGotoEndScreen;
  return React.createElement(SecondarySummaryIcon$LiteracyplanetTupperware.make, {
              onClick: (function (param) {
                  Curry._1(onGotoEndScreen, undefined);
                }),
              className: Skillet$LiteracyplanetTupperware.SummaryIcon.className
            });
}

var SecondarySummaryButton = {
  make: SkilfulQuestionContent$SecondarySummaryButton
};

function SkilfulQuestionContent$ActivityProgressBar(props) {
  var percent = props.question / props.questionCount * 100.0;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ActivityProgressBar.className(percent)
            });
}

var ActivityProgressBar = {
  make: SkilfulQuestionContent$ActivityProgressBar
};

function SkilfulQuestionContent$ActivityProgressMeter(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ActivityProgressMeter.className
            }, React.createElement(SkilfulQuestionContent$ActivityProgressBar, {
                  question: props.question,
                  questionCount: props.questionCount
                }));
}

var ActivityProgressMeter = {
  make: SkilfulQuestionContent$ActivityProgressMeter
};

function SkilfulQuestionContent$ActivityProgress(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var questionCount = match.questionCount;
  var question = match.currentQuestionIndex + 1 | 0;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ActivityProgress.className
            }, React.createElement("div", undefined, String(question) + " of " + String(questionCount)), React.createElement(SkilfulQuestionContent$ActivityProgressMeter, {
                  question: question,
                  questionCount: questionCount
                }));
}

var ActivityProgress = {
  make: SkilfulQuestionContent$ActivityProgress
};

function SkilfulQuestionContent$CorrectCount(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.CorrectCount.className
            }, React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.CorrectCountIcon.className
                }), React.createElement("div", undefined, String(props.questionsCorrect)));
}

var CorrectCount = {
  make: SkilfulQuestionContent$CorrectCount
};

function SkilfulQuestionContent$IncorrectCount(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.InorrectCount.className
            }, React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.IncorrectCountIcon.className
                }), React.createElement("div", undefined, String(props.questionsIncorrect)));
}

var IncorrectCount = {
  make: SkilfulQuestionContent$IncorrectCount
};

function SkilfulQuestionContent$MiddleContainer(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.MiddleContainer.className
            }, React.createElement(SkilfulQuestionContent$ActivityProgress, {}), React.createElement(SkilfulQuestionContent$CorrectCount, {
                  questionsCorrect: match.questionsCorrect
                }), React.createElement(SkilfulQuestionContent$IncorrectCount, {
                  questionsIncorrect: match.questionsIncorrect
                }));
}

var MiddleContainer = {
  make: SkilfulQuestionContent$MiddleContainer
};

function SkilfulQuestionContent$ContentHeader(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ContentHeader.className
            }, React.createElement(SkilfulQuestionContent$SecondarySummaryButton, {}), React.createElement(SkilfulQuestionContent$MiddleContainer, {}));
}

var ContentHeader = {
  make: SkilfulQuestionContent$ContentHeader
};

function SkilfulQuestionContent$Button(props) {
  return React.createElement("div", {
              className: props.className,
              onClick: props.onClick
            }, props.children);
}

var Button = {
  make: SkilfulQuestionContent$Button
};

function SkilfulQuestionContent$MarkButton(props) {
  return React.createElement(SkilfulQuestionContent$Button, {
              onClick: props.onClick,
              className: props.className,
              children: "Submit"
            });
}

var MarkButton = {
  make: SkilfulQuestionContent$MarkButton
};

var enabledCss = Skillet$LiteracyplanetTupperware.BehaviourMarkButton.enabledCss;

function SkilfulQuestionContent$BehaviourMarkButton(props) {
  var markState = MarkButtonStatesHook$LiteracyplanetTupperware.use();
  if (typeof markState !== "object") {
    switch (markState) {
      case "QuestionNotAttempted" :
          return React.createElement(SkilfulQuestionContent$MarkButton, {
                      className: Skillet$LiteracyplanetTupperware.BehaviourMarkButton.disabledCss,
                      onClick: (function (param) {
                          
                        })
                    });
      case "QuestionMarked" :
      case "AssessmentMode" :
          return null;
      
    }
  } else {
    var cb = markState._0;
    return React.createElement(SkilfulQuestionContent$MarkButton, {
                className: enabledCss,
                onClick: (function (param) {
                    Curry._1(cb, undefined);
                  })
              });
  }
}

var BehaviourMarkButton = {
  enabledCss: enabledCss,
  make: SkilfulQuestionContent$BehaviourMarkButton
};

function SkilfulQuestionContent$BackButton(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.BackButton.className,
              onClick: props.onClick
            }, React.createElement(PrevArrow$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.BackArrowIcon.className
                }), React.createElement("div", undefined, "Back"));
}

var BackButton = {
  make: SkilfulQuestionContent$BackButton
};

function SkilfulQuestionContent$BehaviourBackButton(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  if (match.currentQuestionIndex === 0) {
    return React.createElement("div", undefined);
  }
  var onPreviousQuestion = match.onPreviousQuestion;
  return React.createElement(SkilfulQuestionContent$BackButton, {
              onClick: (function (param) {
                  Curry._1(onPreviousQuestion, undefined);
                })
            });
}

var BehaviourBackButton = {
  make: SkilfulQuestionContent$BehaviourBackButton
};

function SkilfulQuestionContent$NextButton(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.NextButton.className,
              onClick: props.onClick
            }, React.createElement("div", undefined, "Next"), React.createElement(NextArrow$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.NextArrowIcon.className
                }));
}

var NextButton = {
  make: SkilfulQuestionContent$NextButton
};

function SkilfulQuestionContent$BehaviourNextButton(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var onNextQuestion = match.onNextQuestion;
  return React.createElement(SkilfulQuestionContent$NextButton, {
              onClick: (function (param) {
                  Curry._1(onNextQuestion, undefined);
                })
            });
}

var BehaviourNextButton = {
  make: SkilfulQuestionContent$BehaviourNextButton
};

function SkilfulQuestionContent$ShowCorrectAnswerButton(props) {
  return React.createElement(SkilfulQuestionContent$Button, {
              onClick: props.onClick,
              className: Skillet$LiteracyplanetTupperware.ShowCorrectAnswerButton.className,
              children: props.text
            });
}

var ShowCorrectAnswerButton = {
  make: SkilfulQuestionContent$ShowCorrectAnswerButton
};

function SkilfulQuestionContent$BehaviourShowCorrectAnswerButton(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  var onToggleShowCorrectAnswer = match.onToggleShowCorrectAnswer;
  var currentQuestionIndex = match.currentQuestionIndex;
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, currentQuestionIndex);
  var marked = Curry._1(match.getMarkState, currentQuestionIndex);
  var onClick = function (param) {
    Curry._1(onToggleShowCorrectAnswer, undefined);
  };
  if (answerVisible !== undefined) {
    if (answerVisible) {
      return React.createElement(SkilfulQuestionContent$ShowCorrectAnswerButton, {
                  text: "Show My Answer",
                  onClick: onClick
                });
    }
    if (typeof marked === "object" && !marked._0.questionCorrect) {
      return React.createElement(SkilfulQuestionContent$ShowCorrectAnswerButton, {
                  text: "Show Correct Answer",
                  onClick: onClick
                });
    }
    
  }
  if (typeof marked !== "object") {
    return null;
  } else {
    return React.createElement("div", undefined);
  }
}

var BehaviourShowCorrectAnswerButton = {
  make: SkilfulQuestionContent$BehaviourShowCorrectAnswerButton
};

function SkilfulQuestionContent$GridJustifyCenter(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.GridJustifyCenter.className
            }, props.children);
}

var GridJustifyCenter = {
  make: SkilfulQuestionContent$GridJustifyCenter
};

function SkilfulQuestionContent$ContentFooter(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ContentFooter.className
            }, React.createElement(SkilfulQuestionContent$BehaviourBackButton, {}), React.createElement(SkilfulQuestionContent$GridJustifyCenter, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$BehaviourMarkButton, {}), React.createElement(SkilfulQuestionContent$BehaviourShowCorrectAnswerButton, {})), React.createElement(SkilfulQuestionContent$BehaviourNextButton, {}));
}

var ContentFooter = {
  make: SkilfulQuestionContent$ContentFooter
};

function SkilfulQuestionContent$MarkStatusPanel(props) {
  return React.createElement("div", {
              className: props.className
            }, props.children);
}

var MarkStatusPanel = {
  make: SkilfulQuestionContent$MarkStatusPanel
};

function SkilfulQuestionContent$Content(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.Content.className
            }, React.createElement(BehaviourActivityMarkClassNames$LiteracyplanetTupperware.make, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$MarkStatusPanel, {
                      className: Skillet$LiteracyplanetTupperware.MarkStatusPanelCorrect.className,
                      children: React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.CorrectCountIcon.className
                          })
                    }), React.createElement(SkilfulQuestionContent$MarkStatusPanel, {
                      className: Skillet$LiteracyplanetTupperware.MarkStatusPanelIncorrect.className,
                      children: React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.IncorrectCountIcon.className
                          })
                    })), props.children);
}

var Content = {
  make: SkilfulQuestionContent$Content
};

function SkilfulQuestionContent$ContentContainer(props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ContentContainer.className
            }, props.children);
}

var ContentContainer = {
  make: SkilfulQuestionContent$ContentContainer
};

function SkilfulQuestionContent(props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use();
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulContentContainer.className
            }, React.createElement(SkilfulQuestionContent$ContentContainer, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$ContentHeader, {}), React.createElement(SkilfulQuestionContent$Content, {
                      children: props.children
                    }), React.createElement(SkilfulQuestionContent$ContentFooter, {})), match.isEndscreenShowing ? React.createElement(SkilfulEndScreenContainer$LiteracyplanetTupperware.make, {}) : null);
}

var make = SkilfulQuestionContent;

var $$default = SkilfulQuestionContent;

export {
  SecondarySummaryButton ,
  ActivityProgressBar ,
  ActivityProgressMeter ,
  ActivityProgress ,
  CorrectCount ,
  IncorrectCount ,
  MiddleContainer ,
  ContentHeader ,
  Button ,
  MarkButton ,
  BehaviourMarkButton ,
  BackButton ,
  BehaviourBackButton ,
  NextButton ,
  BehaviourNextButton ,
  ShowCorrectAnswerButton ,
  BehaviourShowCorrectAnswerButton ,
  GridJustifyCenter ,
  ContentFooter ,
  MarkStatusPanel ,
  Content ,
  ContentContainer ,
  make ,
  $$default as default,
}
/* react Not a pure module */
