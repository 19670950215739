// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactDom from "react-dom";

function HtmlBody(props) {
  return ReactDom.createPortal(props.children, document.body);
}

var make = HtmlBody;

export {
  make ,
}
/* react-dom Not a pure module */
