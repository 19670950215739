// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.res.mjs";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.res.mjs";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.res.mjs";

function BehaviourMultiChoiceGroupedOptionContainer(props) {
  var responseOptionId = props.responseOptionId;
  var questionIndex = props.questionIndex;
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch();
  var dispatch = match[1];
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use();
  var responseType = Reducer$LiteracyplanetTupperware.buildResponseType(props.group, responseOptionId);
  var marked = Reducer$LiteracyplanetTupperware.getMarkType(Curry._1(match$1.getStep, questionIndex), responseOptionId);
  var selected = Curry._2(match$1.isResponseSelected, questionIndex, responseOptionId);
  var tmp;
  if (selected) {
    switch (marked) {
      case "Correct" :
          tmp = "selected correct";
          break;
      case "Wrong" :
          tmp = "selected wrong";
          break;
      case "Unanswered" :
          tmp = "selected";
          break;
      
    }
  } else {
    switch (marked) {
      case "Correct" :
          tmp = "correct";
          break;
      case "Wrong" :
          tmp = "unselected wrong";
          break;
      case "Unanswered" :
          tmp = "unselected";
          break;
      
    }
  }
  return React.createElement("div", {
              className: tmp,
              onClick: (function (param) {
                  Curry._1(dispatch, {
                        TAG: "SetQuestionState",
                        _0: questionIndex,
                        _1: responseType
                      });
                })
            }, props.children);
}

var make = BehaviourMultiChoiceGroupedOptionContainer;

var $$default = BehaviourMultiChoiceGroupedOptionContainer;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
