// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as Md5$LiteracyplanetClientLibsActivity from "./Md5.res.mjs";

var basicResponseSchema = S$RescriptSchema.object(function (s) {
      return {
              id: s.f("id", S$RescriptSchema.string),
              name: s.f("name", S$RescriptSchema.string),
              questionIndex: s.f("questionIndex", S$RescriptSchema.$$int),
              answers: s.f("answers", S$RescriptSchema.string),
              correct: s.f("correct", S$RescriptSchema.bool)
            };
    });

var batmanResponseSchema = S$RescriptSchema.object(function (s) {
      return {
              answer: s.f("answer", S$RescriptSchema.string),
              questionIndex: s.f("questionIndex", S$RescriptSchema.$$int),
              correct: s.f("correct", S$RescriptSchema.bool)
            };
    });

var schema = S$RescriptSchema.union([
      S$RescriptSchema.object(function (s) {
            s.tag("responseType", "BasicResponse");
            return {
                    TAG: "BasicResponse",
                    _0: {
                      responses: s.f("responses", S$RescriptSchema.array(basicResponseSchema))
                    }
                  };
          }),
      S$RescriptSchema.object(function (s) {
            s.tag("responseType", "BatmanResponse");
            return {
                    TAG: "BatmanResponse",
                    _0: {
                      responses: s.f("responses", S$RescriptSchema.array(batmanResponseSchema))
                    }
                  };
          }),
      S$RescriptSchema.object(function (param) {
            return "NotSet";
          }),
      S$RescriptSchema.to(S$RescriptSchema.unknown, (function (param) {
              return "NotSet";
            }))
    ]);

function decode(__x) {
  return S$RescriptSchema.parseJsonStringOrThrow(__x, schema);
}

function encode(__x) {
  return S$RescriptSchema.reverseConvertToJsonStringOrThrow(__x, schema, undefined);
}

function decodeIfMdFived(persistedState, md5) {
  var match = Md5$LiteracyplanetClientLibsActivity.get(md5);
  if (match !== undefined && persistedState !== undefined) {
    return S$RescriptSchema.parseJsonStringOrThrow(persistedState, schema);
  } else {
    return "NotSet";
  }
}

var responseStateData = {
  contents: "NotSet"
};

function initResponses(stringData, md5) {
  responseStateData.contents = decodeIfMdFived(stringData, md5);
}

function stringifyResponses(param) {
  var d;
  try {
    d = encode(responseStateData.contents);
  }
  catch (_e){
    return ;
  }
  return d;
}

function collectResponses(t) {
  var tmp;
  if (typeof t !== "object" || t.TAG !== "Orig") {
    tmp = responseStateData.contents;
  } else {
    var match = t._0;
    var r_id = match.id;
    var r_name = match.name;
    var r_questionIndex = match.questionIndex;
    var r_answers = match.answers;
    var r_correct = match.correct;
    var r = {
      id: r_id,
      name: r_name,
      questionIndex: r_questionIndex,
      answers: r_answers,
      correct: r_correct
    };
    var match$1 = responseStateData.contents;
    tmp = typeof match$1 !== "object" ? ({
          TAG: "BasicResponse",
          _0: {
            responses: [r]
          }
        }) : (
        match$1.TAG === "BasicResponse" ? ({
              TAG: "BasicResponse",
              _0: {
                responses: Belt_Array.concat(match$1._0.responses, [r])
              }
            }) : responseStateData.contents
      );
  }
  responseStateData.contents = tmp;
  return stringifyResponses();
}

function collectBatchResponses(responses) {
  var tmp;
  tmp = typeof responses !== "object" ? responseStateData.contents : ({
        TAG: "BatmanResponse",
        _0: {
          responses: Belt_Array.map(responses._0, (function (param) {
                  return {
                          answer: param.answer,
                          questionIndex: param.questionIndex,
                          correct: param.correct
                        };
                }))
        }
      });
  responseStateData.contents = tmp;
  return stringifyResponses();
}

function use(persistedState, md5) {
  React.useEffect((function () {
          initResponses(persistedState, md5);
          return (function () {
                    responseStateData.contents = "NotSet";
                  });
        }), []);
  return {
          collectResponses: collectResponses,
          collectBatchResponses: collectBatchResponses,
          stringifyResponses: stringifyResponses
        };
}

export {
  use ,
  decodeIfMdFived ,
  decode ,
}
/* basicResponseSchema Not a pure module */
